import * as React from "react";

import {ClassHelper} from "../../../../commons/helpers/ClassHelper";
import {OtherHtmlAttributes} from "../../../../framework/form/types/OtherHtmlAttributes";
import {useCallback, useEffect, useRef, useState} from "react";
import './TopNavMenu.scss'
import {Divider} from "../../../../framework/components/Divider";
import {NavLink} from "react-router-dom";

type TopNavMenuItem = TopNavMenuItem_Separator | TopNavMenuItem_Link | TopNavMenuItem_Action;

type TopNavMenuItem_Separator = 'separator';
type TopNavMenuItem_Link = {
    to:string
    label:string
};
type TopNavMenuItem_Action = {
    action:() => void
    label:string
};

type TopNavMenuProps = {
    disabled?:boolean
    alignedToRight?:boolean
    className?:string

    header?:React.ReactNode
    items:TopNavMenuItem[]
    onClose?:() => void
}

export const TopNavMenu = ({disabled, className, header, items, alignedToRight, onClose}:TopNavMenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const backdropRef = useRef(null);

    const openClose = useCallback(() => {
        setIsOpen(prevState => {
            console.info(`TopNavMenu:openClose: ${prevState} => ${!prevState}`);
            return !prevState;
        });
    }, []);

    useEffect(() => {
        console.info(`TopNavMenu:useEffect isOpen=${isOpen}`);
        const backdrop:HTMLDivElement = backdropRef.current!;

        const keyHandler = (e:KeyboardEvent) => {
            if (['Esc' /* IE / Edge */, 'Escape'].indexOf(e.key) >= 0) {
                console.info('TopNavMenu:keyHandler escape detected');
                if (onClose) {
                    onClose();
                } else {
                    setIsOpen(false);
                }
            }
        }

        const preventClickHandler = (e:MouseEvent) => {
            console.info('TopNavMenu:preventClickHandler e.target', e.target);
            // without this, the mousedown from window is triggered,
            // leading to the menu being closed
            e.stopPropagation();
        }

        const clickHandler = (e:MouseEvent) => {
            console.info('TopNavMenu:clickHandler e.target', e.target);
            if (onClose) {
                onClose();
            } else {
                setIsOpen(false);
            }
        }

        if (backdrop !== null && isOpen) {
            // to prevent click on the menu are closing the menu
            backdrop.addEventListener("mousedown", preventClickHandler);
            
            window.addEventListener("mousedown", clickHandler);
            window.addEventListener("keyup", keyHandler);
        }

        return () => {
            if (backdrop) {
                backdrop.removeEventListener("mousedown", preventClickHandler);
            }

            window.removeEventListener("mousedown", clickHandler);
            window.removeEventListener("keyup", keyHandler);
        };
    }, [isOpen, onClose]);

    const classes = ClassHelper.combine('TopNavMenu', disabled && 'disabled', isOpen ? 'open' : 'close', alignedToRight ? 'aligned-to-right' : 'aligned-to-left', className);
    return (
        <div className={classes} ref={backdropRef}>
            <>
                <div className="TopNavMenuHeader" onClick={openClose}>
                    {header}
                </div>
                {isOpen && (
                    <div className="TopNavMenuList">
                        {items.map((item, i) =>
                            item === 'separator' ? (
                                <Divider key={i} />
                            ) : ('to' in item) ? (
                                <TopNavMenuItemLink key={item.label} {...item} />
                            ) : (
                                <TopNavMenuItemAction key={item.label} {...item} />
                            )
                        )}
                    </div>
                )}
            </>
        </div>
    );
};

type TopNavMenuItemLinkProps = TopNavMenuItem_Link & {
    // icon: NonNullable<React.ReactNode>
    icon?:React.ReactNode
    afterIcon?:React.ReactNode
    className?:string
    children?:React.ReactNode
} & OtherHtmlAttributes

const TopNavMenuItemLink = ({className, label, to, ...rest}:TopNavMenuItemLinkProps) => {
    return (
        <NavLink to={to} className={ClassHelper.combine('TopNavMenuItem', className)} {...rest}>{label}</NavLink>
        // <div className={ClassHelper.combine('TopNavMenuItem', className)} {...rest}>
        //       {label}
        //   </div>
    )
}

type TopNavMenuItemActionProps = TopNavMenuItem_Action & {
    // icon: NonNullable<React.ReactNode>
    icon?:React.ReactNode
    afterIcon?:React.ReactNode
    className?:string
    children?:React.ReactNode
} & OtherHtmlAttributes

const TopNavMenuItemAction = ({className, label, action, ...rest}:TopNavMenuItemActionProps) => {
    return (
        <div className={ClassHelper.combine('TopNavMenuItem', className)} {...rest} onClick={action}>
           {label}
        </div>
    )
}
