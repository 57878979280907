import * as React from 'react';
import {useCallback, useState} from 'react';
import {useAuth} from '../../../../commons/hooks/useAuth';
import {apiFetch} from "../../../../framework/apiFetch";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {TextButton} from '../../../../framework/components/TextButton';

export const TestPage = () => {
	const {authInfo, setAuthInfo} = useAuth();

	const [whoAmI1, setWhoAmI1] = useState();
	const [whoAmI2, setWhoAmI2] = useState();
	const [publicTest, setPublicTest] = useState<string>();
	const [connectedTest, setConnectedTest] = useState<string>();
	const [connectedTestPost, setConnectedTestPost] = useState<string>();
	const [userTest, setUserTest] = useState<string>();
	const [adminTest, setAdminTest] = useState<string>();
	const [jira1, setJira1] = useState<string>();
	const [disconnectData, setDisconnectData] = useState<string>();

	const testWhoAmI1 = useCallback(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/public/who-am-i`)
			.then(response => {
				return response.json()
					.then(data => {
							setWhoAmI1(data);
						}
					)
			}).catch((e) => setWhoAmI1(e.toString()));
	}, []);
	const testWhoAmI2 = useCallback(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/public/who-am-i`, {credentials:'include'})
			.then(response => {
				return response.json()
					.then(data => {
							setWhoAmI2(data);
						}
					)
			}).catch((e) => setWhoAmI2(e.toString()));
	}, []);
	const testPublicTest = useCallback(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/public/test`, {credentials:'include'})
			.then(response => {
				return response.text()
					.then(data => {
							setPublicTest(data);
						}
					)
			}).catch((e) => setPublicTest(e.toString()));
	}, []);
	const testConnectedTest = useCallback(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/connected/test`, {credentials:'include'})
			.then(response => {
				return response.text()
					.then(data => {
							setConnectedTest(data);
						}
					)
			}).catch((e) => setConnectedTest(e.toString()));
	}, []);
	const testConnectedTestPost = useCallback(() => {
		apiFetch.post('api/connected/test')
			.then(response => {
				if (response.ok) {
					setConnectedTestPost(JSON.stringify(response.payload));
				} else {
					setConnectedTestPost(response.error);
				}
			});
		// fetch(`${process.env.REACT_APP_API_URL}/api/connected/test`, {method:'POST', credentials:'include'})
		// 	.then(response => {
		// 		return response.text()
		// 			.then(data => {
		// 					setConnectedTestPost(data);
		// 				}
		// 			)
		// 	}).catch((e) => setConnectedTestPost(e.toString()));
	}, []);
	const testUserTest = useCallback(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/user/test`, {credentials:'include'})
			.then(response => {
				return response.text()
					.then(data => {
							setUserTest(data);
						}
					)
			}).catch((e) => setUserTest(e.toString()));
	}, []);
	const testAdminTest = useCallback(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/admin/test`, {credentials:'include'})
			.then(response => {
				return response.text()
					.then(data => {
							setAdminTest(data);
						}
					)
			}).catch((e) => setAdminTest(e.toString()));
	}, []);

	const queryJira1 = useCallback(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/admin/jira-ticket`, {credentials:'include'})
			.then(response => {
				return response.text()
					.then(data => {
							setJira1(data);
						}
					)
			}).catch((e) => setJira1(e.toString()));
	}, []);

	const disconnect = useCallback(() => {
		fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {credentials:'include'})
			.then(response => {
				return response.text()
					.then(data => {
						setDisconnectData(data);
						setAuthInfo({authInfo:null});
					})
			}).catch((e) => setDisconnectData(e.toString()));
	}, [setAuthInfo]);

	return (
		<PageContent>
			<div>
				<TextButton label="Test /who-am-i without creds" onClick={testWhoAmI1} />

				{whoAmI1 && (
					<pre>{JSON.stringify(whoAmI1, null, 3)}</pre>
				)}
			</div>
			<div>
				<TextButton label="Test /who-am-i with creds" onClick={testWhoAmI2} />

				{whoAmI2 && (
					<pre>{JSON.stringify(whoAmI2, null, 3)}</pre>
				)}
			</div>
			<div>
				<TextButton label="Test GET /api/public/test" onClick={testPublicTest} />

				{publicTest && (
					<pre>{publicTest}</pre>
				)}
			</div>
			<div>
				<TextButton label="Test GET /api/connected/test" onClick={testConnectedTest} />

				{connectedTest && (
					<pre>{connectedTest}</pre>
				)}
			</div>
			<div>
				<TextButton label="Test POST /api/connected/test" onClick={testConnectedTestPost} />

				{connectedTestPost && (
					<pre>{connectedTestPost}</pre>
				)}
			</div>
			<div>
				<TextButton label="Test /api/user/test" onClick={testUserTest} />

				{userTest && (
					<pre>{userTest}</pre>
				)}
			</div>
			<div>
				<TextButton label="Test /api/admin/test" onClick={testAdminTest} />

				{adminTest && (
					<pre>{adminTest}</pre>
				)}
			</div>

			{authInfo!.isAdmin && (<>
				<div>
					<TextButton label="Jira1 query tickets" onClick={queryJira1} />

					{jira1 && (
						<pre>{jira1}</pre>
					)}
				</div>
				{/*<div>*/}
				{/*	/!*<input name="issueKey">{issueKey}</input>*!/*/}
				{/*	<TextButton label="Jira2 apply labels" onClick={queryJira2} />*/}

				{/*	{jira2 && (*/}
				{/*		<pre>{jira2}</pre>*/}
				{/*	)}*/}
				{/*</div>*/}
			</>)}

			<div>
				<TextButton label="Disconnect" onClick={disconnect} />

				{disconnectData && (
					<pre>{disconnectData}</pre>
				)}
			</div>
		</PageContent>
	);
}
