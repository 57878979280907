import {ReactNode} from 'react';

type DynamicOrderProps = {
	first:ReactNode;
	second:ReactNode;
	changeOrder:boolean
}

export const DynamicOrder = ({first, second, changeOrder}:DynamicOrderProps) => {
	if (changeOrder) {
		return (
			<>
				{second}
				{first}
			</>
		)
	} else {
		return (
			<>
				{first}
				{second}
			</>

		)
	}
};
