import * as React from 'react';
import {useCallback} from 'react';
import {MdLock, MdOutlineHelp, MdRefresh, MdVerified} from "react-icons/md";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {apiFetch} from "../../../../framework/apiFetch";
import {IconButton} from "../../../../framework/components/IconButton";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {QueryListBody} from "../../../../framework/crud/CrudApiService2";
import {CrudPage2, CrudPageLayout, CrudPageLayoutBasic} from "../../../../framework/crud2/CrudPage2";
import {ClosureColumnDef} from "../../../../framework/table/columns/ClosureColumnDef";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {ExternalLinkColumnDef} from "../../../../framework/table/columns/ExternalLinkColumnDef";
import {IconColumnDef} from "../../../../framework/table/columns/IconColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {Pagination2} from "../../../../framework/table/Pagination2";
import {Table2} from "../../../../framework/table/Table2";
import {ApiFetchResponse} from "../../../../framework/WrappedJsonFetch";

//TODO adjust columns
import "./JiraTicketDisclosurePage.scss";
import {JiraUrlHelper} from "@commons/helpers/JiraUrlHelper";

type JiraTicketModel = {
	id:string
	key:string
	fields:{
		summary:string
		status:{
			name:string,
			statusCategory:{
				colorName:string
			}
		}
	}
}

const jiraTicketService = new class JiraTicketService extends AbstractApiService<JiraTicketModel> {
	constructor() {
		super('api/admin/jira-jenkins-tooling');
	}

	async queryList(paramPart:string):Promise<ApiFetchResponse<QueryListBody<JiraTicketModel>>> {
		const fragment = paramPart;
		const queryResponse = await apiFetch.get<QueryListBody<any>>(`${this.urlFragmentPart}?${fragment}`);
		if (queryResponse.ok) {
			const payload:any = queryResponse.payload;
			const wrapper = {
				// meta:{filteredTotal:payload.ticketsBySummary.total, total:payload.ticketsBySummary.total},
				meta:{filteredTotal:payload.total, total:payload.total},
				// data:payload.ticketsBySummary.issues
				data:payload.tickets
			};
			return {ok:true, code:200, payload:wrapper};
		} else {
			return queryResponse;
		}
	}
}();

const convertColorToClass = (statusColorRaw:string):string => {
	const map:{ [key:string]:string } = {
		'green':'status-color-green',
		'blue-gray':'status-color-blue-gray',
		'yellow':'status-color-yellow'
	};
	return map[statusColorRaw] || 'status-color-unknown';
}

export const JiraTicketDisclosurePage = () => {
	const {columnDefs, initialState} = useColumnDefs<JiraTicketModel>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('key', 'Key', row => row.key || ''),
		new ExternalLinkColumnDef('openLink', row => JiraUrlHelper.linkToTicket(row.key)),
		new ClosureColumnDef('status', 'Status', row => {
			const statusColorRaw = row.fields.status.statusCategory.colorName;
			const colorClass:string = convertColorToClass(statusColorRaw);
			const statusName = row.fields.status.name;
			return (
				<span className={ClassHelper.combine('status-label', colorClass)}>{statusName}</span>
			);
		}),
		new StringColumnDef('summary', 'Summary', row => row.fields.summary || ''),
		new IconColumnDef('accept', 'Mark the ticket as not a vulnerability', MdVerified, markOneTicketAsAccepted, {}, {iconClassName:'hover-success'}),
		new IconColumnDef('requireMoreAttention', 'Request a deeper analysis', MdOutlineHelp, markOneTicketAsRequireAttention, {}, {iconClassName:'hover-warning'}),
		new IconColumnDef('markAsVulnerability', 'Mark as a vulnerability', MdLock, markOneTicketAsVulnerability, {}, {iconClassName:'hover-error'}),
	]);

	return (
		<CrudPage2 pageId="jira-ticket-disclosure" pageClass="JiraTicketDisclosurePage" crudService={jiraTicketService}
		           initialState={initialState}
		           layout={useCallback((state, itemsData, dispatch) => (
			           <CrudPageLayoutBasic pageName="Jira tickets" topLeftActions={<>
				           <IconButton icon={MdRefresh} title="Refresh data"
				                       onClick={() => dispatch({type:'reloadContent'})} />
			           </>} topRightActions={<>
				           <span>Right</span>
			           </>} table={<>
				           <Table2 columnDefs={columnDefs} className="fluid"
				                   dataState={itemsData} state={state} dispatch={dispatch} />
			           </>} bottomLeftAction={<>
				           <div>Selection: {state.selection.length} (TODO)</div>
			           </>} bottomRightAction={<>
				           {/* TODO cache issue between requests*/}
				           <Pagination2 pagination={state.pagination} dispatch={dispatch}
				                        filteredTotalResults={itemsData.data?.meta?.filteredTotal}
				                        totalResults={itemsData.data?.meta?.total}
				           />
			           </>} />
		           ), [columnDefs]) as CrudPageLayout<JiraTicketModel>}
		/>
	)
}

const markOneTicketAsAccepted = ({row}:IconColumnDef.OnClickArgs<JiraTicketModel>) => {
}
const markOneTicketAsRequireAttention = ({row}:IconColumnDef.OnClickArgs<JiraTicketModel>) => {

}
const markOneTicketAsVulnerability = ({row}:IconColumnDef.OnClickArgs<JiraTicketModel>) => {

}

// const markMultipleTicketsAsAccepted = (ids:string[]) => {
//
// }
