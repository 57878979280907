import {apiFetch} from "../../framework/apiFetch";
import {ApiFetchResponse} from "../../framework/WrappedJsonFetch";

// export type AssignResponse = { timing:number, output:CertAutomationResponse, errors:string[] };
// export type LabelResponse = { timing:number, output:CertAutomationResponse, errors:string[] };
export type AssignResponse = { async:boolean, taskUrl:string, errors:string[] };
export type LabelResponse = { timing:number, taskUrl:string, errors:string[] };

export class TicketSecurityAutomationService {
	private urlFragmentPart = 'api/user/task/jira-security/automation';

	assignTicket(issueKey:string):Promise<ApiFetchResponse<AssignResponse>> {
		const url = `${this.urlFragmentPart}/jira-assign-issue?ticket=${encodeURIComponent(issueKey)}`;
		const response = apiFetch.post<AssignResponse>(url);
		return response;
	}

	addLabelsToTicket(issueKey:string):Promise<ApiFetchResponse<LabelResponse>> {
		const url = `${this.urlFragmentPart}/jira-update-plugin-labels?ticket=${encodeURIComponent(issueKey)}`;
		const response = apiFetch.post<LabelResponse>(url);
		return response;
	}
}
