import {FunctionNM} from "@commons/types/FunctionNM";
import {ReactNode, useCallback, useRef, useState} from "react";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';

import './ScrollablePanel.scss';
import {IconButton} from "./IconButton";
import { MdContentCopy, MdOutlineHourglassEmpty, MdCheck} from "react-icons/md";

type Props = {
	children:ReactNode
} & OtherHtmlAttributes

export const ScrollablePanel = ({children, ...rest}:Props) => {
	return (
		<div className="ScrollablePanel" {...rest}>
			<CopyContent />
			<div className="content-to-copy">{children}</div>
		</div>
	);
}

type IconType = 'base' | 'pending' | 'done';
const iconTypeToIcon:{[key:string]:FunctionNM<any, JSX.Element>} = {
	base: MdContentCopy,
	pending: MdOutlineHourglassEmpty,
	done: MdCheck,
}

const CopyContent = () => {
	const [currIcon, setCurrIcon] = useState<IconType>('base');
	const handleClick = useCallback((e:any) => {
		const contentToCopy = copyContentRef.current?.parentElement?.querySelector('.content-to-copy');
		if (contentToCopy) {
			setCurrIcon('pending');
			navigator.clipboard.writeText(contentToCopy.textContent!).then(() => {
				// normally it's instant
				setCurrIcon('done');
				setTimeout(() => {
					setCurrIcon(prevState => {
						if (prevState === 'done') {
							return 'base';
						}
						return prevState;
					})
				}, 5 * 1000);
			});
		}
	}, []);
	
	const copyContentRef = useRef<HTMLDivElement>(null);

	const icon = iconTypeToIcon[currIcon]
	
	return (
		<div className="CopyContent" ref={copyContentRef}>
			<IconButton icon={icon} onClick={handleClick} />
		</div>
	)
}
