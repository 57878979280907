import {JENKINS_ORG} from "@commons/constants/JenkinsConstants";
import {GitHubUrlHelper} from "@commons/helpers/GitHubUrlHelper";
import {SortHelper} from "@commons/helpers/SortHelper";
import {
	GitHubPermissionToolingResponse_All,
	GitHubPermissionToolingResponse_Collaborators_Item,
	GitHubPermissionToolingResponse_Permission_ADMIN,
	GitHubPermissionToolingResponse_Permission_MAINTAIN,
	GitHubPermissionToolingResponse_Permission_PULL,
	GitHubPermissionToolingResponse_Permission_PUSH,
	GitHubPermissionToolingResponse_Permission_TRIAGE,
	GitHubPermissionToolingResponse_PermissionType,
	GitHubPermissionToolingResponse_Teams_Item
} from "@commons/protocol/GitHubPermissionToolingResponse";
import {FunctionNM} from "@commons/types/FunctionNM";
import * as React from "react";
import {useCallback, useState} from "react";
import {MdEdit, MdOpenInNew, MdOutlinePeopleAlt, MdOutlinePersonOutline, MdSave, MdUndo} from "react-icons/md";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";
import {LogIfMountedType, useLogIfStillMounted} from "../../../../commons/hooks/useLogIfStillMounted";
import {apiFetch} from "../../../../framework/apiFetch";
import {Card} from "../../../../framework/components/Card";
import {Divider} from "../../../../framework/components/Divider";
import {IconButton} from "../../../../framework/components/IconButton";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {LinkButton} from "../../../../framework/components/LinkButton";
import {LogPanel, useLogPanel} from "../../../../framework/components/LogPanel";
import {SingleSelectGroup} from "../../../../framework/components/SingleSelectGroup";
import {TextButton} from "../../../../framework/components/TextButton";
import "./GitHubPermissionToolingPage.scss";

type PermissionType = GitHubPermissionToolingResponse_PermissionType;

const PERMISSION_ORDER = {
	[GitHubPermissionToolingResponse_Permission_ADMIN]:5,
	[GitHubPermissionToolingResponse_Permission_MAINTAIN]:4,
	[GitHubPermissionToolingResponse_Permission_PUSH]:3,
	[GitHubPermissionToolingResponse_Permission_TRIAGE]:2,
	[GitHubPermissionToolingResponse_Permission_PULL]:1,
}

type HasPermission = { permission:PermissionType };

const PERMISSION_SORTER = (item:HasPermission) => {
	return PERMISSION_ORDER[item.permission] ?? 0;
}

export const GitHubPermissionToolingPage = () => {
	const {logRows, appendLog, clearLog} = useLogPanel();
	const {logIfMounted} = useLogIfStillMounted(appendLog);

	const [lastOrg, setLastOrg] = useState<string>();
	const [lastRepo, setLastRepo] = useState<string>();
	const [listing, setListing] = useState<GitHubPermissionToolingResponse_All | null>(null);

	const [org, setOrg] = useState<string>(JENKINS_ORG);
	const [reloading, setReloading] = useState(false);

	/*
	 *  Emails:
	 *      - get total number of emails in the account
	 *      - get current number of emails tracked
	 *      - force re-processing of emails (re-sync)
	 *  JiraJenkins:
	 *      - run search to find number of unlabelled tickets
	 *      - run search to find number of labelled tickets
	 *      - label + attach watcher on tickets (ease task of recurrent task)
	 *      - reprocess labelled tickets (re-sync)
	 */
	const searchListingPerRepo = useCallback(() => {
		const org = document.querySelector<HTMLInputElement>('#listing_org')!.value.trim();
		const repo = document.querySelector<HTMLInputElement>('#listing_repo')!.value.trim();
		if (!org || !repo) {
			appendLog(`The org/repo are mandatory`);
			return;
		}

		setReloading(true);
		appendLog(`Listing for org=${org}, repo=${repo}`);

		const url = `api/user/github-permission-tooling/all?org=${encodeURIComponent(org)}&repo=${encodeURIComponent(repo)}`;
		apiFetch.get<GitHubPermissionToolingResponse_All>(url).then(response => {
			logIfMounted(response)
			if (response.ok) {
				setLastOrg(org);
				setLastRepo(repo);
				setListing(response.payload);
			} else {
				setLastOrg(org);
				setLastRepo(repo);
				setListing(null);
			}
			setReloading(false);
		});
	}, [appendLog, logIfMounted]);

	const startRequest = useCallback(() => {
		setReloading(true);
	}, []);
	const stopRequest = useCallback(() => {
		setReloading(false);
	}, []);

	const reloadLast = useCallback(() => {
		if (!lastOrg || !lastRepo) {
			appendLog(`Cannot reload for lastOrg=${lastOrg}, lastRepo=${lastRepo}`);
			setReloading(false);
			return;
		}

		setReloading(true);

		const innerOrg = lastOrg!;
		const innerRepo = lastRepo!;
		appendLog(`Reloading for lastOrg=${lastOrg}, lastRepo=${lastRepo}`);
		const url = `api/user/github-permission-tooling/all?org=${encodeURIComponent(innerOrg)}&repo=${encodeURIComponent(innerRepo)}`;
		apiFetch.get<GitHubPermissionToolingResponse_All>(url).then(response => {
			logIfMounted(response)
			if (response.ok) {
				setLastOrg(innerOrg);
				setLastRepo(innerRepo);
				setListing(response.payload);
			} else {
				setLastOrg(innerOrg);
				setLastRepo(innerRepo);
				setListing(null);
			}
			setReloading(false);
		});
	}, [appendLog, lastOrg, lastRepo, logIfMounted]);

	return (
		<PageContent className="GitHubPermissionToolingPage">
			<div>
				List permissions per GitHub repository
				<input id="listing_org" placeholder="jenkinsci" value={org}
				       onChange={event => setOrg(event.target.value)} />
				<input id="listing_repo" placeholder="strict-crumb-issuer-plugin" />
				<TextButton label="Search" onClick={searchListingPerRepo} />

				{listing && (
					<div className={ClassHelper.combine('dynamic-response-panel', reloading && 'reloading')}>
						<div className="response-title">Response for {lastOrg}/{lastRepo}</div>
						{listing.teams.length + listing.collaborators.length > 0 ? (<>
							{listing.teams.length > 0 && listing.teams.concat().sort(SortHelper.by(PERMISSION_SORTER, 'teamSlug')).map(teamEntry => (
								// special key to force reload when changing org/repo
								<ListingPermissionTeamEntry
									key={lastOrg! + lastRepo! + teamEntry.teamSlug}
									orgName={lastOrg!}
									repositoryName={lastRepo!}
									teamEntry={teamEntry}
									onRequestStart={startRequest}
									onRequestStop={stopRequest}
									onRefreshRequest={reloadLast}
									logIfMounted={logIfMounted}
								/>
							))}
							{listing.teams.length > 0 && listing.collaborators.length > 0 && (
								<Divider noHorizontalMargin={true} />
							)}
							{listing.collaborators.length > 0 && listing.collaborators.concat().sort(SortHelper.by(PERMISSION_SORTER, 'login')).map(collaboratorEntry => (
								<ListingPermissionCollaboratorEntry
									key={lastOrg! + lastRepo! + collaboratorEntry.login}
									orgName={lastOrg!}
									repositoryName={lastRepo!}
									collaboratorEntry={collaboratorEntry}
									onRequestStart={startRequest}
									onRequestStop={stopRequest}
									onRefreshRequest={reloadLast}
									logIfMounted={logIfMounted}
								/>
							))}
						</>) : (
							<span className="no-entries">No entries found</span>
						)}
					</div>
				)}
			</div>
			<Divider />
			<Card>
				<LogPanel rows={logRows} numRowsToDisplay={30} onClearLog={clearLog} />
			</Card>
		</PageContent>
	);
}

type ListingPermissionTeamEntryParams = {
	orgName:string
	repositoryName:string
	teamEntry:GitHubPermissionToolingResponse_Teams_Item
	onRequestStart?:FunctionNM<[], void>
	onRequestStop?:FunctionNM<[], void>
	onRefreshRequest?:FunctionNM<[], void>
	logIfMounted:LogIfMountedType
}

// security2606-staging
const ListingPermissionTeamEntry = ({
	                                    orgName,
	                                    repositoryName,
	                                    teamEntry,
	                                    onRequestStart,
	                                    onRequestStop,
	                                    onRefreshRequest,
	                                    logIfMounted
                                    }:ListingPermissionTeamEntryParams) => {
	const {teamSlug, permission} = teamEntry;

	// https://github.com/orgs/jenkinsci/teams/nis-notification-lamp-plugin-developers
	const viewUrl = GitHubUrlHelper.linkToTeam(orgName, teamSlug);

	const permissionLabel = permission === GitHubPermissionToolingResponse_Permission_PUSH ? 'write' :
		permission === GitHubPermissionToolingResponse_Permission_PULL ? 'read' :
			permission;

	const permissionTitle = permission === GitHubPermissionToolingResponse_Permission_PUSH ? 'In GitHub\'s UI it\'s displayed "Write" but for the API it\'s "Push"' :
		permission === GitHubPermissionToolingResponse_Permission_PULL ? 'In GitHub\'s UI it\'s displayed "Read" but for the API it\'s "Pull"' :
			permission;

	const handlePermissionChange = useCallback((currentPermission:PermissionType, newPermission:PermissionType) => {
		console.warn('handlePermissionChange team', currentPermission, newPermission);

		logIfMounted(`Changing permission for team=${teamSlug}, from=${currentPermission} to ${newPermission}`);
		onRequestStart && onRequestStart();

		const url = `api/user/github-permission-tooling/teams/${encodeURIComponent(teamSlug)}?org=${encodeURIComponent(orgName)}&repo=${encodeURIComponent(repositoryName)}&permission=${newPermission}`;
		apiFetch.patch(url).then(response => {
			logIfMounted(response);
			if (response.ok) {
				onRefreshRequest && onRefreshRequest();
			} else {
				onRequestStop && onRequestStop();
			}
		});
	}, [onRequestStart, onRequestStop, onRefreshRequest, logIfMounted, orgName, repositoryName, teamSlug]);

	return (
		<div className="ListingPermissionEntry ListingPermissionTeamEntry">
			<span className="ListingPermissionEntryLabel ListingPermissionTeamName">
				<MdOutlinePeopleAlt />
				<code className="ListingPermission" title={permissionTitle}>{permissionLabel}</code>
				<PermissionChange permission={permission} logIfMounted={logIfMounted}
				                  onPermissionChange={handlePermissionChange}
				/>
				<code className="ListingName">{teamSlug}</code>
				<LinkButton href={viewUrl} label={"View"} icon={MdOpenInNew} size="s" />
			</span>
		</div>
	)
}

type ListingPermissionCollaboratorEntryParams = {
	orgName:string
	repositoryName:string
	collaboratorEntry:GitHubPermissionToolingResponse_Collaborators_Item
	onRequestStart?:FunctionNM<[], void>
	onRequestStop?:FunctionNM<[], void>
	onRefreshRequest?:FunctionNM<[], void>
	logIfMounted:LogIfMountedType
}

const ListingPermissionCollaboratorEntry = ({
	                                            orgName,
	                                            repositoryName,
	                                            collaboratorEntry,
	                                            onRequestStart,
	                                            onRequestStop,
	                                            onRefreshRequest,
	                                            logIfMounted
                                            }:ListingPermissionCollaboratorEntryParams) => {
	const {login, permission} = collaboratorEntry;
	const viewUrl = GitHubUrlHelper.linkToUser(collaboratorEntry.login);

	const permissionLabel = permission === GitHubPermissionToolingResponse_Permission_PUSH ? 'write' :
		permission === GitHubPermissionToolingResponse_Permission_PULL ? 'read' :
			permission;
	const permissionTitle = permission === GitHubPermissionToolingResponse_Permission_PUSH ? 'In GitHub\'s UI it\'s displayed "Write" but for the API it\'s "Push"' :
		permission === GitHubPermissionToolingResponse_Permission_PULL ? 'In GitHub\'s UI it\'s displayed "Read" but for the API it\'s "Pull"' :
			permission;

	const handlePermissionChange = useCallback((currentPermission:PermissionType, newPermission:PermissionType) => {
		console.warn('handlePermissionChange collaborator', currentPermission, newPermission);

		logIfMounted(`Changing permission for collaborator=${login}, from=${currentPermission} to ${newPermission}`);
		onRequestStart && onRequestStart();

		const url = `api/user/github-permission-tooling/collaborators/${encodeURIComponent(login)}?org=${encodeURIComponent(orgName)}&repo=${encodeURIComponent(repositoryName)}&permission=${newPermission}`;
		apiFetch.patch(url).then(response => {
			logIfMounted(response);
			if (response.ok) {
				if (response.code === 200) {
					logIfMounted(`\n/!\\\nWarning, the collaborator permission did not change. It could be because they are "org owner" or part of a team.\n/!\\`);
				}
				onRefreshRequest && onRefreshRequest();
			} else {
				onRequestStop && onRequestStop();
			}
		});
	}, [onRequestStart, onRequestStop, onRefreshRequest, logIfMounted, orgName, repositoryName, login]);

	return (
		<div className="ListingPermissionEntry ListingPermissionCollaboratorEntry">
			<span className="ListingPermissionEntryLabel ListingPermissionCollaboratorName">
				<MdOutlinePersonOutline />
				<code className="ListingPermission" title={permissionTitle}>{permissionLabel}</code>
				<PermissionChange permission={permission} logIfMounted={logIfMounted}
				                  onPermissionChange={handlePermissionChange}
				/>
				<code className="ListingName">{login}</code>
				<LinkButton href={viewUrl} label={"View"} icon={MdOpenInNew} size="s" />
			</span>
		</div>
	)
}

type PermissionChangeParams = {
	permission:PermissionType
	onPermissionChange?:FunctionNM<[PermissionType, PermissionType], void>
	logIfMounted:LogIfMountedType
}

const PermissionChange = ({permission, onPermissionChange, logIfMounted}:PermissionChangeParams) => {
	const [editMode, setEditMode] = useState(false);
	const [tempPermission, setTempPermission] = useState(permission);

	const handleEditClick = useCallback(() => {
		setEditMode(true);
	}, []);
	const handleSaveClick = useCallback(() => {
		if (tempPermission === permission) {
			logIfMounted('Same permission => no change')
			setEditMode(false);
			return;
		}
		const confirmed = window.confirm(`Are you sure to change the permission from "${permission}" to "${tempPermission}"?`);
		if (!confirmed) {
			return;
		}

		setEditMode(false);
		onPermissionChange && onPermissionChange(permission, tempPermission);
	}, [onPermissionChange, logIfMounted, tempPermission, permission]);
	const handleCancelClick = useCallback(() => {
		setEditMode(false);
	}, []);
	const handlePermissionTempChange = useCallback((value:PermissionType) => {
		setTempPermission(value);
	}, []);

	return (<>
		{!editMode && (
			<IconButton icon={MdEdit} size="s" onClick={handleEditClick} />
		)}
		{editMode && (<>
			<SingleSelectGroup value={tempPermission} items={[
				{label:'Read', value:GitHubPermissionToolingResponse_Permission_PULL},
				{label:'Triage', value:GitHubPermissionToolingResponse_Permission_TRIAGE},
				{label:'Write', value:GitHubPermissionToolingResponse_Permission_PUSH},
				{label:'Maintain', value:GitHubPermissionToolingResponse_Permission_MAINTAIN},
				{label:'Admin', value:GitHubPermissionToolingResponse_Permission_ADMIN},
			]} onChange={handlePermissionTempChange} />
			<IconButton icon={MdSave} size="s" onClick={handleSaveClick} />
			<IconButton icon={MdUndo} size="s" onClick={handleCancelClick} />
		</>)}
	</>)
}
