import * as React from 'react';
import {ReactNode} from 'react';
import {ClassHelper} from "../../../commons/helpers/ClassHelper";

import "./TwoColumns.scss"

type TwoColumnsProps = {
	className?:string
	left:ReactNode
	right:ReactNode
}

/**
 * A section contains multiple pages managed through the sidenav.
 * It's not meant to be used for a single page.
 */
export const TwoColumns = ({className, left, right}:TwoColumnsProps) => {
	return (
		<div className={ClassHelper.combine('TwoColumns', className)}>
			<div className="Left">
				{left}
			</div>
			<div className="Right">
				{right}
			</div>
		</div>
	);
}
