import * as React from "react";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";

import "./JiraTextFormat.scss";
import {JiraTextFormatHelper} from "./JiraTextFormatHelper";

export type JiraTextFormatProps = {
    className?:string
    text:string
}

// Not supported: Panel, Table, Mention, Link,
// and some particular corner cases (look into JiraTextFormatHelper.test.ts for more details)

export const JiraTextFormat = ({className, text}:JiraTextFormatProps) => {
    const classes = ClassHelper.combine('JiraDescriptionFormat', className);

    const formatted = JiraTextFormatHelper.formatAllText(text);

    return (
        <div className={classes} dangerouslySetInnerHTML={formatted}/>
    );
}
