/**
 * e.g. 2022-01-08T16:27:07.365Z
 */
// export type IsoDateString = `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`;
// export type IsoDateString = `${number}-${string}`;
// export type IsoDateString = `${string}Z`;
// => RangeError: Maximum call stack size exceeded
//TODO to be re-tested in future versions of typescript
export type IsoDateString = string;
export type IsoDayString = `${number}-${number}-${number}`;

export const IsoDateString_DEFAULT:IsoDateString = '1900-01-01T00:00:00.000Z';
export const IsoDateString_1900:IsoDateString = '1900-01-01T00:00:00.000Z';
