import * as H from "history";
import {ReactNode} from "react";
import {IconType} from "react-icons";
import {MdZoomOutMap} from "react-icons/md";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {CrudModel} from '../../../commons/types/CrudModel';
import {InternalLinkIconButton} from "../../components/InternalLinkIconButton";
import {ColDefType} from "../ColDefType";

//TODO create variant for safe HTML
import {CrudTableColumnDefinition} from "../CrudTableColumnDefinition";
import {NullCellValue} from "../NullCellValue";

export class InternalLinkColumnDef<T extends CrudModel> implements CrudTableColumnDefinition<T> {
	type:ColDefType = 'string';

	defaultVisibility:boolean;
	sortable:boolean;
	fixed:boolean;
	headerClassName:string;
	cellClassName:string;
	header:string
	title:string | undefined;
	private icon:IconType

	constructor(public name:string,
	            private rowToLink:((row:T) => H.LocationDescriptorObject<any> | null),
	            opts?:{ defaultVisibility?:boolean, cellClassName?:string, icon?:IconType, title?:string }) {
		this.header = '';
		this.defaultVisibility = opts?.defaultVisibility ?? true;
		this.headerClassName = 'InternalLinkColumnDef';
		this.cellClassName = ClassHelper.combine('InternalLinkColumnDef td-icon', opts?.cellClassName);
		this.sortable = false;
		this.fixed = false;
		this.title = opts?.title;
		this.icon = opts?.icon || MdZoomOutMap;
	}

	nowrap():this {
		this.cellClassName = 'nowrap';
		return this;
	}

	computeCell(row:T):ReactNode {
		const to = this.rowToLink(row);
		if (to === null) {
			return <NullCellValue />
		}

		return (
			<InternalLinkIconButton to={to} icon={this.icon} title={this.title} />
		);
	}
}
