import * as React from "react";
import {useCallback} from "react";
import {useLogIfStillMounted} from "../../../../commons/hooks/useLogIfStillMounted";
import {apiFetch} from "../../../../framework/apiFetch";
import {Card} from "../../../../framework/components/Card";
import {WarningPanel} from "../../../../framework/components/color/Panel";
import {Divider} from "../../../../framework/components/Divider";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {LogPanel, useLogPanel} from "../../../../framework/components/LogPanel";
import {TextButton} from "../../../../framework/components/TextButton";
import "./CertAutomationPage.scss";

const logWrapper = (appendLog:(data:any) => void) => {
	return (data:any) => {
		if (data && data.payload && data.payload.output) {
			let stdout = data.payload.output.stdout.replace(/\r/g, '');
			if (stdout.trim().length !== 0) {
				stdout = '==>\n' + stdout + '\n<==';
				data.payload.output.stdout = '$pattern1$';
			}

			let stderr = data.payload.output.stderr.replace(/\r/g, '');
			if (stderr.trim().length !== 0) {
				stderr = '==>\n' + stderr + '\n<==';
				data.payload.output.stderr = '$pattern2$';
			}

			let customOutput:string = '';
			if (data.payload.output.output !== undefined) {
				customOutput = data.payload.output.output.replace(/\r/g, '');
				if (customOutput === '' || customOutput === '[no-findings]') {
					customOutput = 'No findings found in that repository';
				}
				customOutput = '==>\n' + customOutput + '\n<==';
				data.payload.output.output = '$pattern3$';
			}

			let newData = JSON.stringify(data);
			newData = newData.replace(/\$pattern1\$/, stdout);
			newData = newData.replace(/\$pattern2\$/, stderr);
			if (data.payload.output) {
				newData = newData.replace(/\$pattern3\$/, customOutput);
			}
			const array = newData.split('\n');
			appendLog(array);
			return;
		}
		appendLog(data);
	}
}

export const ReleaseBlockToolingPage = () => {
	const {logRows, appendLog, clearLog} = useLogPanel();
	const {logIfMounted} = useLogIfStillMounted(logWrapper(appendLog));

	/*
	 *  Emails:
	 *      - get total number of emails in the account
	 *      - get current number of emails tracked
	 *      - force re-processing of emails (re-sync)
	 *  JiraJenkins:
	 *      - run search to find number of unlabelled tickets
	 *      - run search to find number of labelled tickets
	 *      - label + attach watcher on tickets (ease task of recurrent task)
	 *      - reprocess labelled tickets (re-sync)
	 */
	const triggerRequestReleaseBlock = useCallback(() => {
		const issueKey = document.querySelector<HTMLInputElement>('#releaseBlock_issueKey')!.value.trim();
		const pluginArtifactId = document.querySelector<HTMLInputElement>('#releaseBlock_artifactId')!.value.trim();
		appendLog(`Requesting /block-release with issueKey=${issueKey} and pluginArtifactId=${pluginArtifactId}`);

		const url = `api/user/release-block-tooling/block-release?issueKey=${encodeURIComponent(issueKey)}&pluginArtifactId=${encodeURIComponent(pluginArtifactId)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);
	const triggerRequestReleaseUnblock = useCallback(() => {
		const issueKey = document.querySelector<HTMLInputElement>('#releaseUnblock_issueKey')!.value.trim();
		const pluginArtifactId = document.querySelector<HTMLInputElement>('#releaseUnblock_artifactId')!.value.trim();
		appendLog(`Requesting /unblock-release with issueKey=${issueKey} and pluginArtifactId=${pluginArtifactId}`);

		const url = `api/user/release-block-tooling/unblock-release?issueKey=${encodeURIComponent(issueKey)}&pluginArtifactId=${encodeURIComponent(pluginArtifactId)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);

	return (
		<PageContent className="ReleaseBlockRequestPage">
			<WarningPanel>
				This tooling is not expected to be used for a regular SECURITY ticket with a single plugin.
				It will not write a Jira comment and will not add the related labels in the ticket.
			</WarningPanel>

			<div>
				/block-release <input id="releaseBlock_issueKey" placeholder="SECURITY-1234" /> <input
				id="releaseBlock_artifactId" placeholder="junit" />
				<TextButton label="Trigger" onClick={triggerRequestReleaseBlock} />
			</div>
			<Divider />
			<div>
				/unblock-release <input id="releaseUnblock_issueKey" placeholder="SECURITY-1234" /> <input
				id="releaseUnblock_artifactId" placeholder="junit" />
				<TextButton label="Trigger" onClick={triggerRequestReleaseUnblock} />
			</div>
			<Divider />
			<Card>
				<LogPanel rows={logRows} numRowsToDisplay={30} onClearLog={clearLog} />
			</Card>
		</PageContent>
	);
}
