import {ReactNode} from "react";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';

import './BreakSpaces.scss';

type Props = {
	children:ReactNode
} & OtherHtmlAttributes

export const BreakSpaces = ({children, ...rest}:Props) => {
	return (
		<div className="BreakSpaces" {...rest}>{children}</div>
	);
}
