import {useCallback, useMemo} from "react";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {CrudModel} from '../../../commons/types/CrudModel';
import {CustomCheckbox} from "../../components/CustomCheckbox";
import {CrudPage2Dispatcher} from "../../crud2/CrudPage2";
import {CrudTableColumnDefinition} from "../CrudTableColumnDefinition";
import {
	SelectionFullInfo,
	SelectionFullInfoChangeHandler
} from "./SelectionInfo";

type Props<T extends CrudModel> = {
	columnDef:CrudTableColumnDefinition<T>
	row:T
	dispatch?:CrudPage2Dispatcher<T>

	selection:SelectionFullInfo<T>
	onSelectionChange:SelectionFullInfoChangeHandler<T>
}

export const SelectTableCell2 = <T extends CrudModel>({columnDef, row, dispatch, selection, onSelectionChange}:Props<T>) => {
	const isSelected = useMemo(() => {
		// return selection.includes(row.id);
		return selection.includes(row);
	}, [selection, row]);

	const handleClick = useCallback(() => {
		if (isSelected) {
			// const selectionMinusThis = selection.filter(s => s !== row.id);
			const selectionMinusThis = selection.filter(s => s !== row);
			onSelectionChange(selectionMinusThis);
		} else {
			// const selectionPlusThis = selection.concat(row.id);
			const selectionPlusThis = selection.concat(row);
			onSelectionChange(selectionPlusThis);
		}
	// }, [row.id, isSelected, selection, onSelectionChange]);
	}, [row, isSelected, selection, onSelectionChange]);

	return (
		<td className={ClassHelper.combine('SelectTableCell', columnDef.cellClassName)}>
			<CustomCheckbox value={isSelected ? 'true' : 'false'} onChange={handleClick} />
		</td>
	);
}
