import * as React from "react";
import {MdSave} from "react-icons/md";
import {TextButton} from "../../components/TextButton";

type Props = {
	label:string
	type?: 'primary' | 'shadowed' | 'bordered' | 'regular'
	disabled?:boolean
}

export const FormSubmitButton = ({label, type, disabled}:Props) => {
	return (
		<TextButton label={label} icon={MdSave} disabled={disabled}
		            buttonType="submit" type={type} />
	);
}
