import {DateTime} from "luxon";
import * as React from 'react';
import {useCallback} from 'react';
import {MdRefresh} from "react-icons/md";
import {TicketJenkins} from "@commons/models/TicketJenkins";
import {WithDbAndId} from "@commons/types/DbType";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {IconButton} from "../../../../framework/components/IconButton";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {CrudPage2, CrudPageLayout, CrudPageLayoutBasic} from "../../../../framework/crud2/CrudPage2";
import {DateColumnDef} from "../../../../framework/table/columns/DateColumnDef";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {ExternalLinkColumnDef} from "../../../../framework/table/columns/ExternalLinkColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {PaginationUsingCursor} from "../../../../framework/table/PaginationUsingCursor";
import {Table2} from "../../../../framework/table/Table2";
import {JiraUrlHelper} from "@commons/helpers/JiraUrlHelper";

type TicketJenkinsModel = WithDbAndId<TicketJenkins>;

const crudService = new class TicketSecurityApiService extends AbstractApiService<TicketJenkinsModel> {
	constructor() {
		super('api/user/ticket/jenkins');
	}
}();

export const TicketJenkinsPage = () => {
	const {columnDefs, initialState} = useColumnDefs<TicketJenkinsModel>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'Id', row => row.id || '', {defaultVisibility:false}),
		new StringColumnDef('ticketNum', 'Ticket', row => row.ticket || '', {size:110}),
		new ExternalLinkColumnDef('openLink', row => JiraUrlHelper.linkToTicket(row.ticket)),
		new StringColumnDef('name', 'Summary', row => row.summary || '', {size:'expand'}),
		// new StringColumnDef('sprint', 'Sprint', row => row.sprint || '', {size:180}),

		new DateColumnDef('ticketUpdatedDate', 'Updated', DateTime.DATE_MED, row => row.ticketUpdatedDate!, {
			headerTitle:'Ticket last update',
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS
		}),
		new DateColumnDef('createdDate', 'Created', DateTime.DATE_MED, row => row.createdDate, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS, defaultVisibility:false
		}),
		new DateColumnDef('updatedDate', 'Updated', DateTime.DATE_MED, row => row.updatedDate, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS, defaultVisibility:false
		}),
		// DefaultColumnFactory.EDIT_REQUIREMENT,
		// DefaultColumnFactory.DELETE_REQUIREMENT,
	], {initialOrder:'targetIdNum', initialOrderDirection:'desc'});

	return (
		<CrudPage2 pageId="ticket-jenkins" pageClass="TicketJenkins" crudService={crudService}
		           initialState={initialState}
		           layout={useCallback((state, itemsData, dispatch) => (
			           <CrudPageLayoutBasic pageName="JENKINS tickets" topLeftActions={<>
				           <IconButton icon={MdRefresh} title="Refresh data"
				                       onClick={() => dispatch({type:'reloadContent'})} />
			           </>} topRightActions={<>
				           <span>Right</span>
			           </>} table={<>
				           <Table2 columnDefs={columnDefs} className="fluid"
				                   dataState={itemsData} state={state} dispatch={dispatch} />
			           </>} bottomLeftAction={<>
				           {state.selection.length > 0 && (
					           <div className="Selection">
						           <div className="SelectionCount">Selection: {state.selection.length}</div>
						           {/*<TextButton icon={MdClear} label="Clear selection"*/}
						           {/*            onClick={() => dispatch({type:'setSelection', selection:[]})} />*/}
						           {/*<TextButton icon={MdDelete} label="Delete selection"*/}
						           {/*            onClick={() => deleteMultipleItems(state.selection, dispatch)} />*/}
					           </div>
				           )}
			           </>} bottomRightAction={<>
				           <PaginationUsingCursor pagination={state.pagination} itemsData={itemsData}
				                                  dispatch={dispatch} />
			           </>} />
		           ), [columnDefs]) as CrudPageLayout<TicketJenkinsModel>}
		/>
	)
}
