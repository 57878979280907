import * as React from "react";
import {MdCheck, MdClose} from "react-icons/md";
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';

import "./IconBoolean.scss";

export type IconBooleanProps = {
	value:boolean
	className?:string
} & OtherHtmlAttributes

//TODO add # for the selected tab using Tabs name
export const IconBoolean = ({value, className, ...rest}:IconBooleanProps) => {
	if (value) {
		return (
			<MdCheck className={ClassHelper.combine('IconBoolean', 'icon-green', className)} {...rest} />
		);
	} else {
		return (
			<MdClose className={ClassHelper.combine('IconBoolean', 'icon-red', className)} {...rest} />
		);
	}
}
