const ESCAPE_CHAR = '%';
const ESCAPE_CHAR2 = '|';

export class StringHelper {
	static hashCode(str:string):number {
		// https://stackoverflow.com/a/7616484 (and ChatGPT provided the same output...)
		let hash = 0;
		if (str.length === 0) return hash;
		for (let i = 0; i < str.length; i++) {
			let chr = str.charCodeAt(i);
			hash = ((hash << 5) - hash) + chr;
			// Convert to 32bit integer
			hash |= 0; 
		}
		return hash;
	}

	static excerpt(longString:string, size:number = 50, replacement:string = '...'):string {
		if (!longString) {
			return '';
		}
		if (longString.length > size) {
			return longString.substring(0, size - replacement.length) + replacement;
		}
		return longString;
	}

	/**
	 * The separator is encoded by doubling it if present within a value
	 */
	static encodeAsArray(values:string[], separator:string):string {
		if (!separator) {
			throw new Error('Separator cannot be empty otherwise it will be guaranteed to be encoded correctly')
		}

		const escapeChar = separator === ESCAPE_CHAR ? ESCAPE_CHAR2 : ESCAPE_CHAR;

		const encodedValues:string[] = values.map(v => {
			if (v.includes(escapeChar)) {
				v = v.split(escapeChar).join(escapeChar + '1')
			}
			if (v.includes(separator)) {
				// normally we should never have "|2"
				v = v.split(separator).join(escapeChar + '2');
			}
			return v;
		})
		return encodedValues.join(separator);
	}

	static decodeAsArray(encodedValuesString:string, separator:string):string[] {
		if (!separator) {
			throw new Error('Separator cannot be empty otherwise it will be guaranteed to be decoded correctly')
		}
		if (!encodedValuesString) {
			return [];
		}

		const escapeChar = separator === ESCAPE_CHAR ? ESCAPE_CHAR2 : ESCAPE_CHAR;

		const result = encodedValuesString.split(separator).map(v => {
			if (v.includes(escapeChar)) {
				v = v.split(escapeChar + '2').join(separator);
				v = v.split(escapeChar + '1').join(escapeChar);
			}
			return v;
		})

		return result;
	}

	static uppercaseFirstLetter(value:string):string {
		if (!value) {
			return '';
		}
		const result = value.charAt(0).toUpperCase() + value.slice(1);
		return result;
	}

	/**
	 * Convert all non-visible characters to their unicode code (+ \n\t\r as that)
	 */
	static encodeNonVisibleCharacters(value:string):string {
		let temp = value;
		// simplify usual invisible characters
		temp = temp.replace(/\u0009/g, '[\\t]');
		temp = temp.replace(/\u000d/g, '[\\r]');
		temp = temp.replace(/\u000a/g, '[\\n]');
		// display unicode for others
		temp = temp.replace(/[^\x20-\x7E]/g, function(char) {
			return '[\\u' + char.charCodeAt(0).toString(16).padStart(4, '0') + ']';
		});
		return temp;
	}
}
