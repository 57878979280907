import {Task_JiraSecurity} from "@commons/models/TaskJiraSecurity";
import * as React from "react";
import {CrudModel} from "../../../../commons/types/CrudModel";
import {ClosureColumnDef} from "../../../../framework/table/columns/ClosureColumnDef";
import {StringColumnDefOps} from "../../../../framework/table/columns/StringColumnDef";
import {JiraStatus} from "./JiraStatus";

const wrapper = (row:Task_JiraSecurity) => {
	return <JiraStatus label={row.targetStatus!} color={row.ticketStatusColor} />;
}

export class JiraStatusColumnDef<T extends CrudModel & Task_JiraSecurity> extends ClosureColumnDef<T> {
	constructor(opts:StringColumnDefOps = {size:100, sortable:false}) {
		super('targetStatusNum', 'Jira Status', wrapper, opts);
	}
}
