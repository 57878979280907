import {FunctionNM} from "@commons/types/FunctionNM";
import {useCallback} from 'react';
import {ClassHelper} from "../../commons/helpers/ClassHelper";

import "./MultiSelectGroup.scss";

type MultiSelectItem<T = any> = {
	value:T
	label:string
	className?:string
	icon?:FunctionNM<any, JSX.Element>
	title?:string
	activeClassName?:string
}

export type MultiSelectProps<T> = {
	label?:string
	allValues:T[]
	items:MultiSelectItem<T>[]
	onChange?:FunctionNM<[T], any>
	className?:string
	title?:string
	id?:string
}

export const MultiSelectGroup = <T, >({
	                                 label,
	                                 allValues,
	                                 onChange,
	                                 items,
	                                 className,
	                                 title,
	                                 id,
                                 }:MultiSelectProps<T>) => {

	const handleClick = useCallback((newValue:T) => {
		onChange && onChange(newValue);
	}, [onChange]);

	return (
		<div className={ClassHelper.combine('MultiSelectGroup', className)}
		     id={id}
		     title={title ? title : undefined}>
			{label && (
				<span className="MultiSelectGroupLabel">{label}</span>)
			}
			{items.map((item, index) => (
				// not expected to have a dynamic list of items, hence the key=index
				<MultiSelectGroupItem key={index} item={item} selected={allValues?.includes(item.value)} onClick={handleClick} />
			))}
		</div>
	);
}

type MultiSelectGroupItemProps<T> = {
	selected:boolean
	item:MultiSelectItem<T>
	onClick?:FunctionNM<[T], any>
}

const MultiSelectGroupItem = <T, >({selected, item, onClick}:MultiSelectGroupItemProps<T>) => {
	const handleClick = useCallback(() => {
		onClick && onClick(item.value);
	}, [item.value, onClick]);

	const Icon = item.icon;
	return (
		<div className={ClassHelper.combine('MultiSelectGroupItem', item.className, selected && 'selected', selected && item.activeClassName)}
		     onClick={handleClick}
		     title={item.title ? item.title : undefined}>
			<>
				{item.label}
				{Icon && <Icon />}
			</>
		</div>
	)
};
