import * as React from "react";
import {ReactNode} from "react";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {CrudModel} from '../../../commons/types/CrudModel';
import {IconBoolean} from "../../components/IconBoolean";
import {ColDefType} from "../ColDefType";
import {ColSizeType} from "../ColSizeType";
import {CrudTableColumnDefinition} from "../CrudTableColumnDefinition";

//TODO add tooltip
export class BooleanColumnDef<T extends CrudModel> implements CrudTableColumnDefinition<T> {
	type:ColDefType = 'boolean';

	defaultVisibility:boolean;
	sortable:boolean;
	fixed:boolean;
	headerClassName:string;
	cellClassName:string;
	headerTitle?:string;

	private iconTrue:string;
	private classTrue:string;
	private iconFalse:string;
	private classFalse:string;

	constructor(public name:string,
	            public header:string,
	            public rowToValue:((row:T) => boolean | null),
	            opts:{
		            defaultVisibility?:boolean, sortable?:boolean, fixed?:boolean,
		            iconTrue?:string, classTrue?:string,
		            iconFalse?:string, classFalse?:string,
		            cellClassName?:string, headerClassName?:string,
		            size?:ColSizeType, title?:string
	            } = {}) {
		this.defaultVisibility = opts.defaultVisibility ?? true;
		this.sortable = opts.sortable ?? true;
		this.fixed = opts.fixed ?? false;
		this.iconTrue = opts.iconTrue ?? 'check';
		this.classTrue = opts.classTrue ?? 'green-icon';
		this.iconFalse = opts.iconFalse ?? 'close';
		this.classFalse = opts.classFalse ?? 'red-icon';
		this.headerTitle = opts.title;

		this.cellClassName = ClassHelper.combine('BooleanColumnDef', opts.cellClassName, opts.size && `column-size-${opts.size}`);
		this.headerClassName = ClassHelper.combine('BooleanColumnDef', opts.headerClassName, opts.size && `column-size-${opts.size}`);
	}

	computeCell(row:T):ReactNode {
		const value = this.rowToValue(row);
		if (value === true || value === false) {
			return (
				<IconBoolean value={value} />
			)
		} else {
			return <div />;
		}
	}
}
