import * as React from "react";
import {ReactNode} from "react";
import {MdHelpOutline} from "react-icons/md";

import "./FormRow.scss"

export type FormRowProps = {
	label:string
	className?:string
	inlineHelp?:string
	tooltipHelp?:string
}

type FormRowPropsInternal = {
	errorMessage?:string | string[]
	labelId?:string
	children:ReactNode
}

type FormRowPropsAll = FormRowProps & FormRowPropsInternal;

export const FormRow = ({
	                        label, className, inlineHelp, tooltipHelp,
	                        errorMessage, labelId, children
                        }:FormRowPropsAll) => {
	const classes = ['FormRow', 'row'];
	if (className) {
		classes.push(className);
	}
	if (Array.isArray(errorMessage)) {
		errorMessage = errorMessage.join(', ');
	}

	return (
		<div className={classes.join(' ')}>
			<div className="row-label col-4">
				<label htmlFor={labelId}>
					{label}
				</label>
				{tooltipHelp && (
					<MdHelpOutline className="tooltip-help" title={tooltipHelp} />
				)}
			</div>
			<div className="row-value col-8">
				{children}

				{inlineHelp && (
					<span className="inline-help">{inlineHelp}</span>
				)}
				{/*{warningMessage && (*/}
				{/*	<span className="warning-message">{warningMessage}</span>*/}
				{/*)}*/}
				{errorMessage && (
					<span className="error-message">{errorMessage}</span>
				)}
			</div>
		</div>
	);
}
