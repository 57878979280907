import {StringHelper} from "@commons/helpers/StringHelper";

const NUMERICAL_SIZE = 10;
const ALPHABETICAL_SIZE = 26;

const CHARCODE_0 = 48;
const CHARCODE_a = 97;
const CHARCODE_A = 65;

type Props = {
    text: string
}

//TODO not sure it's really needed as the inline function seems to be enough
export const DemoModeText = ({text}: Props) => {
    return demoModeInline(text)
}

// Debug just in case it starts to become too slow
// let totalTime = 0;
(window as any)._demoModeTotalTime = 0;

export function demoModeInline(text:string):string;
export function demoModeInline(text:string | null):string | null;
export function demoModeInline(text:string | undefined):string | undefined;
export function demoModeInline(text:string | null | undefined):string | null | undefined{
    if (text === null) {
        return null;
    }
    if (text === undefined) {
        return undefined;
    }
    const startTime = Date.now();

    const demoMode = localStorage.getItem('demo') !== null;
    
    // // e.g., ?demo=1
    // const urlQueryPart = window.location.search;
    // const params = new URLSearchParams(urlQueryPart);
    //
    // if (params.has('demo')) {
    if (demoMode) {
        const stringHash = StringHelper.hashCode(text);
        const seed = Math.abs(stringHash);

        text = text.replaceAll(/[a-zA-Z0-9]/g, char => {
            const charCode = char.charCodeAt(0);
            // Shift alphanumeric characters
            if (char >= '0' && char <= '9') {
                return String.fromCharCode(((charCode - CHARCODE_0 + seed) % NUMERICAL_SIZE) + CHARCODE_0);
            } else if (char >= 'a' && char <= 'z') {
                return String.fromCharCode(((charCode - CHARCODE_a + seed) % ALPHABETICAL_SIZE) + CHARCODE_a);
            } else if (char >= 'A' && char <= 'Z') {
                return String.fromCharCode(((charCode - CHARCODE_A + seed) % ALPHABETICAL_SIZE) + CHARCODE_A);
            } else {
                // Non-alphanumeric characters remain unchanged
                return char;
            }
        });
    }
    const endTime = Date.now();
    // totalTime += endTime - startTime;
    (window as any)._demoModeTotalTime += endTime - startTime;
    return text;
}
