import {WithDbAndId} from "./DbType";
import {
	Task_JiraSecurity_AdvisoryData,
	Task_JiraSecurity_Assign,
	Task_JiraSecurity_CertAttention,
	Task_JiraSecurity_Credit,
	Task_JiraSecurity_DisclosureDeadline,
	Task_JiraSecurity_Labels,
	Task_JiraSecurity_NonCertComment,
	Task_JiraSecurity_Reproduction,
	Task_JiraSecurity_Review,
	Task_JiraSecurity_Triage
} from "../models/TaskJiraSecurity";

export type AllSecurityTasksType = [
		WithDbAndId<Task_JiraSecurity_Triage> | null,
		WithDbAndId<Task_JiraSecurity_Labels> | null,
		WithDbAndId<Task_JiraSecurity_Reproduction> | null,
		WithDbAndId<Task_JiraSecurity_Assign> | null,
		WithDbAndId<Task_JiraSecurity_Credit> | null,
		WithDbAndId<Task_JiraSecurity_AdvisoryData> | null,
		WithDbAndId<Task_JiraSecurity_Review> | null,
		WithDbAndId<Task_JiraSecurity_NonCertComment> | null,
		WithDbAndId<Task_JiraSecurity_CertAttention> | null,
		WithDbAndId<Task_JiraSecurity_DisclosureDeadline> | null,
];

export const AllSecurityTasksTypeNameArray = [
	'To triage',
	'Missing labels',
	'To reproduce',
	'To assign',
	'Missing credit',
	'Advisory data',
	'To review',
	'Non CERT comment',
	'CERT attention',
	'Disclosure deadline',
];
