import {IsoDateString, IsoDayString} from "../types/IsoDateString";

export class DateHelper {
	private static readonly DAY_DURATION_MS = 24 * 60 * 60 * 1000;

	static nowIso():IsoDateString {
		return new Date().toISOString() as IsoDateString;
	}

	/**
	 * @param plusValue can be negative
	 */
	static nowIsoPlus(plusValue:number):IsoDateString {
		return DateHelper.addMs(DateHelper.nowIso(), plusValue);
	}

	static addMs(baseDate:IsoDateString, additionInMs:number):IsoDateString{
		return DateHelper.toIso(new Date(DateHelper.parseDate(baseDate).getTime() + additionInMs))
	}

	static toIso(date:Date):IsoDateString {
		return date.toISOString() as IsoDateString;
	}

	static toIsoDay(date:Date):IsoDayString {
		return date.toISOString().split('T')[0] as IsoDayString;
	}

	static parseAndFormatToIso(dateString:string):IsoDateString {
		return new Date(dateString).toISOString();
	}

	static parseDate(dateString:IsoDateString):Date {
		return new Date(dateString);
	}

	static isOlderThanNowMinusMs(dateIso:IsoDateString, millisToRemoveToNow:number):boolean {
		const nowMs = Date.now();
		const nowMinusMs = new Date(nowMs - millisToRemoveToNow);
		return new Date(dateIso) < nowMinusMs;
	}

	static minOfDates(dateIsoA:IsoDateString | null, dateIsoB:IsoDateString | null):IsoDateString | null {
		if (dateIsoA) {
			if (dateIsoB) {
				if (dateIsoA < dateIsoB) {
					return dateIsoA;
				} else {
					return dateIsoB;
				}
			} else {
				return dateIsoA;
			}
		} else {
			if (dateIsoB) {
				return dateIsoB;
			} else {
				return null;
			}
		}
	}

	static businessDiffMs(fromDateString:IsoDateString, toDateString:IsoDateString):number {
		const fromDate = new Date(fromDateString);
		const fromDateTime = fromDate.getTime();
		const toDate = new Date(toDateString);
		const toDateTime = toDate.getTime();
		if (isNaN(fromDateTime)) {
			throw new Error('From is invalid, from=' + fromDateString);
		}
		if (isNaN(toDateTime)) {
			throw new Error('To is invalid, to=' + toDateString);
		}
		if (fromDateTime > toDateTime) {
			throw new Error('From should be smaller than to, from=' + fromDateString + ', to=' + toDateString);
		}

		const fromDateNextDay = this.toNextDay(fromDate);
		const fromDateNextDayTime = fromDateNextDay.getTime();
		const distanceBetweenFromAndTomorrowInMs = fromDateNextDayTime - fromDateTime;

		const toDateStartOfDay = this.toStartingOfDay(toDate);
		const toDateStartOfDayTime = toDateStartOfDay.getTime();
		const distanceBetweenToAndStartingOfDayInMs = toDateTime - toDateStartOfDayTime;

		let totalMs = 0;
		// [   a  ][      ][  b   ]
		if (fromDateNextDayTime < toDateStartOfDayTime) {
			if (this.isWeekday(fromDate)) {
				totalMs += distanceBetweenFromAndTomorrowInMs;
			}
			if (this.isWeekday(toDate)) {
				totalMs += distanceBetweenToAndStartingOfDayInMs;
			}
			// TODO could be optimized for period greater than one week by reusing the same week pattern
			let curr = fromDateNextDay;
			while (curr < toDateStartOfDay) {
				if (this.isWeekday(curr)) {
					totalMs += this.DAY_DURATION_MS;
				}
				curr = this.toNextDay(curr);
			}
		}
		// [      ][  a   ][  b   ]
		else if (fromDateNextDayTime === toDateStartOfDayTime) {
			if (this.isWeekday(fromDate)) {
				totalMs += distanceBetweenFromAndTomorrowInMs;
			}
			if (this.isWeekday(toDate)) {
				totalMs += distanceBetweenToAndStartingOfDayInMs;
			}
		}
		// [      ][  a b ][      ]
		else {
			if (this.isWeekday(fromDate)) {
				totalMs = toDateTime - fromDateTime;
			}
		}

		return totalMs;
	}

	static diffInDays(fromDateString:IsoDateString, toDateString:IsoDateString):number {
		const fromDate = new Date(fromDateString);
		const fromDateTime = fromDate.getTime();
		const toDate = new Date(toDateString);
		const toDateTime = toDate.getTime();
		if (isNaN(fromDateTime)) {
			throw new Error('From is invalid, from=' + fromDateString);
		}
		if (isNaN(toDateTime)) {
			throw new Error('To is invalid, to=' + toDateString);
		}
		if (fromDateTime > toDateTime) {
			throw new Error('From should be smaller than to, from=' + fromDateString + ', to=' + toDateString);
		}

		const diffInMs = toDateTime - fromDateTime;
		const DAY_DURATION = (1000 * 3600 * 24);

		const diffInDays = diffInMs / DAY_DURATION;
		return diffInDays;
	}

	private static isWeekday(date:Date):boolean {
		const day = date.getDay();
		return day !== 0 && day !== 6;
	}

	private static toNextDay(date:Date):Date {
		const result = new Date(date);
		// take into account the month/year change
		result.setDate(result.getDate() + 1);
		return this.toStartingOfDay(result);
	}

	private static toStartingOfDay(date:Date):Date {
		const result = new Date(date);
		result.setHours(0, 0, 0, 0);
		return result;
	}
}
