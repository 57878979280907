import {DateFormats} from "@commons/constants/DateFormats";
import {ActionLog} from "@commons/models/ActionLog";
import {WithDbAndId} from "@commons/types/DbType";
import {DateTime} from "luxon";
import * as React from "react";
import {useCallback} from "react";
import {MdRefresh} from "react-icons/md";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {IconButton} from "../../../../framework/components/IconButton";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {CrudPage2, CrudPageLayout, CrudPageLayoutBasic} from "../../../../framework/crud2/CrudPage2";
import {DateColumnDef} from "../../../../framework/table/columns/DateColumnDef";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {IntegerColumnDef} from "../../../../framework/table/columns/IntegerColumnDef";
import {MetaInfoColumnDef} from "../../../../framework/table/columns/MetaInfoColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {Pagination2} from "../../../../framework/table/Pagination2";
import {Table2} from "../../../../framework/table/Table2";

type Model = WithDbAndId<ActionLog>;

const crudService = new class ActionLogService extends AbstractApiService<Model> {
	constructor() {
		super('api/user/action-log');
	}
}();

export const ActionLogListPage = () => {
	// const currentLocation = useLocation();
	// const {authInfo} = useAuth();

	// const filterAssign = useCallback((state:CrudPage2State):boolean | null => {
	// 	const currFilterActive = state.filters.find((f:string) => f.startsWith('assignee:'));
	// 	if (currFilterActive === 'assignee:s:eq:' + authInfo!.login) {
	// 		return true;
	// 	} else if (currFilterActive === 'assignee:s:eq:null') {
	// 		return false;
	// 	} else {
	// 		return null;
	// 	}
	// }, [authInfo]);

	// const filterAssignChange = useCallback((newValue:boolean | null, state:CrudPage2State, dispatch:CrudPage2Dispatcher) => {
	// 	const currValue = filterAssign(state);
	// 	if (currValue === newValue) {
	// 		dispatch({type:'changeFilters', removeFields:['assignee']})
	// 		return;
	// 	}
	// 	if (newValue === true) {
	// 		//TODO put @me or similar
	// 		dispatch({type:'changeFilters', addFilters:['assignee:s:eq:' + authInfo!.login]})
	// 	} else {
	// 		dispatch({type:'changeFilters', addFilters:['assignee:s:eq:null']})
	// 	}
	// }, [authInfo, filterAssign]);

	const {columnDefs, initialState} = useColumnDefs<Model>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'ID', row => row.id + '', {sortable:false, defaultVisibility:false}).nowrap(),
		new IntegerColumnDef('version', 'Version', row => row.version, {
			sortable:false,
			defaultVisibility:false
		}).nowrap(),
		new StringColumnDef('type', 'Type', row => row.type || '', {
			sortable:false,
			size:225
		}),
		new StringColumnDef('subType', 'Subtype', row => row.subType || '', {
			sortable:false,
			size:225
		}),
		new StringColumnDef('requester', 'Requester', row => row.requester || row.creator || '', {
			sortable:false,
			size:175
		}),
		new StringColumnDef('meta', 'Meta', row => row.meta ? JSON.stringify(row.meta, null, 3) : '', {
			sortable:false,
			size:'expand'
		}),
		new MetaInfoColumnDef(),
		new DateColumnDef('createdDate', 'Created', DateFormats.FULL, row => row.updatedDate, {
			sortable:false,
			defaultVisibility:false,
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			size:175
		}),
		new DateColumnDef('updatedDate', 'Updated', DateFormats.FULL, row => row.updatedDate, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			size:175
		}),
		// new IconColumnDef('update', 'Update the item', MdEdit, (row) => openUpdateModal(row), {iconClassName:'hover-info'}),
		// new IconColumnDef('update', 'Update the item', MdEdit, openUpdateModalWrapper, {openUpdateModal}, {iconClassName:'hover-info'}),
		// new IconColumnDef('delete', 'Delete the item', MdDelete, deleteOneItem, {}, {iconClassName:'hover-error'}),
	], {initialPageSize:20, initialOrder:'-updatedDate'});

	return (
		<CrudPage2 pageId="action-log" pageClass="ActionLogListPage" crudService={crudService}
		           initialState={initialState}
		           layout={useCallback((state, itemsData, dispatch) => (
			           <CrudPageLayoutBasic
				           pageName="Action log"
				           topLeftActions={<>
					           <IconButton icon={MdRefresh} title="Refresh data"
					                       onClick={() => dispatch({type:'reloadContent'})} />

					           {/*{instructionMessage && (*/}
					           {/*    <InfoPanel size="small">{instructionMessage}</InfoPanel>*/}
					           {/*)}*/}
				           </>}
				           topRightActions={<>
					           {/*<TextButton label="Reset filter" onClick={() => resetFilter(dispatch)} />*/}

					           {/*<ToggleGroup label="Done" value={filterDone(state)}*/}
					           {/*             onChange={(newValue) => filterDoneChange(newValue, state, dispatch)}*/}
					           {/*             items={[*/}
					           {/*                 {*/}
					           {/*                     value:true,*/}
					           {/*                     icon:MdCheck,*/}
					           {/*                     activeClassName:'icon-within-green',*/}
					           {/*                     title:'Only display queries without errors'*/}
					           {/*                 },*/}
					           {/*                 {*/}
					           {/*                     value:false,*/}
					           {/*                     icon:MdClose,*/}
					           {/*                     activeClassName:'icon-within-red',*/}
					           {/*                     title:'Only display queries with errors'*/}
					           {/*                 },*/}
					           {/*             ]} />*/}
					           {/*<ToggleGroup label="Assignee" value={filterAssign(state)}*/}
					           {/*             onChange={(newValue) => filterAssignChange(newValue, state, dispatch)}*/}
					           {/*             items={[*/}
					           {/*                 {*/}
					           {/*                     value:true,*/}
					           {/*                     icon:MdPerson,*/}
					           {/*                     activeClassName:'icon-within-blue',*/}
					           {/*                     title:'Only display tasks assigned to me'*/}
					           {/*                 },*/}
					           {/*                 {*/}
					           {/*                     value:false,*/}
					           {/*                     icon:MdPersonOff,*/}
					           {/*                     activeClassName:'icon-within-red',*/}
					           {/*                     title:'Only display unassigned tasks'*/}
					           {/*                 },*/}
					           {/*             ]} />*/}
				           </>}
				           table={<>
					           <Table2 columnDefs={columnDefs} className="fluid"
					                   dataState={itemsData} state={state} dispatch={dispatch} />
				           </>}
				           bottomLeftAction={<>
					           <div>Selection: {state.selection.length} (TODO)</div>
				           </>}
				           bottomRightAction={<>
					           <Pagination2 pagination={state.pagination} dispatch={dispatch}
					                        filteredTotalResults={itemsData.data?.meta?.filteredTotal}
					                        totalResults={itemsData.data?.meta?.total}
					           />
				           </>} />
		           ), [columnDefs, /*filterAssign, filterAssignChange,*/]) as CrudPageLayout<Model>}
		/>
	)
}
