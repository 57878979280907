export type GitHubPermissionToolingResponse_All = {
	teams:GitHubPermissionToolingResponse_Teams_Item[]
	collaborators:GitHubPermissionToolingResponse_Collaborators_Item[]
};

export type GitHubPermissionToolingResponse_Teams = {
	teams:GitHubPermissionToolingResponse_Teams_Item[]
};

export type GitHubPermissionToolingResponse_Teams_Item = {
	teamSlug:string
	// role:GitHubPermissionToolingResponse_RoleType
	permission:GitHubPermissionToolingResponse_PermissionType
}

export type GitHubPermissionToolingResponse_Collaborators = {
	collaborators:GitHubPermissionToolingResponse_Collaborators_Item[]
};

export type GitHubPermissionToolingResponse_Collaborators_Item = {
	login:string
	// role:GitHubPermissionToolingResponse_RoleType
	permission:GitHubPermissionToolingResponse_PermissionType
}

// In order of importance: pull (= read), triage, push (= write), maintain, admin
export const GitHubPermissionToolingResponse_Permission_ADMIN = 'admin';
export const GitHubPermissionToolingResponse_Permission_MAINTAIN = 'maintain';
/** Displayed as "Write" in GitHub interface */
export const GitHubPermissionToolingResponse_Permission_PUSH = 'push';
export const GitHubPermissionToolingResponse_Permission_TRIAGE = 'triage';
/** Displayed as "Read" in GitHub interface */
export const GitHubPermissionToolingResponse_Permission_PULL = 'pull';

export type GitHubPermissionToolingResponse_PermissionType =
	typeof GitHubPermissionToolingResponse_Permission_ADMIN
	| typeof GitHubPermissionToolingResponse_Permission_MAINTAIN 
	| typeof GitHubPermissionToolingResponse_Permission_PUSH 
	| typeof GitHubPermissionToolingResponse_Permission_TRIAGE 
	| typeof GitHubPermissionToolingResponse_Permission_PULL 
	// typeof GitHubPermissionToolingResponse_Role_WRITE |
	// typeof GitHubPermissionToolingResponse_Role_READ |
	;

