import {CommandStatusType} from "@commons/types/CommandStatusType";
import * as React from "react";
import {ClassHelper} from "../../../../../commons/helpers/ClassHelper";

import "./CommandStatus.scss";

type CommandStatusProps = {
	className?:string
	status:CommandStatusType
}

export const CommandStatus = ({status, className}:CommandStatusProps) => {
	let color, label;
	switch (status) {
		case 'success':
			color = 'green';
			label = 'Success';
			break;
		case 'error':
			color = 'warm-red';
			label = 'Error';
			break;
		case 'progress':
			color = 'braun';
			label = 'In progress';
			break;
		case 'unknown':
			color = 'blue-gray';
			label = 'Unknown';
			break;
		case 'invalid':
		default:
			color = 'purple';
			label = 'Invalid';
			break;
	}

	return (
		<div className={ClassHelper.combine('CommandStatus', className, color)}>
			{label}
		</div>
	)
}
