import {Cursor} from "../crud2/CrudPage2";
import {PaginationInfo_DEFAULT} from "./features/PaginationFeature";

export type QueryData = {
	limit?:number, offset?:number, filters?:string
	search?:string, order?:string, fetchEntities?:string[],
	cursor?:Cursor | null,
	cacheId:number
}

export const QueryData_DEFAULT = {
	// limit:25, offset:0, 
	limit:PaginationInfo_DEFAULT.pageSize, offset:PaginationInfo_DEFAULT.pageSize * (PaginationInfo_DEFAULT.pageNo - 1),
	filters:'',
	search:'', order:'', fetchEntities:[],
	cursor:null,
	cacheId:1
};
