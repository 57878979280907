import {CrudModelId} from "@commons/types/WithId";
import {CrudModel} from '../../commons/types/CrudModel';
import {apiFetch} from "../apiFetch";
import {ApiFetchResponse} from "../WrappedJsonFetch";
import {CrudApiService2, QueryGetBody, QueryListBody} from "./CrudApiService2";

/**
 * Naming convention: all children should be called AbcApiService, with "Abc" being the model name in plural form
 */
export abstract class AbstractApiService<T extends CrudModel> implements CrudApiService2<T> {
	protected constructor(protected urlFragmentPart:string) {
	}

	queryList(paramPart:string) {
		console.info(`queryList: ${paramPart}`);

		//TODO put the url/port in configuration
		const url = `${this.urlFragmentPart}?${paramPart}`;

		// setResult({status:'loading'});
		// setResult(STATE_LOADING);
		// setLastQuery(paramPart)

		return apiFetch.get<QueryListBody<T>>(url);
	}

	getOne(id:string):Promise<ApiFetchResponse<QueryGetBody<T>>> {
		console.info(`getOne(${id})`);
		const url = `${this.urlFragmentPart}/${encodeURIComponent(id)}`;

		return apiFetch.get<QueryGetBody<T>>(url);
	}

	createOne(data:Omit<T, 'id'>) {
		console.info(`createOne`);

		return apiFetch.post<T>(this.urlFragmentPart, data);
	}

	updateOne(data:T) {
		const url = `${this.urlFragmentPart}/${encodeURIComponent(data.id)}`;
		return apiFetch.put<T>(url, data);
	}

	partialUpdateOne(data:Partial<T>) {
		const url = `${this.urlFragmentPart}/${encodeURIComponent(data.id!)}`;
		return apiFetch.patch<T>(url, data);
	}

	deleteOne(id:CrudModelId, version:number) {
		const url = `${this.urlFragmentPart}/${encodeURIComponent(id)}?version=${version}`;
		return apiFetch.delete<{ done:boolean }>(url);
	}
}
