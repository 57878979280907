import {DateFormats} from "@commons/constants/DateFormats";
import {DurationHelper} from "@commons/helpers/DurationHelper";
import {RpuCommentCommand} from "@commons/models/RpuCommentCommand";
import {WithDbAndId} from "@commons/types/DbType";
import {DateTime} from "luxon";
import * as React from "react";
import {useCallback} from "react";
import {MdRefresh, MdWeb} from "react-icons/md";
import {useLocation} from "react-router";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {IconButton} from "../../../../framework/components/IconButton";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {CrudPage2, CrudPageLayout, CrudPageLayoutBasic} from "../../../../framework/crud2/CrudPage2";
import {DateColumnDef} from "../../../../framework/table/columns/DateColumnDef";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {ExternalLinkColumnDef} from "../../../../framework/table/columns/ExternalLinkColumnDef";
import {IntegerColumnDef} from "../../../../framework/table/columns/IntegerColumnDef";
import {InternalLinkColumnDef} from "../../../../framework/table/columns/InternalLinkColumnDef";
import {MetaInfoColumnDef} from "../../../../framework/table/columns/MetaInfoColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {Pagination2} from "../../../../framework/table/Pagination2";
import {Table2} from "../../../../framework/table/Table2";
import {CommandStatusColumnDef} from "./commons/CommandStatusColumnDef";

type Model = WithDbAndId<RpuCommentCommand>;

const crudService = new class RpuCommentCommandService extends AbstractApiService<Model> {
	constructor() {
		super('api/user/rpu-comment-command');
	}
}();

export const RpuCommentCommandListPage = () => {
	const currentLocation = useLocation();

	const {columnDefs, initialState} = useColumnDefs<Model>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'ID', row => row.id + '', {sortable:false, defaultVisibility:false}).nowrap(),
		new IntegerColumnDef('version', 'Version', row => row.version, {
			sortable:false,
			defaultVisibility:false
		}).nowrap(),
		new StringColumnDef('issueId', 'Issue', row => '#' + row.issueId, {sortable:false, size:110}),
		new StringColumnDef('commentId', 'Comment', row => ((row.commentId === -1) ? 'Issue creation' : '' + row.commentId), {
			sortable:false,
			size:110
		}),
		new ExternalLinkColumnDef('openOriginLink', row => {
			if (row.commentId === -1) {
				return `https://github.com/${row.fromOrg}/${row.fromRepo}/issues/${row.issueId}`
			} else {
				return `https://github.com/${row.fromOrg}/${row.fromRepo}/issues/${row.issueId}#issuecomment-${row.commentId}`
			}
		}),
		new StringColumnDef('requester', 'Requester', row => row.requester || '', {
			sortable:false,
			size:200
		}),
		new CommandStatusColumnDef(),
		new StringColumnDef('targetOrg', 'Target', row => `${row.targetOrg} / ${row.targetRepo}` || '', {
			sortable:false,
			size:'expand'
		}),
		new ExternalLinkColumnDef('openTargetLink', row => `https://github.com/${row.targetOrg}/${row.targetRepo}`),
		new StringColumnDef('script', 'Script', row => row.script || '', {
			sortable:false,
			size:210,
			title:'The command once translated to a cert-automation script if relevant',
			inlineCode:true,
		}),
		new InternalLinkColumnDef('browseToItem', row => ({
			state:{fromView:currentLocation, task:row},
			pathname:`./${encodeURIComponent(row.id)}`
		}), {icon:MdWeb, title:'Browse to details page'}),
		new StringColumnDef('duration', 'Duration', row => row.duration > 0 ? DurationHelper.toString(row.duration) : '', {
			sortable:false,
			size:75,
		}),
		new IntegerColumnDef('numberOfFindings', '# Findings', row => row.numberOfFindings, {
			sortable:false,
			size:100
		}),
		new MetaInfoColumnDef(),
		new DateColumnDef('createdDate', 'Created', DateFormats.FULL, row => row.updatedDate, {
			sortable:false,
			defaultVisibility:false,
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			size:175
		}),
		new DateColumnDef('updatedDate', 'Updated', DateFormats.FULL, row => row.updatedDate, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			size:175
		}),
	], {initialPageSize:20, initialOrder:'-updatedDate'});

	return (
		<CrudPage2 pageId="rpu-comment-command" pageClass="RpuCommentCommandListPage" crudService={crudService}
		           initialState={initialState}
		           layout={useCallback((state, itemsData, dispatch) => (
			           <CrudPageLayoutBasic
				           pageName="RPU Comment command"
				           topLeftActions={<>
					           <IconButton icon={MdRefresh} title="Refresh data"
					                       onClick={() => dispatch({type:'reloadContent'})} />
				           </>}
				           topRightActions={<>
				           </>}
				           table={<>
					           <Table2 columnDefs={columnDefs} className="fluid"
					                   dataState={itemsData} state={state} dispatch={dispatch} />
				           </>}
				           bottomLeftAction={<>
					           <div>Selection: {state.selection.length} (TODO)</div>
				           </>}
				           bottomRightAction={<>
					           <Pagination2 pagination={state.pagination} dispatch={dispatch}
					                        filteredTotalResults={itemsData.data?.meta?.filteredTotal}
					                        totalResults={itemsData.data?.meta?.total}
					           />
				           </>} />
		           ), [columnDefs]) as CrudPageLayout<Model>}
		/>
	)
}
