import {JENKINS_ORG} from "@commons/constants/JenkinsConstants";
import {SortHelper} from "@commons/helpers/SortHelper";
import {
	GitHubAdvisoryToolingResponse_Advisories,
	GitHubAdvisoryToolingResponse_Advisories_CreditType,
	GitHubAdvisoryToolingResponse_Advisories_Item
} from "@commons/protocol/GitHubAdvisoryToolingResponse";
import * as React from "react";
import {useCallback, useState} from "react";
import {MdEventBusy, MdEventRepeat, MdOpenInNew, MdOutlineEditCalendar, MdPersonOutline} from "react-icons/md";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";
import {LogIfMountedType, useLogIfStillMounted} from "../../../../commons/hooks/useLogIfStillMounted";
import {apiFetch} from "../../../../framework/apiFetch";
import {Card} from "../../../../framework/components/Card";
import {Divider} from "../../../../framework/components/Divider";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {LinkButton} from "../../../../framework/components/LinkButton";
import {LogPanel, useLogPanel} from "../../../../framework/components/LogPanel";
import {TextButton} from "../../../../framework/components/TextButton";
import "./GitHubAdvisoryToolingPage.scss";

export const GitHubAdvisoryToolingPage = () => {
	const {logRows, appendLog, clearLog} = useLogPanel();
	const {logIfMounted} = useLogIfStillMounted(appendLog);

	const [lastOrg, setLastOrg] = useState<string>();
	const [lastRepo, setLastRepo] = useState<string>();
	const [listing, setListing] = useState<GitHubAdvisoryToolingResponse_Advisories_Item[] | null>(null);

	/*
	 *  Emails:
	 *      - get total number of emails in the account
	 *      - get current number of emails tracked
	 *      - force re-processing of emails (re-sync)
	 *  JiraJenkins:
	 *      - run search to find number of unlabelled tickets
	 *      - run search to find number of labelled tickets
	 *      - label + attach watcher on tickets (ease task of recurrent task)
	 *      - reprocess labelled tickets (re-sync)
	 */
	const searchListingPerRepo = useCallback(() => {
		const org = document.querySelector<HTMLInputElement>('#listing_org')!.value.trim();
		const repo = document.querySelector<HTMLInputElement>('#listing_repo')!.value.trim();
		if (!org || !repo) {
			appendLog(`The org/repo are mandatory`);
			return;
		}

		appendLog(`Listing for org=${org}, repo=${repo}`);

		const url = `api/user/github-advisory-tooling/advisories?org=${encodeURIComponent(org)}&repo=${encodeURIComponent(repo)}`;
		apiFetch.get<GitHubAdvisoryToolingResponse_Advisories>(url).then(response => {
			logIfMounted(response)
			if (response.ok) {
				setLastOrg(org);
				setLastRepo(repo);
				setListing(response.payload.advisories);
			} else {
				setLastOrg(org);
				setLastRepo(repo);
				setListing(null);
			}
		});
	}, [appendLog, logIfMounted]);

	const [org, setOrg] = useState<string>(JENKINS_ORG);

	return (
		<PageContent className="GitHubAdvisoryToolingPage">
			<div>
				List advisories per GitHub repository
				<input id="listing_org" placeholder="jenkinsci" value={org}
				       onChange={event => setOrg(event.target.value)} />
				<input id="listing_repo" placeholder="strict-crumb-issuer-plugin" />
				<TextButton label="Search" onClick={searchListingPerRepo} />

				{listing && (
					<div className="dynamic-response-panel">
						<div className="response-title">Response for {lastOrg}/{lastRepo}</div>
						{listing.length > 0 ? listing.concat().sort(SortHelper.byField('summary')).map(advisoryEntry => (
							<ListingAdvisoryEntry
								key={advisoryEntry.ghsa_id}
								orgName={lastOrg!}
								repoName={lastRepo!}
								advisoryEntry={advisoryEntry}
								logIfMounted={logIfMounted}
							/>
						)) : (
							<span className="no-entries">No entries found</span>
						)}
					</div>
				)}
			</div>
			<Divider />
			<Card>
				<LogPanel rows={logRows} numRowsToDisplay={30} onClearLog={clearLog} />
			</Card>
		</PageContent>
	);
}

type ListingAdvisoryEntryParams = {
	orgName:string
	repoName:string
	advisoryEntry:GitHubAdvisoryToolingResponse_Advisories_Item
	logIfMounted:LogIfMountedType
}

const ListingAdvisoryEntry = ({orgName, repoName, advisoryEntry, logIfMounted}:ListingAdvisoryEntryParams) => {
	const {ghsa_id, summary, state, credits} = advisoryEntry;
	// https://github.com/Wadeck/sample-repo-plugin/security/advisories/GHSA-jfq6-g86x-gpwc
	const viewUrl = `https://github.com/${encodeURIComponent(orgName)}/${encodeURIComponent(repoName)}/security/advisories/${encodeURIComponent(ghsa_id)}`;

	const creditsElements = credits.concat().sort(SortHelper.byFields('type', 'login')).map(c =>
		<ListingAdvisoryCredit orgName={orgName} repoName={repoName} login={c.login} type={c.type}
		                       key={c.login} logIfMounted={logIfMounted} />
	);

	const [currState, setCurrState] = useState(state);

	const reopenAdvisory = useCallback(() => {
		const confirmed = window.confirm(`Are you sure to reopen the advisory: ${summary}`);
		if (!confirmed) {
			return;
		}

		logIfMounted(`Reopening advisory ghsa_id=${ghsa_id}, summary=${summary}`);

		const url = `api/user/github-advisory-tooling/advisories/${encodeURIComponent(ghsa_id)}?org=${encodeURIComponent(orgName)}&repo=${encodeURIComponent(repoName)}&state=draft`;
		apiFetch.patch<void>(url).then(response => {
			if (response.ok) {
				logIfMounted(`Advisory reopened for ghsa_id=${ghsa_id}`);
				setCurrState('draft');
			} else {
				logIfMounted(`Error for the advisory reopen request for ghsa_id=${ghsa_id}`);
			}
			logIfMounted(response)
		});
	}, [orgName, repoName, ghsa_id, summary, logIfMounted]);
	const closeAdvisory = useCallback(() => {
		const confirmed = window.confirm(`Are you sure to close the advisory: ${summary}`);
		if (!confirmed) {
			return;
		}

		logIfMounted(`Closing advisory ghsa_id=${ghsa_id}, summary=${summary}`);

		const url = `api/user/github-advisory-tooling/advisories/${encodeURIComponent(ghsa_id)}?org=${encodeURIComponent(orgName)}&repo=${encodeURIComponent(repoName)}&state=closed`;
		apiFetch.patch<void>(url).then(response => {
			if (response.ok) {
				logIfMounted(`Advisory closed for ghsa_id=${ghsa_id}`);
				setCurrState('closed');
			} else {
				logIfMounted(`Error for the advisory close request for ghsa_id=${ghsa_id}`);
			}
			logIfMounted(response)
		});
	}, [orgName, repoName, ghsa_id, summary, logIfMounted]);

	return (
		<div className="ListingAdvisoryEntry">
			<span className="ListingAdvisoryName">
				<MdOutlineEditCalendar />
				<code className="ListAdvisoryId">{ghsa_id}</code>
				<span className="ListAdvisorySummary">{summary}</span>
				<code className="ListAdvisoryState">{currState}</code>
				<LinkButton href={viewUrl} label={"View"} icon={MdOpenInNew} size="s" />
				{currState === 'closed' ? (
					<TextButton label={"Reopen"} icon={MdEventRepeat} size="s" className="color-blue"
					            title="The advisory will be moved back to 'Draft' state"
					            onClick={reopenAdvisory} />
				) : (
					<TextButton label={"Close"} icon={MdEventBusy} size="s" className="color-red"
					            title="The advisory will not be deleted or hidden, just moved to the 'Closed' state"
					            onClick={closeAdvisory} />
				)}
			</span>
			<ol>{creditsElements}</ol>
		</div>
	)
}

type ListingAdvisoryCreditParams = {
	orgName:string
	repoName:string
	login:string
	type:GitHubAdvisoryToolingResponse_Advisories_CreditType
	logIfMounted:LogIfMountedType
}

// security2606-staging
// view: https://repo.jenkins-ci.org/ui/repos/tree/General/security2141-staging/io/jenkins/configuration-as-code
// direct download: https://repo.jenkins-ci.org/artifactory/security2141-staging/io/jenkins/configuration-as-code/1.55.1/configuration-as-code-1.55.1.hpi
const ListingAdvisoryCredit = ({
	                               orgName,
	                               repoName,
	                               login,
	                               type,
	                               logIfMounted
                               }:ListingAdvisoryCreditParams) => {
	const userUrl = `https://github.com/${login}`;

	return (
		<li className={ClassHelper.combine('ListingAdvisoryCredit')}>
			<MdPersonOutline />
			<code className="ListingAdvisoryCreditType">{type}</code>
			<span className="ListingAdvisoryCreditLogin">{login}</span>
			<LinkButton href={userUrl} label={"View"} icon={MdOpenInNew} size="s" />
		</li>
	)
}
