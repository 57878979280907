import * as React from "react";
import {useCallback} from "react";
import {useLogIfStillMounted} from "../../../../commons/hooks/useLogIfStillMounted";
import {apiFetch} from "../../../../framework/apiFetch";
import {Card} from "../../../../framework/components/Card";
import {Divider} from "../../../../framework/components/Divider";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {LogPanel, useLogPanel} from "../../../../framework/components/LogPanel";
import {TextButton} from "../../../../framework/components/TextButton";
import "./CertAutomationPage.scss";

const logWrapper = (appendLog:(data:any) => void) => {
	return (data:any) => {
		if (data && data.payload && data.payload.output) {
			let stdout = data.payload.output.stdout.replace(/\r/g, '');
			if (stdout.trim().length !== 0) {
				stdout = '==>\n' + stdout + '\n<==';
				data.payload.output.stdout = '$pattern1$';
			}

			let stderr = data.payload.output.stderr.replace(/\r/g, '');
			if (stderr.trim().length !== 0) {
				stderr = '==>\n' + stderr + '\n<==';
				data.payload.output.stderr = '$pattern2$';
			}

			let customOutput:string = '';
			if (data.payload.output.output !== undefined) {
				customOutput = data.payload.output.output.replace(/\r/g, '');
				if (customOutput === '' || customOutput === '[no-findings]') {
					customOutput = 'No findings found in that repository';
				}
				customOutput = '==>\n' + customOutput + '\n<==';
				data.payload.output.output = '$pattern3$';
			}

			let newData = JSON.stringify(data);
			newData = newData.replace(/\$pattern1\$/, stdout);
			newData = newData.replace(/\$pattern2\$/, stderr);
			if (data.payload.output) {
				newData = newData.replace(/\$pattern3\$/, customOutput);
			}
			const array = newData.split('\n');
			appendLog(array);
			return;
		}
		appendLog(data);
	}
}

export const CertAutomationPage = () => {
	const {logRows, appendLog, clearLog} = useLogPanel();
	const {logIfMounted} = useLogIfStillMounted(logWrapper(appendLog));

	/*
	 *  Emails:
	 *      - get total number of emails in the account
	 *      - get current number of emails tracked
	 *      - force re-processing of emails (re-sync)
	 *  JiraJenkins:
	 *      - run search to find number of unlabelled tickets
	 *      - run search to find number of labelled tickets
	 *      - label + attach watcher on tickets (ease task of recurrent task)
	 *      - reprocess labelled tickets (re-sync)
	 */
	const triggerJiraUpdatePluginLabels = useCallback(() => {
		const tickets = document.querySelector<HTMLInputElement>('#jiraUpdatePluginLabels_ticket')!.value.trim();
		appendLog(`Requesting jira-update-plugin-labels.sh with ticket=${tickets}`);

		const url = `api/user/task/jira-security/automation/jira-update-plugin-labels?ticket=${encodeURIComponent(tickets)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);
	const triggerJiraAssignIssue = useCallback(() => {
		const tickets = document.querySelector<HTMLInputElement>('#jiraAssignIssue_ticket')!.value.trim();
		appendLog(`Requesting jira-assign-issue.sh with ticket=${tickets}`);

		const url = `api/user/task/jira-security/automation/jira-assign-issue?ticket=${encodeURIComponent(tickets)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);
	const triggerCertFork = useCallback(() => {
		const repoName = document.querySelector<HTMLInputElement>('#certFork_repoName')!.value.trim();
		const ticket = document.querySelector<HTMLInputElement>('#certFork_ticket')!.value.trim();
		const collaborators = document.querySelector<HTMLInputElement>('#certFork_collaborators')!.value.trim();
		appendLog(`Requesting cert-fork.sh with repoName=${repoName}, ticket=${ticket} and collaborators=${collaborators}`);

		const url = `api/user/task/jira-security/automation/cert-fork?repoName=${encodeURIComponent(repoName)}&ticket=${encodeURIComponent(ticket)}&collaborators=${encodeURIComponent(collaborators)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);
	const triggerCertGrantAccess = useCallback(() => {
		const repoName = document.querySelector<HTMLInputElement>('#certGrantAccess_repoName')!.value.trim();
		const ticket = document.querySelector<HTMLInputElement>('#certGrantAccess_ticket')!.value.trim();
		const collaborators = document.querySelector<HTMLInputElement>('#certGrantAccess_collaborators')!.value.trim();
		appendLog(`Requesting cert-grant-access.sh with repoName=${repoName}, ticket=${ticket} and collaborators=${collaborators}`);

		const url = `api/user/task/jira-security/automation/cert-grant-access?repoName=${encodeURIComponent(repoName)}&ticket=${encodeURIComponent(ticket)}&collaborators=${encodeURIComponent(collaborators)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);
	const triggerUpdateDefaultBranch = useCallback(() => {
		const repoName = document.querySelector<HTMLInputElement>('#updateDefaultBranch_repoName')!.value.trim();
		appendLog(`Requesting update-default-branch.sh with repoName=${repoName}`);
		const url = `api/user/task/jira-security/automation/update-default-branch?repoName=${encodeURIComponent(repoName)}`;

		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);
	const triggerCreateIssueStagingRepo = useCallback(() => {
		const ticket = document.querySelector<HTMLInputElement>('#createIssueStagingRepo_ticket')!.value.trim();
		const collaborators = document.querySelector<HTMLInputElement>('#createIssueStagingRepo_collaborators')!.value.trim();
		appendLog(`Requesting create-issue-staging-repo.sh with ticket=${ticket} and collaborators=${collaborators}`);

		const url = `api/user/task/jira-security/automation/create-issue-staging-repo?ticket=${encodeURIComponent(ticket)}&collaborators=${encodeURIComponent(collaborators)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);
	const triggerCreateSprintStagingRepo = useCallback(() => {
		const codename = document.querySelector<HTMLInputElement>('#createSprintStagingRepo_codename')!.value.trim();
		appendLog(`Requesting create-sprint-staging-repo.sh with codename=${codename}`);

		const url = `api/user/task/jira-security/automation/create-sprint-staging-repo?codename=${encodeURIComponent(codename)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);
	const triggerPluginBackportLtsCheck = useCallback(() => {
		const plugins = document.querySelector<HTMLInputElement>('#pluginBackportLtsCheck_plugins')!.value.trim();
		appendLog(`Requesting plugin-backport-lts-check.sh with plugins=${plugins}`);

		const url = `api/user/task/jira-security/automation/plugin-backport-lts-check?plugins=${encodeURIComponent(plugins)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);
	const triggerCodeQLScan = useCallback(() => {
		const repo = document.querySelector<HTMLInputElement>('#codeQLScanAndUpload_repo')!.value.trim();
		appendLog(`Requesting codeql-scan-and-upload.sh with repository=${repo}`);

		const url = `api/user/task/jira-security/automation/codeql-scan-and-upload?repo=${encodeURIComponent(repo)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);

	return (
		<PageContent className="CertAutomationPage">
			<div>
				jira-update-plugin-labels.sh <input id="jiraUpdatePluginLabels_ticket"
				                                    placeholder="SECURITY-1234 (separated by space)" />
				<TextButton label="Trigger" onClick={triggerJiraUpdatePluginLabels} />
			</div>
			<Divider />
			<div>
				jira-assign-issue.sh <input id="jiraAssignIssue_ticket" placeholder="SECURITY-1234 (separated by space)" />
				<TextButton label="Trigger" onClick={triggerJiraAssignIssue} />
			</div>
			<Divider />
			<div>
				cert-fork.sh <input id="certFork_repoName" placeholder="credentials-plugin" />
				<input id="certFork_ticket" placeholder="SECURITY-1234" />
				<input id="certFork_collaborators"
				       placeholder="githubLogin1 githubLogin2 ... (for the collaborators to add)" />
				<TextButton label="Trigger" onClick={triggerCertFork} />
			</div>
			<Divider />
			<div>
				cert-grant-access.sh <input id="certGrantAccess_repoName" placeholder="credentials-plugin" />
				<input id="certGrantAccess_ticket" placeholder="SECURITY-1234" />
				<input id="certGrantAccess_collaborators"
				       placeholder="githubLogin1 githubLogin2 ... (for the collaborators to add)" />
				<TextButton label="Trigger" onClick={triggerCertGrantAccess} />
			</div>
			<Divider />
			<div>
				update-default-branch.sh <input id="updateDefaultBranch_repoName" placeholder="credentials-plugin" />
				<TextButton label="Trigger" onClick={triggerUpdateDefaultBranch} />
			</div>
			<Divider />
			<div>
				create-issue-staging-repo.sh <input id="createIssueStagingRepo_ticket" placeholder="SECURITY-1234" />
				<input id="createIssueStagingRepo_collaborators"
				       placeholder="jiraName1 jiraName2 ... (for the collaborators to add)" />
				<TextButton label="Trigger" onClick={triggerCreateIssueStagingRepo} />
			</div>
			<Divider />
			<div>
				create-sprint-staging-repo.sh <input id="createSprintStagingRepo_codename" placeholder="coolio" />
				<TextButton label="Trigger" onClick={triggerCreateSprintStagingRepo} />
			</div>
			<Divider />
			<div>
				plugin-backport-lts-check.sh <input id="pluginBackportLtsCheck_plugins"
				                                    placeholder="credentials-plugin script-security" />
				<TextButton label="Trigger" onClick={triggerPluginBackportLtsCheck} />
			</div>
			<Divider />
			<div>
				codeql-scan-and-upload.sh <input id="codeQLScanAndUpload_repo"
				                                 placeholder="<org>/<repo>[:ref]" />
				<TextButton label="Trigger" onClick={triggerCodeQLScan} />
			</div>
			<Divider />
			<Card>
				<LogPanel rows={logRows} numRowsToDisplay={30} onClearLog={clearLog} />
			</Card>
		</PageContent>
	);
}
