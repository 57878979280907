import {FunctionNM} from "@commons/types/FunctionNM";
import {OtherHtmlAttributes} from "../form/types/OtherHtmlAttributes";
import {OnClickHandler, useOnClick} from "./commons/OnClick";

import "./TextButton.scss";

export type TextButtonProps<TClick> = {
	label:string
	icon?:FunctionNM<any, JSX.Element>

	buttonType?:'submit' | 'button'
	afterIcon?:FunctionNM<any, JSX.Element>
	disabled?:boolean
	className?:string
	title?:string
	size?:'s' | 'm'

	type?:'primary' | 'shadowed' | 'bordered' | 'regular'
} & OnClickHandler<TClick> & OtherHtmlAttributes<HTMLButtonElement>

export const TextButton = <TClick, >({
	                                     icon,
	                                     label,
	                                     buttonType,
	                                     type,
	                                     afterIcon,
	                                     onClick, onClickArgs,
	                                     className,
	                                     disabled,
	                                     title,
	                                     size,
	                                     id,
	                                     ref
                                     }:TextButtonProps<TClick>) => {
	buttonType = buttonType ?? 'button';
	const sizeClass = {m:'size-m', s:'size-s'}[size || 'm'];
	const typeClass = {
		primary:'primary elevation-z3',
		shadowed:'elevation-z3',
		bordered:'bordered',
		regular:'regular'
	}[type || 'regular'];

	const Icon = icon;
	const AfterIcon = afterIcon;
	const classes = ['TextButton', sizeClass, typeClass, (disabled ? 'disabled' : 'enabled')];
	if (className) {
		classes.push(className);
	}

	const {handleClick} = useOnClick({onClick, onClickArgs, disabled});

	return (
		<button type={buttonType} className={classes.join(' ')}
		        id={id}
		        onClick={handleClick}
		        title={title ? title : undefined}
		        ref={ref}>
			<span className="button-effects" />
			{Icon && <Icon />}
			<span className="button-label">{label}</span>
			{AfterIcon && <AfterIcon />}
		</button>
	);
}
