import {FunctionNM} from "./FunctionNM";

// type DirectOrGenerator<T> = T extends () => infer TReturn ? FunctionNM<[], TReturn> : T;
type DirectOrGenerator<T> = T | FunctionNM<[], T>;

type D1 = DirectOrGenerator<number>;
const d1:D1 = 1;
type D2 = DirectOrGenerator<() => string>;
const d2:D2 = () => '2';

const callDirectOrGenerator = <T>(d:DirectOrGenerator<T>):T => {
	if (d instanceof Function) {
		return d();
	}
	return d;
}

export class FunctionHelper {
	static buildSuccessiveFunction<T>(firstValue:DirectOrGenerator<T> | null, thenValue:DirectOrGenerator<T>):FunctionNM<[], T>{
		let firstSent = false;
		const f = () => {
			if (firstSent) {
				return callDirectOrGenerator(thenValue);
			}else{
				firstSent = true;
				if (firstValue === null) {
					return callDirectOrGenerator(thenValue);
				}
				return callDirectOrGenerator(firstValue);
			}
		};
		
		return f;
	}
}
