import {FunctionNM} from "../types/FunctionNM";
import {ObjectMap} from "../types/ObjectMap";

export class ArrayHelper {
    static filterNonNull<T>(list:(T | null)[]):T[] {
        return list.filter(item => item !== null) as T[];
    }
    static filterNonFalse<T>(list:(T | false)[]):T[] {
        return list.filter(item => item !== false) as T[];
    }

    /**
     * Can be combined with {@link ObjectHelper#max} to find the most represented key
     * @param list
     */
    static countPerValue(list:string[]):{ [key:string]:number } {
        const result:{ [key:string]:number } = {};
        for (let i = 0; i < list.length; i++) {
            const valueAsKey = list[i];
            if (result.hasOwnProperty(valueAsKey)) {
                result[valueAsKey]++;
            } else {
                result[valueAsKey] = 1;
            }
        }
        return result;
    }

    static unique(list:string[]):string[] {
        return [...new Set(list)];
        // const uniqueValues = new Set();
        // const result:string[] = [];
        // for (let i = 0; i < list.length; i++) {
        //     const item = list[i];
        //     if (!uniqueValues.has(item)) {
        //         uniqueValues.add(item);
        //         result.push(item);
        //     }
        // }
        //
        // return result;
    }

    static uniqueBy<T, V>(list:T[], selector:FunctionNM<[T], V>):T[] {
        const uniqueValues = new Set<V>();
        const result:T[] = [];
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            const selectedValue = selector(item);
            if (!uniqueValues.has(selectedValue)) {
                uniqueValues.add(selectedValue);
                result.push(item);
            }
        }

        return result;
    }

    static subMap<T, V>(list:T[], startAt:number, endAt:number, callback:FunctionNM<[T, number], V>):V[] {
        const result:V[] = [];
        for (let i = startAt; i < endAt; i++) {
            const curr = list[i];
            const currResult = callback(curr, i);
            result.push(currResult);
        }
        return result;
    }

    static toObjectMap<V, T = any>(list:T[], callback:FunctionNM<[T, ObjectMap<V>, number], void>):ObjectMap<V> {
        const result:ObjectMap<V> = {};
        for (let i = 0; i < list.length; i++) {
            const curr = list[i];
            callback(curr, result, i);
        }
        return result;
    }

    static generateIntegerFromTo(from:number, to:number) {
        if (!isFinite(from)) {
            throw new Error(`from(${from}) has to be finite`)
        }
        if (!isFinite(to)) {
            throw new Error(`to(${to}) has to be finite`)
        }
        if (!Number.isInteger(from)) {
            throw new Error(`from(${from}) has to be an integer`)
        }
        if (!Number.isInteger(to)) {
            throw new Error(`to(${to}) has to be an integer`)
        }
        if (from > to) {
            throw new Error(`from(${from}) has to be less than to(${to})`)
        }
        const result = Array.from({length: to - from + 1}, (_, i) => from + i)
        return result;
    }
}
