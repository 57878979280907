import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';
import {useMemo} from "react";
import {marked} from "marked";
import DOMPurify from 'dompurify';

import './Markdown.scss';

type Props = {
	value:string,
	className?:string
} & OtherHtmlAttributes

export const Markdown = ({className, value, ...rest}:Props) => {
	const markdownText = useMemo(() => {
		return marked.parse(value, {async: false});
	}, [value]);

	const sanitizedText = useMemo(() => {
		return DOMPurify.sanitize(markdownText);
	}, [markdownText]);

	const trustedHtml = {__html: sanitizedText};

	return (
		<div className={ClassHelper.combine('Markdown', className)}
			 dangerouslySetInnerHTML={trustedHtml}
			 {...rest} />
	);
}
