import {GitHubUserHelper} from "@commons/helpers/GitHubUserHelper";
import {PromiseHelper} from "@commons/helpers/PromiseHelper";
import {UserIdentity} from "@commons/models/UserIdentity";
import {WithDbAndId} from "@commons/types/DbType";
import {DateTime} from "luxon";
import * as React from 'react';
import {useCallback} from 'react';
import {MdAdd, MdCheck, MdClear, MdClose, MdDelete, MdEdit, MdGrass, MdRefresh} from "react-icons/md";
import * as zod from "zod";
import {ZodCrudModel_DEFAULT} from "../../../../commons/helpers/ZodHelper";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {apiFetch} from "../../../../framework/apiFetch";
import {IconButton} from "../../../../framework/components/IconButton";
import {TextButton} from "../../../../framework/components/TextButton";
import {ToggleGroup} from "../../../../framework/components/ToggleGroup";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {CrudFormFragmentProps} from "../../../../framework/crud/CrudForm";
import {OnSaveCallbackType, useFeatureFormModal} from "../../../../framework/crud/features/useFeatureFormModal";
import {
	action_resetFilter,
	CrudPage2,
	CrudPage2Dispatcher,
	CrudPage2State,
	CrudPageLayout,
	CrudPageLayoutBasic
} from "../../../../framework/crud2/CrudPage2";
import {BooleanField} from "../../../../framework/form/fields/BooleanField";
import {StringField} from "../../../../framework/form/fields/StringField";
import {GLOBAL_ERROR_KEY} from "../../../../framework/form/types/FormTypes";
import {BooleanColumnDef} from "../../../../framework/table/columns/BooleanColumnDef";
import {DateColumnDef} from "../../../../framework/table/columns/DateColumnDef";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {IconColumnDef} from "../../../../framework/table/columns/IconColumnDef";
import {ImageColumnDef} from "../../../../framework/table/columns/ImageColumnDef";
import {IntegerColumnDef} from "../../../../framework/table/columns/IntegerColumnDef";
import {MetaInfoColumnDef} from "../../../../framework/table/columns/MetaInfoColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {PaginationUsingCursor} from "../../../../framework/table/PaginationUsingCursor";
import {Table2} from "../../../../framework/table/Table2";

type UserIdentityModel = WithDbAndId<UserIdentity>;

const crudService = new class UserIdentityApiService extends AbstractApiService<UserIdentityModel> {
	constructor() {
		super('api/admin/user-identities');
	}

	resetSeed(id:string, version:number) {
		console.info(`resetSeed, id=${id}, version=${version}`);

		const url = `${this.urlFragmentPart}/${encodeURIComponent(id)}/seed?version=${encodeURIComponent(version)}`;
		return apiFetch.post<void>(url);
	}
}();

export const UserIdentitiesPage = () => {
	const {
		openModal:openCreateModal,
		renderFormModal:renderCreateFormModal,
	} = useFeatureFormModal({
		modalTitle:'Create User Identity',
		formFragment:FormFragment,
		formSchema:formSchema,
		onSave:handleCreate,
	});
	const {
		openModal:openUpdateModal,
		renderFormModal:renderUpdateFormModal,
	} = useFeatureFormModal({
		modalTitle:'Update User Identity',
		formFragment:FormFragment,
		formSchema:formSchema,
		onSave:handleUpdate,
	});

	const {columnDefs, initialState} = useColumnDefs<UserIdentityModel>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'ID', row => row.id + '', {sortable:false, defaultVisibility:false}).nowrap(),
		new IntegerColumnDef('version', 'Version', row => row.version, {
			sortable:false,
			defaultVisibility:false
		}).nowrap(),
		new ImageColumnDef('avatarUrl', 'Avatar', row => row.avatarUrl ? row.avatarUrl : (row.ghId ? GitHubUserHelper.ghIdToAvatarUrlOrDefault(row.ghId) : null), {
			altText:'Avatar'
		}),
		new StringColumnDef('login', 'Login', row => row.login, {
			// sortable:false,
			size:200
		}),
		new StringColumnDef('slackUserId', 'Slack', row => row.slackUserId, {
			// sortable:false,
			size:200
		}),
		new StringColumnDef('fullName', 'Full Name', row => row.fullName, {
			// sortable:false,
			size:'expand'
		}),
		new BooleanColumnDef('hasAiAccess', 'AI', row => row.hasAiAccess, {
			sortable:false,
			size:75,
			title:'Determine if the user can access the basic AI features'
		}),
		new BooleanColumnDef('isUser', 'User', row => row.isUser, {
			sortable:false,
			size:75
		}),
		new BooleanColumnDef('isAdmin', 'Admin', row => row.isAdmin, {
			sortable:false,
			size:75
		}),
		new MetaInfoColumnDef(),
		new DateColumnDef('createdDate', 'Created', DateTime.DATE_MED, row => row.createdDate, {
			sortable:false,
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			defaultVisibility:false
		}),
		new DateColumnDef('updatedDate', 'Updated', DateTime.DATE_MED, row => row.updatedDate, {
			sortable:false,
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS
		}),
		// new IconColumnDef('update', 'Update the item', MdEdit, (row) => openUpdateModal(row), {iconClassName:'hover-info'}),
		new IconColumnDef('update', 'Update the item', MdEdit, openUpdateModalWrapper, {openUpdateModal}, {iconClassName:'hover-info'}),
		new IconColumnDef('resetSeed', 'Reset the seed', MdGrass, resetSeed, {}, {iconClassName:'hover-info'}),
		new IconColumnDef('delete', 'Delete the item', MdDelete, deleteOneItem, {}, {iconClassName:'hover-error'}),
	], {initialOrder:'login'});

	return (
		<CrudPage2 pageId="user-identities" pageClass="UserIdentitiesPage" crudService={crudService}
		           initialState={initialState}
			// others={useMemo(() => <>
			//     {renderCreateModal()}
			// </>, [renderCreateModal])}
			       layout={useCallback((state, itemsData, dispatch) => (
				       <CrudPageLayoutBasic
					       pageName="User Identities"
					       popupPart={<>
						       {renderCreateFormModal({dispatch})}
						       {renderUpdateFormModal({dispatch})}
					       </>}

					       topLeftActions={<>
						       <TextButton icon={MdAdd} label="Add" className="primary elevation-z3"
						                   onClick={() => openCreateModal()} />
						       <IconButton icon={MdRefresh} title="Refresh data"
						                   onClick={() => dispatch({type:'reloadContent'})} />
					       </>}
					       topRightActions={<>
						       <TextButton label="Reset filter" onClick={action_resetFilter} onClickArgs={dispatch} />

						       <ToggleGroup label="User" value={filterUser(state)}
						                    onChange={(newValue) => filterUserChange(newValue, state, dispatch)}
						                    items={[
							                    {value:true, icon:MdCheck, activeClassName:'icon-within-green'},
							                    {value:false, icon:MdClose, activeClassName:'icon-within-red'},
						                    ]} />
						       <ToggleGroup label="Admin" value={filterAdmin(state)}
						                    onChange={(newValue) => filterAdminChange(newValue, state, dispatch)}
						                    items={[
							                    {value:true, icon:MdCheck, activeClassName:'icon-within-green'},
							                    {value:false, icon:MdClose, activeClassName:'icon-within-red'},
						                    ]} />
					       </>}
					       table={<>
						       <Table2 columnDefs={columnDefs} className="fluid"
						               dataState={itemsData} state={state} dispatch={dispatch} />
					       </>}
					       bottomLeftAction={<>
						       {state.selection.length > 0 && (
							       <div className="Selection">
								       <div className="SelectionCount">Selection: {state.selection.length}</div>
								       <TextButton icon={MdClear} label="Clear selection"
								                   onClick={() => dispatch({type:'setSelection', selection:[]})} />
								       <TextButton icon={MdDelete} label="Delete selection"
								                   onClick={() => deleteMultipleItems(state.selection, dispatch)} />
							       </div>
						       )}
					       </>}
					       bottomRightAction={<>
						       <PaginationUsingCursor pagination={state.pagination} itemsData={itemsData}
						                              dispatch={dispatch} />
					       </>}
				       />
			       ), [renderCreateFormModal, renderUpdateFormModal, columnDefs, openCreateModal]) as CrudPageLayout<UserIdentityModel>}
		/>
	)
}

type OpenUpdateModalWrapper<T> = { openUpdateModal:(t?:Partial<T>) => void, row:T, dispatch:CrudPage2Dispatcher<T> };
const openUpdateModalWrapper = ({openUpdateModal, row, dispatch}:OpenUpdateModalWrapper<UserIdentityModel>) => {
	openUpdateModal(row);
}

const filterAdmin = (state:CrudPage2State):boolean | null => {
	const currFilterActive = state.filters.find((f:string) => f.startsWith('isAdmin:'));
	if (currFilterActive === 'isAdmin:b:eq:true') {
		return true;
	} else if (currFilterActive === 'isAdmin:b:eq:false') {
		return false;
	} else {
		return null;
	}
}
const filterAdminChange = (newValue:boolean | null, state:CrudPage2State, dispatch:CrudPage2Dispatcher) => {
	const currValue = filterAdmin(state);
	if (currValue === newValue) {
		dispatch({type:'changeFilters', removeFields:['isAdmin']})
		return;
	}
	if (newValue === true) {
		dispatch({type:'changeFilters', addFilters:['isAdmin:b:eq:true']})
	} else {
		dispatch({type:'changeFilters', addFilters:['isAdmin:b:eq:false']})
	}
}
const filterUser = (state:CrudPage2State):boolean | null => {
	const currFilterActive = state.filters.find((f:string) => f.startsWith('isUser:'));
	if (currFilterActive === 'isUser:b:eq:true') {
		return true;
	} else if (currFilterActive === 'isUser:b:eq:false') {
		return false;
	} else {
		return null;
	}
}

const filterUserChange = (newValue:boolean | null, state:CrudPage2State, dispatch:CrudPage2Dispatcher) => {
	const currValue = filterUser(state);
	if (currValue === newValue) {
		dispatch({type:'changeFilters', removeFields:['isUser']})
		return;
	}
	if (newValue === true) {
		dispatch({type:'changeFilters', addFilters:['isUser:b:eq:true']})
	} else {
		dispatch({type:'changeFilters', addFilters:['isUser:b:eq:false']})
	}
}

const handleCreate:OnSaveCallbackType<UserIdentityModel, { dispatch:CrudPage2Dispatcher<UserIdentityModel> }> = async (data:UserIdentityModel, {dispatch}) => {
	console.warn('handleCreate before', data);
	const createdItemOrError = await crudService.createOne(data);
	console.warn('handleCreate after', createdItemOrError);
	if (createdItemOrError.ok) {
		dispatch({type:'reloadContent'});
		return null;
	} else {
		dispatch({type:'reloadContent'});
		return {[GLOBAL_ERROR_KEY]:createdItemOrError.error};
	}
};

const handleUpdate:OnSaveCallbackType<UserIdentityModel, { dispatch:CrudPage2Dispatcher<UserIdentityModel> }> = async (data:UserIdentityModel, {dispatch}) => {
	console.warn('handleUpdate before', data);
	const updatedItemOrError = await crudService.partialUpdateOne(data);
	console.warn('handleUpdate after', updatedItemOrError);
	if (updatedItemOrError.ok) {
		dispatch({type:'reloadContent'});
		return null;
	} else {
		//TODO optimize for conflict
		dispatch({type:'reloadContent'});
		return {[GLOBAL_ERROR_KEY]:updatedItemOrError.error};
	}
};

const deleteOneItem = async ({row, dispatch}:IconColumnDef.OnClickArgs<UserIdentityModel>) => {
	//TODO implement our own confirm popup with application style
	const confirmed = window.confirm(`Are you sure to delete: "${row.fullName}"?`);
	if (!confirmed) {
		return;
	}

	dispatch({type:'setLoading'});

	const deleteResponse = await crudService.deleteOne(row.id, row.version);
	if (deleteResponse.ok) {
		dispatch({type:'reloadContent'});
	} else {
		if (deleteResponse.code === 409) {
			//TODO optimize for conflict
			// notificationService.error('conflict');
			dispatch({type:'reloadContent'});
		} else {
			// notificationService.error('error');
			dispatch({type:'reloadContent'});
		}
	}
};
const resetSeed = async ({row, dispatch}:IconColumnDef.OnClickArgs<UserIdentityModel>) => {
	//TODO implement our own confirm popup with application style
	const confirmed = window.confirm(`Are you sure to reset the seed of "${row.fullName}"?`);
	if (!confirmed) {
		return;
	}

	dispatch({type:'setLoading'});

	const deleteResponse = await crudService.resetSeed(row.id, row.version);
	if (deleteResponse.ok) {
		dispatch({type:'reloadContent'});
	} else {
		if (deleteResponse.code === 409) {
			//TODO optimize for conflict
			// notificationService.error('conflict');
			dispatch({type:'reloadContent'});
		} else {
			// notificationService.error('error');
			dispatch({type:'reloadContent'});
		}
	}
};

const deleteMultipleItems = async (selectedRows:UserIdentityModel[], dispatch:CrudPage2Dispatcher<UserIdentityModel>) => {
	const confirmed = window.confirm(`Are you sure to delete: ${selectedRows.length} items?`);
	if (!confirmed) {
		return;
	}

	console.info('Deleting', selectedRows);
	dispatch({type:'setLoading'})

	let okCount = 0;
	let koCount = 0;
	await PromiseHelper.batch(selectedRows, async (row:UserIdentityModel) => {
		const deleteResponse = await crudService.deleteOne(row.id, row.version);
		if (deleteResponse.ok) {
			okCount++;
		} else {
			koCount++;
			if (deleteResponse.code === 409) {
				console.warn('Conflict when deleting', row.id, row.version);
				// notificationService.error('conflict');
			}
		}
	}, 10);
	if (koCount === 0) {
		// notificationService.success(`Success deletion of ${okCount} items`);
	} else if (okCount !== 0) {
		// notificationService.info(`Only ${okCount} deletion(s) were successful, with ${koCount} failures`);
	} else {
		// notificationService.error(`Error for the ${koCount} deletions`);
	}
	dispatch({type:'reloadContent'});
};

const FormFragment = ({f, item}:CrudFormFragmentProps<UserIdentityModel>) => {
	console.info('FormFragment render');

	return (
		<>
			{item.id && (
				<StringField f={f} name="id" label="ID" readonly={true} />
			)}

			<StringField f={f} name="fullName" label="Full Name" />
			<StringField f={f} name="login" label="Login" />
			<StringField f={f} name="ghId" label="GitHub ID" />
			<StringField f={f} name="avatarUrl" label="Avatar Url" />
			<StringField f={f} name="slackUserId" label="Slack User ID" />

			<BooleanField f={f} name="hasAiAccess" label="Has AI access" />
			<BooleanField f={f} name="isUser" label="Is User" />
			<BooleanField f={f} name="isAdmin" label="Is Admin" />
		</>
	);
}
const formSchema = zod.object({
	fullName:zod.string(),
	login:zod.string(),
	ghId:zod.string(),
	avatarUrl:zod.string(),
	slackUserId:zod.string(),
	hasAiAccess:zod.boolean(),
	isAdmin:zod.boolean(),
	isUser:zod.boolean(),
	...ZodCrudModel_DEFAULT,
});
