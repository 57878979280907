import {ReactNode} from "react";
import * as React from "react";

import './TopNavLink.scss'
import {NavLink} from "react-router-dom";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";

type TopNavLinkProps = {
	// icon: NonNullable<React.ReactNode>
	icon?: React.ReactNode
	afterIcon?: React.ReactNode
	/** Shortcut instead of using the children */
	name?:string
	to:string
	disabled?:boolean
	className?:string
	children?:React.ReactNode
}

export const TopNavLink = ({icon, afterIcon, name, to, disabled, className, children}:TopNavLinkProps) => {
	const classes = ClassHelper.combine('TopNavLink', disabled && 'disabled', className);
	const content:ReactNode = <>
		{icon}
		<div className="label">
			{name}
			{children}
		</div>
		{afterIcon}
	</>;

	return (
		(!disabled ? (
			<NavLink to={to} className={classes}>
				{content}
			</NavLink>
		) : (
			<div className={classes}>
				{content}
			</div>
		))
	);
};
