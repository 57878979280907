import * as React from "react";
import {useCallback, useMemo} from "react";
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {useCache} from '../../commons/hooks/useCache';
import {QueryResponse} from '../../commons/hooks/useGetQuery';
import {CrudModel} from '../../commons/types/CrudModel';
import {ErrorRow, InfoRow} from '../components/color/Row';
import {InlineCode} from '../components/InlineCode';
import {Spinner} from '../components/misc/Spinner';
import {QueryListBody} from "../crud/CrudApiService2";
import {CrudPage2Dispatcher, CrudPage2State} from "../crud2/CrudPage2";
import {ActionTableHeader} from "./ActionTableHeader";
import {AbstractActionColumnDef} from "./columns/DefaultColumnFactory";
import {CrudTableColumnDefinition} from "./CrudTableColumnDefinition";
import {SelectionFullInfo, SelectionFullInfoChangeHandler} from "./selection/SelectionInfo";
import {SelectTableCell2} from "./selection/SelectTableCell2";
import {SelectTableHeader2} from "./selection/SelectTableHeader2";

import "./Table2.scss";

import {TableCell} from "./TableCell";
import {TableHeader} from "./TableHeader";
import {SecondRowDef} from "../../commons/hooks/useColumnDefs";
import {Simulate} from "react-dom/test-utils";

export type CrudTable2Props<T extends CrudModel> = {
	columnDefs:CrudTableColumnDefinition<T>[]
	secondRowDef?:SecondRowDef<T>

	// dataState:QueryResponse<ApiBody<T[]>>
	dataState:QueryResponse<QueryListBody<T>>

	// We do not care about the filter within the table
	state:CrudPage2State<T>;
	dispatch:CrudPage2Dispatcher<T>

	className:string
}

export type CrudTable2Component<T extends CrudModel> = (props:CrudTable2Props<T>) => JSX.Element;

export const Table2 = <T extends CrudModel>({
	                                            columnDefs,
												secondRowDef,
	                                            dataState,
	                                            state, dispatch,
	                                            className
                                            }:CrudTable2Props<T>) => {
	// console.info('Table2', JsonHelper.stringifyOneLevel(dataState));

	const onSelectionChange = useCallback((selection:SelectionFullInfo<T>) => {
		dispatch({type:'setSelection', selection:selection});
	}, [dispatch]);
	const onOrderChange = useCallback((columnId:string) => {
		dispatch({type:'setOrder', order:{columnId}})
	}, [dispatch]);

	const {order, selection, columnVisibility} = state;

	let payload;
	if (!dataState.loading && dataState.error === null) {
		payload = dataState.data;
	}
	const oldPayload = useCache(payload, dataState.loading);

	const visibleColumnDefs = useMemo(() => {
		return columnDefs.filter(columnDef => columnDef.fixed || columnVisibility.includes(columnDef.name));
	}, [columnDefs, columnVisibility]);

	const [selectColDef, restOfColDef, actionColDefs] = useMemo(() => {
		const selectColDef = (visibleColumnDefs.length > 0 && visibleColumnDefs[0].type === 'select') ? visibleColumnDefs[0] : null;
		const selectCount = selectColDef === null ? 0 : 1;

		const actionColDefs = visibleColumnDefs.filter((d) => {
			// noinspection SuspiciousTypeOfGuard
			return d instanceof AbstractActionColumnDef;
		})
		return [
			selectColDef,
			visibleColumnDefs.slice(selectCount, visibleColumnDefs.length + 1 - selectCount - actionColDefs.length),
			actionColDefs
		];
	}, [visibleColumnDefs]);

	// console.info('Table render', dataState);

	return (<>
		<table className={ClassHelper.combine('Table2', className)}>
			<thead>
				<tr>
					{selectColDef && (
						<SelectTableHeader2 columnDef={selectColDef}
						                    selection={selection} onSelectionChange={onSelectionChange}
							// allRows={(!dataState.loading && dataState.error === null) ? dataState.data.data.map(d => d.id) : null}
							                allRows={(!dataState.loading && dataState.error === null) ? dataState.data.data : null}
						/>
					)}
					{restOfColDef.map(columnDef => (
						<TableHeader key={columnDef.name}
						             columnDef={columnDef}
						             order={order} onOrderChange={onOrderChange} />
					))}
					{actionColDefs.map(columnDef => (
						<ActionTableHeader key={columnDef.name}
						                   columnDef={columnDef} />
					))}
				</tr>
			</thead>
			<tbody className={ClassHelper.combine(!!oldPayload && dataState.loading && 'content-loading')}>
				{dataState.loading && (
					oldPayload ? (
						<TableContent data={oldPayload.data} dispatch={dispatch}
						              restOfColDef={restOfColDef} actionColDefs={actionColDefs}
						              selectColDef={selectColDef}
						              secondRowDef={secondRowDef}
						              selection={selection} onSelectionChange={onSelectionChange}
						/>
					) : (
						<TableLoadingRow />
					)
				)}

				{(!dataState.loading && dataState.error === null) && (
					<TableContent data={dataState.data.data} dispatch={dispatch}
					              restOfColDef={restOfColDef} actionColDefs={actionColDefs}
					              selectColDef={selectColDef} selection={selection}
					              secondRowDef={secondRowDef}
					              onSelectionChange={onSelectionChange} />
				)}
				{(!dataState.loading && dataState.error !== null) && (
					<TableErrorRow error={dataState.error} errorId={dataState.errorId} />
				)}
			</tbody>
		</table>
	</>);
}

type TableContentProps<T extends CrudModel> = {
	data:T[]
	dispatch:CrudPage2Dispatcher<T>
	selectColDef:CrudTableColumnDefinition<T> | null
	restOfColDef:CrudTableColumnDefinition<T>[]
	actionColDefs:CrudTableColumnDefinition<T>[]
	secondRowDef?:SecondRowDef<T>
	selection:SelectionFullInfo<T>
	onSelectionChange:SelectionFullInfoChangeHandler<T>
}

const TableContent = <T extends CrudModel>({
	                                           data,
	                                           dispatch,
	                                           selectColDef,
	                                           restOfColDef,
	                                           actionColDefs,
	                                           secondRowDef,
	                                           selection,
	                                           onSelectionChange,
                                           }:TableContentProps<T>) => {
	return (<>{
		data.length === 0 ? (
			<InfoRow className="no-element-found">
				No element found
			</InfoRow>
		) : (
			data.map(row => (<React.Fragment key={row.id}>
				<tr className={ClassHelper.combine(`row-${row.id}`)}>
					{selectColDef && (
						<SelectTableCell2 columnDef={selectColDef} row={row}
						                  dispatch={dispatch}
						                  selection={selection} onSelectionChange={onSelectionChange}
						/>
					)}
					{restOfColDef.map(columnDef => (
						<TableCell key={columnDef.name}
						           dispatch={dispatch}
						           columnDef={columnDef}
						           row={row} />
					))}
					{actionColDefs.map(columnDef => (
						<TableCell key={columnDef.name}
						           dispatch={dispatch}
						           columnDef={columnDef}
						           row={row} />
					))}
				</tr>
				<SecondRow rowData={row} secondRowDef={secondRowDef} dispatch={dispatch} />
			</React.Fragment>)
		))
	}</>)
}

type SecondRowProps<T extends CrudModel> = {
	rowData:T
	className?:string
	secondRowDef?:SecondRowDef<T>
	dispatch:CrudPage2Dispatcher<T>
}

const SecondRow = <T extends CrudModel>({rowData, className, secondRowDef, dispatch}:SecondRowProps<T>) => {
	if (!secondRowDef || !secondRowDef.secondRowClosure) {
		return null;
	}

	const content = secondRowDef.secondRowClosure(rowData, dispatch);
	if (!content) {
		return null;
	}

	return (
		<tr className={ClassHelper.combine('SecondRow', secondRowDef.rowClass, className)}>
			<td colSpan={999}>
				{typeof content === 'string' ? (
					<div className={ClassHelper.combine('row-content')}>
						{content}
					</div>
				) : (
					content
				) }
			</td>
		</tr>
	);
}

const TableLoadingRow = () => {
	return (
		<tr className="TableLoadingRow row-loading">
			<td colSpan={99}>
				<div className="loading">
					<Spinner/>
					<span className="loading-message">Loading in progress...</span>
				</div>
			</td>
		</tr>
	);
}

type TableErrorRowProps = {
	error: string
	errorId:string | null
}

const TableErrorRow = ({error, errorId}:TableErrorRowProps) => {
	return (
		<ErrorRow>
			<span className="ErrorMessageLabel">Error during the request: </span>
			<span className="ErrorMessageValue">"{error || 'Error, the backend moved to the dark side.'}"</span>
			{errorId && (
				<span className="ErrorId"
				     title="You can find more information in the server log">
					&nbsp;(<InlineCode>{errorId}</InlineCode>)
				</span>
			)}
		</ErrorRow>
	);
}
