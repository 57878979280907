import * as React from "react";
import {useMemo} from "react";
import {MdOutlineNewLabel, MdPersonAddAlt1, MdRefresh} from "react-icons/md";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {TaskComment} from "@commons/models/TaskComment";
import {
	Task_JiraSecurity,
	Task_JiraSecurity_NonCertComment, Task_JiraSecurity_Reproduction,
	TaskSecurityType_ASSIGN,
	TaskSecurityType_DISCLOSURE_DEADLINE,
	TaskSecurityType_LABEL,
	TaskSecurityType_NON_CERT_COMMENT, TaskSecurityType_REPRODUCTION
} from "@commons/models/TaskJiraSecurity";
import {WithDbAndId} from "@commons/types/DbType";
import {FunctionHelper} from "@commons/types/FunctionHelper";
import {useCreateQueryProvider} from "../../../../commons/hooks/useCreateQueryProvider";
import {GetQueryProviderResult} from "../../../../commons/hooks/useGetQuery";
import {useParamsFromRoute} from "../../../../commons/hooks/useParamsFromRoute";
import {TicketSecurityAutomationService} from "../../../../commons/services/TicketSecurityAutomationService";
import {apiFetch} from "../../../../framework/apiFetch";
import {Card} from "../../../../framework/components/Card";
import {IconButton} from "../../../../framework/components/IconButton";
import {MetaInfoIcon} from "../../../../framework/components/MetaInfoIcon";
import {RefreshableQueryDisplay} from "../../../../framework/components/RefreshableQueryDisplay";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {QueryListBody} from "../../../../framework/crud/CrudApiService2";

import "./SecurityTaskDetailsPage.scss";
import {demoModeInline} from "../../../../framework/components/DemoModeText";
import {Date} from "../../../../framework/components/Date";
import {DetailsRow} from "../../../../framework/components/layout/elements/DetailsRow";
import {JiraStatus} from "../commons/JiraStatus";
import {TwoColumns} from "../../../../framework/components/layout/TwoColumns";
import {TaskBaseStatusBadge} from "../commons/TaskBaseStatusBadge";
import {Priority} from "../commons/Priority";
import {ExternalLink} from "../../../../framework/components/ExternalLink";
import {JiraUrlHelper} from "@commons/helpers/JiraUrlHelper";
import {Divider} from "../../../../framework/components/Divider";

type TaskSecurityModel = WithDbAndId<Task_JiraSecurity>;
type TaskCommentModel = WithDbAndId<TaskComment>;

const task_crudService = new class TaskSecurityService extends AbstractApiService<TaskSecurityModel> {
	constructor() {
		super('api/user/task/jira-security/generic');
	}
}();

//TODO extract the comment part to a generic component for any tasks
const comments_crudService = new class TaskSecurityService extends AbstractApiService<TaskSecurityModel> {
	constructor() {
		super('api/user/task-comments');
	}

	getAllByTaskId(taskId:string) {
		console.info(`getAllByTaskId(${taskId})`);
		const url = `${this.urlFragmentPart}?filters=taskId:s:eq:${taskId}&limit=50`;

		return apiFetch.get<QueryListBody<TaskCommentModel>>(url);
	}
}();
const ticketSecurityAutomationService = new TicketSecurityAutomationService();

type PageParams = {
	ticketKey:string;
	taskId:string;
};

export const SecurityTaskDetailsPage = () => {
	const {ticketKey, taskId} = useParamsFromRoute<PageParams>();

	const location:any = useLocation();

	const itemFromPrevPage:TaskSecurityModel | undefined = location?.state?.task;
	const fromView = location?.state?.fromView;

	const taskQueryProvider = useCreateQueryProvider<{ data:TaskSecurityModel }>(
		useMemo(() => {
			//TODO if A => B => A, the value will not be requested the first time
			// in the case an update on A occurs after A => B, the first value of A after B => A, will be the state one

			if (itemFromPrevPage && itemFromPrevPage.id === taskId) {
				const firstValue = {data:itemFromPrevPage};
				const secondValue = () => task_crudService.getOne(taskId);

				return FunctionHelper.buildSuccessiveFunction<GetQueryProviderResult<{ data:TaskSecurityModel }>>(firstValue, secondValue);
			} else {
				return () => task_crudService.getOne(taskId);
			}
		}, [taskId, itemFromPrevPage])
	);

	const commentQueryProvider = useCreateQueryProvider<{ data:TaskCommentModel[] }>(
		useMemo(() => {
			return () => comments_crudService.getAllByTaskId(taskId)
		}, [taskId])
	);

	return (
		<div className="SecurityTaskDetailsPage">
			<Card className="NavigationRow">
				{fromView && (<>
					<NavLink to={fromView}>
						Back to previous view
					</NavLink>
				</>)}
				<NavLink to={`/tasks/ticket/${ticketKey}`} className="ticket-details-link">
					Ticket details
				</NavLink>
				<ExternalLink url={JiraUrlHelper.linkToTicket(ticketKey)}>{ticketKey}</ExternalLink>
			</Card>

			<Card>
				<RefreshableQueryDisplay
					queryProvider={taskQueryProvider}
					render={({data}, refresh) => (<>
						<Card.TitleAndActions
							// title={`Task details: ${demoModeInline(data.name)}`} size="big"
							// htmlTitle={`<code>${data.type}</code> ${demoModeInline(data.name)}`} size="big"
							htmlTitle={`Task <code>${data.type}</code> details: ${demoModeInline(data.name)}`} size="big"
							rightChildren={(<>
								<MetaInfoIcon item={data} />
								
								{/*TODO add self-assign buttons*/}
								
								<TaskSpecificActions task={data} />
								<IconButton icon={MdRefresh} onClick={refresh} title="Refresh the ticket" />
							</>)}
						/>

						<TwoColumns
							className="TaskDetails"
							left={<>
								<DetailsRow label="Type" value={data.type} valueInlineCode={true} />
								<DetailsRow label="Ticket" value={<ExternalLink url={JiraUrlHelper.linkToTicket(data.targetId)}>{data.targetId}</ExternalLink>} />
								<DetailsRow label="Ticket status" marginToCompensate="badge" value={<JiraStatus label={data.targetStatus!} color={data.ticketStatusColor} />} />
							</>}
							right={<>
								<DetailsRow label="Task Assignee" value={demoModeInline(data.assignee)} />
								<DetailsRow label="Task status" marginToCompensate="badge" value={<TaskBaseStatusBadge task={data} />} />
								<DetailsRow label="Task Priority" marginToCompensate="svg" value={(
									<Priority priority={data.priority} />
								)} />
								{/*<DetailsRow label="Task done date" value={data.doneDate ? <Date value={data.doneDate}/> : ''} />*/}
							</>}
						/>

						{data.type === TaskSecurityType_REPRODUCTION && (<>
							<Divider />
							<DetailsRow label="Failed to reproduce" value={(data as unknown as Task_JiraSecurity_Reproduction).failedToReproduce} />
							{/*<DetailsRow label="Disclosure deadline" value={<Date value={(data as unknown as Task_JiraSecurity_DisclosureDeadline).dueDate!}/>} />*/}
						</>)}
						{data.type === TaskSecurityType_DISCLOSURE_DEADLINE && (<>
							<Divider />
							<DetailsRow label="Disclosure deadline" value={<Date value={data.customDate1!}/>} />
							{/*<DetailsRow label="Disclosure deadline" value={<Date value={(data as unknown as Task_JiraSecurity_DisclosureDeadline).dueDate!}/>} />*/}
						</>)}
						{data.type === TaskSecurityType_NON_CERT_COMMENT && (<>
							<Divider />
							<DetailsRow label="Num comments since JenSec comment" value={(data as unknown as Task_JiraSecurity_NonCertComment).numCommentsSinceCertComment} hideIfNoValue={true} />
							{/*TODO replace by JiraComment directly*/}
							<DetailsRow label="Last comment author" value={demoModeInline((data as unknown as Task_JiraSecurity_NonCertComment).lastCommentAuthor.displayName)} />
							<DetailsRow label="Last comment" value={demoModeInline((data as unknown as Task_JiraSecurity_NonCertComment).lastComment)} />
						</>)}
						
						{/*<pre>{JSON.stringify({...data, name:demoModeInline(data.name), lastComment:demoModeInline((data as any).lastComment)}, null, 3)}</pre>*/}
					</>)}
				/>
			</Card>

			<Card>
				<RefreshableQueryDisplay
					queryProvider={commentQueryProvider}
					render={({data}:{ data:TaskCommentModel[] }, refresh) => (<>
						<Card.TitleAndActions
							title="Task Comments" size="medium"
							rightChildren={(<>
								<IconButton icon={MdRefresh} onClick={refresh} title="Refresh the comments" />
							</>)}
						/>

						{data.length > 0 ? (
							data.map(comment => (<>
								<div className="comment-row" key={comment.id}>
									<div className="comment-header">
										<span className="comment-author">{comment.creator}</span>
										&nbsp;on&nbsp;
										<Date className="comment-updated-date" value={comment.updatedDate}/>
									</div>
									<div className="comment-body">
										{comment.content}
									</div>
								</div>
							</>))
						) : (
							<div>No comments</div>
						)}
					</>)}
				/>
			</Card>

		</div>
	)
}

type TaskSpecificActionsProps = {
	task: TaskSecurityModel
}
const TaskSpecificActions = ({task}: TaskSpecificActionsProps) => {
	if (task.type === TaskSecurityType_LABEL) {
		return (
			<span className="TaskSpecificActions">
                {/*TODO add confirm + notification + disabled while running */}
				<IconButton icon={MdOutlineNewLabel} size="m"
				            title="Add labels to the ticket by triggering the script 'jira-update-plugin-labels'"
				            onClick={addLabelsToTicket} onClickArgs={{task}} />
			</span>
		)
	}

	if (task.type === TaskSecurityType_ASSIGN) {
		return (
			<span className="TaskSpecificActions">
                {/*TODO add confirm + notification + disabled while running */}
				<IconButton icon={MdPersonAddAlt1} size="m"
				            title="Assign the ticket to the security contact and maintainer(s) by triggering the script 'jira-assign-issue'"
				            onClick={assignTicket} onClickArgs={{task}} />
			</span>
		)
	}

	return (
		<span className="TaskSpecificActions" />
	);
}

const addLabelsToTicket = ({task}:{ task:TaskSecurityModel }) => {
	ticketSecurityAutomationService.addLabelsToTicket(task.targetId)
		.then((response) => {
			console.info('addLabelsToTicket', response);
		})
}

const assignTicket = ({task}:{ task:TaskSecurityModel }) => {
	ticketSecurityAutomationService.assignTicket(task.targetId)
		.then((response) => {
			console.info('assignTicket', response);
		})
}
