import {ReactNode} from "react";

import "./PageContent.scss";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";

type Props = {
	className?:string
	withShadow?:boolean
	withoutPadding?:boolean
	children:ReactNode
}

export const PageContent = ({className, withShadow, withoutPadding, children}:Props) => {
	return (
		<div className={ClassHelper.combine('PageContent', className, withShadow && 'with-shadow', !withoutPadding && 'with-padding')}>
			{children}
		</div>
	)
}
