import * as React from "react";
import {
	MdOutgoingMail,
	MdOutlineBuild,
	MdOutlineEditCalendar,
	MdOutlineGrading,
	MdOutlineInventory2,
	MdOutlinePrecisionManufacturing,
	MdOutlineRecentActors,
	MdOutlineSyncLock,
	MdOutlineTextSnippet,
	MdOutlineVpnLock
} from "react-icons/md";
import {Navigate, Route, Routes} from "react-router-dom";
import {Divider} from "../../../framework/components/Divider";
import {Section} from '../../../framework/components/layout/Section';
import {SideNavLink} from '../../nav/side/elements/SideNavLink';
import {SideNav} from '../../nav/side/SideNav';
import {ActionLogListPage} from "./pages/ActionLogListPage";
import {ArtifactoryReleaseListPage} from "./pages/ArtifactoryReleaseListPage";
import {ArtifactoryToolingPage} from "./pages/ArtifactoryToolingPage";
import {CertAutomationPage} from "./pages/CertAutomationPage";
import {CommentCommandDetailsPage} from "./pages/CommentCommandDetailsPage";
import {CommentCommandListPage} from "./pages/CommentCommandListPage";
import {DirectCommandDetailsPage} from "./pages/DirectCommandDetailsPage";
import {DirectCommandListPage} from "./pages/DirectCommandListPage";
import {GitHubAdvisoryToolingPage} from "./pages/GitHubAdvisoryToolingPage";
import {GitHubPermissionToolingPage} from "./pages/GitHubPermissionToolingPage";
import {ReleaseBlockDetailsPage} from "./pages/ReleaseBlockDetailsPage";
import {ReleaseBlockListPage} from "./pages/ReleaseBlockListPage";
import {ReleaseBlockToolingPage} from "./pages/ReleaseBlockToolingPage";
import {RpuCommentCommandDetailsPage} from "./pages/RpuCommentCommandDetailsPage";
import {RpuCommentCommandListPage} from "./pages/RpuCommentCommandListPage";
import {SendEmailsPage} from "./pages/SendEmailsPage";

export const ToolboxSection = () => {
	return (
		<Section sideNav={<ToolboxSideNav />}>
			<Routes>
				<Route path="cert-automation" element={<CertAutomationPage />} />
				<Route path="send-emails" element={<SendEmailsPage />} />
				<Route path="direct-command" element={<DirectCommandListPage />} />
				<Route path="comment-command" element={<CommentCommandListPage />} />
				<Route path="rpu-comment-command" element={<RpuCommentCommandListPage />} />
				<Route path="artifactory-release" element={<ArtifactoryReleaseListPage />} />
				<Route path="artifactory-tooling" element={<ArtifactoryToolingPage />} />
				<Route path="release-block" element={<ReleaseBlockListPage />} />
				<Route path="release-block-tooling" element={<ReleaseBlockToolingPage />} />
				<Route path="github-advisory-tooling" element={<GitHubAdvisoryToolingPage />} />
				<Route path="github-permission-tooling" element={<GitHubPermissionToolingPage />} />
				<Route path="action-log" element={<ActionLogListPage />} />

				<Route path="direct-command/:logId" element={<DirectCommandDetailsPage />} />
				<Route path="comment-command/:logId" element={<CommentCommandDetailsPage />} />
				<Route path="rpu-comment-command/:logId" element={<RpuCommentCommandDetailsPage />} />
				<Route path="release-block/:itemId" element={<ReleaseBlockDetailsPage />} />

				<Route path="*" element={<Navigate to="cert-automation" replace={true} />} />
			</Routes>
		</Section>
	);
}

const ToolboxSideNav = () => {
	return (
		<SideNav classes="ToolboxSideNav">
			<SideNavLink name="CERT Automation" to="cert-automation" icon={MdOutlinePrecisionManufacturing} />

			<Divider />
			<SideNavLink name="Direct command" to="direct-command" icon={MdOutlineTextSnippet} />
			<SideNavLink name="Comment command" to="comment-command" icon={MdOutlineTextSnippet} />
			<SideNavLink name="RPU command" to="rpu-comment-command" icon={MdOutlineTextSnippet} />

			<Divider />
			<SideNavLink name="Artifactory releases" to="artifactory-release" icon={MdOutlineInventory2} />
			<SideNavLink name="Artifactory tooling" to="artifactory-tooling" icon={MdOutlineBuild} />

			<Divider />
			<SideNavLink name="Release block list" to="release-block" icon={MdOutlineVpnLock} />
			<SideNavLink name="Release block tooling" to="release-block-tooling" icon={MdOutlineSyncLock} />
			<SideNavLink name="GH Advisory tooling" to="github-advisory-tooling" icon={MdOutlineEditCalendar} />
			<SideNavLink name="GH Permission tooling" to="github-permission-tooling" icon={MdOutlineRecentActors} />

			<Divider />
			<SideNavLink name="Send Emails" to="send-emails" icon={MdOutgoingMail} />
			<Divider />
			<SideNavLink name="Action logs" to="action-log" icon={MdOutlineGrading} />

			{/*<pre>{JSON.stringify(counterMap, null, 3)}</pre>*/}
		</SideNav>
	);
}
