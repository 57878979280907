import {useCallback, useMemo} from "react";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {TriStateForCheckbox} from '../../../commons/types/TriStateForCheckbox';
import {CustomCheckbox} from "../../components/CustomCheckbox";
import {CrudTableColumnDefinition} from "../CrudTableColumnDefinition";
import {SelectionFullInfo, SelectionFullInfoChangeHandler} from "./SelectionInfo";

type Props<T> = {
	columnDef:CrudTableColumnDefinition<any>

	selection:SelectionFullInfo<T>
	onSelectionChange:SelectionFullInfoChangeHandler<T>

	allRows:T[] | null
}

export const SelectTableHeader2 = <T, >({columnDef, selection, onSelectionChange, allRows}:Props<T>) => {
	const checkboxValue:TriStateForCheckbox = useMemo(() => {
		if (allRows === null || selection.length === 0) {
			return 'false';
		} else if (selection.length === allRows.length) {
			return 'true';
		} else {
			return 'mixed';
		}
	}, [selection, allRows]);

	const handleClick = useCallback(() => {
		if (allRows === null) {
			// Cannot click before the data is loaded
			//TODO disabled the select until loaded
		}
		if (checkboxValue === 'true') {
			onSelectionChange([]);
		} else {
			onSelectionChange(allRows!);
		}
	}, [checkboxValue, allRows, onSelectionChange]);

	const thClasses = [];
	columnDef.headerClassName && thClasses.push(columnDef.headerClassName);

	const divClasses = ['header'];
	columnDef.sortable && divClasses.push('sortable');

	return (
		<th className={ClassHelper.combine('SelectTableHeader', columnDef.headerClassName)}
		    title={columnDef.headerTitle}>
			<CustomCheckbox value={checkboxValue}
			                className={ClassHelper.combine('header', columnDef.sortable && 'sortable')}
			                onChange={handleClick} />

			{/*<div className={divClasses.join(' ')}*/}
			{/*     onClick={handleClick}>{checkboxValue.substr(0, 1)}</div>*/}
		</th>
	);
}
