import {StringHelper} from "@commons/helpers/StringHelper";
import {EmailInfo} from "@commons/models/EmailInfo";
import {WithDbAndId} from "@commons/types/DbType";
import {DateTime} from "luxon";
import * as React from 'react';
import {useCallback} from 'react';
import {MdRefresh} from "react-icons/md";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {IconButton} from "../../../../framework/components/IconButton";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {CrudPage2, CrudPageLayout, CrudPageLayoutBasic} from "../../../../framework/crud2/CrudPage2";
import {ClosureColumnDef} from "../../../../framework/table/columns/ClosureColumnDef";
import {DateColumnDef} from "../../../../framework/table/columns/DateColumnDef";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {PaginationUsingCursor} from "../../../../framework/table/PaginationUsingCursor";
import {Table2} from "../../../../framework/table/Table2";

import "./Emails3Page.scss";

type EmailInfoModel = WithDbAndId<EmailInfo>;

const REGEX_REMOVE_TAGS = /(<style((\s|.)*?)style>|<title(.*?)\/title>|<(.*?)>|(\s\s+?)|&nbsp;)/g;

function subjectBodyToReactNode(row:EmailInfoModel):React.ReactNode {
	const subject = row.subject;
	let body:string = row.body || '';
	if (!body && row.bodyHtml) {
		body = row.bodyHtml.replace(REGEX_REMOVE_TAGS, '');
	}

	const bodyWithoutNewLine = body.trim().replace(/\r?\n|\r/g, ' ');
	const bodyExcerpt = StringHelper.excerpt(bodyWithoutNewLine, 200, '');

	return (
		<div className="email-subject-body">
			<div className="email-subject-body2">
				<div className="email-subject">
					<span className="email-subject2">
						{subject}
					</span>
				</div>
				<span className="email-body-span">
					<span className="sb-separator">&nbsp;-&nbsp;</span>
					<span className="email-body">{bodyExcerpt}</span>
				</span>
			</div>
		</div>
	);
}

const crudService = new class EmailsApiService extends AbstractApiService<EmailInfoModel> {
	constructor() {
		super('api/user/emails');
	}
}();

export const Emails3Page = () => {
	const {columnDefs, initialState} = useColumnDefs<EmailInfoModel>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'ID', row => row.id + '', {defaultVisibility:false}).nowrap(),
		new StringColumnDef('sender', 'Sender', row => row.sender || '', {size:180}),
		new ClosureColumnDef('subject', 'Subject / Body', subjectBodyToReactNode, {size:'expand'}),
		new StringColumnDef('labels', 'Labels', row => row.labels ? row.labels.join(', ') : '', {
			defaultVisibility:false,
			size:25
		}),
		new StringColumnDef('tags', 'Tags', row => row.tags ? row.tags.join(', ') : '', {
			defaultVisibility:false,
			size:175
		}),
		new StringColumnDef('source', 'Source', row => row.source, {size:130}),
		new DateColumnDef('receivedDate', 'Receipt', DateTime.DATE_MED, row => row.receivedDate!, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS
		}),
		new DateColumnDef('createdDate', 'Created', DateTime.DATE_MED, row => row.createdDate, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS, defaultVisibility:false
		}),
		new DateColumnDef('updatedDate', 'Updated', DateTime.DATE_MED, row => row.updatedDate, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS, defaultVisibility:false
		}),
		// DefaultColumnFactory.EDIT_REQUIREMENT,
		// DefaultColumnFactory.DELETE_REQUIREMENT,
	], {initialOrder:'receivedDate', initialOrderDirection:'desc'});

	return (
		<CrudPage2 pageId="emails" pageClass="Emails3Page" crudService={crudService}
		           initialState={initialState}
		           layout={useCallback((state, itemsData, dispatch) => (
			           <CrudPageLayoutBasic pageName="Emails" topLeftActions={<>
				           <IconButton icon={MdRefresh} title="Refresh data"
				                       onClick={() => dispatch({type:'reloadContent'})} />
			           </>} topRightActions={<>
				           <span>Right</span>
			           </>} table={<>
				           <Table2 columnDefs={columnDefs} className="fluid"
				                   dataState={itemsData} state={state} dispatch={dispatch} />
			           </>} bottomLeftAction={<>
				           {state.selection.length > 0 && (
					           <div className="Selection">
						           <div className="SelectionCount">Selection: {state.selection.length}</div>
						           {/*<TextButton icon={MdClear} label="Clear selection"*/}
						           {/*            onClick={() => dispatch({type:'setSelection', selection:[]})} />*/}
						           {/*<TextButton icon={MdDelete} label="Delete selection"*/}
						           {/*            onClick={() => deleteMultipleItems(state.selection, dispatch)} />*/}
					           </div>
				           )}
			           </>} bottomRightAction={<>
				           <PaginationUsingCursor pagination={state.pagination} itemsData={itemsData}
				                                  dispatch={dispatch} />
			           </>} />
		           ), [columnDefs]) as CrudPageLayout<EmailInfoModel>}
		/>
	)
}
