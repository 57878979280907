import {ReactNode} from "react";
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';
import {MdOpenInNew} from "react-icons/md";

import "./ExternalLink.scss";

export type ExternalLinkProps = {
	url:string
	className?:string
	title?:string
	children:ReactNode
} & OtherHtmlAttributes

export const ExternalLink = ({url, className, title, children, ...rest}:ExternalLinkProps) => {
	return (
		<a href={url} target="_blank" rel="noopener noreferrer"
		   className={ClassHelper.combine('ExternalLink', className)}
		   title={title}
		   {...rest}>
			{children}
			<MdOpenInNew />
		</a>
	);
}
