import {FunctionNM} from "@commons/types/FunctionNM";
import {TKeys} from "@commons/types/TKeys";
import {zodResolver} from '@hookform/resolvers/zod';
import * as React from "react";
import {useCallback, useState} from "react";
import {useForm, UseFormReturn} from "react-hook-form";
import {ErrorPanel} from "../components/color/Panel";
import {GenericErrorResponse, GLOBAL_ERROR_KEY} from "../form/types/FormTypes";
import { z } from 'zod';

import './CrudForm.scss';

export type ItemFormResponse<T> = Promise<GenericErrorResponse<T> | null>;

// export type CrudFormSchema<T> = yup.SchemaOf<Partial<Omit<T, 'id'>>>;
// export type CrudFormSchema<T> = any;
// export type CrudFormSchema = any;
// export type CrudFormSchema<T = any> = any;
export type CrudFormSchema<T = any> = z.Schema<T>;

export type CrudFormFragmentProps<T> = { f:UseFormReturn<Partial<T>>, item:Partial<T> };
// export type CrudFormFragmentProps<T extends CrudModel> = {f:UseFormMethods<CrudFormSchema<T>>, item:Partial<T>};

// export type CrudFormFragment<T extends CrudModel> = FunctionNM<[{f: UseFormMethods<T>, item:Partial<T>}], JSX.Element>;
// export type CrudFormFragment<T extends any> = FunctionNM<[{f: any, item:Partial<T>}], JSX.Element>;
export type CrudFormFragment<T extends any> = FunctionNM<[{ f:any, item:Partial<T> }], JSX.Element>;
// export type CrudFormFragment<T> = FunctionNM<any, JSX.Element>;
// export type CrudFormFragment<T> = FunctionNM<[CrudFormFragmentProps<T>], JSX.Element>;

type CrudFormProps<T extends any, TSubmitArgs> = {
	beforeComp?:React.ReactNode
	item:Partial<T> | null

	// itemForm?:(f:UseFormMethods) => React.ReactNode
	// itemForm2?:() => React.ReactNode
	// formSchema:CrudFormSchema<T>
	formSchema:CrudFormSchema
	formFragment?:CrudFormFragment<T>
	// itemForm?:FunctionNM<[CrudFormFragmentProps<T>], JSX.Element>

	onSubmit?:(item:T, args:TSubmitArgs) => ItemFormResponse<T>
	onSubmitArgs?:TSubmitArgs

	afterComp?:React.ReactNode
}

export const CrudForm = <T extends any, TSubmitArgs extends any>(
	{
		item,
		formFragment,
		formSchema,
		onSubmit,
		onSubmitArgs,
		beforeComp,
		afterComp
	}:CrudFormProps<T, TSubmitArgs>
) => {
	console.info('CrudForm render', item);

	const f:UseFormReturn = useForm({
		mode:'onTouched',
		reValidateMode:'onBlur',
		defaultValues:item === null ? {} : Object.assign({}, item as T) as any,
		// resolver:zodResolver(formSchema as any)
		resolver:async (values:any, context:any, validateAllFieldCriteria:any):Promise<any> => {
			const resultPromise = zodResolver(formSchema)(values, context, validateAllFieldCriteria);
			const result = await resultPromise;
			if (Object.keys(result.errors).length > 0) {
				console.warn('Form validation failed', result.errors);
			}
			return result;
		}
	});
	const {handleSubmit, setError} = f;
	// const {handleSubmit, setError, watch} = f;
	// const watchedValues = watch();
	// watch();

	const [globalError, setGlobalError] = useState('');

	const submitWrapper = useCallback((data:T) => {
		console.log('submitWrapper: ', data);

		if (onSubmit) {
			onSubmit(data, onSubmitArgs!).then(errorResponse => {
				if (errorResponse === null) {
					// success
				} else {
					for (const k in errorResponse) {
						if (errorResponse.hasOwnProperty(k)) {
							const key = k as TKeys<T>
							// const key = k as string
							setError(key as any, {type:'server', message:errorResponse[key]});
						}
					}
					if (GLOBAL_ERROR_KEY in errorResponse) {
						setGlobalError(errorResponse[GLOBAL_ERROR_KEY]);
					}
				}
			}).catch(reason => {
				setGlobalError(reason);
			})
		}
	}, [onSubmit, onSubmitArgs, setError]);

	const FormFragment = formFragment;

	// const h = handleSubmit;
	// const t:(data:T) => void = submitWrapper;
	// const s:SubmitHandler<T> = submitWrapper;

	return (
		<form onSubmit={handleSubmit(submitWrapper as any)} className="CrudForm">
			{beforeComp && (
				<div className="beforeForm">{beforeComp}</div>
			)}

			<div className="formContent">
				{globalError && (
					<ErrorPanel>{globalError}</ErrorPanel>
				)}

				{FormFragment && item !== null && (
					// <FormFragment {...formFragmentProps} formFragmentProps={formFragmentProps} f={f} item={item} />
					<FormFragment f={f} item={item} />
				)}
			</div>

			{/*<div>*/}
			{/*	<pre>dirtyFields: {JSON.stringify(f.formState.dirtyFields, null, 3)}</pre>*/}
			{/*	<pre>touchedField: {JSON.stringify(f.formState.touchedFields, null, 3)}</pre>*/}
			{/*	/!*<pre>errors: {JSON.stringify(Object.keys(f.formState.errors), null, 3)}</pre>*!/*/}
			{/*	<pre>errors: {JSON.stringify(*/}
			{/*		Object.keys(f.formState.errors)*/}
			{/*			.filter(k => !k.startsWith('__'))*/}
			{/*			.map(k => k + ': ' + f.formState.errors[k].message)*/}
			{/*		, null, 3)}</pre>*/}
			{/*</div>*/}
			
			{/*<div>*/}
			{/*	<pre>values: {JSON.stringify(watchedValues, null, 3)}</pre>*/}
			{/*	<br />*/}
			
			{/*	/!*Does not refresh unless watch is used in addition*!/*/}
			{/*	/!*<pre>values: {JSON.stringify(getValues(), null, 3)}</pre><br />*!/*/}
			
			{/*	<pre>dirtyFields: {JSON.stringify(Object.keys(f.formState.dirtyFields), null, 3)}</pre>*/}
			{/*	<br />*/}
			{/*	<pre>touchedField: {JSON.stringify(Object.keys(f.formState.touchedFields), null, 3)}</pre>*/}
			{/*	<br />*/}
			{/*	/!*<pre>errors: {JSON.stringify(Object.keys(f.formState.errors), null, 3)}</pre>*!/*/}
			{/*	<pre>errors: {JSON.stringify(*/}
			{/*		Object.keys(f.formState.errors)*/}
			{/*			.filter(k => !k.startsWith('__'))*/}
			{/*			.map(k => k + ': ' + (f.formState.errors[k] as any).message as string)*/}
			{/*		, null, 3)}</pre>*/}
			{/*</div>*/}

			{afterComp && (
				<div className="afterForm">{afterComp}</div>
			)}
		</form>
	);
}
