import {FunctionNM} from "../types/FunctionNM";
import {Logger} from "./Logger";

const log = Logger.get('PromiseHelper');

export type PromiseOrNot<T> = Promise<T> | T;

export class PromiseHelper {
	/**
	 * @param list The item list that we use to generate the promises
	 * @param promiseGenerator Build a promise using the item, if the value return is null, we do not wait for this (lack of) promise
	 * @param numPerBatch The number of promises we are starting at the same time
	 */
	//TODO add option for the batching size as arg/map
	static async batch<TInput, TOutput>(list:TInput[], promiseGenerator:FunctionNM<[TInput, number], Promise<TOutput> | null>, numPerBatch:number = 10):Promise<TOutput[]> {
		const promises:Promise<TOutput>[] = [];

		const cumulatedResult:TOutput[] = [];

		for (let i = 0; i < list.length; i++) {
			const item = list[i];
			const promise = promiseGenerator(item, i);
			if (promise !== null) {
				promises.push(promise);
			}
			if (i !== 0 && i % numPerBatch === 0) {
				log.info(`batch(i=${i}, batch=${numPerBatch}, total=${list.length})`);
				const newResults = await Promise.all(promises);
				cumulatedResult.push(...newResults);
				promises.length = 0;
			}
		}

		if (promises.length > 0) {
			log.info(`last batch(batch=${numPerBatch}, total=${list.length})`);
			const newResults = await Promise.all(promises);
			cumulatedResult.push(...newResults);
		}

		return cumulatedResult;
	}
}
