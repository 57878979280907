import {Comparator} from "@commons/helpers/Comparator";
import {AllSecurityTasksType, AllSecurityTasksTypeNameArray} from "@commons/types/AllSecurityTasksType";
import {Task_JiraSecurity, TaskSecurityType_ASSIGN, TaskSecurityType_LABEL} from "@commons/models/TaskJiraSecurity";
import {TicketSecurity} from "@commons/models/TicketSecurity";
import {WithDbAndId} from "@commons/types/DbType";
import {FunctionHelper} from "@commons/types/FunctionHelper";
import {FunctionNM} from "@commons/types/FunctionNM";
import * as React from "react";
import {useCallback, useContext, useMemo, useState} from "react";
import {
	MdOutlineCheckCircleOutline,
	MdOutlineNewLabel,
	MdOutlineSwapCalls,
	MdOutlineUnpublished,
	MdPersonAddAlt1,
	MdRefresh
} from "react-icons/md";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {EnhancedQueryProvider, useCreateQueryProvider} from "../../../../commons/hooks/useCreateQueryProvider";
import {GetQueryProviderResult} from "../../../../commons/hooks/useGetQuery";
import {useParamsFromRoute} from "../../../../commons/hooks/useParamsFromRoute";
import {TicketSecurityAutomationService} from "../../../../commons/services/TicketSecurityAutomationService";
import {apiFetch} from "../../../../framework/apiFetch";
import {Card} from "../../../../framework/components/Card";
import {IconButton} from "../../../../framework/components/IconButton";
import {DetailsRow} from "../../../../framework/components/layout/elements/DetailsRow";
import {TwoColumns} from "../../../../framework/components/layout/TwoColumns";
import {MetaInfoIcon} from "../../../../framework/components/MetaInfoIcon";
import {RefreshableQueryDisplay} from "../../../../framework/components/RefreshableQueryDisplay";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {QueryGetBody} from "../../../../framework/crud/CrudApiService2";
import {JiraLabels} from "../commons/JiraLabels";
import {JiraStatus} from "../commons/JiraStatus";
import {TaskBaseStatusBadge} from "../commons/TaskBaseStatusBadge";

import "./SecurityTicketDetailsPage.scss";
import {TaskCounterContext} from "../commons/TaskCounterContext";
import {JiraTextFormat} from "../commons/JiraTextFormat";
import {demoModeInline} from "../../../../framework/components/DemoModeText";
import {JiraUrlHelper} from "@commons/helpers/JiraUrlHelper";
import {ExternalLink} from "../../../../framework/components/ExternalLink";

type TicketSecurityModel = WithDbAndId<TicketSecurity>;
type TaskModel = WithDbAndId<Task_JiraSecurity>;

const ticket_crudService = new class TicketSecurityService extends AbstractApiService<TicketSecurityModel> {
	constructor() {
		super('api/user/ticket/security');
	}

	forceSync(ticketKey:string) {
		console.info(`forceSync(${ticketKey})`);
		const url = `${this.urlFragmentPart}/${ticketKey}/force-sync`;

		return apiFetch.post<QueryGetBody<{ ticketSecurity:TicketSecurityModel }>>(url);
	}

	getTicketsWithSameComponent(ticketKey:string) {
		console.info(`getTicketsWithSameComponent(${ticketKey})`);
		const url = `${this.urlFragmentPart}/${ticketKey}/same-component`;
		return apiFetch.get<QueryGetBody<{ othersWithSameComponent:TicketSecurityModel[] }>>(url);
	}
}();

const tasks_crudService = new class TicketSecurityService extends AbstractApiService<TaskModel> {
	constructor() {
		super('api/user/task/jira-security/generic');
	}

	getRelatedTasks(ticketKey:string) {
		console.info(`getRelatedTasks(${ticketKey})`);
		const url = `${this.urlFragmentPart}/${ticketKey}/tasks`;

		return apiFetch.get<QueryGetBody<AllSecurityTasksType>>(url);
	}
}();

const ticketSecurityAutomationService = new TicketSecurityAutomationService();

type PageParams = {
	ticketKey:string;
};

export const SecurityTicketDetailsPage = () => {
	const {ticketKey} = useParamsFromRoute<PageParams>();

	const location:any = useLocation();

	const {dispatch:counterDispatch} = useContext(TaskCounterContext);

	const [showSamePluginClosedTickets, setShowSamePluginClosedTickets] = useState(false);

	//TODO not used for the moment, currently the user is coming from a taskList, which does not have a ticket
	const itemFromPrevPage:TicketSecurityModel | undefined = location?.state?.ticket;
	const fromView = location?.state?.fromView;

	//TODO the first data will break the refresh button
	const ticketDetailsQueryProvider:EnhancedQueryProvider<{ data:TicketSecurityModel }> = useCreateQueryProvider(
		useMemo(() => {
			if (itemFromPrevPage && itemFromPrevPage.ticket === ticketKey) {
				const firstValue = {data:itemFromPrevPage};
				const secondValue = () => ticket_crudService.getOne(ticketKey);

				return FunctionHelper.buildSuccessiveFunction<GetQueryProviderResult<{ data:TicketSecurityModel }>>(firstValue, secondValue);
			} else {
				return () => ticket_crudService.getOne(ticketKey);
			}
		}, [ticketKey, itemFromPrevPage])
	);

	const relatedTasksQueryProvider = useCreateQueryProvider(
		useCallback(() => tasks_crudService.getRelatedTasks(ticketKey), [ticketKey])
	);

	//TODO two requests instead of a single one, will require the useCreateProvider to optimize this
	const forceSync = useCallback(() => {
		ticket_crudService.forceSync(ticketKey)
			.then(value => {
				if (value.ok) {
					ticketDetailsQueryProvider.forceProvideData({data:value.payload.data.ticketSecurity});
				} else {
					ticketDetailsQueryProvider.refresh();
				}
				relatedTasksQueryProvider.refresh();
				counterDispatch();
			});
	}, [ticketKey, ticketDetailsQueryProvider, relatedTasksQueryProvider, counterDispatch]);

	const ticketsWithSameComponentProvider:EnhancedQueryProvider<{ data:{ othersWithSameComponent:TicketSecurityModel[] } }> = useCreateQueryProvider(
		useCallback(() => {
			return ticket_crudService.getTicketsWithSameComponent(ticketKey)
			// .then(response => {
			// 	if (!response.ok) {
			// 		const empty:TicketSecurityModel[] = [];
			// 		return ProviderHelper.wrapSuccess({othersWithSameComponent:empty});
			// 	}
			// 	return response;
			// })
		}, [ticketKey])
	)

	return (
		<div className="SecurityTicketDetailsPage">
			<TwoColumns
				className="size-60-40"
				left={<>
					<Card className="NavigationRow">
						{fromView && (
							<NavLink to={fromView} className="BackLink">
								Back link
							</NavLink>
						)}
						{/*TODO external link */}
						<ExternalLink url={JiraUrlHelper.linkToTicket(ticketKey)}>{ticketKey}</ExternalLink>
						{/*<MdOpenInNew />*/}

						{/*<IconButton icon={MdSkipPrevious} onClick={() => setTicketKey(curr => {*/}
						{/*	const ref = parseInt(curr.split('-')[1], 10);*/}
						{/*	return `SECURITY-${ref - 1}`;*/}
						{/*})} />*/}
						{/*<IconButton icon={MdSkipNext} onClick={() => setTicketKey(curr => {*/}
						{/*	const ref = parseInt(curr.split('-')[1], 10);*/}
						{/*	return `SECURITY-${ref + 1}`;*/}
						{/*})} />*/}
					</Card>

					<Card className="TicketPanel">
						<RefreshableQueryDisplay
							queryProvider={ticketDetailsQueryProvider}
							render={({data}, refresh) => (<>
								<Card.TitleAndActions
									title={`Ticket details: ${demoModeInline(data.summary)}`} size="big"
									rightChildren={(<>
										<MetaInfoIcon item={data} />
										<IconButton icon={MdRefresh} onClick={refresh} title="Refresh the ticket" />
									</>)}
								/>
								<TwoColumns
									className="TicketDetails"
									left={<>
										<DetailsRow label="Type" value={data.type} />
										<DetailsRow label="Labels" marginToCompensate="badge" value={<JiraLabels labels={data.labels} />} />
										<DetailsRow label="CVSS" value={data.cvss} />
										<DetailsRow label="Severity" value={data.severity} />
										<DetailsRow label="CWE" value={demoModeInline(data.cwe)} />
										<DetailsRow label="Advisory Credit"
										            value={demoModeInline(data.advisoryCredit)} />
										<DetailsRow label="CVE" value={data.cve} />
										<DetailsRow label="Affected versions" value={data.affectedVersions} />
									</>}
									right={<>
										<DetailsRow label="Status" marginToCompensate="badge" value={
											<JiraStatus label={data.statusName}
											            color={data.statusColor} />
										} />
										<DetailsRow label="Resolution" value={data.resolutionName} />
										<DetailsRow label="Component/s" value={data.components} />
										<DetailsRow label="Sprint" value={data.sprint} />
										<DetailsRow label="Reporter" value={demoModeInline(data.reporter?.displayName)} />
									</>}
								/>
								<JiraTextFormat className="TicketDescription" text={demoModeInline(data.description)} />
								{/*<pre>{JSON.stringify(data, null, 3)}</pre>*/}
							</>)}
						/>
					</Card>

					<Card className="CommentsRow">
						<Card.TitleAndActions
							title="Ticket comments" size="medium"
							// rightChildren={(<>
							// 	<MetaInfoIcon item={data} />
							// 	<IconButton icon={MdRefresh} onClick={refresh} title="Refresh the ticket" />
							// </>)}
						/>
						TODO Comments
						{/*<JiraTextFormat className="TicketDescription" text={demoModeInline(data.description)} />*/}
					</Card>
				</>}
				right={<>
					<Card className="ActionRow">
						<IconButton icon={MdOutlineSwapCalls} onClick={forceSync} title="Sync with Jira" />
					</Card>
					<Card className="TasksRow">
						<RefreshableQueryDisplay
							queryProvider={relatedTasksQueryProvider}
							render={({data}, refresh) => (<>
								<Card.TitleAndActions title="Related tasks" rightChildren={(
									<IconButton icon={MdRefresh} onClick={refresh}
									            title="Refresh the tasks" />
								)} />
								{data.map((task:TaskModel | null, i:number) => (
									<TaskRow key={task?.id || `no-task-at-${i}`}
									         ticketKey={ticketKey} task={task}
									         typeName={AllSecurityTasksTypeNameArray[i]} />
								))}
							</>)}
						/>
					</Card>
					<Card className="OtherTicketForSamePlugin">
						<RefreshableQueryDisplay
							queryProvider={ticketsWithSameComponentProvider}
							render={({data:{othersWithSameComponent}}, refresh) => (<>
								<OtherTicketForSamePlugin otherTickets={othersWithSameComponent} refresh={refresh}
								                          showClosed={showSamePluginClosedTickets}
								                          onShowClosedChange={setShowSamePluginClosedTickets} />
							</>)}
							renderError={(error, errorId, refresh) => (<>
								<OtherTicketForSamePlugin error={error} refresh={refresh}
								                          showClosed={showSamePluginClosedTickets}
								                          onShowClosedChange={setShowSamePluginClosedTickets} />
							</>)}
						/>
					</Card>
				</>}
			/>
		</div>
	)
}

type TaskRowProps = {
	ticketKey:string
	task:TaskModel | null
	typeName:string
}

const TaskRow = ({ticketKey, task, typeName}:TaskRowProps) => {
	if (!task) {
		return (
			<div className="TaskRow">
				<span className="type-name">{typeName}</span>
				<span className="no-task">No task</span>
			</div>
		)
	}
	return (
		<div className="TaskRow">
			<span className="type-name">{typeName}</span>
			<span className="status">
				<TaskBaseStatusBadge task={task} />
			</span>

			<TaskSpecificActions task={task} />

			<NavLink to={`/tasks/ticket/${ticketKey}/task/${task.id}`} className="task-details-link">
				Link
			</NavLink>
		</div>
	);
}

type OtherTicketForSamePluginProps = OtherTicketForSamePluginProps_list | OtherTicketForSamePluginProps_empty;
type OtherTicketForSamePluginPropsBase = {
	showClosed:boolean
	onShowClosedChange:FunctionNM<[boolean], void>
	refresh:FunctionNM<[], void>
};

type OtherTicketForSamePluginProps_list = OtherTicketForSamePluginPropsBase & {
	otherTickets:TicketSecurityModel[]
}
type OtherTicketForSamePluginProps_empty = OtherTicketForSamePluginPropsBase & {
	error:string
}

const OtherTicketForSamePlugin = (props:OtherTicketForSamePluginProps) => {
	let content;
	// if (props.hasOwnProperty('otherTickets')) {
	if ('otherTickets' in props) {
		const list = props.otherTickets;
		const filteredList = props.showClosed ? list : list.filter(ticket => ticket.statusName !== 'Closed');
		let listContent;
		if (filteredList.length) {
			listContent = filteredList.sort(Comparator.number('ticketNum')).map((ticket:TicketSecurityModel) => (
				<TicketRow key={ticket.id} ticket={ticket} />
			))
		} else {
			if (list.length) {
				listContent = <TicketRowEmpty message="No other non-closed tickets related to this plugin" />
			} else {
				listContent = <TicketRowEmpty message="No other tickets related to this plugin" />
			}
		}

		content = listContent

		if (filteredList.length < list.length) {
			content = [listContent,
				<div className="closed-tickets-presence"
				     onClick={() => props.onShowClosedChange(true)}>and {list.length - filteredList.length} closed
					ticket(s)</div>
			]
		} else {
			content = listContent
		}
	} else {
		content = (
			<TicketRowEmpty message="No plugin in this ticket" />
		)
	}

	return (<>
		<Card.TitleAndActions title="Tickets with same plugin" leftChildren={<>
			{props.showClosed ? (
				<IconButton icon={MdOutlineCheckCircleOutline} title="Click to hide the closed tickets"
				            onClick={props.onShowClosedChange} onClickArgs={false} />
			) : (
				<IconButton icon={MdOutlineUnpublished} title="Click to also list the closed tickets"
				            onClick={props.onShowClosedChange} onClickArgs={true} />
			)}
		</>} rightChildren={(
			<IconButton icon={MdRefresh} onClick={props.refresh}
			            title="Refresh the tickets" />
		)} />
		{content}
	</>)

}

type TicketRowProps = {
	ticket:TicketSecurityModel
}

const TicketRow = ({ticket}:TicketRowProps) => {
	return (
		<div className="TicketRow">
			<span className="ticket">{ticket.ticket}</span>
			<span className="summary">
				{ticket.summary.substring(ticket.bracketName!.length + 2 /* the [] around */).trim()}
			</span>
			{/*TODO sprint link to its own page*/}
			<span className="sprint">{
				ticket.sprint ? (
					ticket.sprint.startsWith('SECURITY: ') ? ticket.sprint.substring('SECURITY: '.length) : ticket.sprint
				) : (
					<span className="no-sprint">No sprint</span>
				)}</span>
			<span className="status">
				<JiraStatus label={ticket.statusName} color={ticket.statusColor} />
			</span>

			<NavLink to={`/tasks/ticket/${ticket.ticket}`} className="ticket-details-link">
				Link
			</NavLink>
		</div>
	);
}

type TicketRowEmptyProps = {
	message:string
}
const TicketRowEmpty = ({message}:TicketRowEmptyProps) => {
	return (
		<div className="TicketRowEmpty">{message}</div>
	);
}

type TaskSpecificActionsProps = {
	task:TaskModel
}
const TaskSpecificActions = ({task}:TaskSpecificActionsProps) => {
	if (task.type === TaskSecurityType_LABEL) {
		return (
			<span className="TaskSpecificActions">
                {/*TODO add confirm + notification + disabled while running */}
				<IconButton icon={MdOutlineNewLabel} size="m"
				            title="Add labels to the ticket by triggering the script 'jira-update-plugin-labels'"
				            onClick={addLabelsToTicket} onClickArgs={{task}} />
			</span>
		)
	}
	if (task.type === TaskSecurityType_ASSIGN) {
		return (
			<span className="TaskSpecificActions">
                {/*TODO add confirm + notification + disabled while running */}
				<IconButton icon={MdPersonAddAlt1} size="m"
				            title="Assign the ticket to the security contact and maintainer(s) by triggering the script 'jira-assign-issue'"
				            onClick={assignTicket} onClickArgs={{task}} />
			</span>
		)
	}

	return (
		<span className="TaskSpecificActions" />
	);
}

const addLabelsToTicket = ({task}:{ task:TaskModel }) => {
	ticketSecurityAutomationService.addLabelsToTicket(task.targetId)
		.then((response) => {
			console.info('addLabelsToTicket', response);
		})
}

const assignTicket = ({task}:{ task:TaskModel }) => {
	ticketSecurityAutomationService.assignTicket(task.targetId)
		.then((response) => {
			console.info('assignTicket', response);
		})
}
