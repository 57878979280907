// pointer to the latest preview
export const MODEL_GPT_4o = 'gpt-4o';
export const MODEL_GPT_4_TURBO = 'gpt-4-turbo-preview';
export const MODEL_GPT_4 = 'gpt-4';
export const MODEL_GPT_3_5_TURBO = 'gpt-3.5-turbo';

export type ModelType =
	| typeof MODEL_GPT_4o
	| typeof MODEL_GPT_4_TURBO
	| typeof MODEL_GPT_4
	| typeof MODEL_GPT_3_5_TURBO
	;
