import {DurationHelper} from "@commons/helpers/DurationHelper";
import {CommentCommand} from "@commons/models/CommentCommand";
import {WithDbAndId} from "@commons/types/DbType";
import {FunctionHelper} from "@commons/types/FunctionHelper";
import * as React from "react";
import {useMemo} from "react";
import {MdRefresh} from "react-icons/md";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {useCreateQueryProvider} from "../../../../commons/hooks/useCreateQueryProvider";
import {GetQueryProviderResult} from "../../../../commons/hooks/useGetQuery";
import {useParamsFromRoute} from "../../../../commons/hooks/useParamsFromRoute";
import {BreakSpaces} from "../../../../framework/components/BreakSpaces";
import {Card} from "../../../../framework/components/Card";
import {ExternalLink} from "../../../../framework/components/ExternalLink";
import {IconButton} from "../../../../framework/components/IconButton";
import {InlineCode} from "../../../../framework/components/InlineCode";
import {DetailsRow} from "../../../../framework/components/layout/elements/DetailsRow";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {MetaInfoIcon} from "../../../../framework/components/MetaInfoIcon";
import {RefreshableQueryDisplay} from "../../../../framework/components/RefreshableQueryDisplay";
import {ScrollablePanel} from "../../../../framework/components/ScrollablePanel";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {NoData} from "../../task/commons/NoData";
import {CommandStatus} from "./commons/CommandStatus";
import {JiraUrlHelper} from "@commons/helpers/JiraUrlHelper";

type Model = WithDbAndId<CommentCommand>;

const crudService = new class CommentCommandService extends AbstractApiService<Model> {
	constructor() {
		super('api/user/comment-command');
	}
}();

type PageParams = {
	logId:string;
};

export const CommentCommandDetailsPage = () => {
	const {logId:itemId} = useParamsFromRoute<PageParams>();

	const location:any = useLocation();

	const itemFromPrevPage:Model | undefined = location?.state?.item;
	const fromView = location?.state?.fromView;

	const itemQueryProvider = useCreateQueryProvider<{ data:Model }>(
		useMemo(() => {
			//TODO if A => B => A, the value will not be requested the first time
			// in the case an update on A occurs after A => B, the first value of A after B => A, will be the state one

			if (itemFromPrevPage && itemFromPrevPage.id === itemId) {
				const firstValue = {data:itemFromPrevPage};
				const secondValue = () => crudService.getOne(itemId);

				return FunctionHelper.buildSuccessiveFunction<GetQueryProviderResult<{ data:Model }>>(firstValue, secondValue);
			} else {
				return () => crudService.getOne(itemId);
			}
		}, [itemId, itemFromPrevPage])
	);

	return (
		<PageContent className="CommentCommandDetailsPage" withoutPadding={true}>
			<Card className="NavigationRow">
				{fromView && (<>
					<NavLink to={fromView}>
						Back to previous view
					</NavLink>
				</>)}
			</Card>

			<Card>
				{/*TODO add details about the subQueries*/}

				<RefreshableQueryDisplay
					queryProvider={itemQueryProvider}
					render={({data}, refresh) => (<>
						<Card.TitleAndActions
							title={data.script} size="big"
							rightChildren={(<>
								<MetaInfoIcon item={data} />
								<IconButton icon={MdRefresh} onClick={refresh} title="Refresh the ticket" />
							</>)}
						/>

						<DetailsRow label="Requester" value={data.requester} valueInlineCode={true} />
						<DetailsRow label="Ticket" value={<>
							<ExternalLink url={JiraUrlHelper.linkToTicket(data.ticket)}
							              title="Open the Jira ticket">
								{data.ticket}
							</ExternalLink>
						</>} />

						<DetailsRow label="Status" marginToCompensate="badge" value={
							<CommandStatus status={data.commandStatus} />
						} />

						<DetailsRow label="Script" value={data.script} valueInlineCode={true} />
						<DetailsRow label="Arguments" title="Arguments passed by the requester in the comment"
						            value={data.args} valueInlineCode={true} />
						<DetailsRow label="Translated arguments"
						            title="Arguments after transformation on the server side"
						            value={data.translatedArgs} valueInlineCode={true} />
						<DetailsRow label="Response code" value={data.responseCode} valueInlineCode={true} />
						<DetailsRow label="UUID" value={data.uuid} valueInlineCode={true} hideIfNoValue={true} title="To ease the log analysis" />
						<DetailsRow label="Duration" value={
							DurationHelper.toString(data.duration) || <NoData label="None" />
						} valueTitle={data.duration > 0 ? `${data.duration} ms` : undefined} />

						<DetailsRow label="Detected errors" value={data.detectedErrors.join(', ')} hideIfNoValue={true} />
						<DetailsRow label="Invalid reason" value={data.invalidReason} hideIfNoValue={true} />

						<DetailsRow label="Standard out" value={
							data.stdout ? (
								<ScrollablePanel>
									<BreakSpaces>
										<InlineCode>{data.stdout}</InlineCode>
									</BreakSpaces>
								</ScrollablePanel>
							) : ''
						} />
						<DetailsRow label="Standard error" value={
							data.stderr ? (
								<ScrollablePanel>
									<BreakSpaces>
										<InlineCode>{data.stderr}</InlineCode>
									</BreakSpaces>
								</ScrollablePanel>
							) : ''
						} />
						{(data.output || data.logClone || data.logCreate || data.logAnalyze || data.logSubmit) && (<>
							<DetailsRow label="Output" value={
								data.output ? (
									<ScrollablePanel>
										<BreakSpaces>
											<InlineCode>{data.output}</InlineCode>
										</BreakSpaces>
									</ScrollablePanel>
								) : ''
							} />
							<DetailsRow label="1-clone.log" value={
								data.logClone ? (
									<ScrollablePanel>
										<BreakSpaces>
											<InlineCode>{data.logClone}</InlineCode>
										</BreakSpaces>
									</ScrollablePanel>
								) : ''
							} />
							<DetailsRow label="2-create.log" value={
								data.logCreate ? (
									<ScrollablePanel>
										<BreakSpaces>
											<InlineCode>{data.logCreate}</InlineCode>
										</BreakSpaces>
									</ScrollablePanel>
								) : ''
							} />
							<DetailsRow label="3-analyze.log" value={
								data.logAnalyze ? (
									<ScrollablePanel>
										<BreakSpaces>
											<InlineCode>{data.logAnalyze}</InlineCode>
										</BreakSpaces>
									</ScrollablePanel>
								) : ''
							} />
							<DetailsRow label="4-submit.log" value={
								data.logSubmit ? (
									<ScrollablePanel>
										<BreakSpaces>
											<InlineCode>{data.logSubmit}</InlineCode>
										</BreakSpaces>
									</ScrollablePanel>
								) : ''
							} />
						</>)}

						{/*<hr />*/}
						{/*<pre>{JSON.stringify(data, null, 3)}</pre>*/}
					</>)}
				/>
			</Card>
		</PageContent>
	)
}
