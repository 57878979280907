import {JsonHelper} from "@commons/helpers/JsonHelper";
import {wait} from "@commons/helpers/Wait";

/**
 * Fetch with an automatic access token refresh mechanism
 */
export class WrappedJsonFetch {
	/** Does not have a trailing slash */
	protected url:string = process.env.REACT_APP_API_URL!;

	get<T>(urlFragment:string):Promise<ApiFetchResponse<T>> {
		return this._fetch(this.url + '/' + urlFragment, {
			headers:{
				'Accept':'application/json',
			},
			method:'GET',
			credentials:'include',
		})
	}

	/**
	 * The body will be JSON.stringify-ed
	 */
	post<T>(urlFragment:string, body:any = {}):Promise<ApiFetchResponse<T>> {
		return this._fetch(this.url + '/' + urlFragment, {
			headers:{
				...this.headerValues()
			},
			method:'POST',
			credentials:'include',
			body:JSON.stringify(body)
		})
	}

	rawPost<T>(urlFragment:string, body:any = {}):Promise<ApiFetchResponse<T>> {
		return this._fetch(this.url + '/' + urlFragment, {
			headers:{
				...this.headerValues()
			},
			method:'POST',
			credentials:'include',
			body:JSON.stringify(body),
		}, true)
	}

	put<T>(urlFragment:string, body:any = {}):Promise<ApiFetchResponse<T>> {
		const bodyOneLevel = JsonHelper.stringifyOneLevel(body);
		return this._fetch(this.url + '/' + urlFragment, {
			headers:{
				...this.headerValues()
			},
			method:'PUT',
			credentials:'include',
			body:bodyOneLevel
		})
	}

	patch<T>(urlFragment:string, body:any = {}):Promise<ApiFetchResponse<T>> {
		const bodyOneLevel = JsonHelper.stringifyOneLevel(body);
		return this._fetch(this.url + '/' + urlFragment, {
			headers:{
				...this.headerValues()
			},
			method:'PATCH',
			credentials:'include',
			body:bodyOneLevel
		})
	}

	delete<T>(urlFragment:string):Promise<ApiFetchResponse<T>> {
		return this._fetch(this.url + '/' + urlFragment, {
			headers:{
				...this.headerValues()
			},
			credentials:'include',
			method:'DELETE'
		})
	}

	protected async _fetch<T>(url:string, config:RequestInit, raw:boolean = false):Promise<ApiFetchResponse<T>> {
		if (process.env.NODE_ENV === 'development') {
			await wait(200 + Math.random() * 300);
			// await wait(1300 + Math.random() * 700);
		}
		if (raw) {
			return await fetch(url, config) as any;
		}
		return await fetch(url, config).then(transformResponse, transformError) as ApiFetchResponse<T>;
	}

	protected headerValues():HeadersInit {
		return {
			'Accept':'application/json',
			'Content-Type':'application/json',
		}
	}
}

export type ApiFetchResponse<T> =
	ApiFetchResponse_Success<T>
	| ApiFetchResponse_NotJSON
	| ApiFetchResponse_RequestError
	| ApiFetchResponse_OtherError;

/** The server got the request and replied with a success (< 400) */
type ApiFetchResponse_Success<T> = { ok:true, code:number, payload:T }
/** Unexpected format for the response */
type ApiFetchResponse_NotJSON = { ok:false, code:number, error:'Not a JSON', errorId:undefined }
/** The server got the request but replied with an error (>= 400) */
type ApiFetchResponse_RequestError = { ok:false, code:number, error:string, errorId:string };
/** Error happened on the network, like host not found, no connection, server not responding, etc. */
type ApiFetchResponse_OtherError = { ok:false, code:-1, error:string, errorId:undefined };

const CONTENT_TYPE_HEADER_NAME = 'content-type';
// const CONTENT_TYPE_HEADER_VALUE_JSON = 'application/json; charset=utf-8';
const CONTENT_TYPE_HEADER_VALUE_EVENTSTREAM = 'text/event-stream';

const transformResponse = <T>(response:Response):Promise<ApiFetchResponse_Success<T> | ApiFetchResponse_NotJSON | ApiFetchResponse_RequestError> => {
	// const isJsonContentType = response.headers.get(CONTENT_TYPE_HEADER_NAME) === CONTENT_TYPE_HEADER_VALUE_JSON;
	const contentType = response.headers.get(CONTENT_TYPE_HEADER_NAME);
	
	// const status = response.status;
	const status = response.status;
	if (status === 204) {
		// calling "json()" when the status is 204 leads to "Unexpected end of JSON input"
		return Promise.resolve({ok:true, code:status, payload:{} as any});
	}

	if (response.ok && contentType === CONTENT_TYPE_HEADER_VALUE_EVENTSTREAM) {
		return Promise.resolve({ok:true, code: status, payload: response as T});
	}

	try {
		return response.json().then((responseJson:any) => {
			if (status < 400) {
				return {ok:true, code:status, payload:responseJson};
			} else {
				const errorMessage = responseJson.message || `Error ${status}`;
				const errorId = responseJson.uuid || responseJson.errorId || undefined;
				//TODO support the data:{message: 'Validation failed', validationError: string[]} from 400
				return {ok:false, code:status, error:errorMessage, errorId};
			}
		})
	} catch (e) {
		// json issue
		console.warn('Received response not a JSON', e);
		return Promise.resolve({ok:false, code:status, error:'Not a JSON', errorId:undefined});
	}
}

const transformError = (error:string | Error):Promise<ApiFetchResponse_OtherError> => {
	if (error instanceof Error) {
		const message = error.message;
		if (message === 'Failed to fetch') {
			return Promise.resolve({ok:false, code:-1, error:'The server is not reachable', errorId:undefined});
		} else {
			return Promise.resolve({ok:false, code:-1, error:message, errorId:undefined});
		}
	} else {
		return Promise.resolve({ok:false, code:-1, error:error, errorId:undefined});
	}
}
