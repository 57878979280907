import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';

import {IsoDateString} from "@commons/types/IsoDateString";
import {DateTime, DateTimeFormatOptions} from "luxon";

type Props = {
    value: IsoDateString
    titleFormat?: DateTimeFormatOptions | string
    format?: DateTimeFormatOptions | string
    className?: string
} & OtherHtmlAttributes

export const Date = ({className, value, titleFormat, format, ...rest}: Props) => {
    const dt = DateTime.fromISO(value).setLocale('en');

    titleFormat = titleFormat ?? DateTime.DATETIME_FULL_WITH_SECONDS;
    format = format ?? DateTime.DATE_MED;

    return (
        <span className={ClassHelper.combine('Date', className)}
              title={formatDate(dt, titleFormat)} {...rest}>{formatDate(dt, format)}</span>
    );
}

function formatDate(date: DateTime, format: DateTimeFormatOptions | string): string {
    if (typeof format === 'string') {
        return date.toFormat(format);
    } else {
        return date.toLocaleString(format);
    }
}
