import {useMemo} from "react";

let idGenerator = 0;

export const TEST_resetUniqueId = () => idGenerator = 0;

export const useUniqueId = (prefix:string = 'uid_'):string => {
	const id = useMemo(() => {
		return prefix + idGenerator++;
	}, [prefix]);

	return id;
}
