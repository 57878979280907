import {Task_JiraSecurity_NonCertComment} from "@commons/models/TaskJiraSecurity";
import {WithDbAndId} from "@commons/types/DbType";
import * as React from 'react';
import {useLocation} from "react-router";
import {useColumnDefs, useSecondRow} from "../../../../commons/hooks/useColumnDefs";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {ExternalLinkColumnDef} from "../../../../framework/table/columns/ExternalLinkColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {JiraStatusColumnDef} from "../commons/JiraStatusColumnDef";
import {SecurityTaskCrudPage} from "../commons/SecurityTaskCrudPage";
import {AbstractTaskApiService, TaskHelper} from "../commons/TaskHelper";
import {useContext} from "react";
import {TaskCounterContext} from "../commons/TaskCounterContext";
import {DateTime} from "luxon";

import './NonCertComment_TaskSecurityPage.scss';
import {demoModeInline} from "../../../../framework/components/DemoModeText";
import {JiraUrlHelper} from "@commons/helpers/JiraUrlHelper";

type TaskSecurityModel = WithDbAndId<Task_JiraSecurity_NonCertComment>;

const crudService = new class TaskSecurityService extends AbstractTaskApiService<TaskSecurityModel> {
	constructor() {
		super('api/user/task/jira-security/non-cert-comment');
	}
}();

export const NonCertComment_TaskSecurityPage = () => {
	const currentLocation = useLocation();
	const {dispatch:counterDispatch} = useContext(TaskCounterContext);

	const {columnDefs, initialState} = useColumnDefs<TaskSecurityModel>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'Id', row => row.id || '', {defaultVisibility:false, sortable:false}),
		new StringColumnDef('targetIdNum', 'Ticket', row => row.targetId || '', {size:110}),
		new ExternalLinkColumnDef('openLink', row => JiraUrlHelper.linkToTicket(row.targetId)),
		...TaskHelper.defaultBrowseIcons(currentLocation),
		...TaskHelper.defaultInformationDisplay(),
		new JiraStatusColumnDef(),
		...TaskHelper.defaultActionIcons(crudService, counterDispatch),
	], {initialOrder:'targetIdNum', initialOrderDirection:'desc', initialFilters:['taskDone:b:eq:false']});

	const secondRowDef = useSecondRow<TaskSecurityModel>((row, dispatch) => {
		const dt = DateTime.fromISO(row.lastCommentDate).setLocale('en');

		const numComments = row.numCommentsSinceCertComment >= 0 ? (
			<code title="x / y, where x is the number of comments since the last one from JenSec and y the total number">[{row.numCommentsSinceCertComment}/{row.numComments}] </code>
		) : '';

		// const author = <span className="author">From <span title={row.lastCommentAuthor.login}>{row.lastCommentAuthor.displayName}</span></span>;
		const author = <span className="author"><span title={demoModeInline(row.lastCommentAuthor.login)}>{demoModeInline(row.lastCommentAuthor.displayName)}</span></span>;
		const date = <span className="date" title={dt.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}>{dt.toLocaleString(DateTime.DATE_MED)}</span>;
		const content = <span className="content" title={demoModeInline(row.lastComment)}>{demoModeInline(row.lastComment)}</span>;
		return (
			<>{numComments}{author} ({date}): {content}</>
		)
	}, {rowClass: 'non-cert-comment-second-row'});

	return (
		<SecurityTaskCrudPage pageId="jira-security-non-cert-comment" pageClass="NonCertComment_TaskSecurityPage"
		                      instructionMessage={`Open until the last comment is coming from a CERT member`}
		                      crudService={crudService}
		                      initialState={initialState} pageName="Last comment not from CERT"
		                      columnDefs={columnDefs} secondRowDef={secondRowDef} />
	)
}
