import {CrudModelId} from "../types/WithId";

export const ITEM_TYPE_DIRECT_COMMAND = 'direct';
export const ITEM_TYPE_COMMENT_COMMAND = 'comment-command';
export const ITEM_TYPE_RPU_COMMAND = 'rpu-command';

export type ItemType =
	typeof ITEM_TYPE_DIRECT_COMMAND
	| typeof ITEM_TYPE_COMMENT_COMMAND
	| typeof ITEM_TYPE_RPU_COMMAND;

export const REQUEST_ID_SEPARATOR = ':';

export const REQUEST_ID_HEALTH_CHECK = 'health-check';

export type RequestId = `${ItemType}${typeof REQUEST_ID_SEPARATOR}${CrudModelId}` | typeof REQUEST_ID_HEALTH_CHECK;

export const REQUEST_ID_SAMPLE :RequestId = 'direct:sample';

export class RequestIdHelper {
	static buildRequestId = (itemType:ItemType, itemId:string):RequestId => {
		// return [itemType, itemId].join(REQUEST_ID_SEPARATOR) as RequestId;
		return `${itemType}${REQUEST_ID_SEPARATOR}${itemId}`;
	}
	static parseRequestId = (requestId:RequestId):[itemType:ItemType, itemId:string] => {
		const [itemType, ...itemIdParts] = requestId.split(REQUEST_ID_SEPARATOR);
		const itemId = itemIdParts.join(REQUEST_ID_SEPARATOR);

		return [itemType as ItemType, itemId];
	}
}
