import {ReactNode} from "react";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';

import './InlineCode.scss';

type Props = {
	children:ReactNode
} & OtherHtmlAttributes

export const InlineCode = ({children, ...rest}:Props) => {
	return (
		<span className="InlineCode" {...rest}>{children}</span>
	);
}
