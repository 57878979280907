import * as React from "react";
import {ForwardedRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {MdArrowDropDown} from 'react-icons/md';
import {FunctionNM} from "@commons/types/FunctionNM";
import {ClassHelper, ClassName} from "../../../../commons/helpers/ClassHelper";
import {DomHelper} from "../../../../commons/helpers/DomHelper";
import {DynamicOrder} from '../../../components/misc/DynamicOrder';
import {TextButton} from '../../../components/TextButton';
import {useRefForSpaceEnter} from '../../hooks/useRefForSpaceEnter';
import {DropdownInputOption} from "../../types/DropdownTypes";
import {OtherHtmlAttributes} from '../../types/OtherHtmlAttributes';

import "./DropdownMenu2.scss"

export type DropdownMenuProps<T> = {
	// Input
	values:DropdownInputOption<T>[]
	loading?:boolean
	selectedValue?:string
	disabled?:boolean

	// Output
	onValueSelected?:FunctionNM<[T], void>
}

//TODO focus-within the outline is displayed only on top/bottom, not the left/right side
export const DropdownMenu2 = <T extends any>({
	                                             values,
	                                             loading,
	                                             selectedValue,
	                                             disabled,
	                                             onValueSelected
                                             }:DropdownMenuProps<T>) => {
	// console.info(`DropdownMenu2 render`);

	const [open, setOpen] = useState(false);
	// console.info('DropdownMenu2 open', open);

	const handleClickOutside = useCallback((e?:MouseEvent | KeyboardEvent) => {
		if (e instanceof KeyboardEvent) {
			// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
			// old Firefox and IE are returning 'Esc'
			if (e.key === 'Escape' || e.key === 'Esc') {

			} else {
				// console.info('ignore keyevent', e.key);
				return;
			}
		}
		// when the user clicks on the menu items, the event listener is removed before the event is handled
		// thus, no need to check for target being the menu item or not
		// console.info('setOpen(false) and remove click listener', (handleClickOutside as any).r);
		setOpen(false);
		document.removeEventListener('click', handleClickOutside);
		document.removeEventListener('keyup', handleClickOutside);
	}, []);
	useMemo(() => {
		(handleClickOutside as any).r = Math.random();
	}, [handleClickOutside]);

	const handleButtonClick = useCallback(() => {
		if (open) {
			return;
		}
		// console.info('setOpen(true)');
		setOpen(true);
		setTimeout(() => {
			// console.info('add click listener', (handleClickOutside as any).r);
			document.addEventListener('click', handleClickOutside);
			document.addEventListener('keyup', handleClickOutside);
		}, 1);
	}, [open, handleClickOutside]);

	const handleValueSelect = useCallback((value:T) => {
		handleClickOutside();
		onValueSelected && onValueSelected(value);
	}, [onValueSelected, handleClickOutside]);

	//TODO add arrow change on the textButton directly

	let directionUp = false;
	let directionLeft = false;

	const ref = useRef<HTMLDivElement>(null);

	const menuPartRef = useRef<DropdownMenuPartRef>(null);

	//TODO restrict when to compute this
	if (open && ref.current && menuPartRef.current) {
		const marginX = 20;
		const marginY = 20;

		// https://stackoverflow.com/a/8876069
		const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

		menuPartRef.current.show();
		const menuRect = menuPartRef.current.getBoundingClientRect();
		const menuSizeX = menuRect.width;
		const menuSizeY = menuRect.height;
		menuPartRef.current.hide();

		const rect = ref.current.getBoundingClientRect();
		if (rect.right + menuSizeX > vw - marginX) {
			directionLeft = true;
		}
		if (rect.bottom + menuSizeY > vh - marginY) {
			directionUp = true;
		}
	}

	return (
		<div className="DropdownMenu2" ref={ref}>
			<DynamicOrder
				changeOrder={directionUp}
				first={
					<TextButton label={selectedValue || values[0].label} onClick={handleButtonClick}
					            type="bordered"
					            afterIcon={MdArrowDropDown} />
				}
				second={
					<div className="DropdownArea">
						<DropdownMenuPart open={open} directionUp={directionUp} directionLeft={directionLeft}
						                  disabled={disabled}
						                  ref={menuPartRef}
						                  onValueSelected={handleValueSelect} values={values}
						                  selectedValue={selectedValue} />
					</div>
				}
			/>
		</div>
	);
}

export type DropdownMenuPartProps<T = any> = {
	// Input
	values:DropdownInputOption<T>[]
	open?:boolean
	// loading?:boolean
	selectedValue?:string
	disabled?:boolean
	className?:string
	directionUp?:boolean
	directionLeft?:boolean

	// Output
	onValueSelected?:FunctionNM<[T], void>
} & OtherHtmlAttributes

type DropdownMenuPartRef = {
	show:() => void
	hide:() => void
	getBoundingClientRect:() => DOMRect
}

const DropdownMenuPart = React.forwardRef(({
	                                           values,
	                                           open,
	                                           directionUp,
	                                           directionLeft,
	                                           disabled,
	                                           className,
	                                           selectedValue,
	                                           onValueSelected,
	                                           ...rest
                                           }:DropdownMenuPartProps, forwardedRef:ForwardedRef<DropdownMenuPartRef>) => {
	// console.info('DropdownMenuPart open', open, 'up', directionUp, 'left', directionLeft);

	const [currIndex, setCurrIndex] = useState(() => {
		return values?.findIndex(v => selectedValue === v.value);
	});

	const ref = useRef<HTMLDivElement | null>(null);
	useImperativeHandle(forwardedRef, () => {
		return {
			show:() => {
				ref.current?.classList.remove('not-open');
			},
			hide:() => {
				ref.current?.classList.add('not-open');
			},
			getBoundingClientRect:() => {
				return ref.current?.getBoundingClientRect();
			}
		} as DropdownMenuPartRef;
	});

	const keyDownHandle = useCallback((e:KeyboardEvent) => {
		if (e.key === 'ArrowUp') {
			setCurrIndex(currIndex => {
				const newIndex = currIndex - 1;
				if (newIndex >= 0) {
					const allItems = ref.current?.getElementsByClassName('DropdownMenuItem');
					if (allItems?.length && newIndex < allItems.length) {
						const targetItem = allItems[newIndex];
						if (!DomHelper.isUserVisible(targetItem)) {
							targetItem.scrollIntoView({behavior:'smooth'});
						}
					}
				}
				e.preventDefault();
				return newIndex;
			});
		} else if (e.key === 'ArrowDown') {
			setCurrIndex(currIndex => {
				const newIndex = currIndex + 1;
				if (newIndex < values.length) {
					const allItems = ref.current?.getElementsByClassName('DropdownMenuItem');
					if (allItems?.length && newIndex < allItems.length) {
						const targetItem = allItems[newIndex];
						if (!DomHelper.isUserVisible(targetItem)) {
							targetItem.scrollIntoView({behavior:'smooth'});
						}
					}
				}
				e.preventDefault();
				return newIndex;
			});
		} else if (e.key === 'Enter') {
			// no check on Space, as it's not expected that Space interaction on a dropdown has effect
			// https://webaim.org/techniques/keyboard/#testing
			setCurrIndex(currIndex => {
				const newSelectedValue = values[currIndex];
				// this will trigger the event the next frame, and so not breaking the setState rendering current iteration
				setTimeout(() => {
					onValueSelected && onValueSelected(newSelectedValue.value);
				}, 0)
				return currIndex;
			});
			e.preventDefault();
		}
	}, [values, onValueSelected]);

	useEffect(() => {
		if (!values.length || !open) {
			return;
		}

		// console.info('add keydown');
		document.addEventListener('keydown', keyDownHandle);
		return () => {
			// console.info('remove keydown');
			document.removeEventListener('keydown', keyDownHandle);
		};
		// }, [open, currIndex, values, onValueSelected]);
	}, [open, values, keyDownHandle]);

	const classes = ClassHelper.combine('DropdownMenuPart', className,
		open ? 'open' : 'not-open',
		directionUp ? 'open-up' : '',
		directionLeft ? 'open-left' : '',
	);

	const handleMenuItemClick = useCallback((value:any, index:number) => {
		onValueSelected && onValueSelected(value);
		setCurrIndex(index);
	}, [onValueSelected]);

	return (
		<div className={classes} {...rest} ref={ref}>
			{(!values) ? (
				//TODO not supported yet
				<div className="menu-loading">Data loading...</div>
			) : (
				values.map((v, index) => {
					const menuItemDisabled = (disabled || v.disabled);
					const classes = [menuItemDisabled ? 'disabled' : 'enabled'];
					const isSelected = selectedValue === v.value;
					const simulateHoverEffect = currIndex === index;
					if (isSelected) {
						classes.push('selected');
					} else {
						if (simulateHoverEffect) {
							classes.push('hover');
						}
					}
					return (
						<DropdownMenuItem key={v.value} value={v.value} label={v.label} disabled={v.disabled}
						                  selected={isSelected}
						                  title={v.title} className={classes} tabIndex={0}
						                  onMenuItemClick={handleMenuItemClick} onMenuItemClickArgs={[index]} />
					);
				})
			)}
		</div>
	)
})

type DropdownMenuItemProps<T = any, Args extends any[] = any[]> = {
	label:string
	value:T
	title?:string
	className?:ClassName
	disabled?:boolean
	selected?:boolean
	onMenuItemClick?:FunctionNM<[T, ...Args], void>
	onMenuItemClickArgs?:any[]
} & OtherHtmlAttributes

const DropdownMenuItem = ({
	                          label,
	                          value,
	                          title,
	                          selected,
	                          className,
	                          disabled,
	                          onMenuItemClick,
	                          onMenuItemClickArgs,
	                          ...rest
                          }:DropdownMenuItemProps) => {
	const handleMenuItemClick = useCallback((e:React.MouseEvent<HTMLDivElement>) => {
		if (onMenuItemClick) {
			if (onMenuItemClickArgs) {
				onMenuItemClick(value, ...onMenuItemClickArgs);
			} else {
				onMenuItemClick(value);
			}
		}
	}, [value, onMenuItemClick, onMenuItemClickArgs]);

	const ref = useRefForSpaceEnter();

	return (
		<div title={title} ref={ref}
		     className={ClassHelper.combine('DropdownMenuItem', className)}
		     onClick={disabled ? undefined : handleMenuItemClick}
		     tabIndex={0}
		     {...rest}>{label}</div>
	)
}
