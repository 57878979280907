import {useCallback} from "react";
import {LogAppender} from "../../framework/components/LogPanel";
import {useIsMounted} from "./useIsMounted";

export type LogIfMountedType = (data:any) => void;

export const useLogIfStillMounted = (appendLog:LogAppender) => {
	const isMounted = useIsMounted();
	const logIfMounted = useCallback((data:any) => {
		if (isMounted.current) {
			appendLog(data);
		} else {
			console.info('Just logging in the console as the component was dismounted in the meantime', data);
		}
	}, [isMounted, appendLog]);

	return {isMounted, logIfMounted};
}
