import * as React from 'react';
import {useCallback, useEffect} from 'react';
import {FaGithub} from 'react-icons/fa';
import {MdExitToApp} from 'react-icons/md';
import {useLocation} from 'react-router';

import {useAuth} from '../../../commons/hooks/useAuth';
import {useGetQuery} from '../../../commons/hooks/useGetQuery';
import {authApiService, RefreshUserInfoType} from '../../../commons/services/AuthApiService';
import {apiFetch} from "../../../framework/apiFetch";
import {ErrorPanel, InfoPanel} from "../../../framework/components/color/Panel";
import {Section} from '../../../framework/components/layout/Section';
import {QueryDisplay} from '../../../framework/components/QueryDisplay';
import {TextButton} from '../../../framework/components/TextButton';

import "./LoginPage.scss"

export const LoginPage = () => {
	console.info('LoginPage');
	const {authInfo, setAuthInfo, couldHaveJwtCookie} = useAuth();
	const location:any = useLocation();

	const from = location?.state?.from;
	console.info('[LoginPage] from', from);
	if (from && from !== '/') {
		setAuthInfo({from, justFrom:true});
	}

	useEffect(() => {
		authApiService.whoAmI().then(value => {
			console.info('whoAmI', value);
		});
	}, [])
	
	const onLoggoutClick = useCallback(() => {
		authApiService.logout()
			.then((response) => {
				if (response.ok) {
					setAuthInfo({authInfo: null});
				}
				//TODO display error
			})
	}, [setAuthInfo]);

	const needRefreshUserInfo = (!authInfo && couldHaveJwtCookie);

	const refreshUserInfoQueryResponse = useGetQuery<RefreshUserInfoType>(
		useCallback(() => {
			console.info('needRefreshUserInfo', needRefreshUserInfo);
			if (needRefreshUserInfo) {
				return authApiService.refreshUserInfo(true)
			}
			return null;
		}, [needRefreshUserInfo])
	);

	const refreshUserInfoQueryRender = useCallback(({userInfo, csrfToken, jwtExpirationTime, wasRefresh, userIdentity}:RefreshUserInfoType) => {
		console.info('Refresh success');
		setTimeout(() => {
			if (csrfToken) {
				apiFetch.setCsrfToken(csrfToken.token, csrfToken.header, jwtExpirationTime);
			} else {
				apiFetch.setCsrfToken(null, null, 0);
			}
			setAuthInfo({authInfo: userInfo, from, userIdentity});
		}, 10);
		return <span>Connected</span>;
	}, [setAuthInfo, from])

	const refreshUserInfoQueryRenderError = useCallback((error:string, errorId:string | null) => {
		setTimeout(() => {
			setAuthInfo({authInfo: null});
		}, 10);
		return (
			<div className="error">
				<ErrorPanel>
					Error: {error}
				</ErrorPanel>
				{error === 'No JWT token from cookies' && (
					<InfoPanel>
						If you are using the "Private browsing mode", by default the cross-origin cookies
						are not sent. This means that the authentication of this single-page application
						will not work.
					</InfoPanel>
				)}
			</div>
		)
	}, [setAuthInfo])

	const url = `${process.env.REACT_APP_API_URL}/auth/oauth/github/start`;

	const isDev = process.env.NODE_ENV === 'development';

	return (
		<Section noTopNav={true} className="LoginPage">
			<div className="centered-content">
				<h1>Jenkins CERT application</h1>

				{needRefreshUserInfo && (
					<QueryDisplay queryResponse={refreshUserInfoQueryResponse} 
					              render={refreshUserInfoQueryRender} 
					              renderError={refreshUserInfoQueryRenderError}
					/>
				)}

				{authInfo && (
					<div className="already-connected">
						<pre>{JSON.stringify(authInfo, null, 2)}</pre>

						<TextButton onClick={onLoggoutClick} label="Logout" type="bordered" icon={MdExitToApp} />
					</div>
				)}

				{isDev && (
					<div className="env-panel">
						<div className="env-entry">
							NODE_ENV=
							<span className="env-value">{process.env.NODE_ENV}</span>
						</div>
						<div className="env-entry">
							PUBLIC_URL=
							<span className="env-value">{process.env.PUBLIC_URL}</span>
						</div>
						<div className="env-entry">
							REACT_APP_API_URL=
							<span className="env-value">{process.env.REACT_APP_API_URL}</span>
						</div>
						<div className="env-entry">
							{/*Not env but just debug*/}
							from=
							<span className="env-value">{from}</span>
						</div>
					</div>
				)}

				<a href={url} className="sign-in-link">
					<FaGithub/>
					Sign in using GitHub
				</a>
			</div>
		</Section>
	)
}
