import * as React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {AdminSection} from "./app/sections/admin/AdminSection";
import {AiSection} from "./app/sections/ai/AiSection";
import {MainSection} from "./app/sections/main/MainSection";
import {ConnectionSuccessPage} from './app/sections/misc/ConnectionSuccessPage';
import {Error403Page} from './app/sections/misc/Error403Page';
import {LoginPage} from './app/sections/misc/LoginPage';
import {TaskSection} from "./app/sections/task/TaskSection";
import {ToolboxSection} from "./app/sections/tool/ToolboxSection";
import {AppContainer} from "./AppContainer";
import {ErrorBoundary} from "./framework/components/ErrorBoundary";
import {IfElseRoute} from "./framework/components/IfElseRoute";
import {ProtectedRoute} from './framework/components/ProtectedRoute';

export const App = () => {
	// return (
	// 	<Error403Page />
	// )

	// return (
	// 	<BrowserRouter>
	// 	</BrowserRouter>
	// )

	// return (
	// 	<BrowserRouter>
	// 		<Routes>
	// 			{/*<Route path="/login" element={<LoginPage />} />*/}
	// 			{/*<Route path="/connection-success" element={<ConnectionSuccessPage />} />*/}
	// 			<Route path="/403" element={<Error403Page />} />
	// 		</Routes>
	// 	</BrowserRouter>
	// )
	return (
		<ErrorBoundary>
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<AppContainer>
					<Routes>
						<Route path="/login" element={<LoginPage />} />
						<Route path="/connection-success" element={<ConnectionSuccessPage />} />
						<Route path="/403" element={<Error403Page />} />

						<Route path="/ai/*"
						       element={<ProtectedRoute requiresUserOrAiAccess={true}><AiSection /></ProtectedRoute>} />
						<Route path="/tasks/*"
						       element={<ProtectedRoute requiresUser={true}><TaskSection /></ProtectedRoute>} />
						<Route path="/toolbox/*"
						       element={<ProtectedRoute requiresUser={true}><ToolboxSection /></ProtectedRoute>} />
						<Route path="/admin/*"
						       element={<ProtectedRoute requiresAdmin={true}><AdminSection /></ProtectedRoute>} />
						{/*<Route path="*" element={<ProtectedRoute><MainSection /></ProtectedRoute>} />*/}
						{/*<Route path="*" element={<MainSection />} />*/}
						{/*Not working completely correctly when changing the user in the DevTool component*/}
						{/*<Route path="*" element={*/}
						{/*	<IfElseRoute requiresUserOrAiAccess={true}*/}
						{/*	             ifRoute={<Navigate to={'/ai'} />}*/}
						{/*	             elseRoute={<MainSection />} />*/}
						{/*} />*/}
						<Route path="*" element={
							<IfElseRoute requiresOnlyAiAccess={true}
							             ifRoute={<Navigate to={'/ai'} />}
							             elseRoute={<ProtectedRoute><MainSection /></ProtectedRoute>} />
						} />

						{/*/!*<ProtectedRoute path="/todos" component={TodosSection} />*!/*/}
						{/*<ProtectedRoute path="/tasks" element={<TaskSection />} />*/}
						{/*<ProtectedRoute path="/toolbox" element={<ToolboxSection />} />*/}
						{/*/!*<ProtectedRoute path="/admin" requiresAdmin={true} component={AdminSection} />*!/*/}
						{/*<ProtectedRoute path="/admin" element={<AdminSection />} requiresAdmin={true} />*/}
						{/*<ProtectedRoute path="/" element={<MainSection />} />*/}

						{/*<ProtectedRoute path="/">*/}
						{/*	<Redirect to="/home" />*/}
						{/*</ProtectedRoute>*/}

						{/*<Route exact path="/login" component={LoginStandalonePage} />*/}
						{/*<Route exact path="/register" component={RegisterStandalonePage} />*/}
						{/*<Route path="/super-admin" component={SuperAdminSection} />*/}
						{/*<Route path="/i/:instanceId/admin" component={AdminSection} />*/}
						{/*<Route path="/processes" component={ProcessSection} />*/}
						{/*<Route path="/" component={MainSection} />*/}
					</Routes>
				</AppContainer>
			</BrowserRouter>
		</ErrorBoundary>
	);
};
