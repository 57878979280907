import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {MdFirstPage, MdNavigateBefore, MdNavigateNext} from "react-icons/md";
import {WithDbAndId} from "@commons/types/DbType";
import {QueryResponse} from "../../../commons/hooks/useGetQuery";
import {IconButton} from "../../components/IconButton";
import {QueryListBody} from "../../crud/CrudApiService2";
import {PaginationInfo} from "../../crud/features/PaginationFeature";
import {CrudPage2Dispatcher} from "../../crud2/CrudPage2";
import {DropdownMenu2} from '../../form/inputs/dropdown/DropdownMenu2';
import {DropdownInputOption} from '../../form/types/DropdownTypes';

import "./TablePaginationCursor.scss";

export type TablePaginationCursorProps<T> = {
	pagination:PaginationInfo
	hasCurrentPageElements?:boolean
	//TODO replace by "cached last valid list"
	itemsData:QueryResponse<QueryListBody<WithDbAndId<any>>>
	dispatch:CrudPage2Dispatcher<T>
}

const useLastValidItemList = (itemsData:QueryResponse<QueryListBody<WithDbAndId<any>>>):[WithDbAndId<any>[],WithDbAndId<any>[]] => {
	const [cache, setCache] = useState<WithDbAndId<any>[]>([]);
	// console.info('[useLastValidItemList] use');

	// console.info(`CacheWhenCondition render condition=${condition}`);
	useEffect(() => {
		if (!itemsData.loading && itemsData.error === null && itemsData.data.data.length > 0) {
			// console.info('[useLastValidItemList] cache condition true');
			setCache(itemsData.data.data);
		}else{
			// console.info('[useLastValidItemList] cache condition false');
		}
	}, [itemsData]);
	
	return [cache, itemsData.data?.data || []];
}

export const TablePaginationCursor = <T,>({
	                                      pagination,
	                                      hasCurrentPageElements,
	                                      itemsData,
	                                      dispatch
                                      }:TablePaginationCursorProps<T>) => {
	const hasNext = hasCurrentPageElements === undefined ? true : !hasCurrentPageElements;
	const [firstPageEnabled] = useMemo(() => {
		return [
			pagination.pageNo !== 1,
		]
	}, [pagination]);
	
	const [lastValidItemList, currentItemList] = useLastValidItemList(itemsData);

	// const firstElement = itemsData.data!.data[0];
	// const lastElement = itemsData.data!.data[itemsData.data!.data.length - 1];

	// Always valid when the component is wrapped by CacheWhenCondition
	const firstElement = lastValidItemList[0];
	const lastElement = lastValidItemList[lastValidItemList.length - 1];

	const handleFirstPageClick = useCallback(() => {
		dispatch({type:'setPagination', pagination:{...pagination, pageNo:1}});
	}, [pagination, dispatch]);

	const handlePreviousPageClick = useCallback(() => {
		if (currentItemList.length === 0) {
			dispatch({
				type:'setPaginationCursor',
				pagination:{...pagination, pageNo:pagination.pageNo - 1},
				cursor:{startAtDocId:firstElement.id, startAfterDocId:undefined, endBeforeDocId:undefined}
			});
		}else {
			dispatch({
				type:'setPaginationCursor',
				pagination:{...pagination, pageNo:pagination.pageNo - 1},
				cursor:{startAtDocId:undefined, startAfterDocId:undefined, endBeforeDocId:firstElement.id}
			});
		}
	}, [pagination, dispatch, firstElement, currentItemList]);
	const handleNextPageClick = useCallback(() => {
		dispatch({
			type:'setPaginationCursor',
			pagination:{...pagination, pageNo:pagination.pageNo + 1},
			cursor:{startAtDocId:undefined, startAfterDocId:lastElement.id, endBeforeDocId:undefined}
		});
	}, [pagination, dispatch, lastElement]);

	//not supporting pageNo change means we do not bother with offset
	// const handlePageNoChange = useCallback((newPageNo) => {
	// 	dispatch({type:'setPaginationCursor', pagination: {...pagination, pageNo:newPageNo}, cursor: {startAtDocId: firstElement.id}});
	// }, [pagination, dispatch, firstElement]);

	// As the page size could not multiple between each other, forcing move back to 0 is necessary
	const handlePageSizeChange = useCallback((newPageSize:number) => {
		dispatch({
			type:'setPagination',
			pagination:{...pagination, pageNo:1, pageSize:newPageSize}
		});
	}, [pagination, dispatch]);

	return (
		<div className="TablePaginationCursor">
			<IconButton icon={MdFirstPage} onClick={handleFirstPageClick} disabled={!firstPageEnabled}
			            tabIndex={firstPageEnabled ? 0 : -1} />

			<IconButton icon={MdNavigateBefore} onClick={handlePreviousPageClick} disabled={!firstPageEnabled}
			            tabIndex={firstPageEnabled ? 0 : -1} />

			<div className="page-info">
				<PageNo pageNo={pagination.pageNo} />
				<span className="page-sep"> / </span>
				{hasNext ? (
					<span className="page-max">?</span>
				) : (
					<span className="page-max">{pagination.pageNo}</span>
				)}
			</div>

			<IconButton icon={MdNavigateNext} onClick={handleNextPageClick} disabled={!hasNext}
			            tabIndex={hasNext ? 0 : -1} />

			{/*Never propose the to the last page as we do not know*/}

			<PageSizeSelector currentPageSize={pagination.pageSize} onPageSizeChange={handlePageSizeChange} />
		</div>
	);
}

type PageNoProps = {
	pageNo:number
}

const PageNo = ({pageNo}:PageNoProps) => {
	return (
		<div className="PageNo">
			<span>{pageNo}</span>
		</div>
	)
}

type PageSizeSelectorProps = {
	currentPageSize:number
	onPageSizeChange:(newPageSize:number) => void;
}

const PAGE_SIZES = [5, 10, 20, 50, 100];
const PAGE_SIZE_OPTIONS:DropdownInputOption[] = PAGE_SIZES.map(s => ({value:s + '', label:s + ''}));

const PageSizeSelector = ({currentPageSize, onPageSizeChange}:PageSizeSelectorProps) => {
	const handleChange = useCallback((newValue:string) => {
		// if (newValue === 'custom') {
		//TODO	
		// }
		const newPageSize = parseInt(newValue, 10);
		onPageSizeChange && onPageSizeChange(newPageSize);
	}, [onPageSizeChange]);

	const values = useMemo(() => {
		if (PAGE_SIZES.includes(currentPageSize)) {
			return PAGE_SIZE_OPTIONS;
		} else {
			return [{value:currentPageSize + '', label:currentPageSize + ''}].concat(PAGE_SIZE_OPTIONS);
		}
	}, [currentPageSize]);

	const selectedValue = currentPageSize + '';

	//TODO reduce emphasis on the pageSize select, keeping is simple text like the pageNo?
	return (
		<div className="PageSize">
			<span className="item-per-page-label">Elements per page :</span>

			{/*<DropdownInput values={values} onValueSelected={handleChange} value={selectedValue} />*/}
			{/*<TextButton label={selectedValue}*/}
			{/*            onClick={handleButtonClick} type="bordered" afterIcon={MdArrowDropDown} />*/}
			{/*<DropdownMenu open={open} values={values} selectedValue={selectedValue} onValueSelected={handleChange} />*/}
			<DropdownMenu2 values={values} selectedValue={selectedValue} onValueSelected={handleChange} />
		</div>
	);
}
