import {Dispatch, Reducer, ReducerAction, ReducerState, useEffect, useMemo, useReducer} from 'react';

export const useReducerWithCleanup = <R extends Reducer<any, any>, I = any>(
	reducer:R, initializerArg:I,
	initializer:(arg:I) => ReducerState<R>
):[ReducerState<R>, Dispatch<ReducerAction<R>>] => {
	const [
		state, dispatchRaw
	] = useReducer(reducer, initializerArg, initializer);

	const dispatch = useMemo(() => wrapDispatch(dispatchRaw), [dispatchRaw]);

	// console.info('[useReducerWithCleanup] before useEffect');
	useEffect(() => {
		// console.info('[useReducerWithCleanup] in useEffect');
		return function cleanup() {
			// console.info('[useReducerWithCleanup] in useEffect cleanup');
			dispatch.cleanup();
		};
	}, [dispatch]);
	// console.info('[useReducerWithCleanup] after useEffect');

	return [state, dispatch];
}

const wrapDispatch = <R extends Reducer<any, any>>(dispatch:Dispatch<ReducerAction<R>>) => {
	let valid = true;
	const wrapped = function(action:ReducerAction<R>) {
		if (valid) {
			dispatch(action);
		} else {
			console.info('dispatch no longer valid', action);
		}
	}
	wrapped.cleanup = () => {
		valid = false;
	}
	return wrapped;
}
