import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';

import './Divider.scss';

type Props = {
	noHorizontalMargin?:boolean,
	className?:string
} & OtherHtmlAttributes

export const Divider = ({className, noHorizontalMargin, ...rest}:Props) => {
	return (
		<div className={ClassHelper.combine('Divider', className, noHorizontalMargin && 'no-horizontal-margin')}
		     {...rest} />
	);
}
