import {FunctionNM} from "@commons/types/FunctionNM";
import * as React from "react";
import {useCallback} from "react";
import {EnhancedQueryProvider} from "../../commons/hooks/useCreateQueryProvider";
import {useGetQuery} from '../../commons/hooks/useGetQuery';
import {QueryDisplay} from "./QueryDisplay";

type RefreshableQueryDisplayProps<T> = {
	queryProvider:EnhancedQueryProvider<T>
	className?:string
	render:(data:T, refresh:FunctionNM<[], void>) => JSX.Element | null
	renderError?:(error:string, errorId:string | null, refresh:FunctionNM<[], void>) => JSX.Element | null
}

//TODO use QueryDisplayProps for rest

export const RefreshableQueryDisplay = <T, >({
	                                             queryProvider,
	                                             className,
	                                             render,
	                                             renderError
                                             }:RefreshableQueryDisplayProps<T>) => {

	const requestRefresh = useCallback(() => {
		queryProvider.refresh();
	}, [queryProvider]);

	// const itemQuery = useGetQuery(queryProviderWithCount);
	const itemQuery = useGetQuery(queryProvider);

	const renderAndRefresh = useCallback((data:T) => {
		return render(data, requestRefresh);
	}, [render, requestRefresh]);

	const renderErrorAndRefresh = useCallback((error:string, errorId:string | null) => {
		return renderError ? renderError(error, errorId, requestRefresh) : null;
	}, [renderError, requestRefresh]);

	return (
		<QueryDisplay queryResponse={itemQuery} render={renderAndRefresh} className={className}
		              renderError={renderErrorAndRefresh} />
	);
}
