import {useCallback} from "react";
import {MdCheckBox, MdCheckBoxOutlineBlank, MdIndeterminateCheckBox} from "react-icons/md";
import {FunctionNM} from "@commons/types/FunctionNM";

import {useUniqueId} from "../../commons/hooks/useUniqueId";
import {TriStateForCheckbox} from '../../commons/types/TriStateForCheckbox';

import "./CustomCheckbox.scss"

export type CustomCheckboxProps = {
	value: TriStateForCheckbox
	className?:string
	disabled?: boolean
	onChange?: FunctionNM<[boolean], void>
}

export const CustomCheckbox = ({value, className, disabled, onChange}:CustomCheckboxProps) => {
	const id = useUniqueId();
	const handleChange = useCallback(() => {
		if(onChange){
			const newValue = value === 'true';
			onChange(newValue);
		}
	}, [value, onChange]);
	
	const Element = {true: MdCheckBox, false: MdCheckBoxOutlineBlank, mixed: MdIndeterminateCheckBox}[value];
	// const Element = MdCheckBox;
	const classes = `CustomCheckbox value-${value} ${(disabled ? 'disabled' : 'enabled')}`;
	
	return (
		<label htmlFor={id} className={classes}>
			<span className="effects a" />
			{Element && <Element className={className} onClick={disabled ? undefined : handleChange} />}
			<input type="checkbox" id={id} aria-checked={value} />
		</label>
	);
}
