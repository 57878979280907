import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {MdFirstPage, MdNavigateBefore, MdNavigateNext} from "react-icons/md";
import {IconButton} from "../../components/IconButton";
import {PaginationInfo, PaginationInfoChangeHandler} from "../../crud/features/PaginationFeature";
import {useRefForSpaceEnter} from '../../form/hooks/useRefForSpaceEnter';
import {DropdownMenu2} from '../../form/inputs/dropdown/DropdownMenu2';
import {DropdownInputOption} from '../../form/types/DropdownTypes';

import "./TablePaginationWithoutTotal.scss";

export type TablePaginationProps = {
	pagination:PaginationInfo
	/** If we know that the current page contains elements, we can pass that information to narrow the boundaries */
	hasCurrentPageElements?:boolean
	onPaginationChange:PaginationInfoChangeHandler
}

export const TablePaginationWithoutTotal = ({
	                                            pagination,
	                                            hasCurrentPageElements,
	                                            onPaginationChange
                                            }:TablePaginationProps) => {
	// console.info('TablePagination render', totalResults, pagination);
	const hasNext = hasCurrentPageElements === undefined ? true : !hasCurrentPageElements;
	const [firstPageEnabled] = useMemo(() => {
		return [
			pagination.pageNo !== 1,
		]
	}, [pagination]);

	const handleFirstPageClick = useCallback(() => {
		onPaginationChange({...pagination, pageNo:1});
	}, [pagination, onPaginationChange]);

	const handlePreviousPageClick = useCallback(() => {
		onPaginationChange({...pagination, pageNo:pagination.pageNo - 1});
	}, [pagination, onPaginationChange]);
	const handleNextPageClick = useCallback(() => {
		onPaginationChange({...pagination, pageNo:pagination.pageNo + 1});
	}, [pagination, onPaginationChange]);

	const handlePageNoChange = useCallback((newPageNo:number) => {
		onPaginationChange({...pagination, pageNo:newPageNo});
	}, [pagination, onPaginationChange]);

	const handlePageSizeChange = useCallback((newPageSize:number) => {
		onPaginationChange({...pagination, pageSize:newPageSize});
	}, [pagination, onPaginationChange]);

	return (
		<div className="TablePaginationWithoutTotal">
			<IconButton icon={MdFirstPage} onClick={handleFirstPageClick} disabled={!firstPageEnabled}
			            tabIndex={firstPageEnabled ? 0 : -1} />

			<IconButton icon={MdNavigateBefore} onClick={handlePreviousPageClick} disabled={!firstPageEnabled}
			            tabIndex={firstPageEnabled ? 0 : -1} />

			<div className="page-info">
				<PageNo pageNo={pagination.pageNo} onPageNoChange={handlePageNoChange} />
				<span className="page-sep"> / </span>
				{hasNext ? (
					<span className="page-max">?</span>
				) : (
					<span className="page-max">{pagination.pageNo}</span>
				)}
			</div>

			<IconButton icon={MdNavigateNext} onClick={handleNextPageClick} disabled={!hasNext}
			            tabIndex={hasNext ? 0 : -1} />

			<PageSizeSelector currentPageSize={pagination.pageSize} onPageSizeChange={handlePageSizeChange} />
		</div>
	);
}

type PageNoProps = {
	pageNo:number
	onPageNoChange:(newPageNo:number) => void
}

const PageNo = ({pageNo, onPageNoChange}:PageNoProps) => {
	const [inputMode, setInputMode] = useState<boolean>(false);
	// console.info(`PageNo(${pageNo}, ${maxPage}), ${inputMode}`);

	const enableInputMode = useCallback(() => setInputMode(true), []);
	const handleChange = useCallback((e:React.ChangeEvent<HTMLInputElement>) => {
		const valueInt = parseInt(e.target.value, 10);
		if (isFinite(valueInt) && valueInt + '' === e.target.value) {
			if (0 < valueInt) {
				onPageNoChange && onPageNoChange(valueInt);
				setInputMode(false);
			} else {
				console.info('Outside of range', e.target.value);
			}
		} else {
			console.info('Not an integer', e.target.value);
		}
		e.target.value = '';
	}, [onPageNoChange]);

	const ref = useRefForSpaceEnter();

	return (
		<div className="PageNo">
			{(inputMode) ? (
				<input autoFocus onBlur={handleChange} placeholder={pageNo + ''} />
			) : (
				<span ref={ref} className="input-inactive focusable" tabIndex={0}
				      onClick={enableInputMode}>{pageNo}</span>
			)}
		</div>
	)
}

type PageSizeSelectorProps = {
	currentPageSize:number
	onPageSizeChange:(newPageSize:number) => void;
}

const PAGE_SIZES = [5, 10, 20, 50, 100];
const PAGE_SIZE_OPTIONS:DropdownInputOption[] = PAGE_SIZES.map(s => ({value:s + '', label:s + ''}));

const PageSizeSelector = ({currentPageSize, onPageSizeChange}:PageSizeSelectorProps) => {
	const handleChange = useCallback((newValue:string) => {
		// if (newValue === 'custom') {
		//TODO	
		// }
		const newPageSize = parseInt(newValue, 10);
		onPageSizeChange && onPageSizeChange(newPageSize);
	}, [onPageSizeChange]);

	const values = useMemo(() => {
		if (PAGE_SIZES.includes(currentPageSize)) {
			return PAGE_SIZE_OPTIONS;
		} else {
			return [{value:currentPageSize + '', label:currentPageSize + ''}].concat(PAGE_SIZE_OPTIONS);
		}
	}, [currentPageSize]);

	const selectedValue = currentPageSize + '';

	//TODO reduce emphasis on the pageSize select, keeping is simple text like the pageNo?
	return (
		<div className="PageSize">
			<span className="item-per-page-label">Elements per page :</span>

			{/*<DropdownInput values={values} onValueSelected={handleChange} value={selectedValue} />*/}
			{/*<TextButton label={selectedValue}*/}
			{/*            onClick={handleButtonClick} type="bordered" afterIcon={MdArrowDropDown} />*/}
			{/*<DropdownMenu open={open} values={values} selectedValue={selectedValue} onValueSelected={handleChange} />*/}
			<DropdownMenu2 values={values} selectedValue={selectedValue} onValueSelected={handleChange} />
		</div>
	);
}
