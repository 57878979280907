/**
 * The ticket was flagged because it contains some keywords that
 * could be the signal of a public disclosure
 *
 * See JiraDisclosureDetectionHelper
 */
export const JIRA_LABEL_JCA_DISCLOSURE_DETECTED = 'jcabot:001';
/**
 * A review from CERT is required
 * mutual exclusion with 003/004, taking over in terms of priority (as it's more "dangerous")
 */
export const JIRA_LABEL_JCA_DISCLOSURE_CERT_ATTENTION_REQUIRED = 'jcabot:002';
/**
 * Someone from CERT verified the ticket and nothing was dangerous there
 * mutual exclusion with 002/004, taking over 004
 *
 * Example: discussion related to credentials masking, that seems to be related to user misunderstanding.
 * Potentially, the discussion could derive to something more "interesting".
 */
export const JIRA_LABEL_JCA_DISCLOSURE_CERT_ACCEPTED = 'jcabot:003';
/**
 * Someone from CERT verified the ticket and determined it's a false positive
 * Meaning it's very unlikely that something interesting will be discussed there.
 *
 * Example: discussion about how to use Anchore scanner detecting vulnerability (as a plugin)
 */
export const JIRA_LABEL_JCA_DISCLOSURE_CERT_FALSE_ALERT = 'jcabot:004';

// from https://github.com/jenkinsci-cert/jenkins/wiki/JIRA-Labels
// and https://github.com/jenkinsci-cert/cert-automation/blob/master/jira-label-utils/relative_popularity.sh
export const JIRA_LABEL_SEC_METADATA_LESS_THAN_ONE = '<1%';
export const JIRA_LABEL_SEC_METADATA_ONE_THREE = '1-3%';
export const JIRA_LABEL_SEC_METADATA_THREE_TEN = '3-10%';
export const JIRA_LABEL_SEC_METADATA_GREATER_THAN_TEN = '10+%';

/**
 * Indicate the relative popularity of the plugin 
 */
export const JIRA_LABEL_SEC_METADATA_LABELS = [
	JIRA_LABEL_SEC_METADATA_LESS_THAN_ONE,
	JIRA_LABEL_SEC_METADATA_ONE_THREE,
	JIRA_LABEL_SEC_METADATA_THREE_TEN,
	JIRA_LABEL_SEC_METADATA_GREATER_THAN_TEN,
];

/**
 * Someone from CERT verified the reproduction steps or the proofs were enough
 */
export const JIRA_LABEL_SEC_CONFIRMED_BY_CERT = 'confirmed-by-cert';
/**
 * Someone from CERT decided it was not needed to reproduce a particular issue. 
 * That allows us to filter out tickets.
 */
export const JIRA_LABEL_SEC_REPRODUCTION_NOT_NEEDED = 'reproduction-not-needed';
/**
 * Someone from CERT was not able to reproduce the vulnerability
 */
export const JIRA_LABEL_SEC_FAILED_TO_REPRODUCE = 'failed-to-reproduce';
/**
 * When we are not sure there is a real thing to exploit there
 */
export const JIRA_LABEL_SEC_UNPROVEN_EXPLOITABILITY = 'unproven-exploitability';

/**
 * The vulnerability is not ready to be assign automatically.
 * It could be due to an embargo or a required investigation
 */
export const JIRA_LABEL_SEC_SKIP_AUTO_ASSIGN = 'skip-auto-assign';
/**
 * The plugin has no longer any maintainers
 */
export const JIRA_LABEL_SEC_UNMAINTAINED_PLUGIN = 'unmaintained-plugin';
/**
 * We do not want to disclose this vulnerability to maintainer by default
 */
export const JIRA_LABEL_SEC_EMBARGO = 'embargo';
/**
 * The vulnerability has related information in advisory-data repository
 */
export const JIRA_LABEL_SEC_HAS_ADVISORY_DATA = 'has-advisory-data';
/**
 * Someone from CERT has reviewed the correction
 */
export const JIRA_LABEL_SEC_REVIEWED_BY_CERT = 'reviewed-by-cert';
/**
 * The ticket requires special treatment
 */
export const JIRA_LABEL_SEC_CERT_ATTENTION = 'cert-attention';
/**
 * The vulnerability has a deadline for disclosure set by the reporter
 */
export const JIRA_LABEL_SEC_DISCLOSURE_DEADLINE = 'disclosure-deadline';
/**
 * When the automation failed due to any condition, adding this label to the ticket automatically
 */
export const JIRA_LABEL_SEC_CERT_AUTOMATION_FAILED = 'cert-automation-failed';

/**
 * When the plugin is installed on Jenkins project instances, e.g. ci.jenkins.io
 */
export const JIRA_LABEL_SEC_JENKINS_INFRA_PLUGIN = 'jenkins-infra-plugin';

/**
 * When the problem was published publicly before it was expected.
 * For example, tickets created on JENKINS, or PRs open in public
 */
export const JIRA_LABEL_SEC_PREMATURELY_DISCLOSED = 'prematurely-disclosed';











