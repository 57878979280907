import {
	RepositoryType_INCREMENTALS,
	RepositoryType_PLUGIN,
	RepositoryType_RELEASES,
	RepositoryType_SPRINT
} from "@commons/constants/ArtifactoryConstants";
import {DateFormats} from "@commons/constants/DateFormats";
import {StringHelper} from "@commons/helpers/StringHelper";
import {ArtifactoryRelease} from "@commons/models/ArtifactoryRelease";
import {WithDbAndId} from "@commons/types/DbType";
import {DateTime} from "luxon";
import * as React from "react";
import {useCallback} from "react";
import {MdCheck, MdClose, MdRefresh} from "react-icons/md";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {IconButton} from "../../../../framework/components/IconButton";
import {MultiSelectGroup} from "../../../../framework/components/MultiSelectGroup";
import {TextButton} from "../../../../framework/components/TextButton";
import {ToggleGroup} from "../../../../framework/components/ToggleGroup";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {
	action_resetFilter,
	CrudPage2,
	CrudPage2Dispatcher,
	CrudPage2State,
	CrudPageLayout,
	CrudPageLayoutBasic
} from "../../../../framework/crud2/CrudPage2";
import {BooleanColumnDef} from "../../../../framework/table/columns/BooleanColumnDef";
import {ClosureColumnDef} from "../../../../framework/table/columns/ClosureColumnDef";
import {DateColumnDef} from "../../../../framework/table/columns/DateColumnDef";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {ExternalLinkColumnDef} from "../../../../framework/table/columns/ExternalLinkColumnDef";
import {IntegerColumnDef} from "../../../../framework/table/columns/IntegerColumnDef";
import {MetaInfoColumnDef} from "../../../../framework/table/columns/MetaInfoColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {Pagination2} from "../../../../framework/table/Pagination2";
import {Table2} from "../../../../framework/table/Table2";
import {JiraUrlHelper} from "@commons/helpers/JiraUrlHelper";

type Model = WithDbAndId<ArtifactoryRelease>;

const crudService = new class ArtifactoryReleaseService extends AbstractApiService<Model> {
	constructor() {
		super('api/user/artifactory-release');
	}
}();

function ticketsToReactNode(row:Model):React.ReactNode {
	const ticketRefs = Object.values(row.relatedTickets).map(ticket => ticket.ticket);
	ticketRefs.sort((a, b) => {
		return parseInt(a.split('-')[1], 10) - parseInt(b.split('-')[1], 10)
	});

	const list = ticketRefs.map<JSX.Element | string>(ref => {
		return (
			<a href={JiraUrlHelper.linkToTicket(ref)} target="_blank" rel="noopener noreferrer"
			   key={ref}>{ref}</a>
		)
		// }).reduce((previousValue, currentValue) => [...previousValue, ', ', currentValue])
	}).flatMap((value, index) => {
		if (index > 0) {
			return [', ', value];
		}
		return value;
	})

	return list;
}

export const ArtifactoryReleaseListPage = () => {
	const {columnDefs, initialState} = useColumnDefs<Model>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'ID', row => row.id + '', {sortable:false, defaultVisibility:false}).nowrap(),
		new IntegerColumnDef('version', 'Version', row => row.version, {
			sortable:false,
			defaultVisibility:false
		}).nowrap(),

		new StringColumnDef('groupId', 'Group ID', row => row.groupId, {sortable:false, size:300}),
		new StringColumnDef('artifactId', 'Artifact ID', row => row.artifactId, {sortable:false, size:300}),
		new StringColumnDef('releaseVersion', 'Release Version', row => row.releaseVersion, {
			sortable:false,
			size:240,
			inlineCode:true,
		}),
		new StringColumnDef('repoType', 'Repo type', row => row.repoType, {
			sortable:false,
			size:120,
			inlineCode:true,
		}),
		new BooleanColumnDef('interesting', 'Interest', row => row.interesting, {
			sortable:false,
			size:80,
			title:'A release is interesting for the security team when at that time there are some related SECURITY tickets.'
		}),
		new ExternalLinkColumnDef('openRepo', row => {
			return `https://repo.jenkins-ci.org/ui/repos/tree/General/${row.repoName}/${row.groupId.split('.').join('/')}/${row.artifactId}/${row.releaseVersion}/`
		}),
		new ClosureColumnDef('relatedTickets', 'Tickets', ticketsToReactNode, {sortable:false, size:'expand'}),
		// new StringColumnDef('relatedTickets', 'Tickets', row => Object.values(row.relatedTickets).map(ticket => ticket.ticket).join(', '), {
		// 	sortable:false,
		// 	size:'expand'
		// }),
		new MetaInfoColumnDef(),
		new DateColumnDef('createdDate', 'Created', DateFormats.FULL, row => row.updatedDate, {
			sortable:false,
			defaultVisibility:false,
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			size:175
		}),
		new DateColumnDef('updatedDate', 'Updated', DateFormats.FULL, row => row.updatedDate, {
			sortable:'fixed',
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			size:175
		}),
	], {
		initialPageSize:20, initialOrder:'-updatedDate', 
		initialFilters:[
			'interesting:b:eq:true', 
			`repoType:s:in:${StringHelper.encodeAsArray([RepositoryType_SPRINT, RepositoryType_PLUGIN], '|')}`
		]
	});

	return (
		<CrudPage2 pageId="artifactory-release" pageClass="ArtifactoryReleaseListPage" crudService={crudService}
		           initialState={initialState}
		           layout={useCallback((state, itemsData, dispatch) => (
			           <CrudPageLayoutBasic
				           pageName="Artifactory release"
				           topLeftActions={<>
					           <IconButton icon={MdRefresh} title="Refresh data"
					                       onClick={() => dispatch({type:'reloadContent'})} />
				           </>}
				           topRightActions={<>
					           {/*<TextButton label="Reset filter" onClick={() => resetFilter(dispatch)} />*/}
					           <TextButton label="Reset filter" onClick={action_resetFilter} onClickArgs={dispatch} />

					           <MultiSelectGroup label="Repo types:" allValues={filterRepoType(state)}
					                             onChange={(newValue) => filterRepoTypeChange(newValue, state, dispatch)}
					                             items={[
						                             {value:RepositoryType_RELEASES, label:'Releases'},
						                             {value:RepositoryType_INCREMENTALS, label:'Incrementals'},
						                             {
							                             value:RepositoryType_SPRINT,
							                             label:'Sprint',
							                             title:'Staging repositories for all fixes for a single sprint'
						                             },
						                             {
							                             value:RepositoryType_PLUGIN,
							                             label:'Plugin',
							                             title:'Staging repositories for plugin during security release coordination'
						                             },
					                             ]} />

					           <ToggleGroup label="Interesting" value={filterInteresting(state)}
					                        onChange={(newValue) => filterInterestingChange(newValue, state, dispatch)}
					                        items={[
						                        {value:true, icon:MdCheck, activeClassName:'icon-within-green'},
						                        {value:false, icon:MdClose, activeClassName:'icon-within-red'},
					                        ]} />
				           </>}
				           table={<>
					           <Table2 columnDefs={columnDefs} className="fluid"
					                   dataState={itemsData} state={state} dispatch={dispatch} />
				           </>}
				           bottomLeftAction={<>
					           <div>Selection: {state.selection.length} (TODO)</div>
				           </>}
				           bottomRightAction={<>
					           <Pagination2 pagination={state.pagination} dispatch={dispatch}
					                        filteredTotalResults={itemsData.data?.meta?.filteredTotal}
					                        totalResults={itemsData.data?.meta?.total}
					           />
				           </>} />
		           ), [columnDefs]) as CrudPageLayout<Model>}
		/>
	)
}

const filterInteresting = (state:CrudPage2State):boolean | null => {
	const currFilterActive = state.filters.find((f:string) => f.startsWith('interesting:'));
	if (currFilterActive === 'interesting:b:eq:true') {
		return true;
	} else if (currFilterActive === 'interesting:b:eq:false') {
		return false;
	} else {
		return null;
	}
}
const filterInterestingChange = (newValue:boolean | null, state:CrudPage2State, dispatch:CrudPage2Dispatcher) => {
	const currValue = filterInteresting(state);
	if (currValue === newValue) {
		dispatch({type:'changeFilters', removeFields:['interesting']})
		return;
	}
	if (newValue === true) {
		dispatch({type:'changeFilters', addFilters:['interesting:b:eq:true']})
	} else {
		dispatch({type:'changeFilters', addFilters:['interesting:b:eq:false']})
	}
}
const filterRepoType = (state:CrudPage2State):string[] => {
	const currFilter = state.filters.find((f:string) => f.startsWith('repoType:s:in:'));
	if (!currFilter) {
		return [];
	}
	const rawValuesString = currFilter.substring('repoType:s:in:'.length);
	const values = StringHelper.decodeAsArray(rawValuesString, '|');
	return values;
}
const filterRepoTypeChange = (newValue:string, state:CrudPage2State, dispatch:CrudPage2Dispatcher) => {
	let currValues = filterRepoType(state);
	if (currValues.includes(newValue)) {
		currValues.splice(currValues.indexOf(newValue), 1);
		if (currValues.length === 0) {
			dispatch({
				type:'changeFilters',
				removeFields:['repoType']
			})
		} else {
			dispatch({
				type:'changeFilters',
				addFilters:[`repoType:s:in:${StringHelper.encodeAsArray(currValues, '|')}`]
			})
		}
	} else {
		const valuesToAdd = currValues.concat(newValue);
		dispatch({
			type:'changeFilters',
			addFilters:[`repoType:s:in:${StringHelper.encodeAsArray(valuesToAdd, '|')}`]
		})
	}
}
