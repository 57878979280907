import * as React from "react";
import {useContext} from "react";
import {MdBrightness4, MdBrightness7} from "react-icons/md";
import {ThemeContext} from "../../../../commons/providers/ThemeProvider";

import {TopNavIcon} from './TopNavIcon';

export const ThemeSwitchIcon = () => {
	const {isLightTheme, changeTheme} = useContext(ThemeContext);

	//TODO add keypress space/enter to activate (accessibility)
	const handlesDarkThemeClick = () => {
		changeTheme(false);
	};

	const handlesLightThemeClick = () => {
		changeTheme(true);
	};

	if (isLightTheme) {
		return (
			<TopNavIcon onClick={handlesDarkThemeClick} icon={MdBrightness4} title="Change to dark theme" />
		);
	} else {
		return (
			<TopNavIcon onClick={handlesLightThemeClick} icon={MdBrightness7} title="Change to light theme" />
		);
	}

}
