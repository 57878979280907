import * as React from "react";
import {useEffect, useRef} from "react";

export const useIsMounted = ():React.MutableRefObject<boolean> => {
	const isMounted = useRef(true);
	// console.info('[useIsMounted] call', isMounted.current);
	useEffect(() => {
		// especially useful when developping a page, it's re-mounted
		// console.info('[useIsMounted] in useEffect', isMounted.current);
		isMounted.current = true;
		return function cleanup() {
			// console.info('[useIsMounted] in useEffect:cleanup', isMounted.current);
			isMounted.current = false;
		}
	}, []);
	return isMounted;
}
