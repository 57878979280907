import {ReactNode, useCallback} from "react";
import {MdDelete, MdEdit, MdPageview} from "react-icons/md";
import {CrudModel} from '../../../commons/types/CrudModel';
import {IconButton} from "../../components/IconButton";
import {ColDefType} from '../ColDefType';
import {CrudTableColumnDefinition} from "../CrudTableColumnDefinition";

export type DynamicColumnRequirement = string;

export class DefaultColumnFactory {
	static SELECT_REQUIREMENT:DynamicColumnRequirement = 'select_req';
	static createSelect(defaultVisibility:boolean = true,
	                    fixed:boolean = true) {
		return new SelectColumnDef('default-select', 'Select', 
			'Allow to select rows for batch operations', defaultVisibility, fixed);
	}

	//TODO Transform into IconColumn with action provided by the page directly
	static BROWSE_INTO_REQUIREMENT:DynamicColumnRequirement = 'browseInto_req';
	static createBrowseInto<T extends CrudModel>(handleClick:(t:T) => void,
	                                             defaultVisibility:boolean = true,
	                                             fixed:boolean = true) {
		return new ActionBrowseIntoColumnDef(handleClick, 'Action: Browse', 
			'Allow to browse into item details', defaultVisibility, fixed);
	}

	static EDIT_REQUIREMENT:DynamicColumnRequirement = 'edit_req';
	static createEdit<T extends CrudModel>(handleClick:(t:T) => void,
	                                       defaultVisibility:boolean = true,
	                                       fixed:boolean = true) {
		return new ActionEditColumnDef(handleClick, 'Action: Edit', 
			'Allow to edit the related item', defaultVisibility, fixed);
	}

	static DELETE_REQUIREMENT:DynamicColumnRequirement = 'delete_req';
	static createDelete<T extends CrudModel>(handleClick:(t:T) => void,
	                                         defaultVisibility:boolean = true,
	                                         fixed:boolean = true) {
		return new ActionDeleteColumnDef(handleClick, 'Action: Delete', 
			'Allow to delete the related item', defaultVisibility, fixed);
	}
}

class SelectColumnDef<T extends CrudModel> implements CrudTableColumnDefinition<T> {
	type:ColDefType = 'select';
	sortable:boolean = false;
	headerClassName = 'fix-header-select fix-column-select';
	cellClassName = 'fix-cell-select fix-column-select';

	constructor(public name:string,
	            public header:string, public headerTitle:string,
	            public defaultVisibility:boolean = true,
	            public fixed:boolean = false,
	) {
	}

	computeCell(row:T):ReactNode {
		// managed by the Table component, calling SelectTableCell instead
		throw new Error('Should not be called');
	}
}

export abstract class AbstractActionColumnDef<T extends CrudModel> implements CrudTableColumnDefinition<T> {
	type:ColDefType;
	sortable:boolean = false;
	headerClassName = 'fix-column-action';

	protected constructor(public name:'action-edit' | 'action-delete' | 'action-browse-into',
	            public header:string, public headerTitle:string,
	            public defaultVisibility:boolean = true,
	            public fixed:boolean = false,
	            public handleClick:(t:T) => void
	) {
		this.type = name;
	}

	computeCell(row:T):ReactNode {
		return (
			<ActionEditCell onClick={this.handleClick} row={row} />
		);
	}
}

class ActionEditColumnDef<T extends CrudModel> extends AbstractActionColumnDef<T> {
	constructor(handleClick:(t:T) => void, header:string = '', headerTitle:string = '', defaultVisibility:boolean = true, fixed:boolean = false) {
		super('action-edit', header, headerTitle, defaultVisibility, fixed, handleClick);
	}

	computeCell(row:T):ReactNode {
		return (
			<ActionEditCell onClick={this.handleClick} row={row} />
		);
	}
}

class ActionDeleteColumnDef<T extends CrudModel> extends AbstractActionColumnDef<T> {
	constructor(handleClick:(t:T) => void, header:string = '', headerTitle:string = '', defaultVisibility:boolean = true, fixed:boolean = false) {
		super('action-delete', header, headerTitle, defaultVisibility, fixed, handleClick);
	}

	computeCell(row:T):ReactNode {
		return (
			<ActionDeleteCell onClick={this.handleClick} row={row} />
		);
	}
}

class ActionBrowseIntoColumnDef<T extends CrudModel> extends AbstractActionColumnDef<T> {
	constructor(handleClick:(t:T) => void, header:string = '', headerTitle:string = '', defaultVisibility:boolean = true, fixed:boolean = false) {
		super('action-browse-into', header, headerTitle, defaultVisibility, fixed, handleClick);
	}

	computeCell(row:T):ReactNode {
		return (
			<ActionBrowseIntoCell onClick={this.handleClick} row={row} />
		);
	}
}

type ActionProps<T> = {
	onClick:(t:T) => void
	row:T
}

const ActionEditCell = <T extends CrudModel>({onClick, row}:ActionProps<T>) => {
	const handleClick = useCallback(() => {
		onClick(row);
	}, [row, onClick]);

	return (
		<IconButton icon={MdEdit} onClick={handleClick} />
	);
}

const ActionDeleteCell = <T extends CrudModel>({onClick, row}:ActionProps<T>) => {
	const handleClick = useCallback(() => {
		onClick(row);
	}, [row, onClick]);

	return (
		<IconButton icon={MdDelete} onClick={handleClick} />
	);
}

const ActionBrowseIntoCell = <T extends CrudModel>({onClick, row}:ActionProps<T>) => {
	const handleClick = useCallback(() => {
		onClick(row);
	}, [row, onClick]);

	return (
		<IconButton icon={MdPageview} onClick={handleClick} />
	);
}
