export class TemplateHelper {
	static injectVariables(text:string, variables:{ [key:string]:string }):string {
		let curr = text;
		Object.entries(variables!).forEach(([key, value]) => {
			curr = curr.replace(new RegExp(`%IF\\(${this.escapeRegEx(key)}\\)%(.*)%ENDIF%`, 'gs'), (fullMatch, innerGroup) => {
				if (value) {
					return innerGroup;
				} else {
					return '';
				}
			});
			curr = curr.replace(new RegExp(`%${this.escapeRegEx(key)}%`, 'g'), value);
		})
		return curr;
	}

	/**
	 * Sanitize user input to be used within RegEx
	 */
	private static escapeRegEx(value:string):string{
		// $& means the whole matched string
		return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	}
}
