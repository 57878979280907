export class StringArrayHelper {
	static oneLevelEqual(arrayA:string[], arrayB:string[]):boolean {
		if (arrayA.length !== arrayB.length) {
			return false;
		}
		for (let i = 0; i < arrayA.length; i++) {
			if (arrayA[i] !== arrayB[i]) {
				return false;
			}
		}
		return true;
	}

	static toStringArray(value:string | string[]):string[] {
		if (!value) {
			return [];
		} else if (typeof value === 'string') {
			return [value];
		} else {
			return value;
		}
	}

	static joinToString(value:string | string[], joiner:string = ','):string {
		if (!value) {
			return '';
		} else if (typeof value === 'string') {
			return value;
		} else {
			return value.join(joiner);
		}
	}
}
