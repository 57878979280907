import {WithDbAndId} from "@commons/types/DbType";
import * as React from "react";
import {CrudModel} from '../../../commons/types/CrudModel';
import {MetaInfoIcon} from "../../components/MetaInfoIcon";
import {ClosureColumnDef} from "./ClosureColumnDef";
import {StringColumnDefOps} from "./StringColumnDef";

const wrapper = (row:WithDbAndId<CrudModel>) => {
	return <MetaInfoIcon item={row} />;
}

export class MetaInfoColumnDef<T extends WithDbAndId<CrudModel>> extends ClosureColumnDef<T> {
	constructor(opts:StringColumnDefOps = {size:75, sortable:false}) {
		super('metaInfo', 'Meta', wrapper, opts);
	}
}
