import {ReactNode} from "react";
import {useLocation} from "react-router";
import {Navigate} from 'react-router-dom';
import {useAuth} from '../../commons/hooks/useAuth';

type Props = {
	children:ReactNode
	requiresUser?:boolean
	requiresUserOrAiAccess?:boolean
	requiresAiAccess?:boolean
	requiresOnlyAiAccess?:boolean
	requiresAdmin?:boolean
};

export const ProtectedRoute = ({children, requiresUser, requiresUserOrAiAccess, requiresAiAccess, requiresOnlyAiAccess, requiresAdmin}:Props) => {
	const {authInfo} = useAuth();
	const location = useLocation();

	if (authInfo) {
		console.info('[ProtectedRoute] authInfo', authInfo);
		if (requiresAdmin && !authInfo.isAdmin) {
			return <Navigate to='/403' />
		}
		if (requiresAiAccess && !authInfo.hasAiAccess) {
			return <Navigate to='/403' />
		}
		if (requiresUserOrAiAccess && !(authInfo.hasAiAccess || authInfo.isUser)) {
			return <Navigate to='/403' />
		}
		if (requiresUser && !authInfo.isUser) {
			return <Navigate to='/403' />
		}
		if (requiresOnlyAiAccess && !authInfo.hasAiAccess && (authInfo.isUser || authInfo.isAdmin)) {
			return <Navigate to='/403' />
		}

		return (
			<>{children}</>
		)
	} else {
		const pathname = location?.pathname;
		const search = location?.search;
		const hash = location?.hash;
		const from = pathname + search + hash;

		const searchParams = new URLSearchParams(location.search);
		const searchParamsObj = Object.fromEntries(searchParams.entries());

		const state = {
			from:from,
			originalQuery:searchParamsObj
		}

		console.info('[ProtectedRoute] state', state);
		return (
			<Navigate to="/login" state={state} />
		)
	}
}
