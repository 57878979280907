import * as React from "react";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";

import "./NoData.scss";

type NoDataProps = {
	className?:string
	label:string
}

export const NoData = ({label, className}:NoDataProps) => {
	return (
		<div className={ClassHelper.combine('NoData', className)}>
			{label}
		</div>
	);
}
