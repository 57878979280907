import {CrudModelId} from "@commons/types/WithId";
import {QueryResponse} from '../../commons/hooks/useGetQuery';
import {ApiBody} from "../../commons/types/ApiBody";
import {CrudModel} from '../../commons/types/CrudModel';
import {ApiFetchResponse} from "../WrappedJsonFetch";

export type CrudError = { status:'error', error:string | Error, errorId?:string };
export type CrudSuccess<T> = { status:'loaded', payload:T };
export type CrudContent<T> = CrudSuccess<T> | CrudError;

// export type QueryListResponse<T extends CrudModel> = Promise<ProviderResponse<ApiBody<T[]>>>;
export type QueryListResponse<T extends CrudModel> = Promise<CrudContent<ApiBody<T[]>>>;
export type CreateItemResponse<T extends CrudModel> = Promise<CrudContent<T>>;
export type UpdateItemResponse<T extends CrudModel> = Promise<CrudContent<T>>;
export type DeleteItemResponse<T extends CrudModel> = Promise<CrudContent<T>>;

export type QueryGetBody<T> = {
	data:T
};
export type QueryListBody<T> = {
	meta?:{
		filteredTotal?:number
		total?:number
	},
	data:T[]
};

export type CrudApiService2<T extends CrudModel> =
	CrudApiService2_QueryList<T> &
	CrudApiService2_GetOne<T> &
	CrudApiService2_Create<T> &
	CrudApiService2_Update<T> &
	CrudApiService2_Delete<T>
	;

export interface CrudApiService2_QueryList<T extends CrudModel> {
	queryList(paramPart:string):Promise<ApiFetchResponse<QueryListBody<T>>>
}
export interface CrudApiService2_GetOne<T extends CrudModel> {
	getOne(id:string):Promise<ApiFetchResponse<QueryGetBody<T>>>
}

export interface CrudApiService2_Create<T extends CrudModel> {
	createOne(data:Omit<T, 'id'>):Promise<ApiFetchResponse<T>>
}

export interface CrudApiService2_Update<T extends CrudModel> {
	updateOne(data:T):Promise<ApiFetchResponse<T>>
}

export interface CrudApiService2_Delete<T extends CrudModel> {
	deleteOne(id:CrudModelId, version:number):Promise<ApiFetchResponse<{done:boolean}>>
}

export const extractTotals = (itemsData:QueryResponse<QueryListBody<any>>) => {
	if (!itemsData.loading && itemsData.error === null) {
		const filteredTotalResults = (itemsData.data.meta?.filteredTotal ?? itemsData.data.meta?.total ?? -1);
		const totalResults = itemsData.data.meta?.total ?? -1;
		return {filteredTotalResults, totalResults};
	} else {
		return {filteredTotalResults:-1, totalResults:-1};
	}
}
