import * as React from 'react';
import {IconType} from 'react-icons';
import {MdCheckCircle, MdCircle, MdDangerous, MdHelp, MdInfo, MdWarning} from 'react-icons/md';
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {withFixedArguments} from '../../../commons/hoc/withFixedArguments';
import {AvailableColor} from './AvailableColor';

import './Panel.scss';

type PanelProps = {
	children:React.ReactNode
	color:AvailableColor
	icon?:IconType
	className?:string
	size?:'medium' | 'small'
}

const Panel = ({children, color, icon, className, size = 'medium'}:PanelProps) => {
	const Icon = icon;
	return (
		<div className={ClassHelper.combine('Panel', `color-${color}`, `size-${size}`, className)}>
			{Icon && (
				<span className="icon"><Icon /></span>
			)}
			<span className="message">
				{children}
			</span>
		</div>
	);
}

export const ErrorPanel = withFixedArguments(Panel, {color:'red', icon:MdDangerous});
export const WarningPanel = withFixedArguments(Panel, {color:'orange', icon:MdWarning});
export const InfoPanel = withFixedArguments(Panel, {color:'blue', icon:MdInfo});
export const SuccessPanel = withFixedArguments(Panel, {color:'green', icon:MdCheckCircle});
export const NeutralPanel = withFixedArguments(Panel, {color:'grey', icon:MdCircle});
export const UnknownPanel = withFixedArguments(Panel, {color:'purple', icon:MdHelp});
