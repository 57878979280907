import {
	ArtifactoryToolingResponse_Advisories,
	ArtifactoryToolingResponse_Advisories_Item
} from "@commons/protocol/ArtifactoryToolingResponse";
import * as React from "react";
import {useCallback, useState} from "react";
import {MdDelete, MdDownload, MdOpenInNew, MdOutlineInbox, MdOutlineInventory2} from "react-icons/md";
import {LogIfMountedType, useLogIfStillMounted} from "../../../../commons/hooks/useLogIfStillMounted";
import {apiFetch} from "../../../../framework/apiFetch";
import {Card} from "../../../../framework/components/Card";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {LinkButton} from "../../../../framework/components/LinkButton";
import {LogPanel, useLogPanel} from "../../../../framework/components/LogPanel";
import {TextButton} from "../../../../framework/components/TextButton";
import "./ArtifactoryToolingPage.scss";

export const ArtifactoryToolingPage = () => {
	const {logRows, appendLog, clearLog} = useLogPanel();
	const {logIfMounted} = useLogIfStillMounted(appendLog);

	const [lastRepo, setLastRepo] = useState<string>();
	const [listing, setListing] = useState<ArtifactoryToolingResponse_Advisories_Item[] | null>(null);

	/*
	 *  Emails:
	 *      - get total number of emails in the account
	 *      - get current number of emails tracked
	 *      - force re-processing of emails (re-sync)
	 *  JiraJenkins:
	 *      - run search to find number of unlabelled tickets
	 *      - run search to find number of labelled tickets
	 *      - label + attach watcher on tickets (ease task of recurrent task)
	 *      - reprocess labelled tickets (re-sync)
	 */
	const searchListingPerRepo = useCallback(() => {
		const repo = document.querySelector<HTMLInputElement>('#listing_repo')!.value.trim();
		if (!repo) {
			appendLog(`The repo is mandatory`);
			return;
		}

		appendLog(`Listing for repo=${repo}`);

		const url = `api/user/artifactory-tooling/artifacts?repo=${encodeURIComponent(repo)}`;
		apiFetch.get<ArtifactoryToolingResponse_Advisories>(url).then(response => {
			logIfMounted(response)
			if (response.ok) {
				setLastRepo(repo);
				setListing(response.payload.advisories);
			} else {
				setLastRepo(repo);
				setListing(null);
			}
		});
	}, [appendLog, logIfMounted]);

	return (
		<PageContent className="ArtifactoryToolingPage">
			<div>
				List artifacts per repository <input id="listing_repo"
				                                     placeholder="security1234-staging" />
				<TextButton label="Search" onClick={searchListingPerRepo} />

				{listing && (
					<div className="dynamic-response-panel">
						<div className="response-title">Response for {lastRepo}</div>
						{listing.length > 0 ? listing.map(artifactEntry => (
							<ListingArtifactEntry
								key={artifactEntry.groupId + ':' + artifactEntry.artifactId}
								repositoryName={lastRepo!}
								artifactEntry={artifactEntry}
								logIfMounted={logIfMounted}
							/>
						)) : (
							<span className="no-entries">No entries found</span>
						)}
					</div>
				)}
			</div>
			<hr />
			<Card>
				<LogPanel rows={logRows} numRowsToDisplay={30} onClearLog={clearLog} />
			</Card>
		</PageContent>
	);
}

type ListingArtifactEntryParams = {
	repositoryName:string
	artifactEntry:ArtifactoryToolingResponse_Advisories_Item
	logIfMounted:LogIfMountedType
}

// security2606-staging
const ListingArtifactEntry = ({repositoryName, artifactEntry, logIfMounted}:ListingArtifactEntryParams) => {
	const {groupId, artifactId, versions} = artifactEntry;

	const groupPartEncoded = groupId.split('.').map(g => encodeURIComponent(g)).join('/');
	const viewUrl = `https://repo.jenkins-ci.org/ui/repos/tree/General/${encodeURIComponent(repositoryName)}/${groupPartEncoded}/${encodeURIComponent(artifactId)}`;

	const versionElements = versions.map(v =>
		<ListingArtifactVersion repositoryName={repositoryName}
		                        groupId={groupId} artifactId={artifactId} version={v}
		                        key={v} logIfMounted={logIfMounted} />
	);

	return (
		<div className="ListingArtifactEntry">
			<span className="ListingArtifactName">
				<MdOutlineInbox />
				<code>{groupId}:{artifactId}</code>
				<LinkButton href={viewUrl} label={"View"} icon={MdOpenInNew} size="s" />
			</span>
			<ol>{versionElements}</ol>
		</div>
	)
}

type ListingArtifactVersionParams = {
	repositoryName:string
	groupId:string
	artifactId:string
	version:string
	logIfMounted:LogIfMountedType
}

// security2606-staging
// view: https://repo.jenkins-ci.org/ui/repos/tree/General/security2141-staging/io/jenkins/configuration-as-code
// direct download: https://repo.jenkins-ci.org/artifactory/security2141-staging/io/jenkins/configuration-as-code/1.55.1/configuration-as-code-1.55.1.hpi
const ListingArtifactVersion = ({
	                                repositoryName,
	                                groupId,
	                                artifactId,
	                                version,
	                                logIfMounted
                                }:ListingArtifactVersionParams) => {
	const [wasDeleted, setWasDeleted] = useState<boolean>(false);

	const repoNameEncoded = encodeURIComponent(repositoryName);
	const artifactIdEncoded = encodeURIComponent(artifactId);
	const versionEncoded = encodeURIComponent(version);
	const groupPartEncoded = groupId.split('.').map(g => encodeURIComponent(g)).join('/');
	const viewUrl = `https://repo.jenkins-ci.org/ui/repos/tree/General/${repoNameEncoded}/${groupPartEncoded}/${artifactIdEncoded}/${versionEncoded}`;

	const downloadUrl = `https://repo.jenkins-ci.org/artifactory/${repoNameEncoded}/${groupPartEncoded}/${artifactIdEncoded}/${versionEncoded}/${artifactIdEncoded}-${versionEncoded}.hpi`;
	const deleteArtifactVersion = useCallback(() => {
		const confirmed = window.confirm(`Are you sure to delete the version: ${version}`);
		if (!confirmed) {
			return;
		}

		logIfMounted(`Deleting groupId=${groupId}, artifactId=${artifactId}, version=${version}`);

		const url = `api/user/artifactory-tooling/artifacts?repo=${repoNameEncoded}&groupId=${encodeURIComponent(groupId)}&artifactId=${artifactIdEncoded}&version=${versionEncoded}`;
		apiFetch.delete<void>(url).then(response => {
			if (response.ok) {
				logIfMounted(`Deletion done for version=${version}`);
				setWasDeleted(true);
			} else {
				logIfMounted(`Deletion error for version=${version}`);
			}
			logIfMounted(response)
		});
	}, [version, logIfMounted, groupId, artifactId, repoNameEncoded, artifactIdEncoded, versionEncoded]);

	return (
		<li className={wasDeleted ? 'was-deleted' : ''}>
			<MdOutlineInventory2 />
			<code className="ListingArtifactVersion">{version}</code>
			<LinkButton href={viewUrl} label={"View"} icon={MdOpenInNew} size="s" />
			<LinkButton href={downloadUrl} label={"Download"} icon={MdDownload} size="s" />
			<TextButton label={"Delete"} icon={MdDelete} size="s" className="color-red"
			            onClick={deleteArtifactVersion} />
		</li>
	)
}
