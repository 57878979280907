export const COMMAND_AUDIT_OK = '/audit-ok';
export const COMMAND_AUDIT_SKIP = '/audit-skip';
export const COMMAND_AUDIT_REQUIRED = '/audit-required';
export const COMMAND_AUDIT_FINDINGS = '/audit-findings';

/**
 * Can be requested by anyone (except the bot)
 * And only, when the issue was marked as "security-audit-needs-correction"
 * to prevent rando to change label at undesired moments (like when closed or still pending audit)
 */
export const COMMAND_AUDIT_REVIEW = '/audit-review';

/**
 * Can be requested by anyone (except the bot)
 * Trigger a security scan and does not change the label
 */
export const COMMAND_REQUEST_SECURITY_SCAN = '/request-security-scan';
