import {Section} from '../../../framework/components/layout/Section';
import "./Error403Page.scss"

export const Error403Page = () => {
	return (
		<Section noTopNav={true} className="Error403Page">
			Error 403
		</Section>
	)
}
