import {FunctionNM} from "@commons/types/FunctionNM";
import {ReactElement, ReactNode, useCallback, useState} from "react";
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';

import "./Tabs.scss";
import {ArrayHelper} from "@commons/helpers/ArrayHelper";

export type TabsProps = {
	className?:string
	/**
	 * For storage in the url
	 * Default: "tab"
	 */
	hashId?:string
	children:(ReactElement<TabProps>|false)[]
} & OtherHtmlAttributes

//TODO add # for the selected tab using Tabs name
export const Tabs = ({className, children, hashId, ...rest}:TabsProps) => {
	const filteredChildren:ReactElement<TabProps>[] = ArrayHelper.filterNonFalse(children);

	const [selectedTab, setSelectedTab] = useState<ReactElement<TabProps>>(() => {
		const rawHash = window.location.hash.substring(1);

		if (rawHash) {
			const hashJson = JSON.parse(decodeURIComponent(rawHash));

			const tabsHashId = hashId ?? 'tab';
			const selectedTabId = hashJson[tabsHashId];
			if (selectedTabId) {
				const selectedChild = filteredChildren.find(tab => {
					const tabHashId = tabPropsToHashId(tab.props);
					return tabHashId === selectedTabId;
				});
				if (!!selectedChild) {
					return selectedChild;
				}
			}
		}

		return filteredChildren[0];
	});
	console.info('Tabs', selectedTab.props.name);

	const tabSelectHandler = useCallback((tab:ReactElement<TabProps>) => {
		setSelectedTab(tab);

		const rawHash = window.location.hash.substring(1);
		const hashJson = !!rawHash ? JSON.parse(decodeURIComponent(rawHash)) : {};

		const tabsHashId = hashId ?? 'tab';
		hashJson[tabsHashId] = tabPropsToHashId(tab.props);

		const newHash = encodeURIComponent(JSON.stringify(hashJson));
		window.location.hash = '#' + newHash;
	}, [hashId]);

	return (
		<div className={ClassHelper.combine('Tabs', className)} {...rest}>
			<div className="tab-header-list">
				{filteredChildren.map(tab => (
					// Comparing directly selectedTab and tab does not work
					<TabHeader tab={tab} key={tab.props.name} selected={selectedTab.props.name === tab.props.name} disabled={tab.props.disabled} onTabSelect={tabSelectHandler} />
				))}
			</div>
			<div className="tab-content-list">
				{filteredChildren.map(tab => (
					<TabContent tab={tab} key={tab.props.name} selected={selectedTab.props.name === tab.props.name} />
				))}
			</div>
		</div>
	);
}

export type TabProps = {
	/**
	 * Label of the tab
	 */
	name:string
	/**
	 * For storage in the url
	 * Default: encoding of the name
	 */
	hashId?:string
	title?:string
	disabled?:boolean
	headerClassName?:string
	className?:string
	children:ReactNode
} & OtherHtmlAttributes

export const Tab = ({name, title, className, children, ...rest}:TabProps) => {
	return (
		<div className={ClassHelper.combine('Tab', className)} {...rest}>
			{children}
		</div>
	);
}

const tabPropsToHashId = (tabProps:TabProps) => {
	if (tabProps.hashId) {
		return tabProps.hashId;
	}
	return tabProps.name;
}

type TabHeaderProps = {
	tab:ReactElement<TabProps>
	selected?:boolean
	disabled?:boolean

	/**
	 * Called when a tab is clicked
	 */
	onTabSelect:FunctionNM<[ReactElement<TabProps>], void>
}

const TabHeader = ({tab, selected, disabled, onTabSelect}:TabHeaderProps) => {
	const clickHandler = useCallback(() => {
		onTabSelect(tab);
	}, [tab, onTabSelect]);

	return (
		<div className={ClassHelper.combine('TabHeader', tab.props.headerClassName, selected && 'selected', disabled ? 'disabled' : 'enabled')}
		     title={tab.props.title} key={tab.props.name}
		     onClick={clickHandler} >{tab.props.name}</div>
	)
}

type TabContentProps = {
	tab:ReactElement<TabProps>
	selected?:boolean
}

const TabContent = ({tab, selected}:TabContentProps) => {
	return (
		<div className={ClassHelper.combine('TabContent', tab.props.className, selected && 'selected')}
		     key={tab.props.name}>{tab}</div>
	)
}
