import {DateTime, DateTimeFormatOptions} from "luxon";
import {ReactNode} from "react";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {CrudModel} from '../../../commons/types/CrudModel';
import {ColDefType} from "../ColDefType";
import {ColSizeType} from "../ColSizeType";
import {CrudTableColumnDefinition} from "../CrudTableColumnDefinition";
import {NullCellValue} from "../NullCellValue";
import {Date} from "../../components/Date";

//TODO create variant for safe HTML
export class DateColumnDef<T extends CrudModel> implements CrudTableColumnDefinition<T> {
	type:ColDefType = 'date';

	private titleFormat:DateTimeFormatOptions | string | null;

	defaultVisibility:boolean;
	sortable:boolean | 'fixed';
	fixed:boolean;
	headerClassName:string;
	cellClassName:string;
	headerTitle:string | undefined

	constructor(public name:string,
	            public header:string,
	            private cellFormat:DateTimeFormatOptions | string,
	            public rowToCell:((row:T) => string | null),
	            opts:{ defaultVisibility?:boolean, sortable?:boolean | 'fixed', fixed?:boolean, titleFormat?:DateTimeFormatOptions | string, headerTitle?:string, size?:ColSizeType } = {}
	) {
		this.defaultVisibility = opts.defaultVisibility ?? true;
		this.sortable = opts.sortable ?? true;
		this.fixed = opts.fixed ?? false;
		this.titleFormat = opts.titleFormat ?? null;
		this.headerTitle = opts.headerTitle ?? undefined;

		const size = opts.size;
		this.cellClassName = ClassHelper.combine('DateColumnDef', size && `column-size-${size}`);
		this.headerClassName = ClassHelper.combine('DateColumnDef', size && `column-size-${size}`);
	}

	computeCell(row:T):ReactNode {
		const rawValue = this.rowToCell(row);
		if (!rawValue) {
			return (
				<NullCellValue />
			);
		}

		return (
			<Date value={rawValue} format={this.cellFormat} titleFormat={this.titleFormat ?? undefined} />
		);
	}
}
