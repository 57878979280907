//TODO add typing
export class Comparator {
	/**
	 * E.g. 1, 2, a, b, undefined
	 * Or with nanFirst=false => a, b, undefined, 1, 2
	 *
	 * Reverse order if fieldName starts with -
	 */
	static number(fieldName:string, nanFirst:boolean = true):Comp {
		return (a:object, b:object) => ComparatorPrivate.fieldCompareNumber(fieldName, a, b, nanFirst);
	}

	/**
	 * Reverse order if fieldName starts with -
	 */
	static string(fieldName:string, caseSensitive:boolean = true):Comp {
		return (a:object, b:object) => ComparatorPrivate.fieldCompareString(fieldName, a, b, caseSensitive);
	}

	static by(...args:Array<CompArgument>):Comp {
		return (a:object, b:object) => {
			for (let i = 0; i < args.length; i++) {
				const arg = args[i];
				const r = ComparatorPrivate.applyArg(arg, a, b);
				if (r !== 0) {
					return r;
				}
			}
			return 0;
		};
	}
}

class ComparatorPrivate {
	static applyArg(arg:CompArgument, a:any, b:any):number {
		if (typeof arg === 'string') {
			return this.fieldCompare(arg, a, b);
		} else {
			const numArg = arg.length;
			if (numArg === 2) {
				const directComp = arg as DirectComp;
				return directComp(a, b);
			} else {
				const byField = arg as CompByFieldReference;
				const rA = byField(a);
				const rB = byField(b);
				return this.compare(rA, rB);
			}
		}
	}

	static fieldCompare(fieldName:string, a:any, b:any):number {
		if (fieldName.startsWith('-')) {
			const realName = fieldName.substring(1);
			// reverse
			return this.compare(b[realName], a[realName]);
		} else {
			return this.compare(a[fieldName], b[fieldName]);
		}
	}

	static fieldCompareString(fieldName:string, a:any, b:any, caseSensitive:boolean):number {
		if (fieldName.startsWith('-')) {
			const realName = fieldName.substring(1);
			// reverse
			return this.compareAsString(b[realName], a[realName], caseSensitive);
		} else {
			return this.compareAsString(a[fieldName], b[fieldName], caseSensitive);
		}
	}

	static fieldCompareNumber(fieldName:string, a:any, b:any, nanFirst:boolean) {
		let fieldA:any, fieldB:any;
		let ra:number, rb:number;
		if (fieldName.startsWith('-')) {
			const realName = fieldName.substring(1);
			// reverse
			fieldA = b[realName];
			fieldB = a[realName];
		} else {
			fieldA = a[fieldName];
			fieldB = b[fieldName];
		}
		ra = parseInt(fieldA, 10);
		rb = parseInt(fieldB, 10);

		const nanA = isNaN(ra);
		const nanB = isNaN(rb);
		if (nanA || nanB) {
			if (nanA && nanB) {
				// null / undefind / '' are equivalent here
				const valueA = fieldA ? fieldA : '';
				const valueB = fieldB ? fieldB : '';
				return this.compareAsString(valueA, valueB, true);
			} else {
				//TODO to be tested
				return (nanFirst != nanA) ? 1 : -1;
			}
		}
		return this.compareNumber(ra, rb);
	}

	static compare(a:ComparableValue, b:ComparableValue) {
		if (typeof a === 'number' && typeof b === 'number') {
			return this.compareNumber(a, b);
		} else {
			return this.compareAsString(a, b, true);
		}
	}

	static compareNumber(a:number, b:number) {
		return a - b;
	}

	static compareAsString(a:ComparableValue, b:ComparableValue, caseSensitive:boolean) {
		if (a === b) {
			return 0;
		} else {
			if (caseSensitive) {
				return a > b ? 1 : -1;
			} else {
				return ('' + a).localeCompare('' + b);
			}
		}
	}
}

type Comp = (a:any, b:any) => number;

type ComparableValue = string | number
type CompByFieldName = string
type CompByFieldReference = (item:any) => ComparableValue;
type DirectComp = (a:any, b:any) => number;

type CompArgument = CompByFieldName | CompByFieldReference | DirectComp;
