import {IsoDateString} from "../types/IsoDateString";

export type PriorityType =
	typeof PriorityType_HIGHEST
	| typeof PriorityType_HIGH
	| typeof PriorityType_MEDIUM
	| typeof PriorityType_LOW
	| typeof PriorityType_LOWEST;

export const PriorityType_HIGHEST = 5;
export const PriorityType_HIGH = 4;
export const PriorityType_MEDIUM = 3;
export const PriorityType_LOW = 2;
export const PriorityType_LOWEST = 1;

export const TaskBaseStatus_TODO:string = 'todo';
export const TaskBaseStatus_PROGRESS:string = 'progress';
export const TaskBaseStatus_COMPLETED:string = 'completed';
export type TaskBaseStatus =
	typeof TaskBaseStatus_TODO
	| typeof TaskBaseStatus_PROGRESS
	| typeof TaskBaseStatus_COMPLETED;
export const TaskBaseStatus_VALUES:TaskBaseStatus[] = [TaskBaseStatus_TODO, TaskBaseStatus_PROGRESS, TaskBaseStatus_COMPLETED];

export const Task_Status_Mapping_ToNum = (status:TaskBaseStatus):number => {
	let result:number = -1;
	switch (status) {
		case TaskBaseStatus_TODO:
			result = 1;
			break;
		case TaskBaseStatus_PROGRESS:
			result = 2;
			break;
		case TaskBaseStatus_COMPLETED:
			result = 3
			break;
	}

	return result;
}

export type TaskBase = {
	/** To differentiate the different tasks */
	type:string

	name:string

	/**
	 * Correspond to the ID of the ticket, issue, etc on the external system
	 * e.g. JENKINS-XXX, SECURITY-XXX, 1234 (for GH issue)
	 */
	targetId:string
	/**
	 * To ease sorting, e.g. JENKINS-123 is after JENKINS-2, we are storing only the 123 and 2 here, as numbers
	 */
	targetIdNum:number

	// status:TaskBaseStatus
	/**
	 * Task generic status, to do / in progress / done
	 */
	taskStatus:TaskBaseStatus
	/**
	 * To do = 0, in progress = 1, done = 2
	 */
	taskStatusNum:number
	taskSubStatus?:string
	/**
	 * When possible, to enable the sorting by subStatus
	 */
	taskSubStatusNum?:number

	/**
	 * Status of the target entity, like ticket, issue
	 */
	targetStatus?:string
	/**
	 * When possible, to enable the sorting by targetStatus
	 */
	targetStatusNum?:number

	// done:boolean
	/**
	 * The task is completed
	 */
	taskDone:boolean

	/**
	 * When the task should be measured, like hosting request being bot-checked
	 * null means that the information is not available in that particular case
	 * undefined means that the task class does not have that concept
	 */
	clockStartingDate:IsoDateString | null

	doneDate:IsoDateString | null
	/** Stores the user-identity:login */
	assignee:string | null
	priority:PriorityType

	//TODO add comments count?

	// description:string
	// comments:string[]
}

//TODO to be refactored outside of that file
// /**
//  * When an email was not recognized as coming from an automatic source, it has to be processed manually
//  *
//  * @autoClose if a new email sent from CERT member on the thread
//  */
// export type Task_EmailManual = TaskBase & {
// 	type:'email-manual'
// 	// targetId = emailId
// 	threadId:string
// }

export const TaskBase_DEFAULT:TaskBase = {
	type:'unknown',
	name:'',
	targetId:'',
	targetIdNum:0,
	taskStatus:TaskBaseStatus_TODO,
	taskStatusNum:0,
	// taskSubStatus: '',
	// taskSubStatusNum: 0,
	// targetStatus: '',
	// targetStatusNum:0,
	clockStartingDate:null,
	taskDone:false,
	doneDate:null,
	assignee:null,

	priority:PriorityType_MEDIUM
}
