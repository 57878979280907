import * as zod from "zod";

export class ZodHelper {
	
}

export const ZodCrudModel_DEFAULT = {
	id:zod.string().optional(),
	creator:zod.string().optional(),
	createdDate:zod.string().optional(),
	updater:zod.string().optional(),
	updatedDate:zod.string().optional(),
	version:zod.number().optional(),
}
