import {
	COUNTER_KEY_RPU_HOSTING_AUDIT,
	COUNTER_KEY_SECURITY_TASK_VALUE_ADVISORY_DATA,
	COUNTER_KEY_SECURITY_TASK_VALUE_ASSIGN,
	COUNTER_KEY_SECURITY_TASK_VALUE_CERT_ATTENTION,
	COUNTER_KEY_SECURITY_TASK_VALUE_CREDIT,
	COUNTER_KEY_SECURITY_TASK_VALUE_DISCLOSURE_DEADLINE,
	COUNTER_KEY_SECURITY_TASK_VALUE_LABEL,
	COUNTER_KEY_SECURITY_TASK_VALUE_NON_CERT_COMMENT,
	COUNTER_KEY_SECURITY_TASK_VALUE_REPRODUCTION,
	COUNTER_KEY_SECURITY_TASK_VALUE_REVIEW,
	COUNTER_KEY_SECURITY_TASK_VALUE_TRIAGE
} from "@commons/models/Counter";
import * as React from 'react';
import {ChangeEvent, ReactNode, useCallback, useRef, useState} from 'react';
import {
	MdCheck,
	MdClose,
	MdDoneAll,
	MdLabel,
	MdLabelOff,
	MdNotificationsActive,
	MdNotificationsOff,
	MdOutlineRemoveDone
} from "react-icons/md";
import {useAuth} from '../../../../commons/hooks/useAuth';
import {useIsMounted} from "../../../../commons/hooks/useIsMounted";
import {useLogIfStillMounted} from "../../../../commons/hooks/useLogIfStillMounted";
import {apiFetch} from "../../../../framework/apiFetch";
import {Card} from "../../../../framework/components/Card";
import {Divider} from "../../../../framework/components/Divider";
import {IconButton} from "../../../../framework/components/IconButton";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {LogAppender, LogPanel, useLogPanel} from "../../../../framework/components/LogPanel";
import {Tab, Tabs} from "../../../../framework/components/Tabs";
import {TextButton} from '../../../../framework/components/TextButton';
import {ApiFetchResponse} from "../../../../framework/WrappedJsonFetch";

//TODO add a way to delete all comments from a ticket (typically the SECURITY-2606)

import "./AdminToolingPage.scss"

export const AdminToolingPage = () => {
	const {authInfo} = useAuth();

	const {logRows, appendLog, clearLog} = useLogPanel();

	const findLastSecurityTicketKey = useCallback(() => {
		apiFetch.get(`api/admin/jira-security-tooling/last`).then(appendLog)
	}, [appendLog]);

	const gmailConnect = useCallback(() => {
		window.open(`${process.env.REACT_APP_API_URL}/api/admin/auth/oauth/google/start`, '_blank')
	}, []);

	const isDev = process.env.NODE_ENV === 'development';

	return (
		<PageContent className="AdminToolingPage">
			{authInfo!.isAdmin && (<>
				<Tabs>
					<Tab name="SECURITY ticket">
						<SecurityProcessListSection appendLog={appendLog} />
						<div>
							<TextButton label="Last SECURITY ticket" onClick={findLastSecurityTicketKey} />
						</div>
						<Divider />
						<SecurityProcessListPlaySection appendLog={appendLog} />
						<Divider />
						<SecurityProcessUpdatedSinceSection appendLog={appendLog} />
						<Divider />
						<SecurityTasksRefreshSection appendLog={appendLog} />
					</Tab>
					<Tab name="RPU issues">
						<RpuProcessListSection appendLog={appendLog} />
						<Divider />
						<RpuProcessOpenSection appendLog={appendLog} />
						<Divider />
						<RpuProcessByIdSection appendLog={appendLog} />
						<Divider />
						{/* TODO process open issues */}
						<RpuTasksRefreshSection appendLog={appendLog} />
					</Tab>
					<Tab name="Others">
						<JiraPublicDisclosureSection appendLog={appendLog} />
						<Divider />
						<ClearSecurity2606Section appendLog={appendLog} />
						<Divider />
						<CertAutomationRawSection appendLog={appendLog} />
						<Divider />
						<CodeQlRawSection appendLog={appendLog} />
						<Divider />
						<CounterResetSection appendLog={appendLog} />
						<Divider />
						<HealthCheckSection appendLog={appendLog} />
					</Tab>

					{isDev && (
						<Tab name="Dev test">
							<TestDevSection appendLog={appendLog} />
						</Tab>
					)}

					<Tab name="Gmail (danger)">
						<div>
							<TextButton label="Gmail connect" onClick={gmailConnect} />
						</div>
					</Tab>
				</Tabs>
			</>)}
			<Card>
				<LogPanel rows={logRows} numRowsToDisplay={20} onClearLog={clearLog} />
			</Card>
		</PageContent>
	);
}

type AdminSectionProps = {
	name:string
	children:ReactNode
}

const AdminSection = ({name, children}:AdminSectionProps) => {
	return (
		<div className="AdminSection">
			<h3>{name}</h3>
			<div className="content">
				{children}
			</div>
		</div>
	)
}

type AdminTextFieldProps = {
	defaultValue?:string
	placeholder?:string
	onChange?:(newValue:string) => void
}

const AdminTextField = ({defaultValue, placeholder, onChange}:AdminTextFieldProps) => {
	const handleChange = useCallback((e:ChangeEvent) => {
		const newValue = (e.target as HTMLInputElement).value;
		onChange && onChange(newValue);
	}, [onChange]);

	return (
		<span className="AdminTextField">
			<input type="text" placeholder={placeholder} onChange={handleChange} defaultValue={defaultValue} />
		</span>
	)
}

type AdminBooleanFieldProps = {
	defaultValue?:boolean
	onChange?:(newValue:boolean) => void
}

const AdminBooleanField = ({defaultValue, onChange}:AdminBooleanFieldProps) => {
	const handleChange = useCallback((e:ChangeEvent) => {
		const newValue = (e.target as HTMLInputElement).checked;
		onChange && onChange(newValue);
	}, [onChange]);

	return (
		<span className="AdminBooleanField">
			<input type="checkbox" onChange={handleChange} defaultValue={defaultValue === true ? 'on' : 'off'} />
		</span>
	)
}

type AdminIntegerFieldProps = {
	value?:number
	defaultValue?:number
	placeholder?:string
	onChange?:(valueAsNumber:number) => void
	onChangeAll?:(args:{ valueAsNumber:number, valueAsString:string }) => void
	onValid?:(newValue:number) => void
	onInvalid?:(newValue:string) => void
}

const AdminIntegerField = ({
	                           defaultValue,
	                           value,
	                           placeholder,
	                           onChange,
	                           onChangeAll,
	                           onValid,
	                           onInvalid
                           }:AdminIntegerFieldProps) => {
	const handleChange = useCallback((e:ChangeEvent) => {
		const newValue = (e.target as HTMLInputElement).value;
		const newValueInt = parseInt(newValue, 10);

		onChange && onChange(newValueInt);
		onChangeAll && onChangeAll({valueAsNumber:newValueInt, valueAsString:newValue});

		if (isFinite(newValueInt)) {
			onValid && onValid(newValueInt);
		} else {
			onInvalid && onInvalid(newValue);
		}
	}, [onChange, onChangeAll, onValid, onInvalid]);

	let valueAsInt;
	if (value === undefined || defaultValue !== undefined) {
		valueAsInt = undefined;
	} else {
		valueAsInt = isFinite(value) ? value : '';
	}
	return (
		<span className="AdminIntegerField">
			<input type="number" value={valueAsInt} placeholder={placeholder} onChange={handleChange}
			       defaultValue={defaultValue} />
		</span>
	)
}

type AdminBellProps = {
	value:boolean
	onChange?:(newValue:boolean) => void
}

const AdminBell = ({value, onChange}:AdminBellProps) => {
	const handleChange = useCallback((newValue:boolean) => {
		onChange && onChange(newValue);
	}, [onChange]);

	return (
		<span className="AdminBell">
			{value ? (
				<IconButton title="Notifications enabled" icon={MdNotificationsActive}
				            className="icon-within-green"
				            onClick={handleChange} onClickArgs={false} />
			) : (
				<IconButton title="Notifications disabled" icon={MdNotificationsOff}
				            onClick={handleChange} onClickArgs={true} />
			)}
		</span>
	)
}
const AdminAcknowledge = ({value, onChange}:AdminBellProps) => {
	const handleChange = useCallback((newValue:boolean) => {
		onChange && onChange(newValue);
	}, [onChange]);

	return (
		<span className="AdminAcknowledge">
			{value ? (
				<IconButton title="Acknowledgements enabled" icon={MdDoneAll}
				            className="icon-within-green"
				            onClick={handleChange} onClickArgs={false} />
			) : (
				<IconButton title="Acknowledgements disabled" icon={MdOutlineRemoveDone}
				            onClick={handleChange} onClickArgs={true} />
			)}
		</span>
	)
}
const AdminChangeLabels = ({value, onChange}:AdminBellProps) => {
	const handleChange = useCallback((newValue:boolean) => {
		onChange && onChange(newValue);
	}, [onChange]);

	return (
		<span className="AdminAcknowledge">
			{value ? (
				<IconButton title="Labels change enabled" icon={MdLabel}
				            className="icon-within-green"
				            onClick={handleChange} onClickArgs={false} />
			) : (
				<IconButton title="Labels change disabled" icon={MdLabelOff} onClick={handleChange}
				            onClickArgs={true} />
			)}
		</span>
	)
}

type AdminLineProps = {
	children:ReactNode
}

const AdminLine = ({children}:AdminLineProps) => {
	return (
		<div className="AdminLine">
			{children}
		</div>
	)
}

type HasLogAppender = {
	appendLog:LogAppender
}

const ClearSecurity2606Section = ({appendLog}:HasLogAppender) => {
	const {logIfMounted} = useLogIfStillMounted(appendLog);

	const trigger = useCallback(() => {
		const confirmed = window.confirm(`Are you sure to delete all comments from SECURITY-2606?`);
		if (!confirmed) {
			return;
		}
		appendLog(`Trigger SECURITY-2606`);
		const url = `api/admin/jira-security-tooling/SECURITY-2606/comments`;
		apiFetch.delete(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);

	return (
		<AdminSection name="Clear comments from SECURITY-2606">
			<AdminLine>
				<TextButton label="Trigger" onClick={trigger} />
			</AdminLine>
		</AdminSection>
	)
}

const JiraPublicDisclosureSection = ({appendLog}:HasLogAppender) => {
	const {logIfMounted} = useLogIfStillMounted(appendLog);

	/*
	 *  JiraJenkins:
	 *      - run search to find number of unlabelled tickets
	 *      - run search to find number of labelled tickets
	 *      - label + attach watcher on tickets (ease task of recurrent task)
	 *      - reprocess labelled tickets (re-sync)
	 */
	const queryJiraTotal = useCallback(() => {
		apiFetch.get('api/admin/jira-jenkins-tooling/totals').then(logIfMounted)
	}, [logIfMounted]);
	const queryJiraTotalWithLabel = useCallback(() => {
		apiFetch.get('api/admin/jira-jenkins-tooling/totals-with-labels').then(logIfMounted)
	}, [logIfMounted]);
	const processJiraTickets = useCallback(() => {
		// const keyword = 'security-issue';
		const keyword = 'vulnerability';
		const limit = 5;
		const offset = 0;

		const url = `api/admin/jira-jenkins-tooling/process/by-keyword/${keyword}?limit=${limit}&offset=${offset}`;
		apiFetch.post(url).then(logIfMounted)
	}, [logIfMounted]);
	const reprocessJiraTickets = useCallback(() => {
		// const keyword = 'security-issue';
		const keyword = 'vulnerability';
		const limit = 25;
		const offset = 0;

		const url = `api/admin/jira-jenkins-tooling/process/re-by-keyword/${keyword}?limit=${limit}&offset=${offset}`;
		apiFetch.post(url).then(logIfMounted)
	}, [logIfMounted]);
	const processOneJiraTicket = useCallback((issueKey:string) => {
		const url = `api/admin/jira-jenkins-tooling/process/single/${issueKey}`;
		apiFetch.post(url).then(logIfMounted)
	}, [logIfMounted]);

	return (
		<AdminSection name="Jira Public Disclosure detection">
			<AdminLine>
				<TextButton label="Total without label" onClick={queryJiraTotal} />
				<TextButton label="Total with label" onClick={queryJiraTotalWithLabel} />
			</AdminLine>
			<AdminLine>
				<TextButton label="Process 5 tickets" onClick={processJiraTickets} />
				<TextButton label="Process JENKINS-67535" onClick={processOneJiraTicket}
				            onClickArgs={'JENKINS-67535'} />

				<TextButton label="Reprocess 25 tickets" onClick={reprocessJiraTickets} />
			</AdminLine>
		</AdminSection>
	)
}

const SecurityProcessListSection = ({appendLog}:HasLogAppender) => {
	const {isMounted, logIfMounted} = useLogIfStillMounted(appendLog);

	const [securityTicketsCheckResult_fromTo, setSecurityTicketsCheckResult_fromTo] = useState<string>('');
	const [securityTicketsFrom, setSecurityTicketsFrom] = useState(0);
	const [securityTicketsTo, setSecurityTicketsTo] = useState(0);

	const [includeClosed, setIncludeClosed] = useState(false);

	const [withNotification, setWithNotification] = useState(false);

	const checkAllSecurityTicketsFromTo = useCallback(() => {
		setSecurityTicketsCheckResult_fromTo('');
		setWithNotification(false);

		if (securityTicketsFrom <= 0 || securityTicketsTo <= 0 || securityTicketsFrom > securityTicketsTo) {
			appendLog('From and To must be positive and To cannot be smaller than From');
			return;
		}

		appendLog(`Checking SECURITY issues from=SECURITY-${securityTicketsFrom} to=SECURITY-${securityTicketsTo}`);
		let url = `api/admin/jira-security-tooling/check?from=${securityTicketsFrom}&to=${securityTicketsTo}`;
		if (includeClosed) {
			url += '&include-closed=true';
		}
		apiFetch.post(url).then(response => {
			if (isMounted.current) {
				if (response.ok) {
					setSecurityTicketsCheckResult_fromTo(JSON.stringify(response.payload));
				}
				appendLog(response);
			} else {
				console.info('checkAllSecurityTicketsFromTo after component was dismounted');
			}
		})
	}, [appendLog, includeClosed, isMounted, securityTicketsFrom, securityTicketsTo]);
	const processAllSecurityTicketsFromTo = useCallback(() => {
		if (securityTicketsFrom <= 0 || securityTicketsTo <= 0 || securityTicketsFrom > securityTicketsTo) {
			appendLog('From and To must be positive and To cannot be smaller than From');
			return;
		}

		appendLog(`Processing SECURITY issues from=SECURITY-${securityTicketsFrom} to=SECURITY-${securityTicketsTo}`);
		let url = `api/admin/jira-security-tooling/process?from=${securityTicketsFrom}&to=${securityTicketsTo}`;
		if (includeClosed) {
			url += '&include-closed=true';
		}
		if (withNotification) {
			url += '&notify=true';
		}
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, includeClosed, logIfMounted, securityTicketsFrom, securityTicketsTo, withNotification]);

	return (
		<AdminSection name="SECURITY tickets processing by list">
			<AdminLine>
				<span>
					<span className="cursor-pointer" onClick={useCallback(() => setIncludeClosed(curr => !curr), [])}>
					{includeClosed ? (
						<b>Look for all vulnerabilities</b>
					) : (
						<span>Look only for open vulnerabilities</span>
					)}
					</span>, SECURITY-</span>
				<AdminIntegerField onChange={setSecurityTicketsFrom} />
				<span>to SECURITY-</span>
				<AdminIntegerField onChange={setSecurityTicketsTo} />

				<TextButton label="Check" onClick={checkAllSecurityTicketsFromTo} />
				{securityTicketsCheckResult_fromTo}
				{securityTicketsCheckResult_fromTo && (<>
					<AdminBell value={withNotification} onChange={setWithNotification} />
					<TextButton label="Process" onClick={processAllSecurityTicketsFromTo} />
				</>)}
			</AdminLine>
		</AdminSection>
	)
}

const SecurityProcessListPlaySection = ({appendLog}:HasLogAppender) => {
	const DEFAULT_BATCH_SIZE = 50;
	const isMounted = useIsMounted();

	const isPlaying = useRef(false);
	const [isPlayingState, setIsPlayingState] = useState(false);

	const [upperBoundKey, setUpperBoundKey] = useState<number>(0);

	const [lastTicketKey, setLastTicketKey] = useState<number>(1);
	const [totalItemsDone, setTotalItemsDone] = useState(0);

	const [batchSize, setBatchSize] = useState<number>(DEFAULT_BATCH_SIZE);

	const continueProcessing = useCallback(async () => {
		appendLog(`Next step, processing tracked tickets, batch=${batchSize}`);

		let from:number = lastTicketKey && lastTicketKey > 0 ? lastTicketKey : 1;
		let to:number

		const onlyOneStep = !isPlaying.current;
		let localUpperBoundKey:number = upperBoundKey;

		const responseHandler = async (response:ApiFetchResponse<{ processed:string[], counterMap:any }>) => {
			if (isMounted.current) {
				if (response.ok) {
					setLastTicketKey(to);
					setTotalItemsDone(prevState => prevState + response.payload.processed.length);

					from += batchSize;

					if (!onlyOneStep && !localUpperBoundKey) {
						const response = await apiFetch.get<{ lastKey:string }>(`api/admin/jira-security-tooling/last`);
						if (response.ok) {
							// e.g. SECURITY-XXX
							const lastKey = response.payload.lastKey;
							localUpperBoundKey = parseInt(lastKey.substring(lastKey.indexOf('-') + 1), 10);
							setUpperBoundKey(localUpperBoundKey);
							appendLog('Found upperBound: SECURITY-' + localUpperBoundKey);
						} else {
							localUpperBoundKey = -1;
							setUpperBoundKey(localUpperBoundKey);
							appendLog(response);
						}
					}
					if (localUpperBoundKey && from > localUpperBoundKey) {
						appendLog('Reached upperBound');
						isPlaying.current = false;
						setIsPlayingState(false);
					}
				} else {
					isPlaying.current = false;
					setIsPlayingState(false);
				}
				appendLog(response);
			} else {
				console.info('continueProcessing after component was dismounted');
				isPlaying.current = false;
				// setIsPlayingState(false);
			}
		};

		if (!batchSize) {
			appendLog('No batch value');
			return;
		}
		if (batchSize <= 0) {
			appendLog('Batch size negative');
			return;
		}

		do {
			to = from + batchSize;
			if (localUpperBoundKey && to > localUpperBoundKey) {
				to = localUpperBoundKey;
			}

			const url = `api/admin/jira-security-tooling/process?from=${from}&to=${to}`;
			await apiFetch.post<{ processed:string[], counterMap:any }>(url)
				.then(responseHandler);
		} while (isPlaying.current);

		if (isMounted.current) {
			if (onlyOneStep) {
				appendLog('Step complete');
			} else {
				appendLog('Batch complete');
			}
		} else {
			console.info('Batch stopped, component dismounted in the meantime');
		}
	}, [appendLog, upperBoundKey, batchSize, lastTicketKey, isMounted]);

	const resetTotal = useCallback(() => {
		appendLog(`Reset total from ${totalItemsDone}`);
		setTotalItemsDone(0);
	}, [appendLog, totalItemsDone]);

	const playProcessing = useCallback(() => {
		appendLog('Play processing tracked tickets');
		isPlaying.current = true;
		setIsPlayingState(true);
		continueProcessing().then();
	}, [appendLog, continueProcessing]);

	const stopProcessing = useCallback(() => {
		isPlaying.current = false;
		setIsPlayingState(false);
		appendLog('Stop processing tracked tickets, current in-flight request will be the last one');
	}, [appendLog]);

	return (
		<AdminSection name="SECURITY tickets processing by list (play)">
			<AdminLine>
				<span>Look only for open vulnerabilities, by batch of</span>
				<AdminIntegerField defaultValue={DEFAULT_BATCH_SIZE} onChange={setBatchSize} />
			</AdminLine>
			<AdminLine>
				(optional) From: SECURITY-<AdminIntegerField value={lastTicketKey} onChange={setLastTicketKey} />
			</AdminLine>
			<AdminLine>
				(optional) Upper bound: SECURITY-<AdminIntegerField value={upperBoundKey} onChange={setUpperBoundKey} />
				<i>(0 = will retrieve the last ticket)</i>
			</AdminLine>
			<AdminLine>
				{/*<TextButton label="First step" onClick={firstStepProcessing} />*/}
				{/*{lastTicketKey && (*/}
				{/*	<code>SECURITY-{lastTicketKey}</code>*/}
				{/*)}*/}
				<TextButton label="Next step" onClick={continueProcessing} />
				<span>Done: {totalItemsDone}</span>
				<TextButton label="Reset total" onClick={resetTotal} />
			</AdminLine>
			<AdminLine>
				<TextButton label="Play continuously" onClick={playProcessing} />
				<TextButton label="Stop" onClick={stopProcessing} />
				<span>Running: <code>{isPlayingState ? 'true' : 'false'}</code></span>
			</AdminLine>
		</AdminSection>
	)
}

const SecurityProcessUpdatedSinceSection = ({appendLog}:HasLogAppender) => {
	const DEFAULT_UPDATED_SINCE = '-3d';
	const {isMounted, logIfMounted} = useLogIfStillMounted(appendLog);

	const [securityTicketsCheckResult_updatedSince, setSecurityTicketsCheckResult_updatedSince] = useState<string>('');
	const [securityTicketsUpdatedSince, setSecurityTicketsUpdatedSince] = useState(DEFAULT_UPDATED_SINCE);
	const [withNotification, setWithNotification] = useState(false);

	const checkUpdatedTicketsSince = useCallback(() => {
		setSecurityTicketsCheckResult_updatedSince('');
		setWithNotification(false);

		if (!securityTicketsUpdatedSince) {
			appendLog('Updated since must be provided');
			return;
		}

		appendLog(`Checking SECURITY issues updated since=${securityTicketsUpdatedSince} minutes`);
		const url = `api/admin/jira-security-tooling/check?updated-since=${encodeURIComponent(securityTicketsUpdatedSince)}`
		apiFetch.post(url).then(response => {
			if (isMounted.current) {
				if (response.ok) {
					setSecurityTicketsCheckResult_updatedSince(JSON.stringify(response.payload));
				}
				appendLog(response);
			} else {
				console.info('checkUpdatedTicketsSince after component was dismounted');
			}
		});
	}, [appendLog, isMounted, securityTicketsUpdatedSince]);
	const processUpdatedTicketsSince = useCallback(() => {
		if (!securityTicketsUpdatedSince) {
			appendLog('Updated since must be provided');
			return;
		}

		appendLog(`Processing SECURITY issues updated since=${securityTicketsUpdatedSince} minutes`);
		const url = `api/admin/jira-security-tooling/process?updated-since=${encodeURIComponent(securityTicketsUpdatedSince)}`
		apiFetch.post(url).then(logIfMounted);
	}, [appendLog, logIfMounted, securityTicketsUpdatedSince]);

	return (
		<AdminSection name="SECURITY tickets processing by updatedSince">
			<AdminLine>
				<span>Looking for (all) updated SECURITY tickets since</span>
				<AdminTextField defaultValue={DEFAULT_UPDATED_SINCE}
				                onChange={setSecurityTicketsUpdatedSince} />

				<TextButton label="Check" onClick={checkUpdatedTicketsSince} />
				{securityTicketsCheckResult_updatedSince}
				{securityTicketsCheckResult_updatedSince && (<>
					<AdminBell value={withNotification} onChange={setWithNotification} />
					<TextButton label="Process" onClick={processUpdatedTicketsSince} />
				</>)}
			</AdminLine>
		</AdminSection>
	)
}

const SecurityTasksRefreshSection = ({appendLog}:HasLogAppender) => {
	const DEFAULT_BATCH_SIZE = 20;
	const isMounted = useIsMounted();

	const isPlaying = useRef(false);
	const [isPlayingState, setIsPlayingState] = useState(false);

	const [lastTrackedTicket, setLastTrackedTicket] = useState<string>('');
	const [totalItemsDone, setTotalItemsDone] = useState(0);

	const [batchSize, setBatchSize] = useState<number>(DEFAULT_BATCH_SIZE);

	const startTrackedTickets = useCallback(() => {
		appendLog(`First step, processing tracked tickets, batch=${batchSize}`);
		setLastTrackedTicket('');
		setTotalItemsDone(0);

		if (!batchSize) {
			appendLog('No batch value');
			return;
		}
		if (batchSize <= 0) {
			appendLog('Batch size negative');
			return;
		}

		const url = `api/admin/jira-security-tooling/reprocess?batch=${encodeURIComponent(batchSize)}`;
		apiFetch.post<{ lastTicket:string, batchSize:number }>(url).then(response => {
			if (isMounted.current) {
				if (response.ok) {
					setLastTrackedTicket(response.payload.lastTicket);
					setTotalItemsDone(response.payload.batchSize);
				}
				appendLog(response);
			} else {
				console.info('startTrackedTickets after component was dismounted');
			}
		})

	}, [appendLog, batchSize, isMounted]);

	const continueTrackedTickets = useCallback(async () => {
		appendLog(`Next step, processing tracked tickets, batch=${batchSize}`);
		let lastTrackedTicket_local = lastTrackedTicket;
		const responseHandler = (response:ApiFetchResponse<{ lastTicket:string, batchSize:number }>) => {
			if (isMounted.current) {
				if (response.ok) {
					setLastTrackedTicket(response.payload.lastTicket);
					lastTrackedTicket_local = response.payload.lastTicket;
					setTotalItemsDone(prevState => prevState + response.payload.batchSize)
				} else {
					isPlaying.current = false;
					setIsPlayingState(false);
				}
				appendLog(response);
			} else {
				console.info('continueTrackedTickets after component was dismounted');
				isPlaying.current = false;
				// setIsPlayingState(false);
			}
		};

		if (!batchSize) {
			appendLog('No batch value');
			return;
		}
		if (batchSize <= 0) {
			appendLog('Batch size negative');
			return;
		}

		do {
			const url = `api/admin/jira-security-tooling/reprocess?last=${encodeURIComponent(lastTrackedTicket_local)}&batch=${encodeURIComponent(batchSize)}`;
			await apiFetch.post<{ lastTicket:string, batchSize:number }>(url)
				.then(responseHandler);
		} while (isPlaying.current);

		if (isMounted.current) {
			appendLog('Batch complete');
		} else {
			console.info('Batch stopped, component dismounted in the meantime');
		}
	}, [appendLog, lastTrackedTicket, batchSize, isMounted]);

	const playTrackedTickets = useCallback(() => {
		appendLog('Play reprocessing tracked tickets');
		isPlaying.current = true;
		setIsPlayingState(true);
		continueTrackedTickets().then();
	}, [appendLog, continueTrackedTickets]);
	const stopTrackedTickets = useCallback(() => {
		isPlaying.current = false;
		setIsPlayingState(false);
		appendLog('Stop reprocessing tracked tickets, current in-flight request will be the last one');
	}, [appendLog]);

	return (
		<AdminSection name="Security tasks refreshing">
			<AdminLine>
				<span>Refreshing tasks by batch of</span>
				<AdminIntegerField defaultValue={DEFAULT_BATCH_SIZE} onChange={setBatchSize} />
			</AdminLine>
			<AdminLine>
				<TextButton label="First step" onClick={startTrackedTickets} />
				{lastTrackedTicket && (
					<code>{lastTrackedTicket}</code>
				)}
				<TextButton label="Next step" onClick={continueTrackedTickets} />
				<span>Done: {totalItemsDone}</span>
			</AdminLine>
			<AdminLine>
				<TextButton label="Play continuously" onClick={playTrackedTickets} />
				<TextButton label="Stop" onClick={stopTrackedTickets} />
				<span>Running: <code>{isPlayingState ? 'true' : 'false'}</code></span>
			</AdminLine>
		</AdminSection>
	)
}

const RpuProcessListSection = ({appendLog}:HasLogAppender) => {
	const DEFAULT_UPDATED_SINCE = -60;
	const {isMounted, logIfMounted} = useLogIfStillMounted(appendLog);

	const [rpuIssueCheckResult, setRpuIssueCheckResult] = useState<string>('');
	const [rpuIssueUpdatedSince, setRpuIssueUpdatedSince] = useState(DEFAULT_UPDATED_SINCE);
	const [withNotification, setWithNotification] = useState(false);
	const [withAcknowledge, setWithAcknowledge] = useState(false);
	const [withChangeLabels, setWithChangeLabels] = useState(false);

	const checkUpdatedIssuesSince = useCallback(() => {
		setRpuIssueCheckResult('');
		setWithNotification(false);
		setWithAcknowledge(false);
		setWithChangeLabels(false);

		if (rpuIssueUpdatedSince >= 0) {
			appendLog('Updated since is positive, only negative values are accepted');
			return;
		}

		appendLog(`Checking RPU issues updated since=${rpuIssueUpdatedSince} minutes`);
		const url = `api/admin/rpu-issue-tooling/check?updated-since=${encodeURIComponent(rpuIssueUpdatedSince)}`
		apiFetch.post(url).then(response => {
			if (isMounted.current) {
				if (response.ok) {
					setRpuIssueCheckResult(JSON.stringify(response.payload));
				}
				appendLog(response);
			} else {
				console.info('checkUpdatedIssuesSince after component was dismounted');
			}
		});
	}, [appendLog, isMounted, rpuIssueUpdatedSince]);
	const processUpdatedIssuesSince = useCallback(() => {
		if (rpuIssueUpdatedSince >= 0) {
			appendLog('Updated since is positive, only negative values are accepted');
			return;
		}

		appendLog(`Processing RPU issues updated since=${rpuIssueUpdatedSince} minutes`);
		let url = `api/admin/rpu-issue-tooling/process?updated-since=${encodeURIComponent(rpuIssueUpdatedSince)}`;
		if (withNotification) {
			url += '&notify=true'
		}
		if (withAcknowledge) {
			url += '&acknowledge-commands=true'
		}
		if (withChangeLabels) {
			url += '&change-labels=true'
		}
		apiFetch.post(url).then(logIfMounted);
	}, [appendLog, logIfMounted, rpuIssueUpdatedSince, withAcknowledge, withChangeLabels, withNotification]);

	return (
		<AdminSection name="RPU issues processing by updatedSince">
			<AdminLine>
				<span>Looking for RPU issues since</span>
				<AdminIntegerField defaultValue={DEFAULT_UPDATED_SINCE} onChange={setRpuIssueUpdatedSince} />
				{/*TODO helpText?*/}
				<i>(in minutes, 1440 = 1 day)</i>
				<TextButton label="Check" onClick={checkUpdatedIssuesSince} />
				{rpuIssueCheckResult}
				{rpuIssueCheckResult && (<>
					<AdminBell value={withNotification} onChange={setWithNotification} />
					<AdminAcknowledge value={withAcknowledge} onChange={setWithAcknowledge} />
					<AdminChangeLabels value={withChangeLabels} onChange={setWithChangeLabels} />
					<TextButton label="Process" onClick={processUpdatedIssuesSince} />
				</>)}
			</AdminLine>
		</AdminSection>
	)
}

const RpuProcessOpenSection = ({appendLog}:HasLogAppender) => {
	const {isMounted, logIfMounted} = useLogIfStillMounted(appendLog);

	const [rpuIssueCheckResult, setRpuIssueCheckResult] = useState<string>('');
	const [withNotification, setWithNotification] = useState(false);
	const [withAcknowledge, setWithAcknowledge] = useState(false);
	const [withChangeLabels, setWithChangeLabels] = useState(false);

	const checkOpenIssues = useCallback(() => {
		setRpuIssueCheckResult('');
		setWithNotification(false);
		setWithAcknowledge(false);
		setWithChangeLabels(false);

		appendLog(`Checking RPU open issues`);
		const url = `api/admin/rpu-issue-tooling/check?open=true`
		apiFetch.post(url).then(response => {
			if (isMounted.current) {
				if (response.ok) {
					setRpuIssueCheckResult(JSON.stringify(response.payload));
				}
				appendLog(response);
			} else {
				console.info('checkUpdatedIssuesSince after component was dismounted');
			}
		});
	}, [appendLog, isMounted]);
	const processOpenIssues = useCallback(() => {
		appendLog(`Processing RPU open issues`);
		let url = `api/admin/rpu-issue-tooling/process?open=true`;
		if (withNotification) {
			url += '&notify=true'
		}
		if (withAcknowledge) {
			url += '&acknowledge-commands=true'
		}
		if (withChangeLabels) {
			url += '&change-labels=true'
		}
		apiFetch.post(url).then(logIfMounted);
	}, [appendLog, logIfMounted, withAcknowledge, withChangeLabels, withNotification]);

	return (
		<AdminSection name="RPU open issues processing">
			<AdminLine>
				<span>Looking for RPU open issues</span>
				<TextButton label="Check" onClick={checkOpenIssues} />
				{rpuIssueCheckResult}
				{rpuIssueCheckResult && (<>
					<AdminBell value={withNotification} onChange={setWithNotification} />
					<AdminAcknowledge value={withAcknowledge} onChange={setWithAcknowledge} />
					<AdminChangeLabels value={withChangeLabels} onChange={setWithChangeLabels} />
					<TextButton label="Process" onClick={processOpenIssues} />
				</>)}
			</AdminLine>
		</AdminSection>
	)
}

const RpuProcessByIdSection = ({appendLog}:HasLogAppender) => {
	const {isMounted, logIfMounted} = useLogIfStillMounted(appendLog);

	const [rpuIssueCheckResult, setRpuIssueCheckResult] = useState<string>('');
	const [issueIdsList, setIssueIdsList] = useState<string>('');
	const [withNotification, setWithNotification] = useState(false);
	const [withAcknowledge, setWithAcknowledge] = useState(false);
	const [withChangeLabels, setWithChangeLabels] = useState(false);

	const checkUpdatedIssuesSince = useCallback(() => {
		setRpuIssueCheckResult('');
		setWithNotification(false);
		setWithAcknowledge(false);
		setWithChangeLabels(false);

		if (!issueIdsList) {
			appendLog('Updated since is positive, only negative values are accepted');
			return;
		}

		appendLog(`Checking RPU issues by id issueIdsList=${issueIdsList}`);
		const url = `api/admin/rpu-issue-tooling/check?issues=${encodeURIComponent(issueIdsList)}`
		apiFetch.post(url).then(response => {
			if (isMounted.current) {
				if (response.ok) {
					setRpuIssueCheckResult(JSON.stringify(response.payload));
				}
				appendLog(response);
			} else {
				console.info('checkUpdatedIssuesSince after component was dismounted');
			}
		});
	}, [appendLog, isMounted, issueIdsList]);

	const processUpdatedIssuesSince = useCallback(() => {
		if (!issueIdsList) {
			appendLog('Updated since is positive, only negative values are accepted');
			return;
		}

		appendLog(`Checking RPU issues by id issueIdsList=${issueIdsList}`);
		let url = `api/admin/rpu-issue-tooling/process?issues=${encodeURIComponent(issueIdsList)}`;
		if (withNotification) {
			url += '&notify=true'
		}
		if (withAcknowledge) {
			url += '&acknowledge-commands=true'
		}
		if (withChangeLabels) {
			url += '&change-labels=true'
		}
		apiFetch.post(url).then(logIfMounted);
	}, [issueIdsList, appendLog, withNotification, withAcknowledge, withChangeLabels, logIfMounted]);

	return (
		<AdminSection name="RPU issues processing by id">
			<AdminLine>
				<span>List ids of RPU issues to be processed</span>
				<AdminTextField onChange={setIssueIdsList} />
				{/*TODO helpText?*/}
				<i>(separate by comma)</i>
				<TextButton label="Check" onClick={checkUpdatedIssuesSince} />
				{rpuIssueCheckResult}
				{rpuIssueCheckResult && (<>
					<AdminBell value={withNotification} onChange={setWithNotification} />
					<AdminAcknowledge value={withAcknowledge} onChange={setWithAcknowledge} />
					<AdminChangeLabels value={withChangeLabels} onChange={setWithChangeLabels} />
					<TextButton label="Process" onClick={processUpdatedIssuesSince} />
				</>)}
			</AdminLine>
		</AdminSection>
	)
}

const RpuTasksRefreshSection = ({appendLog}:HasLogAppender) => {
	const DEFAULT_BATCH_SIZE = 10;
	const isMounted = useIsMounted();

	const isPlaying = useRef(false);
	const [isPlayingState, setIsPlayingState] = useState(false);

	const [lastTrackedTask, setLastTrackedTask] = useState<string>('');
	const [totalItemsDone, setTotalItemsDone] = useState(0);

	const [batchSize, setBatchSize] = useState<number>(DEFAULT_BATCH_SIZE);

	const startTrackedTickets = useCallback(() => {
		appendLog(`First step, processing tracked tickets, batch=${batchSize}`);
		setLastTrackedTask('');
		setTotalItemsDone(0);

		if (!batchSize) {
			appendLog('No batch value');
			return;
		}
		if (batchSize <= 0) {
			appendLog('Batch size negative');
			return;
		}

		const url = `api/admin/rpu-issue-tooling/reprocess?batch=${encodeURIComponent(batchSize)}`;
		apiFetch.post<{ lastTask:string, batchSize:number }>(url).then(response => {
			if (isMounted.current) {
				if (response.ok) {
					setLastTrackedTask(response.payload.lastTask);
					setTotalItemsDone(response.payload.batchSize);
				}
				appendLog(response);
			} else {
				console.info('startTrackedTickets after component was dismounted');
			}
		})

	}, [appendLog, batchSize, isMounted]);

	const continueTrackedTickets = useCallback(async () => {
		appendLog(`Next step, processing tracked tickets, batch=${batchSize}`);
		let lastTrackedTask_local = lastTrackedTask;
		const responseHandler = (response:ApiFetchResponse<{ lastTask:string, batchSize:number }>) => {
			if (isMounted.current) {
				if (response.ok) {
					setLastTrackedTask(response.payload.lastTask);
					lastTrackedTask_local = response.payload.lastTask;
					setTotalItemsDone(prevState => prevState + response.payload.batchSize)
				} else {
					isPlaying.current = false;
					setIsPlayingState(false);
				}
				appendLog(response);
			} else {
				console.info('continueTrackedTickets after component was dismounted');
				isPlaying.current = false;
				// setIsPlayingState(false);
			}
		};

		if (!batchSize) {
			appendLog('No batch value');
			return;
		}
		if (batchSize <= 0) {
			appendLog('Batch size negative');
			return;
		}

		do {
			const url = `api/admin/rpu-issue-tooling/reprocess?last=${encodeURIComponent(lastTrackedTask_local)}&batch=${encodeURIComponent(batchSize)}`;
			await apiFetch.post<{ lastTask:string, batchSize:number }>(url)
				.then(responseHandler);
		} while (isPlaying.current);

		if (isMounted.current) {
			appendLog('Batch complete');
		} else {
			console.info('Batch stopped, component dismounted in the meantime');
		}
	}, [appendLog, lastTrackedTask, batchSize, isMounted]);

	const playTrackedTickets = useCallback(() => {
		appendLog('Play reprocessing tracked tasks');
		isPlaying.current = true;
		setIsPlayingState(true);
		continueTrackedTickets().then();
	}, [appendLog, continueTrackedTickets]);
	const stopTrackedTickets = useCallback(() => {
		isPlaying.current = false;
		setIsPlayingState(false);
		appendLog('Stop reprocessing tracked tasks, current in-flight request will be the last one');
	}, [appendLog]);

	return (
		<AdminSection name="RPU tasks refreshing">
			<AdminLine>
				<span>Refreshing tasks by batch of</span>
				<AdminIntegerField defaultValue={DEFAULT_BATCH_SIZE} onChange={setBatchSize} />
			</AdminLine>
			<AdminLine>
				<TextButton label="First step" onClick={startTrackedTickets} />
				{lastTrackedTask && (
					<code>{lastTrackedTask}</code>
				)}
				<TextButton label="Next step" onClick={continueTrackedTickets} />
				<span>Done: {totalItemsDone}</span>
			</AdminLine>
			<AdminLine>
				<TextButton label="Play continuously" onClick={playTrackedTickets} />
				<TextButton label="Stop" onClick={stopTrackedTickets} />
				<span>Running: <code>{isPlayingState ? 'true' : 'false'}</code></span>
			</AdminLine>
		</AdminSection>
	)
}

const CertAutomationRawSection = ({appendLog}:HasLogAppender) => {
	const {logIfMounted} = useLogIfStillMounted(appendLog);

	const [script, setScript] = useState('');
	const [args, setArgs] = useState('');

	const triggerCertAutomation = useCallback(() => {
		if (!script) {
			appendLog('Script is mandatory')
			return;
		}

		appendLog(`Trigger CERT Automation script=${script} with args=${args}`);
		const url = `api/admin/cert-automation/trigger-cloud-run?script=${encodeURIComponent(script)}&args=${encodeURIComponent(args)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [script, args, appendLog, logIfMounted]);

	return (
		<AdminSection name="CERT Automation raw script request">
			<AdminLine>
				Script: <AdminTextField onChange={setScript} />,
				args: <AdminTextField onChange={setArgs} />,
				<TextButton label="Trigger" onClick={triggerCertAutomation} />
			</AdminLine>
		</AdminSection>
	)
}

const CodeQlRawSection = ({appendLog}:HasLogAppender) => {
	const {logIfMounted} = useLogIfStillMounted(appendLog);

	const [script, setScript] = useState('');
	const [args, setArgs] = useState('');

	const triggerCodeQl = useCallback(() => {
		if (!script) {
			appendLog('Script is mandatory')
			return;
		}

		appendLog(`Trigger CodeQL script=${script} with args=${args}`);
		const url = `api/admin/codeql/trigger-cloud-run?script=${encodeURIComponent(script)}&args=${encodeURIComponent(args)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [script, args, appendLog, logIfMounted]);

	return (
		<AdminSection name="CodeQL raw script request">
			<AdminLine>
				Script: <AdminTextField onChange={setScript} />,
				args: <AdminTextField onChange={setArgs} />,
				<TextButton label="Trigger" onClick={triggerCodeQl} />
			</AdminLine>
		</AdminSection>
	)
}

const COUNTER_KEY_OPTIONS = [
	{value:COUNTER_KEY_RPU_HOSTING_AUDIT, label:'RPU Hosting'},
	{value:COUNTER_KEY_SECURITY_TASK_VALUE_TRIAGE, label:'SECURITY triage'},
	{value:COUNTER_KEY_SECURITY_TASK_VALUE_LABEL, label:'SECURITY label'},
	{value:COUNTER_KEY_SECURITY_TASK_VALUE_REPRODUCTION, label:'SECURITY reproduction'},
	{value:COUNTER_KEY_SECURITY_TASK_VALUE_ASSIGN, label:'SECURITY assign'},
	{value:COUNTER_KEY_SECURITY_TASK_VALUE_CREDIT, label:'SECURITY credit'},
	{value:COUNTER_KEY_SECURITY_TASK_VALUE_ADVISORY_DATA, label:'SECURITY advisory data'},
	{value:COUNTER_KEY_SECURITY_TASK_VALUE_REVIEW, label:'SECURITY review'},
	{value:COUNTER_KEY_SECURITY_TASK_VALUE_NON_CERT_COMMENT, label:'SECURITY non-CERT comment'},
	{value:COUNTER_KEY_SECURITY_TASK_VALUE_CERT_ATTENTION, label:'SECURITY CERT attention'},
	{value:COUNTER_KEY_SECURITY_TASK_VALUE_DISCLOSURE_DEADLINE, label:'SECURITY disclosure deadline'},
]

const CounterResetSection = ({appendLog}:HasLogAppender) => {
	const {logIfMounted} = useLogIfStillMounted(appendLog);

	const triggerCounterReset = useCallback(() => {
		const select = document.querySelector<HTMLSelectElement>('#counter-key-select');
		const value = select!.value;
		if (!value) {
			appendLog(`Key must be provided`);
			return;
		}
		appendLog(`Trigger counter reset for key=${value}`);
		const url = `api/admin/counter/recount?key=${value}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);

	const triggerCounterResetAll = useCallback(() => {
		appendLog(`Trigger all counter reset`);
		const url = `api/admin/counter/recount-all`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);

	return (
		<AdminSection name="Counter reset">
			<AdminLine>
				<select id="counter-key-select">
					<option value="">-- No value --</option>
					{COUNTER_KEY_OPTIONS.map(({value, label}) => (
						<option value={value} key={label}>{label}</option>
					))}
				</select>
				<TextButton label="Trigger" onClick={triggerCounterReset} />
			</AdminLine>
			<AdminLine>
				<TextButton label="Trigger for all keys" onClick={triggerCounterResetAll} />
			</AdminLine>
		</AdminSection>
	)
}

const HealthCheckSection = ({appendLog}:HasLogAppender) => {
	const {logIfMounted} = useLogIfStillMounted(appendLog);

	const triggerHealthCheck = useCallback(() => {
		appendLog(`Trigger all health check`);
		const url = `api/admin/health-check`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);

	const retrieveHealthCheck = useCallback(() => {
		appendLog(`Retrieve all health check`);
		const url = `api/admin/health-check`;
		apiFetch.get(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);

	return (
		<AdminSection name="Health check">
			<AdminLine>
				<TextButton label="Trigger" onClick={triggerHealthCheck} />
				<TextButton label="Retrieval only" onClick={retrieveHealthCheck} />
			</AdminLine>
		</AdminSection>
	)
}

const TestDevSection = ({appendLog}:HasLogAppender) => {
	const {logIfMounted} = useLogIfStillMounted(appendLog);

	const [key, setKey] = useState('defaultKey');
	const [timeoutInMs, setTimeoutInMs] = useState(30*1000);
	const [durationInMs, setDurationInMs] = useState(10*1000);
	const [numIterations, setNumIterations] = useState(10);
	const [throwIfTimeout, setThrowIfTimeout] = useState(false);
	const [timeoutExpansion, setTimeoutExpansion] = useState(false);

	const triggerLoop = useCallback(() => {
		appendLog(`Trigger loop`);
		const url = `api/admin/test-dev/trigger-loop?key=${encodeURIComponent(key)}&timeoutInMs=${timeoutInMs}&durationInMs=${durationInMs}&numIterations=${numIterations}&throwIfTimeout=${throwIfTimeout}&timeoutExpansion=${timeoutExpansion}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted,  durationInMs, key, numIterations, timeoutInMs, throwIfTimeout, timeoutExpansion]);

	const triggerHandler = useCallback(() => {
		appendLog(`Trigger handler`);
		const url = `api/admin/test-dev/trigger-handler?key=${encodeURIComponent(key)}&timeoutInMs=${timeoutInMs}&durationInMs=${durationInMs}&numIterations=${numIterations}&throwIfTimeout=${throwIfTimeout}&timeoutExpansion=${timeoutExpansion}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted, durationInMs, key, numIterations, timeoutInMs, throwIfTimeout, timeoutExpansion]);

	return (
		<AdminSection name="Lock testing">
			<AdminLine>
				Key: <AdminTextField defaultValue={key} onChange={setKey} />,
				Timeout (ms): <AdminIntegerField defaultValue={timeoutInMs} onChange={setTimeoutInMs} />,
				Duration (ms): <AdminIntegerField defaultValue={durationInMs} onChange={setDurationInMs} />,
			</AdminLine>
			<AdminLine>
				Num iterations: <AdminIntegerField defaultValue={numIterations} onChange={setNumIterations} />,
				Throw if duration&gt;timeout: <AdminBooleanField defaultValue={throwIfTimeout} onChange={setThrowIfTimeout} />,
				Auto expand: <AdminBooleanField defaultValue={timeoutExpansion} onChange={setTimeoutExpansion} />,
			</AdminLine>
			<AdminLine>
				<TextButton label="Trigger loop" onClick={triggerLoop} />
				<TextButton label="Trigger handler" onClick={triggerHandler} />
			</AdminLine>
		</AdminSection>
	)
}
