import {FunctionNM} from "@commons/types/FunctionNM";

export interface CommunicationChannel<T> {
	sendMessage(data:NonNullable<T>):void;

	/**
	 * Meant to be used within a useEffect with its return value being the cleanup method returned by useEffect
	 */
	onMessage(callback:FunctionNM<[NonNullable<T>], void>):FunctionNM<[void], void>;
}

class BroadcastChannelImpl<T> implements CommunicationChannel<T> {
	private channel:BroadcastChannel;

	constructor(type:MessageType<NonNullable<T>>) {
		const typeString = type as unknown as string;
		this.channel = new BroadcastChannel(typeString);
	}

	sendMessage(data:NonNullable<T>):void {
		this.channel.postMessage(data);
	}

	onMessage(callback:FunctionNM<[NonNullable<T>], void>):FunctionNM<[void], void> {
		const handler = (ev:MessageEvent) => {
			const data = ev.data;
			callback(data);
		};
		this.channel.addEventListener('message', handler);
		return () => {
			this.channel.removeEventListener('message', handler);
		};
	}
}

class LocalStorageImpl<T> implements CommunicationChannel<T> {
	private typeString:string;

	constructor(type:MessageType<NonNullable<T>>) {
		this.typeString = type as unknown as string;
	}

	sendMessage(data:NonNullable<T>):void {
		localStorage.setItem(this.typeString, JSON.stringify(data));
		localStorage.removeItem(this.typeString);
	}

	onMessage(callback:FunctionNM<[NonNullable<T>], void>):FunctionNM<[void], void> {
		const handler = (ev:StorageEvent) => {
			if (ev.key === this.typeString) {
				if (ev.newValue !== null) {
					const newValue = JSON.parse(ev.newValue);
					callback(newValue);
				}
			}
		};
		window.addEventListener('storage', handler);
		return () => {
			window.removeEventListener('storage', handler);
		}
	}
}

function supportBroadcast() {
	if (window.BroadcastChannel !== undefined) {
		return true;
	}
	return false;
}

export const communicationBetweenTabService = new class CommunicationBetweenTabService {
	createWithType<T>(type:MessageType<NonNullable<T>>) {
		return supportBroadcast() ? new BroadcastChannelImpl<T>(type) : new LocalStorageImpl<T>(type);
	}
}();

export type MessageType<T> = T;

export function messageTypeCreator<T>(key:string):MessageType<T> {
	// hack to enforce the type checks
	return key as unknown as MessageType<T>;
}
