import React, {useEffect, useState} from "react";
import {CookieHelper} from "../helpers/CookieHelper";
import {useMediaQuery} from "../hooks/useMediaQuery";

import './ThemeProvider.scss';

const DARK_MODE_COOKIE_NAME = 'darkMode';

const DARK_THEME_CSS = 'dark-theme';
const LIGHT_THEME_CSS = 'light-theme';

export const ThemeContext = React.createContext({
	isLightTheme:true,
	themeCss:LIGHT_THEME_CSS,
	changeTheme:(lightMode:boolean) => {
	}
});

type ThemeProviderProps = {
	// https://github.com/typescript-cheatsheets/react#useful-react-prop-type-examples
	classes?:string
	/**
	 * To force a particular theme
	 */
	forceTheme?:'light' | 'dark'
	children:React.ReactNode
};

export const ThemeProvider = (props:ThemeProviderProps) => {
	// console.log(`ThemeProvider props.forceTheme: ${props.forceTheme}`);
	const darkModeFromMediaQuery = useMediaQuery('(prefers-color-scheme: dark)');

	const [isLightTheme, setTheme] = useState(() => {
		let darkMode = false;
		const darkModeFromCookie = CookieHelper.getCookieBoolean(DARK_MODE_COOKIE_NAME);
		if (darkModeFromCookie === undefined) {
			// if no user perference, falling back to the default from the system
			if (darkModeFromMediaQuery) {
				console.log('darkMode from mediaQuery');
				darkMode = true;
			} else {
				// console.log('darkMode default');
			}
		} else {
			console.log('darkMode from cookie');
			darkMode = darkModeFromCookie;
		}

		return !darkMode;
	});

	useEffect(() => {
		// console.log(`ThemeProvider useEffect props.forceTheme: ${props.forceTheme}`);
		if (props.forceTheme) {
			setTheme(props.forceTheme === 'light');
		}
	}, [props.forceTheme]);

	const changeTheme = (lightMode:boolean) => {
		console.log(`ThemeProvider changeTheme: ${lightMode}`);
		if (lightMode) {
			CookieHelper.resetCookie(DARK_MODE_COOKIE_NAME);
		} else {
			CookieHelper.setCookie(DARK_MODE_COOKIE_NAME, "true");
		}
		// const newTheme = lightMode ? DARK_THEME_CSS : LIGHT_THEME_CSS;
		setTheme(lightMode);
	};

	const themeCss = isLightTheme ? LIGHT_THEME_CSS : DARK_THEME_CSS;
	const themeContext = {isLightTheme, themeCss, changeTheme:changeTheme};

	// variables defined in styles.scss
	// const themeCssClass = 'ThemeProvider ' + (theme.palette.type === 'light' ? 'light-theme' : 'dark-theme');
	const themeCssClass = `${props.classes} ThemeProvider ` + themeCss;
	return (
		<ThemeContext.Provider value={themeContext}>
			<div className={themeCssClass}>
				{props.children}
			</div>
		</ThemeContext.Provider>
	);
}
