import {useCallback, useMemo, useState} from "react";
import {QueryGetBody} from "../../framework/crud/CrudApiService2";
import {ApiFetchResponse} from "../../framework/WrappedJsonFetch";
import {GetQueryProvider} from "./useGetQuery";
import {useInputChange} from "./useInputChange";

interface QueryProviderUtilities<T> {
	refresh():void

	forceProvideData(data:T):void
}

export type EnhancedQueryProvider<T> = GetQueryProvider<T> & QueryProviderUtilities<T>

/**
 * @param providerGenerator Is expected to be memoized
 */
export const useCreateQueryProvider = <T>(providerGenerator:GetQueryProvider<T>):EnhancedQueryProvider<T> => {
	if (!(providerGenerator as any).r) {
		(providerGenerator as any).r = Math.random();
	}

	const {count, directData, setRefresh, setDirectData} = useCreateQueryProviderState(providerGenerator);

	const m = useMemo(() => {
		const temp:any = directData ? () => directData : providerGenerator.bind({});
		temp.refresh = () => {
			console.info('refresh called', count);
			setRefresh();
			// setCount(curr => curr + 1);
		};
		temp.forceProvideData = (data:T) => {
			console.info('forceProvideData called');
			setDirectData(data);
		}
		const result:EnhancedQueryProvider<T> = temp;
		return result;
	}, [count, directData, providerGenerator, setDirectData, setRefresh]);

	return m;
}

export class ProviderHelper {
	static wrapSuccess<T>(data:T):ApiFetchResponse<QueryGetBody<T>> {
		return {
			ok:true,
			code:200,
			payload:{
				data:data,
			}
		}
	}
}

type CreateQueryProviderState<T> = { count:number, directData:T | null };

const DEFAULT_STATE:CreateQueryProviderState<any> = {count:0, directData:null};
const useCreateQueryProviderState = <T>(providerGenerator:any) => {
	const [state, setState] = useState<CreateQueryProviderState<T>>(DEFAULT_STATE);

	const setRefresh = useCallback(() => {
		setState(curr => ({...curr, count:curr.count + 1, directData:null}));
	}, []);
	const setDirectData = useCallback((directData:T) => {
		setState(curr => ({...curr, count:curr.count + 1, directData:directData}));
	}, []);
	const reset = useCallback(() => {
		setState(DEFAULT_STATE);
	}, []);

	let {count, directData} = state;

	const {hasChangedSinceLastTime} = useInputChange([providerGenerator]);
	if (hasChangedSinceLastTime) {
		reset();
		count = DEFAULT_STATE.count;
		directData = DEFAULT_STATE.directData;
	}

	return {count, directData, setRefresh, setDirectData, reset};
} 
