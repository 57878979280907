import * as React from 'react';
import {IconType} from 'react-icons';
import {MdCheckCircle, MdCircle, MdDangerous, MdHelp, MdInfo, MdWarning} from 'react-icons/md';
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {withFixedArguments} from '../../../commons/hoc/withFixedArguments';
import {AvailableColor} from './AvailableColor';

import './Row.scss';

type RowProps = {
	children:React.ReactNode
	color:AvailableColor
	icon?:IconType
	className?:string
}

const Row = ({children, color, icon, className}:RowProps) => {
	const Icon = icon;
	return (
		<tr className={ClassHelper.combine('Row', className)}>
			<td colSpan={999}>
				<div className={ClassHelper.combine('row-content', `color-${color}`)}>
					{Icon && (
						<span className="icon"><Icon /></span>
					)}
					<span className="message">
						{children}
					</span>
				</div>
			</td>
		</tr>
	);
}

export const ErrorRow = withFixedArguments(Row, {color:'red', icon:MdDangerous});
export const WarningRow = withFixedArguments(Row, {color:'orange', icon:MdWarning});
export const InfoRow = withFixedArguments(Row, {color:'blue', icon:MdInfo});
export const SuccessRow = withFixedArguments(Row, {color:'green', icon:MdCheckCircle});
export const NeutralRow = withFixedArguments(Row, {color:'grey', icon:MdCircle});
export const UnknownRow = withFixedArguments(Row, {color:'purple', icon:MdHelp});
