import {CommandStatusType} from "@commons/types/CommandStatusType";
import * as React from "react";
import {CrudModel} from "../../../../../commons/types/CrudModel";
import {ClosureColumnDef} from "../../../../../framework/table/columns/ClosureColumnDef";
import {StringColumnDefOps} from "../../../../../framework/table/columns/StringColumnDef";
import {CommandStatus} from "./CommandStatus";

const wrapper = (row:{ commandStatus:CommandStatusType }) => {
	return <CommandStatus status={row.commandStatus} />;
}

export class CommandStatusColumnDef<T extends CrudModel & { commandStatus:CommandStatusType }> extends ClosureColumnDef<T> {
	constructor(opts:StringColumnDefOps = {size:100, sortable:false}) {
		super('commandStatus', 'Status', wrapper, opts);
	}
}
