import * as React from "react";
import {Path, UseFormReturn} from "react-hook-form";
import {useUniqueId} from "../../../commons/hooks/useUniqueId";
import {FormRow, FormRowProps} from "../components/FormRow";

import "./BooleanField.scss"

export type BooleanFieldProps = {
	f:UseFormReturn<any>
	name:Path<any>
	disabled?:boolean
} & FormRowProps;

export const BooleanField = ({f, name, disabled, ...rowProps}:BooleanFieldProps) => {
	const uniqueId = useUniqueId();

	const {register, formState:{errors}} = f
	const fieldError = errors[name];

	return (
		<FormRow {...rowProps} className="BooleanField" errorMessage={fieldError?.message as string} labelId={uniqueId}>
			<input type="checkbox" {...register(name)} id={uniqueId} disabled={disabled} />
		</FormRow>
	);
}
