import {ReactNode} from "react";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {CrudModel} from '../../../commons/types/CrudModel';
import {ColDefType} from "../ColDefType";
import {ColSizeType} from "../ColSizeType";
import {CrudTableColumnDefinition} from "../CrudTableColumnDefinition";

export type FloatColumnDefOps = {
	defaultVisibility?:boolean, sortable?:boolean, fixed?:boolean,
	cellClassName?:string, headerClassName?:string, size?:ColSizeType
	title?:string
}

export class FloatColumnDef<T extends CrudModel> implements CrudTableColumnDefinition<T> {
	type:ColDefType = 'float';

	defaultVisibility:boolean;
	sortable:boolean;
	fixed:boolean;
	cellClassName?:string;
	headerClassName?:string;
	headerTitle?:string;

	constructor(public name:string,
	            public header:string,
	            private rowToCell:((row:T) => string | number),
	            opts:FloatColumnDefOps = {}) {
		const {
			defaultVisibility, sortable, fixed,
			cellClassName, headerClassName, size,
			title
		} = opts;
		this.defaultVisibility = defaultVisibility ?? true;
		this.sortable = sortable ?? true;
		this.fixed = fixed ?? false;
		this.headerTitle = title;
		this.cellClassName = ClassHelper.combine(cellClassName, size && `column-size-${size}`);
		this.headerClassName = ClassHelper.combine(headerClassName, size && `column-size-${size}`);
	}

	nowrap():this {
		this.cellClassName = 'nowrap';
		return this;
	}

	computeCell(row:T):ReactNode {
		return (
			<span>{this.rowToCell(row)}</span>
		);
	}
}
