import * as React from 'react';
import {ComponentType} from 'react';

// source https://react-typescript-cheatsheet.netlify.app/docs/hoc/full_example/
export function withFixedArguments<
	TInjectedKeys extends keyof TBaseProps,
	TBaseProps,
>(
	Component:ComponentType<TBaseProps>,
	fixedArguments:Pick<TBaseProps, TInjectedKeys>
):ComponentType<Omit<TBaseProps, TInjectedKeys>> {
	const WrappedComponent = (hocProps:Omit<TBaseProps, TInjectedKeys>) => {
		return (
			<Component {...hocProps as TBaseProps} {...fixedArguments} />
		)
	}
	WrappedComponent.displayName = `withFixedArguments(${Component.displayName})`;
	return WrappedComponent;
}
