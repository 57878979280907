import {FunctionNM} from "@commons/types/FunctionNM";
import {useCallback} from "react";

export type OnClickHandler<CArgs> = OnClickNope | OnClickWithoutArgs | OnClickWithArgs<CArgs>;
type OnClickNope = {onClick:never, onClickArgs:never}
type OnClickWithoutArgs = {onClick?:FunctionNM<[], void>, onClickArgs:never}
type OnClickWithArgs<CArgs> = {onClick?:FunctionNM<[CArgs], void>, onClickArgs?:CArgs};

type UseOnClickProps = {disabled?: boolean} &  OnClickHandler<any>

export const useOnClick = (props:UseOnClickProps) => {
	const {onClick, onClickArgs, disabled} = props;
	delete props.onClick;
	delete props.onClickArgs;

	const handleClick = useCallback(() => {
		if (disabled) {
			return undefined;
		}
		if (onClick) {
			onClick(onClickArgs);
			// if (onClickArgs) {
			// 	onClick(onClickArgs);
			// }else{
			// 	onClick();
			// }
		}
	}, [onClick, onClickArgs, disabled]);
	
	return {handleClick};
}
