import * as React from "react";
import {useMemo} from "react";
import {QueryResponse} from "../../commons/hooks/useGetQuery";
import {CrudModel} from '../../commons/types/CrudModel';
import {CacheWhenCondition} from "../components/CacheWhenCondition";
import {Placeholder} from "../components/misc/Placeholder";
import {QueryListBody} from "../crud/CrudApiService2";
import {PaginationInfo} from "../crud/features/PaginationFeature";
import {CrudPage2Dispatcher} from "../crud2/CrudPage2";
import {TablePaginationCursor} from "./pagination/TablePaginationCursor";

export type PaginationUsingCursorProps<T extends CrudModel> = {
	pagination:PaginationInfo
	// onPaginationChange:PaginationInfoChangeHandler

	dispatch:CrudPage2Dispatcher<T>

	itemsData:QueryResponse<QueryListBody<T>>

	/** If there is no way to get the filtered total number, do not pass value */
	filteredTotalResults?:number
	/** If there is no way to get the total number, do not pass value */
	totalResults?:number
}

/**
 * As there is no easy count in Firestore, plus the recommendation to avoid offset, a specialized pagination is used
 */
export const PaginationUsingCursor = <T extends CrudModel>({
	                                                         pagination, dispatch, totalResults, itemsData
                                                         }:PaginationUsingCursorProps<T>) => {
	const paginationPanel:JSX.Element = useMemo(() => {
		// console.info('PaginationUsingCursor useMemo', pagination, totalResults);
		if (totalResults === undefined) {
			return (
				<CacheWhenCondition condition={itemsData.loading}
				                    defaultValue={<Placeholder width={300} height={40} padding={5} />}>
					{/*<TablePaginationWithoutTotal pagination={pagination} onPaginationChange={handlePaginationChange} />*/}
					<TablePaginationCursor pagination={pagination} dispatch={dispatch} itemsData={itemsData} />
				</CacheWhenCondition>
			)
		} else {
			return (
				//TODO add information that it's the total, so adding a "?" somewhere
				<CacheWhenCondition condition={itemsData.loading}
				                    defaultValue={<Placeholder width={300} height={40} padding={5} />}>
					{/*<TablePagination totalResults={totalResults} pagination={pagination}*/}
					{/*                 onPaginationChange={handlePaginationChange} />*/}
					<TablePaginationCursor pagination={pagination} dispatch={dispatch} itemsData={itemsData} />
				</CacheWhenCondition>
			)
		}
	}, [dispatch, itemsData, pagination, totalResults]);

	return paginationPanel;
}
