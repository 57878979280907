import {MODEL_GPT_4_TURBO, MODEL_GPT_4o} from "@commons/constants/OpenAiConstants";
import {OpenAiJiraTriage} from "@commons/models/OpenAiJiraTriage";
import {WithDbAndId} from "@commons/types/DbType";
import {FunctionHelper} from "@commons/types/FunctionHelper";
import * as React from "react";
import {useMemo} from "react";
import {MdRefresh} from "react-icons/md";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {useCreateQueryProvider} from "../../../../commons/hooks/useCreateQueryProvider";
import {GetQueryProviderResult} from "../../../../commons/hooks/useGetQuery";
import {useParamsFromRoute} from "../../../../commons/hooks/useParamsFromRoute";
import {Card} from "../../../../framework/components/Card";
import {Divider} from "../../../../framework/components/Divider";
import {ExternalLink} from "../../../../framework/components/ExternalLink";
import {IconButton} from "../../../../framework/components/IconButton";
import {DetailsRow} from "../../../../framework/components/layout/elements/DetailsRow";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {MetaInfoIcon} from "../../../../framework/components/MetaInfoIcon";
import {RefreshableQueryDisplay} from "../../../../framework/components/RefreshableQueryDisplay";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {JiraUrlHelper} from "@commons/helpers/JiraUrlHelper";

type Model = WithDbAndId<OpenAiJiraTriage>;

const crudService = new class ReleaseBlockService extends AbstractApiService<Model> {
    constructor() {
        super('api/user/jira-triage');
    }
}();

type PageParams = {
    itemId: string;
};

export const JiraTriageDetailsPage = () => {
    const {itemId} = useParamsFromRoute<PageParams>();

    const location: any = useLocation();

    const itemFromPrevPage: Model | undefined = location?.state?.item;
    const fromView = location?.state?.fromView;

    const itemQueryProvider = useCreateQueryProvider<{ data: Model }>(
        useMemo(() => {
            //TODO if A => B => A, the value will not be requested the first time
            // in the case an update on A occurs after A => B, the first value of A after B => A, will be the state one

            if (itemFromPrevPage && itemFromPrevPage.id === itemId) {
                const firstValue = {data: itemFromPrevPage};
                const secondValue = () => crudService.getOne(itemId);

                return FunctionHelper.buildSuccessiveFunction<GetQueryProviderResult<{
                    data: Model
                }>>(firstValue, secondValue);
            } else {
                return () => crudService.getOne(itemId);
            }
        }, [itemId, itemFromPrevPage])
    );

    // const handleMarkAsCompleteClick = useCallback(({itemId, refresh}:{
    // 	itemId:string,
    // 	refresh:FunctionNM<[], void>
    // }) => {
    // 	if (!window.confirm('Are you sure to mark as complete this block?')) {
    // 		return;
    // 	}
    //
    // 	const url = `api/user/release-block/${encodeURIComponent(itemId)}/release-unblocked-date`;
    // 	apiFetch.patch(url).then(refresh);
    // }, []);

    return (
        <PageContent className="JiraTriageDetailsPage" withoutPadding={true}>
            <Card className="NavigationRow">
                {fromView && (<>
                    <NavLink to={fromView}>
                        Back to previous view
                    </NavLink>
                </>)}
            </Card>

            <Card>
                {/*TODO add details about the subQueries*/}

                <RefreshableQueryDisplay
                    queryProvider={itemQueryProvider}
                    render={({data}, refresh) => (<>
                        <Card.TitleAndActions
                            title={`[${data.ticketKey}] ${data.summary}`} size="big"
                            leftChildren={(<>
                                {/*{data.releaseUnblockedDate === null && (*/}
                                {/*	<TextButton label={"Mark as complete"} onClick={handleMarkAsCompleteClick}*/}
                                {/*	            onClickArgs={{itemId:data.id, refresh}} type="primary" icon={MdCheck} />*/}
                                {/*)}*/}
                            </>)}
                            rightChildren={(<>
                                <MetaInfoIcon item={data}/>
                                <IconButton icon={MdRefresh} onClick={refresh} title="Refresh the ticket"/>
                            </>)}
                        />

                        <DetailsRow label="Author" value={data.author}/>
                        <DetailsRow label="Ticket" value={data.ticketKey ? (
                            <ExternalLink
                                url={JiraUrlHelper.linkToTicket(data.ticketKey)}>{data.ticketKey}</ExternalLink>
                        ) : null}/>

                        <DetailsRow label="Summary" value={data.summary}/>

                        <Divider/>
                        <DetailsRow label="Average score" value={data.averageScore} valueInlineCode={true}/>
                        <DetailsRow label="Category" value={data.category}/>
                        <DetailsRow label="Justification" value={data.justification}/>
                        <DetailsRow label="Number of answers requested" value={data.numAnswersRequested}/>

                        <Divider/>
                        <DetailsRow label="Model" value={data.model}/>
                        <DetailsRow label="Duration (ms)" value={data.duration} valueInlineCode={true}/>
                        <DetailsRow label="Total (in tokens)" value={data.cost?.totalTokens}
                                    valueInlineCode={true}/>
                        <DetailsRow label="Prompt (in tokens)" value={data.cost?.promptTokens}
                                    valueInlineCode={true}/>
                        <DetailsRow label="Response (in tokens)" value={data.cost?.responseTokens}
                                    valueInlineCode={true}/>

                        <DetailsRow label="Estimated cost"
                                    title={(
                                        ({
                                            [MODEL_GPT_4o]: '0.5¢ per 1k tokens in prompt, 1.5¢ per 1k tokens in response',
                                            [MODEL_GPT_4_TURBO]: '1¢ per 1k tokens in prompt, 3¢ per 1k tokens in response',
                                        } as any)[data.model] || '3¢ per 1k tokens in prompt, 6¢ per 1k tokens in response'
                                    )}
                                    value={(
                                        ({
                                            [MODEL_GPT_4o]: `${((data.cost?.promptTokens || 0) * 0.000005 + (data.cost?.responseTokens || 0) * 0.000015).toFixed(2)}$`,
                                            [MODEL_GPT_4_TURBO]: `${((data.cost?.promptTokens || 0) * 0.00001 + (data.cost?.responseTokens || 0) * 0.00003).toFixed(2)}$`,
                                        } as any)[data.model] || `${((data.cost?.promptTokens || 0) * 0.00003 + (data.cost?.responseTokens || 0) * 0.00006).toFixed(2)}$`
                                    )}
                                    valueInlineCode={true}/>

                        {/*<hr />*/}
                        {/*<pre>{JSON.stringify(data, null, 3)}</pre>*/}
                    </>)}
                />
            </Card>
        </PageContent>
    )
}
