// Inspired by https://github.com/mui-org/material-ui/blob/59130fd63e1e321ac75738ecc06d9ea7c8761b61/docs/src/modules/utils/helpers.js
// Inspired by https://vanillajstoolkit.com/helpers/getcookie/
export class CookieHelper {
	static getCookie(name:string, defaultValue:string | undefined = undefined):string | undefined {
		if (document) {
			const value = `; ${document.cookie}`;
			const parts = value.split(`; ${name}=`);
			if (parts.length === 2) {
				return parts.pop()!.split(';').shift();
			}
		}
		return defaultValue;
	}

	static getCookieBoolean(name:string, defaultValue:boolean | undefined = undefined):boolean | undefined {
		const value = this.getCookie(name);
		if (value === 'true') {
			return true;
		} else if (value === 'false') {
			return false;
		}
		return defaultValue;
	}

	static setCookie(name:string, value:string):void {
		document.cookie = `${name}=${value};path=/;max-age=31536000`;
	}

	static resetCookie(name:string):void {
		document.cookie = `${name}=;path=/;max-age=0`;
	}
}
