import {useMemo} from "react";
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {CrudModel} from '../../commons/types/CrudModel';
import {CrudPage2Dispatcher} from "../crud2/CrudPage2";
import {CrudTableColumnDefinition} from "./CrudTableColumnDefinition";

type Props<T extends CrudModel> = {
	columnDef:CrudTableColumnDefinition<T>
	row:T
	//TODO remove the ? when Table is removed
	// dispatch:CrudPage2Dispatcher<any>
	dispatch?:CrudPage2Dispatcher<T>
}

export const TableCell = <T extends CrudModel>({columnDef, row, dispatch}:Props<T>) => {
	const cellContent = useMemo(() => {
		// return columnDef.computeCell(row, dispatch)
		return columnDef.computeCell(row, dispatch!)
	}, [columnDef, row, dispatch]);

	return (
		<td className={ClassHelper.combine(`column-${columnDef.name}`, `cell-${columnDef.name}`, columnDef.cellClassName)}>{cellContent}</td>
	);
}
