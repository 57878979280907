import * as React from "react";
import {useCallback, useMemo} from "react";
import {CrudModel} from '../../commons/types/CrudModel';
import {CacheWhenCondition} from "../components/CacheWhenCondition";
import {Placeholder} from "../components/misc/Placeholder";
import {PaginationInfo} from "../crud/features/PaginationFeature";
import {CrudPage2Dispatcher} from "../crud2/CrudPage2";
import {TablePagination} from "./pagination/TablePagination";
import {TablePaginationWithoutTotal} from "./pagination/TablePaginationWithoutTotal";

export type Pagination2Props<T extends CrudModel> = {
	pagination:PaginationInfo
	// onPaginationChange:PaginationInfoChangeHandler

	dispatch:CrudPage2Dispatcher<T>

	/** If there is no way to get the filtered total number, do not pass value */
	filteredTotalResults?:number
	/** If there is no way to get the total number, do not pass value */
	totalResults?:number
	hasNext?:boolean
}
//TODO have a pagination for when there is a total but no filteredTotal
export const Pagination2 = <T extends CrudModel>({
	                                                 pagination, dispatch, filteredTotalResults, totalResults
                                                 }:Pagination2Props<T>) => {
	const handlePaginationChange = useCallback((newPagination:PaginationInfo) => dispatch({
		type:'setPagination',
		pagination:newPagination
	}), [dispatch]);

	const paginationPanel:JSX.Element = useMemo(() => {
		console.info('Pagination2 useMemo', pagination, filteredTotalResults, totalResults);
		if (filteredTotalResults === undefined) {
			if (totalResults === undefined) {
				return (
					<TablePaginationWithoutTotal pagination={pagination} onPaginationChange={handlePaginationChange} />
				)
			} else {
				return (
					//TODO add information that it's the total, so adding a "?" somewhere
					<CacheWhenCondition condition={totalResults === -1}
					                    defaultValue={<Placeholder width={300} height={40} padding={5} />}>
						<TablePagination totalResults={totalResults} pagination={pagination}
						                 onPaginationChange={handlePaginationChange} />
					</CacheWhenCondition>
				)
			}
		} else {
			return (
				<CacheWhenCondition condition={filteredTotalResults === -1}
				                    defaultValue={<Placeholder width={300} height={40} padding={5} />}>
					<TablePagination totalResults={filteredTotalResults} pagination={pagination}
					                 onPaginationChange={handlePaginationChange} />
				</CacheWhenCondition>
			);
		}
	}, [filteredTotalResults, pagination, totalResults, handlePaginationChange]);

	return paginationPanel;
}
// export type Pagination2Props<T extends CrudModel> = {
// 	pagination:PaginationInfo
// 	onPaginationChange:PaginationInfoChangeHandler
// 	filteredTotalResults:number
// 	totalResults:number
// }

// CrudTableComponent<CrudModel> = ({
// export const Table:CrudTableComponent<CrudModel> = <T extends CrudModel>({
// export const Table2:CrudTableComponent<CrudModel> = <T extends CrudModel>({
// export const Pagination2 = <T extends CrudModel>({
// 	                                                 pagination, onPaginationChange, filteredTotalResults, totalResults
//                                                  }:Pagination2Props<T>) => {
// 	const paginationPanel:JSX.Element = useMemo(() => {
// 		// if (!filteredTotalResults) {
// 		if (!totalResults) {
// 			return <span />;
// 		}
// 		console.info('Pagination2 useMemo', pagination, filteredTotalResults, totalResults);
// 		return (
// 			<CacheWhenCondition condition={totalResults === -1}
// 			                    defaultValue={<Placeholder width={300} height={40} padding={5} />}>
// 				<TablePagination totalResults={totalResults} pagination={pagination}
// 				                 onPaginationChange={onPaginationChange} />
// 			</CacheWhenCondition>
// 		// <CacheWhenCondition condition={filteredTotalResults === -1}
// 		//                     defaultValue={<Placeholder width={300} height={40} padding={5} />}>
// 		// 	<TablePagination totalResults={filteredTotalResults} pagination={pagination}
// 		// 	                 onPaginationChange={onPaginationChange} />
// 		// </CacheWhenCondition>
// 		);
// 	}, [filteredTotalResults, pagination, totalResults, onPaginationChange]);
//
// 	return paginationPanel;
// }
