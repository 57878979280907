import {ReactNode, useEffect, useState} from "react";

type CacheWhenNullProps = {
	children:ReactNode
	condition:boolean
	defaultValue?:JSX.Element
}

const emptyDiv:JSX.Element = <div />;

export const CacheWhenCondition = ({children, condition, defaultValue}:CacheWhenNullProps) => {
	const [cache, setCache] = useState<JSX.Element>(() => {
		// console.info(`CacheWhenNull default render`);
		if (defaultValue) {
			return defaultValue;
		} else {
			return emptyDiv;
		}
		// return defaultValue ?? emptyDiv;
		// return emptyDiv;
	});

	// console.info(`CacheWhenCondition render condition=${condition}`);

	useEffect(() => {
		if (!condition) {
			// console.info(`CacheWhenNull render cache`);
			const rendered = <>{children}</>;

			setCache(rendered);
		}
	}, [children, condition]);

	return (
		condition ? (
			cache
		) : (
			<>{children}</>
		)
	)
}
