import * as React from "react";
import {MdCallSplit, MdChatBubbleOutline} from "react-icons/md";
import {Navigate, Route, Routes} from "react-router-dom";
import {useAuth} from "../../../commons/hooks/useAuth";
import {Divider} from "../../../framework/components/Divider";
import {Section} from '../../../framework/components/layout/Section';
import {ProtectedRoute} from "../../../framework/components/ProtectedRoute";
import {SideNavLink} from '../../nav/side/elements/SideNavLink';
import {SideNav} from '../../nav/side/SideNav';
import {ChatThreadPage} from "./pages/ChatThreadPage";
import {JiraTriageDetailsPage} from "./pages/JiraTriageDetailsPage";
import {JiraTriageListPage} from "./pages/JiraTriageListPage";
import {JiraTriageToolingPage} from "./pages/JiraTriageToolingPage";

export const AiSection = () => {
	return (
		<Section sideNav={<AiSideNav />}>
			<Routes>
				<Route path="chat" element={<ChatThreadPage />} />

				<Route path="jira-triage"
				       element={<ProtectedRoute requiresUser={true}><JiraTriageListPage /></ProtectedRoute>} />
				<Route path="jira-triage-tooling"
				       element={<ProtectedRoute requiresUser={true}><JiraTriageToolingPage /></ProtectedRoute>} />

				<Route path="jira-triage/:itemId"
				       element={<ProtectedRoute requiresUser={true}><JiraTriageDetailsPage /></ProtectedRoute>} />

				<Route path="*" element={<Navigate to="chat" replace={true} />} />
			</Routes>
		</Section>
	);
}

const AiSideNav = () => {
	const {authInfo} = useAuth();

	return (
		<SideNav classes="AiSideNav">
			<SideNavLink name="ChatGPT (beta)" to="chat" icon={MdChatBubbleOutline} />

			{authInfo?.isUser && (<>
				<Divider />
				<SideNavLink name="Jira triage list" to="jira-triage" icon={MdCallSplit} />
				<SideNavLink name="Jira triage tooling" to="jira-triage-tooling" icon={MdCallSplit} />

				{/*<Divider />*/}
				{/*<SideNavLink name="Artifactory releases" to="artifactory-release" icon={MdOutlineInventory2} />*/}
				{/*<SideNavLink name="Artifactory tooling" to="artifactory-tooling" icon={MdOutlineBuild} />*/}

				{/*<pre>{JSON.stringify(counterMap, null, 3)}</pre>*/}
			</>)}
		</SideNav>
	);
}
