import {COMMAND_AUDIT_FINDINGS, COMMAND_AUDIT_OK, COMMAND_AUDIT_SKIP} from "@commons/constants/CommandAuditConstants";
import {DateHelper} from "@commons/helpers/DateHelper";
import {Task_RpuIssueHostingAudit} from "@commons/models/TaskRpuIssueHostingAudit";
import {WithDbAndId} from "@commons/types/DbType";
import {DateTime} from "luxon";
import * as React from 'react';
import {useCallback, useContext} from 'react';
import {MdCheck, MdClose, MdOutlineSwapCalls, MdPerson, MdPersonOff, MdRefresh} from "react-icons/md";
import {useAuth} from "../../../../commons/hooks/useAuth";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {apiFetch} from "../../../../framework/apiFetch";
import {InfoPanel} from "../../../../framework/components/color/Panel";
import {IconButton} from "../../../../framework/components/IconButton";
import {TextButton} from "../../../../framework/components/TextButton";
import {ToggleGroup} from "../../../../framework/components/ToggleGroup";
import {QueryGetBody} from "../../../../framework/crud/CrudApiService2";
import {
	action_resetFilter,
	CrudPage2,
	CrudPage2Dispatcher,
	CrudPage2State,
	CrudPageLayout,
	CrudPageLayoutBasic
} from "../../../../framework/crud2/CrudPage2";
import {BooleanColumnDef} from "../../../../framework/table/columns/BooleanColumnDef";
import {DateColumnDef} from "../../../../framework/table/columns/DateColumnDef";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {ExternalLinkColumnDef} from "../../../../framework/table/columns/ExternalLinkColumnDef";
import {IconColumnDef} from "../../../../framework/table/columns/IconColumnDef";
import {MetaInfoColumnDef} from "../../../../framework/table/columns/MetaInfoColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {Pagination2} from "../../../../framework/table/Pagination2";
import {Table2} from "../../../../framework/table/Table2";
import {PriorityColumnDef} from "../commons/PriorityColumnDef";
import {AbstractTaskApiService, TaskHelper} from "../commons/TaskHelper";
import {TaskStatusColumnDef} from "../commons/TaskStatusColumnDef";
import {TaskCounterContext} from "../commons/TaskCounterContext";

const crudService = new class TaskRpuIssueHostingAuditApiService extends AbstractTaskApiService<Task_RpuIssueHostingAuditModel> {
	constructor() {
		super(`api/user/task/rpu-issue-hosting-audit`);
	}

	forceSync(taskId:string) {
		console.info(`forceSync(${taskId})`);
		const url = `${this.urlFragmentPart}/${taskId}/force-sync`;

		return apiFetch.post<QueryGetBody<{ task:Task_RpuIssueHostingAuditModel }>>(url);
	}
}();

const dateToCount = (row:Task_RpuIssueHostingAuditModel) => {
	const dateRequested = row.clockStartingDate!;
	const dateDoneOrAudit = DateHelper.minOfDates(row.firstAuditCompletedDate, row.doneDate) || new Date().toISOString();
	if (dateRequested === null) {
		return '';
	}
	if (dateRequested > dateDoneOrAudit) {
		console.info('Requested > done for', row.targetId, dateRequested, dateDoneOrAudit);
		return '';
	}
	const duration = DateTime.fromISO(dateRequested).diff(DateTime.fromISO(dateDoneOrAudit), ['days', 'hours']);
	if (duration.days < -40000) {
		// we got the default value
		return '';
	}
	let result:string;
	const hours = Math.floor(-duration.hours);
	if (hours) {
		if (duration.days) {
			result = `${-duration.days}D ${hours}H`;
		} else {
			result = `${hours}H`
		}
	} else {
		result = `${-duration.days}D`;
	}
	return result;
}

export type Task_RpuIssueHostingAuditModel = WithDbAndId<Task_RpuIssueHostingAudit>;

export const TaskRpuIssueHostingAuditPage = () => {
	const {dispatch:counterDispatch} = useContext(TaskCounterContext);

	const {columnDefs, initialState} = useColumnDefs<Task_RpuIssueHostingAuditModel>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'ID', row => row.id + '', {defaultVisibility:false, sortable:false}).nowrap(),
		new StringColumnDef('targetIdNum', 'Issue', row => row.targetId ? `#${row.targetId}` : '', {
			size:70,
			sortable:true
		}),
		new ExternalLinkColumnDef('openLink', row => `https://github.com/jenkins-infra/repository-permissions-updater/issues/${row.targetId}`),
		new StringColumnDef('name', 'Name', row => row.name || '', {size:'expand', sortable:false}),
		new TaskStatusColumnDef(),
		//TODO add label style

		new StringColumnDef('taskSubStatusNum', 'Substatus', row => row.taskSubStatus || '', {size:150, sortable:true}),
		new BooleanColumnDef('taskDone', 'Done', row => row.taskDone, {sortable:false, title:'JCA Task done'}),
		new BooleanColumnDef('hasNewComment', 'New activity', row => row.hasNewComment, {
			size:110,
			sortable:false,
			title:'The issue has new comments since last CERT comment'
		}),
		new BooleanColumnDef('skip', 'Skipped', row => row.skip, {
			size:70,
			sortable:false,
			title:'Audit was skipped',
			defaultVisibility:false
		}),
		new BooleanColumnDef('closed', 'Closed', row => row.issueClosed, {
			sortable:false,
			title:'GitHub issue is closed'
		}),
		// new IntegerColumnDef('stepProgress', 'Progress', row => row.stepProgress),
		new StringColumnDef('assignee', 'Assignee', row => row.assignee || '', {size:150, sortable:false}),
		//TODO put some color with a closureColumnDef
		new PriorityColumnDef(),
		new StringColumnDef('clockStartingDate', 'Days open', dateToCount, {
			sortable:true,
			size:100,
			title:'Time since the issue was waiting for an audit. The time starts when the label "bot-check-complete" is added. D = days, H = hours.',
		}),
		new DateColumnDef('clockStartingDate_date', 'Requested', DateTime.DATE_MED, row => row.clockStartingDate!, {
			sortable:false,
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			defaultVisibility:false,
		}),
		new DateColumnDef('doneDate', 'Done at', DateTime.DATE_MED, row => row.doneDate, {
			sortable:false,
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			defaultVisibility:false,
		}),
		new MetaInfoColumnDef(),
		new DateColumnDef('createdDate', 'Created', DateTime.DATE_MED, row => row.createdDate, {
			sortable:false,
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			defaultVisibility:false,
		}),
		new DateColumnDef('updatedDate', 'Updated', DateTime.DATE_MED, row => row.updatedDate, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
		}),
		new IconColumnDef('forceSync', 'Sync with GitHub', MdOutlineSwapCalls, forceSync, {}, {iconClassName:'hover-info'}),
		...TaskHelper.defaultActionIcons(crudService, counterDispatch),
		// DefaultColumnFactory.EDIT_REQUIREMENT,
		// DefaultColumnFactory.DELETE_REQUIREMENT,
	], {initialOrder:'clockStartingDate', initialOrderDirection:'desc', initialFilters:['taskDone:b:eq:false']});
	// ]);

	const {authInfo} = useAuth();

	const filterAssign = useCallback((state:CrudPage2State):boolean | null => {
		const currFilterActive = state.filters.find((f:string) => f.startsWith('assignee:'));
		if (currFilterActive === 'assignee:s:eq:' + authInfo!.login) {
			return true;
		} else if (currFilterActive === 'assignee:s:eq:null') {
			return false;
		} else {
			return null;
		}
	}, [authInfo]);

	const filterAssignChange = useCallback((newValue:boolean | null, state:CrudPage2State, dispatch:CrudPage2Dispatcher) => {
		const currValue = filterAssign(state);
		if (currValue === newValue) {
			dispatch({type:'changeFilters', removeFields:['assignee']})
			return;
		}
		if (newValue === true) {
			//TODO put @me or similar
			dispatch({type:'changeFilters', addFilters:['assignee:s:eq:' + authInfo!.login]})
		} else {
			dispatch({type:'changeFilters', addFilters:['assignee:s:eq:null']})
		}
	}, [authInfo, filterAssign]);

	return (
		<CrudPage2 pageId="task-rpu-issue-hosting-audit" pageClass="TaskRpuIssueHostingAuditPage"
		           crudService={crudService}
		           initialState={initialState}
		           layout={useCallback((state, itemsData, dispatch) => (
			           <CrudPageLayoutBasic
				           pageName="RPU Hosting"
				           //     topLeftActions={<>
				           //     <IconButton icon={MdRefresh} title="Refresh data"
				           //                 onClick={() => dispatch({type:'reloadContent'})} />
				           // </>} 
				           //     topRightActions={<>
				           //     <span>Right</span>
				           // </>} 
				           topLeftActions={<>
					           <IconButton icon={MdRefresh} title="Refresh data"
					                       onClick={() => dispatch({type:'reloadContent'})} />

					           <InfoPanel size="small">Completed when the audit is done.
						           Commands: {COMMAND_AUDIT_OK}, {COMMAND_AUDIT_SKIP} or {COMMAND_AUDIT_FINDINGS}</InfoPanel>
				           </>}
				           topRightActions={<>
					           <TextButton label="Reset filter" onClick={action_resetFilter} onClickArgs={dispatch} />

					           <ToggleGroup label="Done" value={filterDone(state)}
					                        onChange={(newValue) => filterDoneChange(newValue, state, dispatch)}
					                        items={[
						                        {
							                        value:true,
							                        icon:MdCheck,
							                        activeClassName:'icon-within-green',
							                        title:'Only display completed tasks'
						                        },
						                        {
							                        value:false,
							                        icon:MdClose,
							                        activeClassName:'icon-within-red',
							                        title:'Only display uncompleted tasks'
						                        },
					                        ]} />
					           <ToggleGroup label="Assignee" value={filterAssign(state)}
					                        onChange={(newValue) => filterAssignChange(newValue, state, dispatch)}
					                        items={[
						                        {
							                        value:true,
							                        icon:MdPerson,
							                        activeClassName:'icon-within-blue',
							                        title:'Only display tasks assigned to me'
						                        },
						                        {
							                        value:false,
							                        icon:MdPersonOff,
							                        activeClassName:'icon-within-red',
							                        title:'Only display unassigned tasks'
						                        },
					                        ]} />
				           </>}
				           table={<>
					           <Table2 columnDefs={columnDefs} className="fluid"
					                   dataState={itemsData} state={state} dispatch={dispatch} />
				           </>} bottomLeftAction={<>
				           <div>Selection: {state.selection.length} (TODO)</div>
			           </>} bottomRightAction={<>
				           {/*<PaginationUsingCursor pagination={state.pagination} itemsData={itemsData}*/}
				           {/*                       dispatch={dispatch} />*/}
				           <Pagination2 pagination={state.pagination} dispatch={dispatch}
				                        filteredTotalResults={itemsData.data?.meta?.filteredTotal}
				                        totalResults={itemsData.data?.meta?.total}
				           />
			           </>} />
		           ), [columnDefs, filterAssign, filterAssignChange]) as CrudPageLayout<Task_RpuIssueHostingAuditModel>}
		/>
	)
}

//TODO two requests instead of a single one, will require the dispatch to have a reload row to optimize this
const forceSync = ({row, dispatch}:IconColumnDef.OnClickArgs<Task_RpuIssueHostingAuditModel>) => {
	crudService.forceSync(row.id)
		.then(value => {
			console.info('forceSync', value);
			if (value.ok) {
				dispatch({type:'reloadContent'})
			}
		});
};

const filterDone = (state:CrudPage2State):boolean | null => {
	const currFilterActive = state.filters.find((f:string) => f.startsWith('taskDone:'));
	if (currFilterActive === 'taskDone:b:eq:true') {
		return true;
	} else if (currFilterActive === 'taskDone:b:eq:false') {
		return false;
	} else {
		return null;
	}
}
const filterDoneChange = (newValue:boolean | null, state:CrudPage2State, dispatch:CrudPage2Dispatcher) => {
	const currValue = filterDone(state);
	if (currValue === newValue) {
		dispatch({type:'changeFilters', removeFields:['taskDone']})
		return;
	}
	if (newValue === true) {
		dispatch({type:'changeFilters', addFilters:['taskDone:b:eq:true']})
	} else {
		dispatch({type:'changeFilters', addFilters:['taskDone:b:eq:false']})
	}
}
