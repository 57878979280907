import {UserIdentity} from "@commons/models/UserIdentity";
import Cookies from 'js-cookie';
import React, {ReactNode, useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";

type SetAuthInfoArgType =
	| { authInfo:AuthInfo | null, from?:string, couldHaveToken?:undefined, userIdentity?:UserIdentity, justAuthInfo?:undefined, justFrom?:undefined }
	| { authInfo?:undefined, from?:undefined, couldHaveToken:true, userIdentity?:undefined, justAuthInfo?:undefined, justFrom?:undefined }
	| { authInfo:AuthInfo | null, from?:undefined, couldHaveToken?:undefined, userIdentity?:undefined, justAuthInfo:true, justFrom?:undefined}
	| { authInfo?:undefined, from?:string, couldHaveToken?:undefined, userIdentity?:undefined, justAuthInfo?:undefined, justFrom:true}
	;

type AuthProviderContent = {
	authInfo:AuthInfo | null
	userIdentity:UserIdentity | null
	setAuthInfo:(args:SetAuthInfoArgType) => void
	couldHaveJwtCookie:boolean
	getFrom:() => string
}

export const AuthContext = React.createContext<AuthProviderContent>({
	authInfo:null,
	userIdentity:null,
	setAuthInfo:(args:SetAuthInfoArgType) => {
		console.info('AuthContext uninitialized setAuthInfo', args);
	},
	couldHaveJwtCookie:false,
	getFrom:():string => {return '';}
});

export type AuthInfo = {
	login:string
	fullName:string

	/** Has access to the general AI features */
	hasAiAccess:boolean
	/** Authorized to use the application */
	isUser:boolean
	isAdmin:boolean
}

type AuthProviderState = {
	authInfo:AuthInfo | null,
	userIdentity:UserIdentity | null
	couldHaveJwtCookie:boolean
}

function setCookieValue(key:string, value:any) {
	const isSecure = window.location.protocol === 'https:';
	const opts:Cookies.CookieAttributes = {};
	if (isSecure) {
		opts.sameSite = 'none';
		opts.secure = true;
	} else {
		opts.sameSite = 'lax';
	}
	Cookies.set(key, value, opts);
	localStorage.setItem(key, value);
}

function removeCookie(key:string) {
	Cookies.remove(key);
	localStorage.removeItem(key);
}

function getCookieValue(key:string):any {
	return Cookies.get(key) || localStorage.getItem(key);
}

const COOKIE_NAME_AUTH_FROM = 'auth-from';
const COOKIE_NAME_COULD_HAVE_AUTH = 'could-have-auth';

type Props = {
	children:ReactNode
}
export const AuthProvider = ({children}:Props) => {
	const [fullState, setFullState] = useState<AuthProviderState>(() => {
		return {
			authInfo:null,
			userIdentity:null,
			couldHaveJwtCookie:getCookieValue(COOKIE_NAME_COULD_HAVE_AUTH) === 'true'
		};
	});
	const {authInfo, userIdentity, couldHaveJwtCookie} = fullState;

	const navigate = useNavigate();

	const setAuthInfo = useCallback(({authInfo, couldHaveToken, from, userIdentity, justAuthInfo, justFrom}:SetAuthInfoArgType) => {
		console.info('[setAuthInfo] from', from);

		if (justFrom === true) {
			setCookieValue(COOKIE_NAME_AUTH_FROM, from);
			console.info('[setAuthInfo] storing the from', from);
			return;
		}
		if (authInfo === undefined) {
			if (couldHaveToken) {
				setCookieValue(COOKIE_NAME_COULD_HAVE_AUTH, 'true');
			} else {
				removeCookie(COOKIE_NAME_COULD_HAVE_AUTH);
			}
			console.info('[setAuthInfo] authInfo === undefined');
			setFullState({
				authInfo:null,
				userIdentity:null,
				couldHaveJwtCookie:couldHaveToken!
			});
		} else {
			if (authInfo) {
				setCookieValue(COOKIE_NAME_COULD_HAVE_AUTH, 'true');
			} else {
				removeCookie(COOKIE_NAME_COULD_HAVE_AUTH);
			}
			if (!justAuthInfo) {
				console.info('[setAuthInfo] !justAuthInfo');
				setFullState({
					authInfo:authInfo,
					userIdentity:userIdentity!,
					couldHaveJwtCookie:authInfo !== null
				});
			} else {
				console.info('[setAuthInfo] devtool case');
				// for DevTools case
				setFullState({
					authInfo:authInfo,
					userIdentity:fullState.userIdentity!,
					couldHaveJwtCookie:authInfo !== null
				});
			}
		}
		if (!justAuthInfo && couldHaveToken === undefined) {
			console.info('[setAuthInfo] navigate');
			if (from) {
				// flow when the user was still authenticated
				navigate(from);
			} else {
				// flow when the user had to log in again with GH, storing the from to be reused in ConnectionSuccessPage
				navigate('/');
				// navigate('/will-be-caught-by-protected-route')
			}
		}
	}, [setFullState, navigate, fullState]);

	const setCouldHaveJwtCookie = useCallback((couldHave:boolean) => {
		if (couldHave) {
			setCookieValue(COOKIE_NAME_COULD_HAVE_AUTH, 'true');
		} else {
			removeCookie(COOKIE_NAME_COULD_HAVE_AUTH);
		}
	}, []);

	const getFrom = () => {
		const from = getCookieValue(COOKIE_NAME_AUTH_FROM);
		return from;
	}

	const authContext = {
		authInfo:authInfo,
		userIdentity:userIdentity,
		setAuthInfo:setAuthInfo,
		couldHaveJwtCookie,
		setCouldHaveJwtCookie:setCouldHaveJwtCookie,
		getFrom:getFrom
	};

	return (
		<AuthContext.Provider value={authContext}>
			{children}
		</AuthContext.Provider>
	)
}
