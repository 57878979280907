import {PriorityType} from "@commons/models/Task";
import * as React from "react";
import {
    MdExposureNeg1,
    MdExposureNeg2,
    MdExposurePlus1,
    MdExposurePlus2,
    MdExposureZero,
    MdOutlineEast,
    MdOutlineNorth,
    MdOutlineNorthEast,
    MdOutlineSouth,
    MdOutlineSouthEast,
    MdWarning
} from "react-icons/md";
import {FunctionNM} from "@commons/types/FunctionNM";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";

import "./Priority.scss";

type Props = {
    priority: PriorityType
    useArrow?: boolean
}

export const Priority = ({priority, useArrow = true}: Props) => {
    let priorityClass: string;
    let icon: FunctionNM<any, JSX.Element>
    let title: string
    switch (priority) {
        case 5:
            priorityClass = 'priority-highest';
            icon = useArrow ? MdOutlineNorth : MdExposurePlus2
            title = 'Highest'
            break;
        case 4:
            priorityClass = 'priority-high';
            icon = useArrow ? MdOutlineNorthEast : MdExposurePlus1
            title = 'High'
            break;
        case 3:
            priorityClass = 'priority-medium';
            icon = useArrow ? MdOutlineEast : MdExposureZero
            title = 'Medium'
            break;
        case 2:
            priorityClass = 'priority-low';
            icon = useArrow ? MdOutlineSouthEast : MdExposureNeg1
            title = 'Low'
            break;
        case 1:
            priorityClass = 'priority-lowest';
            icon = useArrow ? MdOutlineSouth : MdExposureNeg2
            title = 'Lowest'
            break;
        default:
            priorityClass = 'priority-unknown';
            icon = MdWarning;
            title = 'Unknown'
            break;
    }

    const Icon = icon;
    return (
        <div className={ClassHelper.combine('Priority', priorityClass)} title={title}>
            <Icon/>
        </div>
    );
}
