import * as React from "react";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";

import './SideNav.scss'

type SideNavProps = {
	classes?:string
	children:NonNullable<React.ReactNode>
};

export const SideNav = (props:SideNavProps) => {
	return (
		<div className={ClassHelper.combine('SideNav', 'elevation-z4', props.classes)}>
			{props.children}

			{process.env.NODE_ENV === 'development' && (
				<div className="Dev">development</div>
			)}
		</div>
	);

};
