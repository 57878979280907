import * as React from "react";
import {TaskBase, TaskBaseStatus} from "@commons/models/Task";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";

import "./TaskBaseStatusBadge.scss";

type TaskBaseStatusBadgeProps = {
	task:TaskBase
}

export const TaskBaseStatusBadge = ({task}:TaskBaseStatusBadgeProps) => {
	const status:TaskBaseStatus = task.taskStatus;
	let statusClass:string;
	if (status === 'todo') {
		statusClass = 'status-todo';
	} else if (status === 'progress') {
		statusClass = 'status-progress';
	} else if (status === 'completed') {
		statusClass = 'status-completed';
	} else {
		console.warn('Unknown status received', status);
		statusClass = 'status-unknown';
	}
	return (
		<div className={ClassHelper.combine('TaskBaseStatusBadge', statusClass)}>
			{status}
		</div>
	);
}
