import React from "react";

import "./ActionBar.scss";
import {ClassHelper} from "../../commons/helpers/ClassHelper";

type Props = {
	className?:string
	leftChildren?:React.ReactNode
	rightChildren?:React.ReactNode
}

export const ActionBar = ({className, leftChildren, rightChildren}:Props) => {
	return (
		<div className={ClassHelper.combine('ActionBar', className)}>
			<div className="LeftGroup">{leftChildren}</div>
			<div className="RightGroup">{rightChildren}</div>
		</div>
	);
}

type ActionBarOnlyRightProps = {
	className?:string
	children:React.ReactNode
}

export const ActionBarOnlyRight = ({className, children}:ActionBarOnlyRightProps) => {
	return (
		<ActionBar className={className} rightChildren={children} />
	)
}

type ActionBarOnlyLeftProps = {
	className?:string
	children:React.ReactNode
}

export const ActionBarOnlyLeft = ({className, children}:ActionBarOnlyLeftProps) => {
	return (
		<ActionBar className={className} leftChildren={children} />
	)
}
