import * as React from "react";
import {MdUndo} from "react-icons/md";
import {TextButton} from "../../components/TextButton";

type Props = {
	label:string
	onClick:() => void
}

export const FormCloseButton = ({onClick, label}:Props) => {
	return (
		<TextButton label={label} icon={MdUndo} 
		            onClick={onClick} buttonType="button" />
	);
}
