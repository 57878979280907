import {GitHubUrlHelper} from "@commons/helpers/GitHubUrlHelper";
import {ReleaseBlock} from "@commons/models/ReleaseBlock";
import {WithDbAndId} from "@commons/types/DbType";
import {FunctionHelper} from "@commons/types/FunctionHelper";
import {FunctionNM} from "@commons/types/FunctionNM";
import * as React from "react";
import {useCallback, useMemo} from "react";
import {MdCheck, MdRefresh} from "react-icons/md";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {useCreateQueryProvider} from "../../../../commons/hooks/useCreateQueryProvider";
import {GetQueryProviderResult} from "../../../../commons/hooks/useGetQuery";
import {useParamsFromRoute} from "../../../../commons/hooks/useParamsFromRoute";
import {apiFetch} from "../../../../framework/apiFetch";
import {Card} from "../../../../framework/components/Card";
import {Divider} from "../../../../framework/components/Divider";
import {ExternalLink} from "../../../../framework/components/ExternalLink";
import {IconButton} from "../../../../framework/components/IconButton";
import {DetailsRow} from "../../../../framework/components/layout/elements/DetailsRow";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {MetaInfoIcon} from "../../../../framework/components/MetaInfoIcon";
import {RefreshableQueryDisplay} from "../../../../framework/components/RefreshableQueryDisplay";
import {TextButton} from "../../../../framework/components/TextButton";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {NoData} from "../../task/commons/NoData";
import {JiraUrlHelper} from "@commons/helpers/JiraUrlHelper";

type Model = WithDbAndId<ReleaseBlock>;

const crudService = new class ReleaseBlockService extends AbstractApiService<Model> {
	constructor() {
		super('api/user/release-block');
	}
}();

type PageParams = {
	itemId:string;
};

export const ReleaseBlockDetailsPage = () => {
	const {itemId} = useParamsFromRoute<PageParams>();

	const location:any = useLocation();

	const itemFromPrevPage:Model | undefined = location?.state?.item;
	const fromView = location?.state?.fromView;

	const itemQueryProvider = useCreateQueryProvider<{ data:Model }>(
		useMemo(() => {
			//TODO if A => B => A, the value will not be requested the first time
			// in the case an update on A occurs after A => B, the first value of A after B => A, will be the state one

			if (itemFromPrevPage && itemFromPrevPage.id === itemId) {
				const firstValue = {data:itemFromPrevPage};
				const secondValue = () => crudService.getOne(itemId);

				return FunctionHelper.buildSuccessiveFunction<GetQueryProviderResult<{
					data:Model
				}>>(firstValue, secondValue);
			} else {
				return () => crudService.getOne(itemId);
			}
		}, [itemId, itemFromPrevPage])
	);

	const handleMarkAsCompleteClick = useCallback(({itemId, refresh}:{
		itemId:string,
		refresh:FunctionNM<[], void>
	}) => {
		if (!window.confirm('Are you sure to mark as complete this block?')) {
			return;
		}

		const url = `api/user/release-block/${encodeURIComponent(itemId)}/release-unblocked-date`;
		apiFetch.patch(url).then(refresh);
	}, []);

	return (
		<PageContent className="ReleaseBlockDetailsPage" withoutPadding={true}>
			<Card className="NavigationRow">
				{fromView && (<>
					<NavLink to={fromView}>
						Back to previous view
					</NavLink>
				</>)}
			</Card>

			<Card>
				{/*TODO add details about the subQueries*/}

				<RefreshableQueryDisplay
					queryProvider={itemQueryProvider}
					render={({data}, refresh) => (<>
						<Card.TitleAndActions
							title={data.artifactId} size="big"
							leftChildren={(<>
								{data.releaseUnblockedDate === null && (
									<TextButton label={"Mark as complete"} onClick={handleMarkAsCompleteClick}
									            onClickArgs={{itemId:data.id, refresh}} type="primary" icon={MdCheck} />
								)}
							</>)}
							rightChildren={(<>
								<MetaInfoIcon item={data} />
								<IconButton icon={MdRefresh} onClick={refresh} title="Refresh the ticket" />
							</>)}
						/>

						<DetailsRow label="Source" value={data.source} valueInlineCode={true} />
						<DetailsRow label="Ticket" value={data.ticket ? (
							<ExternalLink
								url={JiraUrlHelper.linkToTicket(data.ticket)}>{data.ticket}</ExternalLink>
						) : null} />

						{/*TODO Add link to details*/}
						<DetailsRow label="RequestId" value={data.requestId} valueInlineCode={true} />

						<DetailsRow label="Requester" value={data.requester} valueInlineCode={true} />

						<DetailsRow label="Organization" value={data.githubOrg} valueInlineCode={true} />
						<DetailsRow label="Repository" value={data.githubRepo ? (
							<ExternalLink
								url={`https://github.com/${data.githubOrg}/${data.githubRepo}`}>{data.githubRepo}</ExternalLink>
						) : null} />

						<DetailsRow label="Artifact ID" value={data.artifactId} valueInlineCode={true} />

						<Divider />
						<DetailsRow label="Permission active" value={data.permissionActive} />

						<DetailsRow label="Permission per team" value={data.permissionDelta.teams.length > 0 ? (
							<ul>{data.permissionDelta.teams.map(t => (
								<li key={t.teamSlug}>
									<ExternalLink url={GitHubUrlHelper.linkToTeam(data.githubOrg, t.teamSlug)}>
										{t.teamSlug}
									</ExternalLink> from <code>{t.oldPermission}</code> to <code>{t.newPermission}</code>
								</li>
							))}</ul>
						) : <NoData label="None" />} />
						<DetailsRow label="Permission per member" value={data.permissionDelta.members.length > 0 ? (
							<ul>{data.permissionDelta.members.map(m => (
								<li key={m.login}>
									<ExternalLink url={GitHubUrlHelper.linkToUser(m.login)}>
										{m.login}
									</ExternalLink> from <code>{m.oldPermission}</code> to <code>{m.newPermission}</code>
								</li>
							))}</ul>
						) : <NoData label="None" />} />

						<Divider />
						<DetailsRow label="Advisory active" value={data.advisoryActive} />

						<DetailsRow label="Advisory GHSA ID" value={data.advisoryGhsaId ? (<>
							<ExternalLink
								url={`https://github.com/${data.githubOrg}/${data.githubRepo}/security/advisories/${data.advisoryGhsaId}`}>{data.advisoryGhsaId}</ExternalLink>
						</>) : null} />
						<Divider />

						<DetailsRow label="Completely done" value={data.releaseUnblockedDate !== null} />
						<DetailsRow label="Was done at" value={data.releaseUnblockedDate} />
						{/*<hr />*/}
						{/*<pre>{JSON.stringify(data, null, 3)}</pre>*/}
					</>)}
				/>
			</Card>
		</PageContent>
	)
}
