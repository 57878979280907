import {WithDbAndId} from "@commons/types/DbType";
import * as React from "react";
import {MdInfoOutline} from "react-icons/md";
import {CrudModel} from "../../commons/types/CrudModel";

import "./MetaInfoIcon.scss";

export const MetaInfoIcon = ({item}:{ item:WithDbAndId<CrudModel> }) => {
	if (!item || !item.createdDate) {
		return <span />;
	}

	const content = `\
Creation: ${item.createdDate}
Creator: ${item.creator}
Update: ${item.updatedDate}
Updater: ${item.updater}
`;
	return <MdInfoOutline title={content} className="MetaInfoIcon cursor-help" />;
}
