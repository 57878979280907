import {authApiService} from "../commons/services/AuthApiService";
import {
	communicationBetweenTabService,
	messageTypeCreator
} from "./CommunicationBetweenTabService";
import {ApiFetchResponse, WrappedJsonFetch} from "./WrappedJsonFetch";

const COMM_CSRF_RENEWAL = messageTypeCreator<{ token:string, header:string, jwtExpirationTime:number }>('csrf-renewal');

const communicationChannel = communicationBetweenTabService.createWithType(COMM_CSRF_RENEWAL);

/**
 * Fetch with an automatic access token refresh mechanism
 */
export const apiFetch = new class ApiFetch extends WrappedJsonFetch {
	/**
	 * Time in milliseconds before the expiration of the access token after which, we force a refresh _before_ a request
	 * Prevent tons of requests to be sent to the server while the access token is perhaps already expired
	 */
	private msBeforeExpirationForceRefreshBefore = 10 * 1000;
	/**
	 * Time in milliseconds before the expiration of the access token after which, we force a refresh _after_ a request
	 * Reduce likelihood next request will request to wait for a refresh before.
	 */
	private msBeforeExpirationForceRefreshAfter = 60 * 1000;

	private nextExpirationTime:number = -1;

	private accessTokenRequest:Promise<void> | null = null;

	private csrfToken:string | null = null;
	private csrfHeader:string | null = null;

	protected async _fetch<T>(url:string, config:RequestInit, raw:boolean = false):Promise<ApiFetchResponse<T>> {
		if (this.nextExpirationTime < Date.now() + this.msBeforeExpirationForceRefreshBefore) {
			console.info('[apiFetch] refreshing before');
			await this.refreshAccessToken();
			this.adjustCsrfHeaderInRequest(config);
		}

		let response = await super._fetch(url, config, raw);
		if (!response.ok && response.code === 401) {
			// due to the two mechanisms in place to refresh the access token, this should not happen too often in production
			console.info('[apiFetch] refreshing during');
			await this.refreshAccessToken();
			this.adjustCsrfHeaderInRequest(config);

			response = await super._fetch(url, config, raw);
		}

		if (response.ok && (this.nextExpirationTime < Date.now() + this.msBeforeExpirationForceRefreshAfter)) {
			console.info('[apiFetch] refreshing after');
			// non-blocking
			this.refreshAccessToken().then();
		}

		return response as ApiFetchResponse<T>;
	}

	/**
	 * @return the expiration time
	 */
	private async refreshAccessToken():Promise<void> {
		if (this.accessTokenRequest === null) {
			console.info('triggering refresh request');
			this.accessTokenRequest = this.createRefreshJwtPromise();
			return this.accessTokenRequest;
		} else {
			console.info('another refresh pending');
			return this.accessTokenRequest;
		}
	}

	//TODO return error to be displayed to the user?
	private async createRefreshJwtPromise():Promise<void> {
		const response = await authApiService.forceRefreshJwt()
		if (response.ok) {
			const csrfToken = response.payload.csrfToken;
			if (csrfToken) {
				this.setCsrfToken(csrfToken.token, csrfToken.header, response.payload.jwtExpirationTime);
			} else {
				this.setCsrfToken(null, null, 0);
			}
		} else {
			//TODO redirect to login page
			console.error('[createRefreshJwtPromise] refresh jwt failed', response.error, response.errorId);
		}

		this.accessTokenRequest = null;
	}

	protected headerValues():HeadersInit {
		const superValues = super.headerValues();
		if (this.csrfToken && this.csrfHeader) {
			return {[this.csrfHeader]:this.csrfToken, ...superValues};
		}
		return superValues;
	}

	private adjustCsrfHeaderInRequest(config:RequestInit):void {
		config.headers = this.headerValues();
	}

	/**
	 * @param token
	 * @param header
	 * @param jwtExpirationTime in ms, less than Date.now() to disable
	 * @param broadcast determines if the code broadcast the information to other tabs, default=true
	 */
	setCsrfToken(token:string | null, header:string | null, jwtExpirationTime:number = 0, broadcast:boolean = true):void {
		this.csrfToken = token;
		this.csrfHeader = header;
		this.setExpirationTime(jwtExpirationTime);
		if (broadcast && token !== null) {
			console.info('CSRF shared with another tabs');
			communicationChannel.sendMessage({token, header:header!, jwtExpirationTime});
		}
	}

	private timeoutBefore?:number;
	private timeoutAfter?:number;

	private setExpirationTime(expirationTime:number):void {
		const remaining = expirationTime - Date.now();
		console.info(`[setExpirationTime] ${new Date(expirationTime).toISOString()}, ${Math.round(remaining / 1000)}s left`);
		this.nextExpirationTime = expirationTime;

		if (process.env.NODE_ENV === 'development') {
			this.timeoutBefore && clearTimeout(this.timeoutBefore);
			this.timeoutAfter && clearTimeout(this.timeoutAfter);
			if (remaining < 0) {
				console.info(`[setExpirationTime] CSRF token expired directly`);
			} else {
				this.timeoutBefore = window.setTimeout(() => {
					console.info(`[setExpirationTime] only ${Math.round((this.msBeforeExpirationForceRefreshBefore) / 1000)}s left (before)`);
				}, remaining - this.msBeforeExpirationForceRefreshBefore);
				this.timeoutAfter = window.setTimeout(() => {
					console.info(`[setExpirationTime] only ${Math.round((this.msBeforeExpirationForceRefreshAfter) / 1000)}s left (after)`);
				}, remaining - this.msBeforeExpirationForceRefreshAfter);
			}
		}
	}
}();

communicationChannel.onMessage(({token, header, jwtExpirationTime}) => {
	console.info('CSRF received from another tab');
	apiFetch.setCsrfToken(token, header, jwtExpirationTime, false);
});
