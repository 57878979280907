import {
	JIRA_LABEL_SEC_EMBARGO,
	JIRA_LABEL_SEC_SKIP_AUTO_ASSIGN,
	JIRA_LABEL_SEC_UNMAINTAINED_PLUGIN
} from "@commons/constants/JiraConstants";
import {Task_JiraSecurity_Assign} from "@commons/models/TaskJiraSecurity";
import {WithDbAndId} from "@commons/types/DbType";
import * as React from 'react';
import {MdPersonAddAlt1} from "react-icons/md";
import {useLocation} from "react-router";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {TicketSecurityAutomationService} from "../../../../commons/services/TicketSecurityAutomationService";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {ExternalLinkColumnDef} from "../../../../framework/table/columns/ExternalLinkColumnDef";
import {IconColumnDef} from "../../../../framework/table/columns/IconColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {JiraStatusColumnDef} from "../commons/JiraStatusColumnDef";
import {SecurityTaskCrudPage} from "../commons/SecurityTaskCrudPage";
import {AbstractTaskApiService, TaskHelper} from "../commons/TaskHelper";
import {useContext} from "react";
import {TaskCounterContext} from "../commons/TaskCounterContext";
import {JiraUrlHelper} from "@commons/helpers/JiraUrlHelper";

type TaskSecurityModel = WithDbAndId<Task_JiraSecurity_Assign>;

const crudService = new class TaskSecurityService extends AbstractTaskApiService<TaskSecurityModel> {
	constructor() {
		super('api/user/task/jira-security/assign');
	}
}();

const ticketSecurityAutomationService = new TicketSecurityAutomationService();

export const Assign_TaskSecurityPage = () => {
	const currentLocation = useLocation();
	const {dispatch:counterDispatch} = useContext(TaskCounterContext);

	const {columnDefs, initialState} = useColumnDefs<TaskSecurityModel>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'Id', row => row.id || '', {defaultVisibility:false, sortable:false}),
		new StringColumnDef('targetIdNum', 'Ticket', row => row.targetId || '', {size:110}),
		new ExternalLinkColumnDef('openLink', row => JiraUrlHelper.linkToTicket(row.targetId)),
		...TaskHelper.defaultBrowseIcons(currentLocation),
		...TaskHelper.defaultInformationDisplay(),
		new JiraStatusColumnDef(),
		new IconColumnDef('assign', 'Assign the ticket to the security contact and maintainer(s) by triggering the script \'jira-assign-issue\'', MdPersonAddAlt1, assignTicket, {}, {iconClassName:'hover-info'}),
		...TaskHelper.defaultActionIcons(crudService, counterDispatch),
	], {initialOrder:'targetIdNum', initialOrderDirection:'desc', initialFilters:['taskDone:b:eq:false']});

	return (
		<SecurityTaskCrudPage pageId="jira-security-assign" pageClass="Assign_TaskSecurityPage"
		                      instructionMessage={`Completed when the ticket is assigned or got one of \`${JIRA_LABEL_SEC_SKIP_AUTO_ASSIGN}\`, \`${JIRA_LABEL_SEC_UNMAINTAINED_PLUGIN}\` or \`${JIRA_LABEL_SEC_EMBARGO}\``}
		                      crudService={crudService}
		                      initialState={initialState} pageName="Need assignation"
		                      columnDefs={columnDefs} />
	)
}

const assignTicket = ({row, dispatch}:IconColumnDef.OnClickArgs<TaskSecurityModel>) => {
	ticketSecurityAutomationService.assignTicket(row.targetId)
		.then((response) => {
			console.info('addLabelsToTicket', response);
			//TODO could break the pagination (due to the before / after x)
			dispatch({type:'reloadContent'});
		})
}
