import * as React from 'react';
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {QueryResponse} from '../../commons/hooks/useGetQuery';

import {ErrorPanel, InfoPanel} from './color/Panel';
import {Spinner} from './misc/Spinner';

import './QueryDisplay.scss';

export type QueryDisplayProps<T> = {
	queryResponse:QueryResponse<T>
	className?:string
	render:(data:T) => JSX.Element | null
	renderError?:(error:string, errorId:string | null) => JSX.Element | null
}

export const QueryDisplay = ({queryResponse, className, render, renderError}:QueryDisplayProps<any>) => {
	return (
		<div className={ClassHelper.combine('QueryDisplay', className)}>
			{queryResponse.loading ? (
				<div className="loading">
					<Spinner />
				</div>
			) : (
				queryResponse.error ? (
					renderError ? (
						renderError(queryResponse.error, queryResponse.errorId)
					) : (
						<div className="error">
							<ErrorPanel>
								Error: {queryResponse.error}
							</ErrorPanel>
							{queryResponse.error === 'No JWT token from cookies' && (
								<InfoPanel>
									If you are using the "Private browsing mode", by default the cross-origin cookies
									are not sent. This means that the authentication of this single-page application
									will not work.
								</InfoPanel>
							)}
						</div>
					)
				) : (
					render(queryResponse.data!)
				)
			)}
		</div>
	);
}
