import {TemplateHelper} from "@commons/helpers/TemplateHelper";
import * as React from "react";
import {useCallback, useState} from "react";
import {MdOutlineSend, MdPeople, MdPerson, MdPersonAddAlt1, MdSend} from "react-icons/md";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";
import {apiFetch} from "../../../../framework/apiFetch";
import {Card} from "../../../../framework/components/Card";
import {Divider} from "../../../../framework/components/Divider";
import {DetailsRow} from "../../../../framework/components/layout/elements/DetailsRow";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {TwoColumns} from "../../../../framework/components/layout/TwoColumns";
import {LogPanel, useLogPanel} from "../../../../framework/components/LogPanel";
import {TextButton} from "../../../../framework/components/TextButton";
import {JiraLabels} from "../../task/commons/JiraLabels";
import {JiraStatus} from "../../task/commons/JiraStatus";

import "./SendEmailsPage.scss";
import {JiraUrlHelper} from "@commons/helpers/JiraUrlHelper";

let id = 0;
const nextId = () => id++;

const DEFAULT_TEMPLATE:Template = {
	subject:'[%TICKET_KEY%] 👋 Your attention is requested on a Jenkins Security ticket',
	body:`\
%IF(ALL_USER_DISPLAY_NAMES)%Dear %ALL_USER_DISPLAY_NAMES%,

%ENDIF%Your attention is requested on the ticket <a href="%TICKET_URL%">%TICKET_KEY%</a>. It's related to a security issue within the Jenkins project.

%IF(ALL_USER_LOGINS)%Please connect to the tracker using the login: <code>%ALL_USER_LOGINS%</code> in order to get access to the ticket.

%ENDIF%If you do not plan to work on the related ticket, please reply to this email or inform us at <a href="mailto:jenkinsci-cert@googlegroups.com?subject=%TICKET_KEY%">jenkinsci-cert@googlegroups.com</a>.

Best Regards,

Jenkins Security team
`
}

type Template = {
	subject:string
	body:string
}

type Variables = {
	TICKET_KEY:string
	TICKET_URL:string
	ALL_USER_DISPLAY_NAMES:string
	ALL_USER_LOGINS:string
}

type JiraTicketInfo = {
	ticketKey:string
	summary:string
	status:{ name:string, statusCategory:{ colorName:string } }
	issueType:{ name:string }
	labels:string[]
	assignee:Assignee
	secondaryAssignees:Assignee[] | undefined
}

export const SendEmailsPage = () => {
	const [userResponse, setUserResponse] = useState<JiraTicketInfo>();

	const [manualEmails, setManualEmails] = useState<ManualAddedUser[]>([]);

	// const [template, setTemplate] = useState<Template>(DEFAULT_TEMPLATE);
	const template = DEFAULT_TEMPLATE;
	const [variables, setVariables] = useState<Variables>();

	const {logRows, appendLog, clearLog} = useLogPanel();

	const checkJiraTicketAndListAssignees = useCallback(() => {
		const ticket = document.querySelector<HTMLInputElement>('#jiraTicket')!.value;
		appendLog(`Requesting users for ticket=${ticket}`);
		setManualEmails([]);
		apiFetch.get<JiraTicketInfo>(`api/user/email/jira-ticket/SECURITY-${ticket}/users`).then((data) => {
			if (data.ok) {
				if (data.payload.assignee) {
					data.payload.assignee.recipientType = 'to';
				}
				if (data.payload.secondaryAssignees) {
					data.payload.secondaryAssignees.forEach(sec => sec.recipientType = 'to');
				}
				setUserResponse(data.payload);

				const allUsers:Assignee[] = [];
				if (data.payload.assignee) {
					allUsers.push(data.payload.assignee);
				}
				if (data.payload.secondaryAssignees) {
					allUsers.push(...data.payload.secondaryAssignees);
				}
				setVariables({
					TICKET_KEY:data.payload.ticketKey,
					TICKET_URL:JiraUrlHelper.linkToTicket(data.payload.ticketKey),
					ALL_USER_LOGINS:allUsers.map(u => u.login).join(', ') || '',
					ALL_USER_DISPLAY_NAMES:allUsers.map(u => u.displayName).join(', ') || '',
				})
			}
			appendLog(data);
		})
	}, [appendLog]);

	const addEmail = useCallback(() => {
		const input = document.querySelector<HTMLInputElement>('#addEmail');
		const email = input!.value;
		input!.value = '';
		setManualEmails(prevState => prevState.concat({id:nextId(), email, recipientType:'to'}));
	}, []);

	const handleAssigneeRecipientTypeChanged = useCallback((target:Assignee, newType:RecipientType) => {
		// both setters are called in the same "iteration" as they are coming from a React event (the onClick)
		setUserResponse((prevState:JiraTicketInfo | undefined) => {
			if (!prevState) {
				return undefined;
			}
			if (prevState.assignee === target) {
				return {
					...prevState,
					assignee:Object.assign({}, prevState.assignee, {recipientType:newType}),
				}
			} else {
				const index = prevState.secondaryAssignees!.indexOf(target);

				return {
					...prevState,
					secondaryAssignees:[
						...prevState.secondaryAssignees!.slice(0, index),
						Object.assign({}, prevState.secondaryAssignees![index], {recipientType:newType}),
						...prevState.secondaryAssignees!.slice(index + 1),
					]
				};
			}
		});
		setVariables(prevState => {
			const allUsers:Assignee[] = [];
			// double negation to support undefined that is equivalent to 'to'
			if (userResponse!.assignee && ((userResponse!.assignee.recipientType === 'to' && target !== userResponse!.assignee) || (target === userResponse!.assignee && newType === 'to'))) {
				allUsers.push(userResponse!.assignee);
			}
			if (userResponse!.secondaryAssignees) {
				const toSecondaryAssignees = userResponse!.secondaryAssignees.filter(assignee => (assignee.recipientType === 'to' && target !== assignee) || (target === assignee && newType === 'to'))
				allUsers.push(...toSecondaryAssignees);
			}
			return {
				...prevState!,
				ALL_USER_LOGINS:allUsers.map(u => u.login).join(', ') || '',
				ALL_USER_DISPLAY_NAMES:allUsers.map(u => u.displayName).join(', ') || '',
			};
		});
	}, [userResponse])
	const handleManualRecipientTypeChanged = useCallback((target:ManualAddedUser, newType:RecipientType) => {
		if (newType === 'none') {
			setManualEmails(prevState => {
				return prevState.filter(value => value !== target);
			});
		} else {
			setManualEmails(prevState => {
				const index = prevState.indexOf(target);
				return [
					...prevState.slice(0, index),
					Object.assign({}, target, {recipientType:newType}),
					...prevState.slice(index + 1),
				];
			});
		}
	}, [])

	const sendEmail = useCallback((ticket:string, testEmail:string | null) => {
		const recipients:{
			recipientType:'to' | 'cc' | 'bcc'
			displayName?:string
			email:string
		}[] = [];
		if (userResponse?.assignee && userResponse!.assignee.recipientType !== 'none') {
			recipients.push({
				recipientType:userResponse!.assignee.recipientType,
				displayName:userResponse!.assignee.displayName,
				email:userResponse!.assignee.email,
			});
		}
		if (userResponse!.secondaryAssignees) {
			userResponse!.secondaryAssignees.forEach(sec => {
				if (sec.recipientType !== 'none') {
					recipients.push({
						recipientType:sec.recipientType,
						displayName:sec.displayName,
						email:sec.email,
					});
				}
			})
		}
		if (manualEmails) {
			manualEmails.forEach(manualEmail => {
				if (manualEmail.recipientType !== 'none') {
					recipients.push({
						recipientType:manualEmail.recipientType,
						email:manualEmail.email,
					});
				}
			})
		}

		const body = {
			template:{subject:template.subject, body:template.body},
			variables:variables,
			recipients
		}
		const url = `api/user/email/jira-ticket/SECURITY-${ticket}/send${testEmail ? `?test-email=${encodeURIComponent(testEmail)}` : ''}`;
		apiFetch.post<any>(url, body).then((data) => {
			appendLog(data);
		})
	}, [appendLog, variables, template, userResponse, manualEmails]);

	const sendActualEmail = useCallback(() => {
		const ticket = document.querySelector<HTMLInputElement>('#jiraTicket')!.value;
		appendLog(`Actual email for ticket=${ticket}`);

		sendEmail(ticket, null);
	}, [appendLog, sendEmail]);
	const sendPreviewEmail = useCallback(() => {
		const ticket = document.querySelector<HTMLInputElement>('#jiraTicket')!.value;
		const testEmail = document.querySelector<HTMLInputElement>('#previewEmail')!.value;
		appendLog(`Preview email for ticket=${ticket}, testEmail=${testEmail}`);

		sendEmail(ticket, testEmail);
	}, [appendLog, sendEmail]);

	return (
		<PageContent className="SendEmailsPage">
			<div>
				SECURITY-<input id="jiraTicket" placeholder="1234" />
				<TextButton label="Check and get assignees" onClick={checkJiraTicketAndListAssignees} />
			</div>

			{userResponse && (<>
				<Card className="TicketCard">
					<Card.TitleAndActions title={`${userResponse.ticketKey} ${userResponse.summary}`} size="big" />
					<TwoColumns
						className="TicketDetails"
						left={<>
							<DetailsRow label="Type" value={userResponse.issueType.name} />
							<DetailsRow label="Labels" value={<JiraLabels labels={userResponse.labels} />} />
						</>}
						right={<>
							<DetailsRow label="Status" value={
								<JiraStatus label={userResponse.status.name}
								            color={userResponse.status.statusCategory.colorName} />
							} />
						</>}
					/>
				</Card>

				<Card className="RecipientCard">
					<div className="CurrentRecipientList">
						{userResponse.assignee ? (
							<JiraUser user={userResponse.assignee} primary={true}
							          onRecipientChange={handleAssigneeRecipientTypeChanged} />
						) : (
							<div className="User">No primary assignee</div>
						)}
						{userResponse.secondaryAssignees?.map((user:Assignee) => (
							<JiraUser key={user.login} user={user} primary={false}
							          onRecipientChange={handleAssigneeRecipientTypeChanged} />
						)) || (
							<div className="User">No secondary assignees</div>
						)}
						{manualEmails.map(manualAddedUser => (
							<ManualEmail key={manualAddedUser.id} manualAddedUser={manualAddedUser}
							             onRecipientChange={handleManualRecipientTypeChanged} />
						))}
					</div>

					<Divider />

					<div>
						<input id="addEmail" placeholder="xxx@yyy.com" />
						<TextButton label="Add email" onClick={addEmail} />
					</div>
				</Card>

				<TwoColumns
					className="EmailEdition"
					left={<>
						<Card className="EmailTemplate EmailPart">
							<Card.TitleAndActions title="Email template" size="medium" />
							<div className="subject">{template.subject}</div>
							<div className="body">{template.body}</div>
						</Card>
					</>}
					right={<>
						<Card className="EmailPreview EmailPart">
							<Card.TitleAndActions title="Email preview (with the first recipient if any)"
							                      size="medium" />
							{variables && (<>
								<div
									className="subject">{TemplateHelper.injectVariables(template.subject, variables)}</div>
								<div className="body">{TemplateHelper.injectVariables(template.body, variables)}</div>
							</>)}
						</Card>
					</>}
				/>

				<Card className="SendPart">
					<TwoColumns
						left={<>
							<div>
								<input id="previewEmail" placeholder="xxx@yyy.com" />
								<TextButton label="Send preview email" onClick={sendPreviewEmail}
								            icon={MdOutlineSend} />
							</div>
						</>}
						right={<>
							<div>
								<TextButton label="Send actual email" onClick={sendActualEmail} icon={MdSend} />
							</div>
						</>}
					/>
				</Card>

				{/*<pre>{JSON.stringify(userResponse, null, 3)}</pre>*/}
			</>)}
			<Divider />
			<Card>
				<LogPanel rows={logRows} numRowsToDisplay={20} onClearLog={clearLog} />
			</Card>
		</PageContent>
	);
}

type Assignee = {
	login:string
	displayName:string
	email:string
	recipientType:RecipientType
}
type ManualAddedUser = {
	id:number
	email:string
	recipientType:RecipientType
}

type RecipientType = 'to' | 'cc' | 'bcc' | 'none'

type JiraUserProps = {
	user:Assignee
	primary:boolean
	onRecipientChange:(target:Assignee, newType:RecipientType) => void
}

export const JiraUser = ({user, primary, onRecipientChange}:JiraUserProps) => {
	return (
		<div className="User JiraUser">
			<span className="type">
				{primary ? (
					<MdPerson title="Primary assignee" />
				) : (
					<MdPeople title="Secondary assignee" />
				)}
			</span>
			<span className="recipient-type">
				<RecipientTypeChoice target={user} recipientType={user.recipientType} onChange={onRecipientChange} />
			</span>
			<span className="email">
				{user.email}
			</span>
			<span className="display-name">
				{user.displayName}
			</span>
			<span className="login">
				{user.login}
			</span>
		</div>
	)
};

type ManualEmailProps = {
	manualAddedUser:ManualAddedUser
	onRecipientChange:(target:ManualAddedUser, newType:RecipientType) => void
}

export const ManualEmail = ({manualAddedUser, onRecipientChange}:ManualEmailProps) => {
	return (
		<div className="User ManualEmail">
			<span className="type">
				<MdPersonAddAlt1 title="Manually added email" />
			</span>
			<span className="recipient-type">
				<RecipientTypeChoice target={manualAddedUser} recipientType={manualAddedUser.recipientType}
				                     onChange={onRecipientChange} />
			</span>
			<span className="email">
				{manualAddedUser.email}
			</span>
			<span className="display-name">
			</span>
			<span className="login">
			</span>
		</div>
	)
};

type RecipientTypeChoiceProps<T> = {
	target:T,
	recipientType:RecipientType
	onChange:(target:T, newType:RecipientType) => void
}

export const RecipientTypeChoice = <T, >({target, recipientType = 'to', onChange}:RecipientTypeChoiceProps<T>) => {
	const setNewType = useCallback((newType:RecipientType) => {
		onChange && onChange(target, newType);
	}, [target, onChange]);

	return (<span className="RecipientTypeChoice">
		<span onClick={() => setNewType('to')}
		      className={ClassHelper.combine('choice', recipientType === 'to' && 'active')}>to:</span>
		<span onClick={() => setNewType('cc')}
		      className={ClassHelper.combine('choice', recipientType === 'cc' && 'active')}>cc:</span>
		<span onClick={() => setNewType('bcc')}
		      className={ClassHelper.combine('choice', recipientType === 'bcc' && 'active')}>bcc:</span>
		<span onClick={() => setNewType('none')}
		      className={ClassHelper.combine('choice', recipientType === 'none' && 'active')}>none</span>
	</span>)
};
