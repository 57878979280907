export type ClassName = string | string[];

// boolean to accept columnDef.sortable && 'sortable'
type ClassNameOrUndefined = ClassName | boolean | undefined | 0;

/**
 * Helper to ease the combination of multiple className to be injected to a component
 * without caring about the undefined / false values
 */
export class ClassHelper {
	static combine(...args:ClassNameOrUndefined[]):string {
		const result:string[] = [];

		for (let i = 0; i < args.length; i++) {
			const curr = args[i];
			if (curr !== true && curr !== false) {
				if (curr) {
					if (Array.isArray(curr)) {
						for (let j = 0; j < curr.length; j++) {
							const currCurr = curr[j];
							if (currCurr) {
								result.push(currCurr);
							}
						}
					} else {
						result.push(curr);
					}
				}
			}
		}

		return result.join(' ');
	}
}
