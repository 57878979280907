import {ReactNode} from "react";
import {useAuth} from '../../commons/hooks/useAuth';

type Props = {
	ifRoute:ReactNode
	elseRoute:ReactNode
	requiresUser?:boolean
	requiresUserOrAiAccess?:boolean
	requiresAiAccess?:boolean
	requiresOnlyAiAccess?:boolean
	requiresAdmin?:boolean
};

export const IfElseRoute = ({
	                            ifRoute,
	                            elseRoute,
	                            requiresUser,
	                            requiresUserOrAiAccess,
	                            requiresOnlyAiAccess,
	                            requiresAiAccess,
	                            requiresAdmin
                            }:Props) => {
	const {authInfo} = useAuth();

	if (!authInfo) {
		return <>{elseRoute}</>;
	}
	if (requiresAdmin && !authInfo.isAdmin) {
		return <>{elseRoute}</>;
	}
	if (requiresAiAccess && !authInfo.hasAiAccess) {
		return <>{elseRoute}</>;
	}
	if (requiresUserOrAiAccess && !(authInfo.hasAiAccess || authInfo.isUser)) {
		return <>{elseRoute}</>;
	}
	if (requiresUser && !authInfo.isUser) {
		return <>{elseRoute}</>;
	}
	if (requiresOnlyAiAccess && (!authInfo.hasAiAccess || authInfo.isUser || authInfo.isAdmin)) {
		return <>{elseRoute}</>;
	}

	return <>{ifRoute}</>;
}
