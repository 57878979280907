import {useCallback} from "react";

import "./Placeholder.scss";

export type PlaceholderProps = {
	width?:number
	height?:number
	padding?:number
}

export const Placeholder = ({width, height, padding}:PlaceholderProps) => {
	//TODO create CSS classes to prevent CSP issues
	const applyStyle = useCallback((div:HTMLDivElement | null) => {
		if (div === null) {
			return;
		}
		if (width) {
			div.style.width = `${width}px`;
		}
		if (height) {
			div.style.height = `${height}px`;
		}
		if (padding) {
			div.style.padding = `${padding}px`;
		}
	}, [width, height, padding]);

	return (
		<div className="Placeholder" ref={applyStyle}>
			<div className="intern" />
		</div>
	)
}
