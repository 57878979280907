import {ReactNode} from "react";
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';

import "./Card.scss";
import {ActionBar} from "./ActionBar";

export type CardProps = {
	className?:string
	children:ReactNode
} & OtherHtmlAttributes

export const Card = ({className, children, ...rest}:CardProps) => {
	return (
		<div className={ClassHelper.combine('Card', className)} {...rest}>
			{children}
		</div>
	);
}

type CardHeaderProps = {
	className?:string
	children:ReactNode
	title:string
	leftAction?:ReactNode
	rightAction?:ReactNode
} & OtherHtmlAttributes

Card.Header = ({className, children, ...rest}:CardHeaderProps) => {
	return (
		<div className={ClassHelper.combine('CardHeader', className)} {...rest}>
			{children}
		</div>
	);
}

type TitleOrHtmlTitle = {title:string, htmlTitle?:undefined} | {title?:undefined, htmlTitle:string};
type CardTitleAndActionsProps = {
	className?:string
	size?:'big' | 'medium' | 'small'
	leftChildren?:ReactNode
	rightChildren?:ReactNode
} & TitleOrHtmlTitle & OtherHtmlAttributes

Card.TitleAndActions = ({className, title, htmlTitle, size = 'medium', leftChildren, rightChildren, ...rest}:CardTitleAndActionsProps) => {
	const titlePart = title ? (
		<div className="title">{title}</div>
	) : (
		//TODO normally we should not have to ! the variable
		<div className="title" dangerouslySetInnerHTML={{__html:htmlTitle!}}></div>
	);

	return (
		<div className={ClassHelper.combine('CardTitleAndActions', className, `size-${size}`)} {...rest}>
			{titlePart}
			{(leftChildren || rightChildren) && (
				<ActionBar className="cardActionBar" leftChildren={leftChildren} rightChildren={rightChildren} />
			)}
		</div>
	);
}
