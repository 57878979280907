import * as React from "react";
import {ReactNode} from "react";
import './AppContainer.scss';
import './assets/scss/styles.scss';
import {AuthProvider} from "./commons/providers/AuthProvider";
import {ThemeProvider} from "./commons/providers/ThemeProvider";

type AppContainerProps = {
	forceTheme?:'light' | 'dark',
	children:ReactNode
}

export const NON_MODAL_ROOT_ELEMENT_ID = 'non-modal-part';
export const MODAL_ROOT_ELEMENT_ID = 'modal-root';

export const AppContainer = ({forceTheme, children}:AppContainerProps) => {
	return (
		<AuthProvider>
			<ThemeProvider forceTheme={forceTheme}>
				<div className="AppContainer">
					<div id={NON_MODAL_ROOT_ELEMENT_ID}>
						{children}
					</div>
					<div id={MODAL_ROOT_ELEMENT_ID} />
				</div>
			</ThemeProvider>
		</AuthProvider>
	);
};
