import * as React from "react";
import {Path, UseFormReturn} from "react-hook-form";
import {useUniqueId} from "../../../commons/hooks/useUniqueId";

import {FormRow, FormRowProps} from "../components/FormRow";
import "./TextField.scss"

export type TextFieldProps = {
	f:UseFormReturn<any>
	name:Path<any>
	numRows?:number
	readonly?:boolean
	disabled?:boolean
} & FormRowProps;

export const TextField = ({f, name, numRows, readonly, disabled, ...rowProps}:TextFieldProps) => {
	const uniqueId = useUniqueId();

	numRows = numRows ?? 3;

	const {register, formState:{errors}} = f
	const fieldError = errors[name];

	return (
		<FormRow {...rowProps} className="TextField" errorMessage={fieldError?.message as string} labelId={uniqueId}>
			<textarea {...register(name)} id={uniqueId}
			          readOnly={readonly} disabled={disabled} rows={numRows}
			          className="form-control" />
		</FormRow>
	);
}
