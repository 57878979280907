import {DateTime} from "luxon";
import * as React from 'react';
import {useCallback} from 'react';
import {MdAdd, MdCheck, MdClear, MdClose, MdDelete, MdEdit, MdRefresh} from "react-icons/md";
import * as zod from "zod";
import {PromiseHelper} from "@commons/helpers/PromiseHelper";
import {CertMember} from "@commons/models/CertMember";
import {WithDbAndId} from "@commons/types/DbType";
import {ZodCrudModel_DEFAULT} from "../../../../commons/helpers/ZodHelper";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {IconButton} from "../../../../framework/components/IconButton";
import {TextButton} from "../../../../framework/components/TextButton";
import {ToggleGroup} from "../../../../framework/components/ToggleGroup";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {CrudFormFragmentProps} from "../../../../framework/crud/CrudForm";
import {OnSaveCallbackType, useFeatureFormModal} from "../../../../framework/crud/features/useFeatureFormModal";
import {
	action_reloadContent, action_resetFilter, action_resetSelection,
	CrudPage2,
	CrudPage2Dispatcher,
	CrudPage2State,
	CrudPageLayout,
	CrudPageLayoutBasic
} from "../../../../framework/crud2/CrudPage2";
import {BooleanField} from "../../../../framework/form/fields/BooleanField";
import {StringField} from "../../../../framework/form/fields/StringField";
import {TextField} from "../../../../framework/form/fields/TextField";
import {GLOBAL_ERROR_KEY} from "../../../../framework/form/types/FormTypes";
import {BooleanColumnDef} from "../../../../framework/table/columns/BooleanColumnDef";
import {DateColumnDef} from "../../../../framework/table/columns/DateColumnDef";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {IconColumnDef} from "../../../../framework/table/columns/IconColumnDef";
import {IntegerColumnDef} from "../../../../framework/table/columns/IntegerColumnDef";
import {MetaInfoColumnDef} from "../../../../framework/table/columns/MetaInfoColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {PaginationUsingCursor} from "../../../../framework/table/PaginationUsingCursor";
import {Table2} from "../../../../framework/table/Table2";

type CertMemberModel = WithDbAndId<CertMember>;

const crudService = new class CertMembersApiService extends AbstractApiService<CertMemberModel> {
	constructor() {
		super('api/admin/cert-members');
	}
}();

export const CertMembersPage = () => {
	const {
		openModal:openCreateModal,
		renderFormModal:renderCreateFormModal,
	} = useFeatureFormModal({
		modalTitle:'Create CERT Member',
		formFragment:FormFragment,
		formSchema:formSchema,
		onSave:handleCreate,
	});
	const {
		openModal:openUpdateModal,
		renderFormModal:renderUpdateFormModal,
	} = useFeatureFormModal({
		modalTitle:'Update CERT Member',
		formFragment:FormFragment,
		formSchema:formSchema,
		onSave:handleUpdate,
	});

	const {columnDefs, initialState} = useColumnDefs<CertMemberModel>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'ID', row => row.id + '', {sortable:false, defaultVisibility:false}).nowrap(),
		new IntegerColumnDef('version', 'Version', row => row.version, {
			sortable:false,
			defaultVisibility:false
		}).nowrap(),
		new StringColumnDef('displayName', 'Name', row => row.displayName || '', {
			sortable:false,
			size:200
		}),
		new StringColumnDef('email', 'Email', row => row.email, {
			size:'expand'
		}),
		new StringColumnDef('note', 'Note', row => row.note || '', {
			sortable:false,
			defaultVisibility:false,
			size:'expand'
		}),
		new StringColumnDef('jiraUsername', 'Jira', row => row.jiraUsername || '', {
			sortable:false,
			size:150
		}),
		new StringColumnDef('githubUsername', 'GitHub', row => row.githubUsername || '', {
			sortable:false,
			size:150
		}),
		new BooleanColumnDef('active', 'Active', row => row.active, {
			sortable:false,
			size:75
		}),
		new BooleanColumnDef('jensec', 'JenSec', row => row.jensec, {
			sortable:false,
			size:75
		}),
		new MetaInfoColumnDef(),
		new DateColumnDef('createdDate', 'Created', DateTime.DATE_MED, row => row.createdDate, {
			sortable:false,
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			defaultVisibility: false
		}),
		new DateColumnDef('updatedDate', 'Updated', DateTime.DATE_MED, row => row.updatedDate, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS
		}),
		// new IconColumnDef('update', 'Update the item', MdEdit, (row) => openUpdateModal(row), {iconClassName:'hover-info'}),
		new IconColumnDef('update', 'Update the item', MdEdit, openUpdateModalWrapper, {openUpdateModal}, {iconClassName:'hover-info'}),
		new IconColumnDef('delete', 'Delete the item', MdDelete, deleteOneItem, {}, {iconClassName:'hover-error'}),
	], {initialOrder: 'email'});

	return (
		<CrudPage2 pageId="cert-members" pageClass="CertMembersPage" crudService={crudService}
		           initialState={initialState}
			// others={useMemo(() => <>
			//     {renderCreateModal()}
			// </>, [renderCreateModal])}
			       layout={useCallback((state, itemsData, dispatch) => (
				       <CrudPageLayoutBasic
					       pageName="CERT Members"
					       popupPart={<>
						       {renderCreateFormModal({dispatch})}
						       {renderUpdateFormModal({dispatch})}
					       </>}

					       topLeftActions={<>
						       <TextButton icon={MdAdd} label="Add" className="primary elevation-z3"
						                   onClick={() => openCreateModal()} />
						       {/*<IconButton icon={MdRefresh} title="Refresh data"*/}
						       {/*            onClick={() => dispatch({type:'reloadContent'})} />*/}
						       <IconButton icon={MdRefresh} title="Refresh data"
						                   onClick={action_reloadContent} onClickArgs={dispatch} />
					       </>}
					       topRightActions={<>
						       {/*<TextButton label="Reset filter" onClick={() => resetFilter(dispatch)} />*/}
						       <TextButton label="Reset filter" onClick={action_resetFilter} onClickArgs={dispatch} />

						       <ToggleGroup label="Active" value={filterActive(state)}
						                    onChange={(newValue) => filterActiveChange(newValue, state, dispatch)}
						                    items={[
							                    {value:true, icon:MdCheck, activeClassName:'icon-within-green'},
							                    {value:false, icon:MdClose, activeClassName:'icon-within-red'},
						                    ]} />
						       <ToggleGroup label="JenSec" value={filterJenSec(state)}
						                    onChange={(newValue) => filterJenSecChange(newValue, state, dispatch)}
						                    items={[
							                    {value:true, icon:MdCheck, activeClassName:'icon-within-green'},
							                    {value:false, icon:MdClose, activeClassName:'icon-within-red'},
						                    ]} />
					       </>}
					       table={<>
						       <Table2 columnDefs={columnDefs} className="fluid"
						               dataState={itemsData} state={state} dispatch={dispatch} />
					       </>}
					       bottomLeftAction={<>
						       {state.selection.length > 0 && (
							       <div className="Selection">
								       <div className="SelectionCount">Selection: {state.selection.length}</div>
								       <TextButton icon={MdClear} label="Clear selection"
								                   onClick={action_resetSelection} onClickArgs={dispatch} />
								       
								       {/*onClickArgs does not support multiple args yet*/}
								       <TextButton icon={MdDelete} label="Delete selection"
								                   onClick={() => deleteMultipleItems(state.selection, dispatch)} />
							       </div>
						       )}
					       </>}
					       bottomRightAction={<>
						       <PaginationUsingCursor pagination={state.pagination} itemsData={itemsData}
						                              dispatch={dispatch} />
					       </>}
				       />
			       ), [renderCreateFormModal, renderUpdateFormModal, columnDefs, openCreateModal]) as CrudPageLayout<CertMemberModel>}
		/>
	)
}

type OpenUpdateModalWrapper<T> = {openUpdateModal:(t?:Partial<T>) => void, row:T, dispatch:CrudPage2Dispatcher<T>};
const openUpdateModalWrapper = ({openUpdateModal, row, dispatch}:OpenUpdateModalWrapper<CertMemberModel>) => {
	openUpdateModal(row);
}

const filterActive = (state:CrudPage2State):boolean | null => {
	const currFilterActive = state.filters.find((f:string) => f.startsWith('active:'));
	if (currFilterActive === 'active:b:eq:true') {
		return true;
	} else if (currFilterActive === 'active:b:eq:false') {
		return false;
	} else {
		return null;
	}
}
const filterActiveChange = (newValue:boolean | null, state:CrudPage2State, dispatch:CrudPage2Dispatcher) => {
	const currValue = filterActive(state);
	if (currValue === newValue) {
		dispatch({type:'changeFilters', removeFields:['active']})
		return;
	}
	if (newValue === true) {
		dispatch({type:'changeFilters', addFilters:['active:b:eq:true']})
	} else {
		dispatch({type:'changeFilters', addFilters:['active:b:eq:false']})
	}
}
const filterJenSec = (state:CrudPage2State):boolean | null => {
	const currFilterActive = state.filters.find((f:string) => f.startsWith('jensec:'));
	if (currFilterActive === 'jensec:b:eq:true') {
		return true;
	} else if (currFilterActive === 'jensec:b:eq:false') {
		return false;
	} else {
		return null;
	}
}

const filterJenSecChange = (newValue:boolean | null, state:CrudPage2State, dispatch:CrudPage2Dispatcher) => {
	const currValue = filterJenSec(state);
	if (currValue === newValue) {
		dispatch({type:'changeFilters', removeFields:['jensec']})
		return;
	}
	if (newValue === true) {
		dispatch({type:'changeFilters', addFilters:['jensec:b:eq:true']})
	} else {
		dispatch({type:'changeFilters', addFilters:['jensec:b:eq:false']})
	}
}

const handleCreate:OnSaveCallbackType<CertMemberModel, { dispatch:CrudPage2Dispatcher<CertMemberModel> }> = async (data:CertMemberModel, {dispatch}) => {
	console.warn('handleCreate before', data);
	const createdItemOrError = await crudService.createOne(data);
	console.warn('handleCreate after', createdItemOrError);
	if (createdItemOrError.ok) {
		dispatch({type:'reloadContent'});
		return null;
	} else {
		dispatch({type:'reloadContent'});
		return {[GLOBAL_ERROR_KEY]:createdItemOrError.error};
	}
};

const handleUpdate:OnSaveCallbackType<CertMemberModel, { dispatch:CrudPage2Dispatcher<CertMemberModel> }> = async (data:CertMemberModel, {dispatch}) => {
	console.warn('handleUpdate before', data);
	const updatedItemOrError = await crudService.updateOne(data);
	console.warn('handleUpdate after', updatedItemOrError);
	if (updatedItemOrError.ok) {
		dispatch({type:'reloadContent'});
		return null;
	} else {
		//TODO optimize for conflict
		dispatch({type:'reloadContent'});
		return {[GLOBAL_ERROR_KEY]:updatedItemOrError.error};
	}
};

const deleteOneItem = async ({row, dispatch}:IconColumnDef.OnClickArgs<CertMemberModel>) => {
	//TODO implement our own confirm popup with application style
	const confirmed = window.confirm(`Are you sure to delete: "${row.displayName}"?`);
	if (!confirmed) {
		return;
	}

	dispatch({type:'setLoading'});

	const deleteResponse = await crudService.deleteOne(row.id, row.version);
	if (deleteResponse.ok) {
		dispatch({type:'reloadContent'});
	} else {
		if (deleteResponse.code === 409) {
			//TODO optimize for conflict
			// notificationService.error('conflict');
			dispatch({type:'reloadContent'});
		} else {
			// notificationService.error('error');
			dispatch({type:'reloadContent'});
		}
	}
};
const deleteMultipleItems = async (selectedRows:CertMemberModel[], dispatch:CrudPage2Dispatcher<CertMemberModel>) => {
	const confirmed = window.confirm(`Are you sure to delete: ${selectedRows.length} items?`);
	if (!confirmed) {
		return;
	}

	console.info('Deleting', selectedRows);
	dispatch({type:'setLoading'})

	let okCount = 0;
	let koCount = 0;
	await PromiseHelper.batch(selectedRows, async (row:CertMemberModel) => {
		const deleteResponse = await crudService.deleteOne(row.id, row.version);
		if (deleteResponse.ok) {
			okCount++;
		} else {
			koCount++;
			if (deleteResponse.code === 409) {
				console.warn('Conflict when deleting', row.id, row.version);
				// notificationService.error('conflict');
			}
		}
	}, 10);
	if (koCount === 0) {
		// notificationService.success(`Success deletion of ${okCount} items`);
	} else if (okCount !== 0) {
		// notificationService.info(`Only ${okCount} deletion(s) were successful, with ${koCount} failures`);
	} else {
		// notificationService.error(`Error for the ${koCount} deletions`);
	}
	dispatch({type:'reloadContent'});
};

const FormFragment = ({f, item}:CrudFormFragmentProps<CertMemberModel>) => {
	console.info('FormFragment render');

	return (
		<>
			{item.id && (
				<StringField f={f} name="id" label="ID" readonly={true} />
			)}

			<StringField f={f} name="displayName" label="Display name" />
			<StringField f={f} name="email" label="Email" />

			<TextField f={f} name="note" label="Note" />
			<StringField f={f} name="jiraUsername" label="Jira Username" />
			<StringField f={f} name="githubUsername" label="GitHub Username" />

			<BooleanField f={f} name="jensec" label="JenSec member" />
			<BooleanField f={f} name="active" label="Active" />
		</>
	);
}
const formSchema = zod.object({
	displayName:zod.string(),
	email:zod.string(),
	note:zod.string(),
	jiraUsername:zod.string(),
	githubUsername:zod.string(),
	jensec:zod.boolean(),
	active:zod.boolean(),
	...ZodCrudModel_DEFAULT,
});
