import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {withFixedArguments} from "../../../commons/hoc/withFixedArguments";
import {OtherHtmlAttributes} from "../../form/types/OtherHtmlAttributes";
import {AvailableColor} from "./AvailableColor";

import "./Badge.scss";

type BadgeProps = {
	value?:number | string
	color:AvailableColor
	pill?:boolean
	className?:string
} & OtherHtmlAttributes

export const Badge = ({value, pill = true, color, className, ...rest}:BadgeProps) => {
	if (!value) {
		return null;
	}

	const classes = ClassHelper.combine('Badge', pill && 'pill', color && `color-${color}`, className);
	return (
		<span className={classes} {...rest}>{value}</span>
	);
}

export const PrimaryBadge = withFixedArguments(Badge, {color:'primary'});
export const ErrorBadge = withFixedArguments(Badge, {color:'red'});
export const WarningBadge = withFixedArguments(Badge, {color:'orange'});
export const InfoBadge = withFixedArguments(Badge, {color:'blue'});
export const SuccessBadge = withFixedArguments(Badge, {color:'green'});
export const NeutralBadge = withFixedArguments(Badge, {color:'grey'});
export const UnknownBadge = withFixedArguments(Badge, {color:'purple'});
