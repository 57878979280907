import * as React from 'react';
import {ReactNode, useCallback, useState} from 'react';
import {ClassHelper} from "../../../commons/helpers/ClassHelper";

import "./Section.scss";
import {TopNav} from "../../../app/nav/top/TopNav";

type Props = {
	className?:string
	noTopNav?:boolean
	sideNav?:JSX.Element
	children:ReactNode
}

/**
 * A section contains multiple pages managed through the sidenav.
 * It's not meant to be used for a single page.
 */
export const Section = ({className, noTopNav, sideNav, children}:Props) => {
	const [sideNavIsOpen, setSideNavIsOpen] = useState(false);

	const topNavReactiveButtonClickHandler = useCallback(() => {
		setSideNavIsOpen(prevState => {
			console.info(`Section:topNavReactiveButtonClickHandler: ${prevState} => ${!prevState}`);
			return !prevState;
		});
	}, []);
	
	return (
		<div className={ClassHelper.combine('Section', className)}>
			{!noTopNav && (
				<TopNav onReactiveButtonClick={topNavReactiveButtonClickHandler}/>
			)}
			<div className={ClassHelper.combine('BelowTopNav', sideNavIsOpen ? 'sidenav-open' : 'sidenav-close')}>
				{sideNav}
				<div className="section-page-content">
					{children}
				</div>
			</div>
			<div className="Footer">
				<div className="version"
				     title={process.env.REACT_APP_BUILD_DATE ? `Built on ${process.env.REACT_APP_BUILD_DATE}` : undefined}>
					Version: {process.env.REACT_APP_VERSION}
					{process.env.NODE_ENV === 'development' && (
						<span className="version-type version-type-dev">development</span>
					)}
				</div>
			</div>
		</div>
	);
}
