import * as React from "react";
import {Path, UseFormReturn} from "react-hook-form";
import {useUniqueId} from "../../../commons/hooks/useUniqueId";

import {FormRow, FormRowProps} from "../components/FormRow";
import "./StringField.scss"

export type StringFieldProps = {
	f:UseFormReturn<any>
	name:Path<any>
	readonly?:boolean
	disabled?:boolean
} & FormRowProps;

export const StringField = ({f, name, readonly, disabled, ...rowProps}:StringFieldProps) => {
	// console.log(`StringField(${name})`);
	const uniqueId = useUniqueId();

	const {register, formState:{errors}} = f
	const fieldError = errors[name];

	return (
		<FormRow {...rowProps} className="StringField" errorMessage={fieldError?.message as string} labelId={uniqueId}>
			<input type="text" {...register(name)} id={uniqueId}
			       readOnly={readonly} disabled={disabled}
			       className="form-control" />
		</FormRow>
	);
}
