import {FunctionNM} from "../types/FunctionNM";

type Comparator<T> = (a:T, b:T) => number;

// Allow the usage of a partial definition for the sorter
type InferP<F> = F extends infer P ? P : never;
type AnyComparator<T extends object, P = InferP<T>> = keyof T | FunctionNM<[P], number>

export class SortHelper {
	static by<T extends object>(...comps:AnyComparator<T>[]):Comparator<T> {
		if (comps.length === 0) {
			throw new Error('Minimum of one field is required');
		} else if (comps.length === 1) {
			return this.byOne(comps[0]);
		} else if (comps.length === 2) {
			const by1 = this.byOne(comps[0]);
			const by2 = this.byOne(comps[1]);
			return (a:T, b:T) => {
				const first = by1(a, b);
				if (first !== 0) {
					return first;
				} else {
					return by2(a, b);
				}
			}
		} else {
			const byArray = comps.map(c => this.byOne(c));
			return (a:T, b:T) => {
				for (let i = 0; i < byArray.length; i++) {
					const byCurr = byArray[i];
					const curr = byCurr(a, b);
					if (curr !== 0) {
						return curr;
					}
				}
				return 0;
			}
		}
	}

	static byOne<T extends object>(comp:AnyComparator<T>):Comparator<T> {
		if (typeof comp === 'function') {
			// return this.byClosure(comp as FunctionNM<[T], number>);
			return this.byClosure(comp);
		} else {
			return this.byField(comp);
		}
	}

	static byClosure<T extends object>(closure:FunctionNM<[InferP<T>], number>):Comparator<T> {
		return (a:T, b:T) => {
			return this.compare(closure(a as InferP<T>), closure(b as InferP<T>));
		}
	}

	static byField<T extends object>(fieldName:keyof T):Comparator<T> {
		return (a:T, b:T) => {
			return this.compare(a[fieldName], b[fieldName]);
		}
	}

	static byFields<T extends object>(...fieldNames:(keyof T)[]):Comparator<T> {
		if (fieldNames.length === 0) {
			throw new Error('Minimum of one field is required');
		} else if (fieldNames.length === 1) {
			return this.byField(fieldNames[0]);
		} else if (fieldNames.length === 2) {
			const key1 = fieldNames[0];
			const key2 = fieldNames[1];
			return (a:T, b:T) => {
				const first = this.compare(a[key1], b[key1]);
				if (first !== 0) {
					return first;
				} else {
					return this.compare(a[key2], b[key2]);
				}
			}
		} else {
			return (a:T, b:T) => {
				for (let i = 0; i < fieldNames.length; i++) {
					const key = fieldNames[i];
					const curr = this.compare(a[key], b[key]);
					if (curr !== 0) {
						return curr;
					}
				}
				return 0;
			}
		}
	}

	private static compareString(a:string, b:string):number {
		return a.localeCompare(b);
	}

	private static compareNumber(a:number, b:number):number {
		return b - a;
	}

	private static compare<T>(a:T, b:T):number {
		const type = typeof a;
		if (type === 'string') {
			return this.compareString(a as string, b as string);
		} else if (type === 'number') {
			return this.compareNumber(a as number, b as number);
		} else {
			console.warn('Type not supported: ' + type);
			return 0;
		}
	}
}
