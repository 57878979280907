import {ReactNode, useCallback} from "react";
import {MdArrowDownward, MdArrowUpward} from "react-icons/md";
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {ColumnSortInfo} from "./ColumnSortInfo";
import {CrudTableColumnDefinition} from "./CrudTableColumnDefinition";

type Props = {
	columnDef:CrudTableColumnDefinition<any>

	order:ColumnSortInfo
	onOrderChange:(columnId:string, columnType:'integer' | 'float' | 'string') => void
}

export const TableHeader = ({columnDef, order, onOrderChange}:Props) => {
	let sortIcon:ReactNode = null;
	if (order.columnId === columnDef.name) {
		if (order.sortDirection === 'asc') {
			sortIcon = <MdArrowUpward className="order-icon" />;
		} else {
			sortIcon = <MdArrowDownward className="order-icon" />;
		}
	}

	const handleClick = useCallback(() => {
		//TODO support other types
		const type = ({
			float:'float',
			integer:'integer'
		} as { [key:string]:'integer' | 'float' })[columnDef.type] ?? 'string';
		onOrderChange(columnDef.name, type)
	}, [columnDef, onOrderChange]);

	const divClasses = ['header'];
	columnDef.sortable && divClasses.push('sortable');

	return (
		<th className={ClassHelper.combine(`header-${columnDef.name}`, `column-${columnDef.name}`, 'content-column', columnDef.headerClassName)}
		    title={columnDef.headerTitle ? columnDef.headerTitle : undefined}>
			<div className={ClassHelper.combine('header', (columnDef.sortable === true) && 'sortable', (columnDef.sortable === 'fixed') && 'sortable-but-fix')}
			     onClick={columnDef.sortable === true ? handleClick : undefined}>
				{columnDef.header ?? '&nbsp;'}
				{sortIcon && (
					<div className="header-sort-icon">{sortIcon}</div>
				)}
			</div>
		</th>
	);
}
