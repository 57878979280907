import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {MdFirstPage, MdLastPage, MdNavigateBefore, MdNavigateNext} from "react-icons/md";
import {IconButton} from "../../components/IconButton";
import {PaginationInfo, PaginationInfoChangeHandler} from "../../crud/features/PaginationFeature";
import {useRefForSpaceEnter} from '../../form/hooks/useRefForSpaceEnter';
import {DropdownMenu2} from '../../form/inputs/dropdown/DropdownMenu2';
import {DropdownInputOption} from '../../form/types/DropdownTypes';

import "./TablePagination.scss";

export type TablePaginationProps = {
	totalResults:number
	pagination:PaginationInfo
	onPaginationChange:PaginationInfoChangeHandler
}

export const TablePagination = ({totalResults, pagination, onPaginationChange}:TablePaginationProps) => {
	// console.info('TablePagination render', totalResults, pagination);

	const maxPage = useMemo(() => {
		return Math.ceil(totalResults / pagination.pageSize);
	}, [pagination, totalResults]);

	const [firstPageEnabled, lastPageEnabled] = useMemo(() => {
		return [
			pagination.pageNo !== 1,
			pagination.pageNo < maxPage,
		]
	}, [maxPage, pagination]);

	const handleFirstPageClick = useCallback(() => {
		onPaginationChange({...pagination, pageNo:1});
	}, [pagination, onPaginationChange]);
	const handleLastPageClick = useCallback(() => {
		onPaginationChange({...pagination, pageNo:maxPage});
	}, [pagination, onPaginationChange, maxPage]);

	const handlePreviousPageClick = useCallback(() => {
		onPaginationChange({...pagination, pageNo:pagination.pageNo - 1});
	}, [pagination, onPaginationChange]);
	const handleNextPageClick = useCallback(() => {
		onPaginationChange({...pagination, pageNo:pagination.pageNo + 1});
	}, [pagination, onPaginationChange]);

	const handlePageNoChange = useCallback((newPageNo:number) => {
		onPaginationChange({...pagination, pageNo:newPageNo});
	}, [pagination, onPaginationChange]);

	const handlePageSizeChange = useCallback((newPageSize:number) => {
		onPaginationChange({...pagination, pageSize:newPageSize});
	}, [pagination, onPaginationChange]);

	return (
		<div className="TablePagination">
			<IconButton icon={MdFirstPage} onClick={handleFirstPageClick} disabled={!firstPageEnabled} tabIndex={firstPageEnabled ? 0 : -1} />

			<IconButton icon={MdNavigateBefore} onClick={handlePreviousPageClick} disabled={!firstPageEnabled} tabIndex={firstPageEnabled ? 0 : -1} />

			<div className="page-info">
				<PageNo pageNo={pagination.pageNo} maxPage={maxPage} onPageNoChange={handlePageNoChange} />
				<span className="page-sep"> / </span>
				<span className="page-max">{maxPage}</span>
			</div>

			<IconButton icon={MdNavigateNext} onClick={handleNextPageClick} disabled={!lastPageEnabled} tabIndex={lastPageEnabled ? 0 : -1} />

			<IconButton icon={MdLastPage} onClick={handleLastPageClick} disabled={!lastPageEnabled} tabIndex={lastPageEnabled ? 0 : -1} />

			<PageSizeSelector currentPageSize={pagination.pageSize} onPageSizeChange={handlePageSizeChange} />
		</div>
	);
}

type PageNoProps = {
	pageNo:number
	maxPage:number
	onPageNoChange:(newPageNo:number) => void
}

const PageNo = ({pageNo, maxPage, onPageNoChange}:PageNoProps) => {
	const [inputMode, setInputMode] = useState<boolean>(false);
	// console.info(`PageNo(${pageNo}, ${maxPage}), ${inputMode}`);

	const enableInputMode = useCallback(() => setInputMode(true), []);
	const handleChange = useCallback((e:React.FocusEvent<HTMLInputElement>) => {
		const valueInt = parseInt(e.target.value, 10);
		if (isFinite(valueInt) && valueInt + '' === e.target.value) {
			if (0 < valueInt && valueInt <= maxPage) {
				onPageNoChange && onPageNoChange(valueInt);
				setInputMode(false);
			} else {
				console.info('Outside of range', e.target.value);
			}
		} else {
			console.info('Not an integer', e.target.value);
		}
		e.target.value = '';
	}, [maxPage, onPageNoChange]);
	
	const ref = useRefForSpaceEnter();

	return (
		<div className="PageNo">
			{(maxPage === 0) ? (
				<span className="input-disabled" title="No change possible when there is no content.">0</span>
			) : (   
				(inputMode) ? (
					<input autoFocus onBlur={handleChange} placeholder={pageNo + ''} />
				) : (
					(maxPage === 1) ? (
						<span className="input-disabled"
						      title="No change possible when the max number of page is one.">{pageNo}</span>
					) : (
						<span ref={ref} className="input-inactive focusable" tabIndex={0} onClick={enableInputMode}>{pageNo}</span>
					)
				)
			)}
		</div>
	)
}

type PageSizeSelectorProps = {
	currentPageSize:number
	onPageSizeChange:(newPageSize:number) => void;
}

const PAGE_SIZES = [5, 10, 20, 50, 100];
const PAGE_SIZE_OPTIONS:DropdownInputOption[] = PAGE_SIZES.map(s => ({value:s + '', label:s + ''}));

const PageSizeSelector = ({currentPageSize, onPageSizeChange}:PageSizeSelectorProps) => {
	const handleChange = useCallback((newValue:string) => {
		const newPageSize = parseInt(newValue, 10);
		onPageSizeChange && onPageSizeChange(newPageSize);
	}, [onPageSizeChange]);

	const values = useMemo(() => {
		if (PAGE_SIZES.includes(currentPageSize)) {
			return PAGE_SIZE_OPTIONS;
		} else {
			return [{value:currentPageSize + '', label:currentPageSize + ''}].concat(PAGE_SIZE_OPTIONS);
		}
	}, [currentPageSize]);

	const selectedValue = currentPageSize + '';

	//TODO reduce emphasis on the pageSize select, keeping is simple text like the pageNo?
	return (
		<div className="PageSize">
			<span className="item-per-page-label">Eléments par page :</span>

			{/*<DropdownInput values={values} onValueSelected={handleChange} value={selectedValue} />*/}
			{/*<TextButton label={selectedValue}*/}
			{/*            onClick={handleButtonClick} type="bordered" afterIcon={MdArrowDropDown} />*/}
			{/*<DropdownMenu open={open} values={values} selectedValue={selectedValue} onValueSelected={handleChange} />*/}
			<DropdownMenu2 values={values} selectedValue={selectedValue} onValueSelected={handleChange} />
		</div>
	);
}
