import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {HashHelper} from "../../../commons/helpers/HashHelper";
import {LocalStorageHelper} from "../../../commons/helpers/LocalStorageHelper";
import {SetState} from "../../../commons/types/SetState";
import {CacheWhenCondition} from "../../components/CacheWhenCondition";
import {Placeholder} from "../../components/misc/Placeholder";
import {TablePagination} from "../../table/pagination/TablePagination";
import {QueryData} from "../QueryData";
import {SetQueryDataAndSelection} from "./SetQueryDataAndSelection";

export interface PaginationInfo {
	pageNo:number;
	pageSize:number;
}

// export const PaginationInfo_DEFAULT:PaginationInfo = {pageSize:5, pageNo:1};
export const PaginationInfo_DEFAULT:PaginationInfo = {pageSize:10, pageNo:1};

export type PaginationInfoChangeHandler = (newPagination:PaginationInfo) => void;

export type PaginationFeature = {
	pagination:PaginationInfo
	handlePaginationChange:PaginationInfoChangeHandler
	filteredTotalResults:number
	totalResults:number
}

// const DEFAULT_PAGINATION = {pageSize:15, pageNo:1};
// const DEFAULT_PAGINATION = {pageSize:5, pageNo:1};
// const DEFAULT_PAGINATION = {pageSize:3, pageNo:1};

/**
 * Order of priorities for values: url > cookie > default > hardcoded
 */
export const usePaginationDefault = (
	pageId:string,
	defaultPagination:PaginationInfo | undefined = undefined
) => {
	const [pagination, setPagination] = useState<PaginationInfo>(() => {
		// 1) Check the URL
		const hash = HashHelper.getHash();
		if (hash) {
			/* allowed characters: https://stackoverflow.com/a/26119120
			 0 - 9
			 a - z
			 A - Z
			 ? / : @ - . _ ~ ! $ & ' ( ) * + , ; =
			 */

			//TODO support multiple table in the same page?
			
			//TODO define format
			// format: key1=value1&key2=value2
			const fragments = hash.split('&');
			const paginationFragment = fragments.find(el => el.startsWith('p='));
			if (paginationFragment && paginationFragment.includes(',')) {
				const values = paginationFragment.substring('p='.length).split(',');
				if (values.length) {
					const pageNo = parseInt(values[0], 10);
					const pageSize = parseInt(values[1], 10);
					// ensure parsing was not necessarily
					if (pageNo + '' === values[0] && pageSize + '' === values[1]) {
						return {pageNo, pageSize};
					}
				}
			}
		}

		// 2) Check the localStorage
		const localStorageKey = `${pageId}:pagination`;
		const paginationFromLocalStorage = LocalStorageHelper.getStringData(localStorageKey);
		if (paginationFromLocalStorage) {
			try {
				const paginationJson:PaginationInfo = JSON.parse(paginationFromLocalStorage);
				if (paginationJson.pageSize !== undefined && paginationJson.pageNo !== undefined) {
					return {pageSize:paginationJson.pageSize, pageNo:paginationJson.pageNo};
				}
			} catch (e) {
				console.warn('Error to parse JSON from cookie', e);
			}

			// clear invalid/corrupted data
			LocalStorageHelper.removeData(localStorageKey);
		}

		// 3) Check the provided value
		if (defaultPagination) {
			return defaultPagination;
		}

		// 4) Check the default value
		return PaginationInfo_DEFAULT;
	});

	return {
		pagination,
		setPagination
	};
}

export const usePaginationFeature = (
	pagination:PaginationInfo,
	setPagination:SetState<PaginationInfo>,
	setQueryDataAndSelection:SetQueryDataAndSelection,
	/**
	 * Number of filtered entries in total.
	 * If no filter enabled, it will be equal to the totalResults
	 */
	filteredTotalResults:number,
	/**
	 * Number of entries in total
	 */
	totalResults:number,
) => {
	const handlePaginationChange = useCallback((info:PaginationInfo) => {
		const maxPage = Math.ceil(filteredTotalResults / info.pageSize);
		if (info.pageNo > maxPage) {
			info.pageNo = maxPage;
		}
		setPagination(info);
		setQueryDataAndSelection((curr:QueryData) => {
			return {...curr, offset:info.pageSize * (info.pageNo - 1), limit:info.pageSize, cacheId:curr.cacheId + 1};
		})
	}, [filteredTotalResults, setPagination, setQueryDataAndSelection]);

	//TODO add storage of pagination info to url/localStorage

	const paginationFeature:PaginationFeature = useMemo(() => {
		return {pagination, handlePaginationChange, filteredTotalResults, totalResults};
	}, [pagination, handlePaginationChange, filteredTotalResults, totalResults]);

	return {
		paginationFeature
	};
}

export const usePaginationPanel = (
	paginationFeature:PaginationFeature
) => {
	//TODO transform into "render-props" (https://reactjs.org/docs/render-props.html)
	const PaginationPanel = useMemo(() => {
		if (!paginationFeature.filteredTotalResults) {
			return <span />;
		}
		// console.info('PaginationPanel useMemo', pagination, filteredTotalResults, totalResults);
		return (
			<CacheWhenCondition condition={paginationFeature.filteredTotalResults === -1}
			                    defaultValue={<Placeholder width={300} height={40} padding={5} />}>
				<TablePagination totalResults={paginationFeature.filteredTotalResults} pagination={paginationFeature.pagination}
				                 onPaginationChange={paginationFeature.handlePaginationChange} />
			</CacheWhenCondition>
		);
	}, [paginationFeature]);

	return {
		PaginationPanel,
	};
}
