import {useEffect, useState} from 'react';

export const useCache = <T>(value:T, condition:boolean, defaultValue:T | null = null):T | null => {
	const [cache, setCache] = useState<T | null>(() => {
		// console.info(`useCache default render`);
		if (defaultValue) {
			return defaultValue;
		} else {
			return null;
		}
	});

	// console.info(`useCache render condition=${condition}`);

	useEffect(() => {
		if (!condition) {
			// console.info(`useCache render cache`);
			setCache(value);
		}
	}, [value, condition]);

	return condition ? cache : value;
}
