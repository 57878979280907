import React, {useCallback, useState} from "react";
import {
	MdArrowDownward,
	MdArrowUpward,
	MdDelete,
	MdFileDownload,
	MdFileUpload,
	MdUnfoldMore,
	MdUnfoldLess
} from "react-icons/md";
import {ArrayHelper} from "@commons/helpers/ArrayHelper";
import {FunctionNM} from "@commons/types/FunctionNM";
import {IconButton} from "./IconButton";

import "./LogPanel.scss";

export type LogAppender = (newRow:any) => void;

export const useLogPanel = () => {
	const [logRows, setLogRows] = useState<string[]>([]);
	const appendLog:LogAppender = useCallback((newRow) => {
		setLogRows(curr => {
			const time = new Date().toISOString();
			if (typeof newRow === 'string') {
				curr = curr.concat(time + ' ' + newRow);
				return curr;
			} else if (Array.isArray(newRow)) {
				if (newRow.length) {
					curr = curr.concat(time + ' ' + newRow.shift());
					curr = curr.concat(newRow);
					return curr;
				} else {
					return curr;
				}
			} else {
				const content = JSON.stringify(newRow);
				curr = curr.concat(time + ' ' + content);
				return curr;
			}
		})
	}, []);
	const clearLog = useCallback(() => {
		setLogRows([]);
	}, []);

	return {logRows, clearLog, appendLog};
}

type Props = {
	rows:string[]
	numRowsToDisplay?:number
	onClearLog?:FunctionNM<any, void>
	expandable?:boolean
}

const MIN_DISPLAY_ROWS = 0;
const URL_REGEX = /(https?:\/\/[^\s/$.?#].[^\s"']*)/g;

export const LogPanel = ({rows, numRowsToDisplay, onClearLog, expandable}:Props) => {
	const numRows = numRowsToDisplay || 10;
	expandable = expandable === undefined ? true : expandable;

	// negative index relative to rows.length to adjust automatically
	const [currIndex, setCurrIndex] = useState<number>(-numRows);
	const [currMaxRows, setCurrMaxRows] = useState<number>(numRows);

	const clearLog = useCallback(() => {
		if (onClearLog) {
			setCurrIndex(-numRows);
			onClearLog();
		}
	}, [numRows, onClearLog])

	const indexMoveToTop = useCallback(() => {
		setCurrIndex(-rows.length);
	}, [rows]);
	const indexMoveToBottom = useCallback(() => {
		setCurrIndex(-currMaxRows);
	}, [currMaxRows]);

	const indexMoveUp = useCallback(() => {
		setCurrIndex(curr => Math.max(-rows.length, curr - 3));
	}, [rows]);
	const indexMoveDown = useCallback(() => {
		setCurrIndex(curr => Math.min(-currMaxRows, curr + 3));
	}, [currMaxRows]);

	const displayMoreRows = useCallback(() => {
		setCurrMaxRows(curr => curr + 3);
		setCurrIndex(curr => Math.max(-rows.length, curr - 3));
	}, [rows]);
	const displayLessRows = useCallback(() => {
		const newMaxRows = Math.max(MIN_DISPLAY_ROWS, currMaxRows - 3);
		setCurrMaxRows(newMaxRows);
		setCurrIndex(curr => Math.min(-newMaxRows, curr + 3));
	}, [currMaxRows]);

	const focusStart = Math.max(0, rows.length + currIndex);
	const focusEnd = Math.min(focusStart + currMaxRows, rows.length);
	return (
		<div className="LogPanel">
			<div className="LogPanelActions">
				{/*{currIndex}<br />*/}
				{/*{focusStart}<br />*/}
				{/*{focusEnd}<br />*/}
				{/*{rows.length}<br />*/}
				{onClearLog && (<>
					<IconButton icon={MdDelete} onClick={clearLog} title="Clear the log panel" />
					<span className="horizontal-spacing" />
				</>)}

				{expandable && (<>
					<IconButton icon={MdUnfoldMore} onClick={displayMoreRows}
					            title="Increase the number of rows to display" />
					<IconButton icon={MdUnfoldLess} onClick={displayLessRows}
					            title="Decrease the number of rows to display" />
					<span className="horizontal-spacing" />
				</>)}

				<IconButton icon={MdFileUpload} onClick={indexMoveToTop} title="Scroll to the top of the logs" />
				<IconButton icon={MdArrowUpward} onClick={indexMoveUp} title="Scroll up a bit" />
				<IconButton icon={MdArrowDownward} onClick={indexMoveDown} title="Scroll down a bit" />
				<IconButton icon={MdFileDownload} onClick={indexMoveToBottom}
				            title="Scroll to the bottom of the logs" />
			</div>

			{ArrayHelper.subMap(rows, focusStart, focusEnd, ((row, i) => {
				if (row.includes('http')) {
					const allMatches = Array.from(row.matchAll(URL_REGEX));
					if (allMatches.length === 0) {
						return (
							<div key={i} className="LogPanelRow">{row}</div>
						)
					} else {
						const out = [];
						let currIndex = 0;
						for (let j = 0; j < allMatches.length; j++) {
							const currMatch = allMatches[j];
							const index = currMatch.index!;
							const matchLength = currMatch[0].length;
							out.push(row.substring(currIndex, index));

							const href = row.substring(index, index + matchLength);
							out.push(<a href={href} target="_blank" rel="noopener noreferrer" key={j}>{href}</a>);
							currIndex = index + matchLength;
						}
						out.push(row.substring(currIndex));
						return (
							<div key={i} className="LogPanelRow">{out}</div>
						);
					}
				} else {
					return (
						<div key={i} className="LogPanelRow">{row}</div>
					)
				}
			}))}
		</div>
	);
}
