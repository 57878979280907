import {TaskBase} from "@commons/models/Task";
import * as React from "react";
import {CrudModel} from "../../../../commons/types/CrudModel";
import {ClosureColumnDef} from "../../../../framework/table/columns/ClosureColumnDef";
import {StringColumnDefOps} from "../../../../framework/table/columns/StringColumnDef";
import {Priority} from "./Priority";

function priorityToReactNode<T extends CrudModel & TaskBase>(row: T): React.ReactNode {
    const priority = row.priority;
    return <Priority priority={priority}/>
}

export class PriorityColumnDef<T extends CrudModel & TaskBase> extends ClosureColumnDef<T> {
    //TODO for the moment, sortable=false as it's not really a supported information
    constructor(opts: StringColumnDefOps = {size: 80, sortable: false}) {
        super('priority', 'Priority', priorityToReactNode, opts);
    }
}
