import {IsoDateString, IsoDateString_1900} from "../types/IsoDateString";
import {JiraAuthor, JiraAuthor_DEFAULT} from "../types/JiraTypes";
import {TaskBase, TaskBase_DEFAULT} from "./Task";

export const TaskSecurityType_TRIAGE = 'triage';

export const TaskSecurityType_LABEL = 'labels';
export const TaskSecurityType_REPRODUCTION = 'reproduction';
export const TaskSecurityType_ASSIGN = 'assign';
export const TaskSecurityType_CREDIT = 'credit';
export const TaskSecurityType_ADVISORY_DATA = 'advisory-data';
export const TaskSecurityType_REVIEW = 'review';

export const TaskSecurityType_NON_CERT_COMMENT = 'non-cert-comment';
export const TaskSecurityType_CERT_ATTENTION = 'cert-attention';
export const TaskSecurityType_DISCLOSURE_DEADLINE = 'disclosure-deadline';

export type TaskSecurityType =
	typeof TaskSecurityType_TRIAGE |
	typeof TaskSecurityType_LABEL |
	typeof TaskSecurityType_REPRODUCTION |
	typeof TaskSecurityType_ASSIGN |
	typeof TaskSecurityType_CREDIT |
	typeof TaskSecurityType_ADVISORY_DATA |
	typeof TaskSecurityType_REVIEW |
	typeof TaskSecurityType_NON_CERT_COMMENT |
	typeof TaskSecurityType_CERT_ATTENTION |
	typeof TaskSecurityType_DISCLOSURE_DEADLINE
	;

type Task_JiraSecurity_Attrs = {
	//name = ticket.summary
	// ticket:string => targetId
	// ticketNum:number => targetIdNum
	/** Order of the status in the workflow for column sort */
	// ticketStatusNumber:number, => targetStatusNum
	ticketStatusColor:string,
	// ticketStatusName:string, => targetStatus
	// numComments:number
	// lastCheckDate:IsoDateString
	/** Per task specific date that has an index */
	customDate1:IsoDateString | null
}

type Task_JiraSecurity_TypeAttrs<T extends TaskSecurityType = TaskSecurityType> = {
	type:T
}
export type Task_JiraSecurity<T extends TaskSecurityType = TaskSecurityType> =
	TaskBase
	& Task_JiraSecurity_Attrs
	& Task_JiraSecurity_TypeAttrs<T>;

const task_JiraSecurity_Attrs:Task_JiraSecurity_Attrs = {
	// ticket:'',
	// ticketNum:0,

	// ticketStatusNumber:-1,
	ticketStatusColor:'',
	// ticketStatusName:'',
	// numComments:0,
	// lastCheckDate:IsoDateString_DEFAULT,
	customDate1:null
};
export const Task_JiraSecurity_DEFAULT:TaskBase & Task_JiraSecurity_Attrs = Object.assign({}, TaskBase_DEFAULT,
	task_JiraSecurity_Attrs
);

export type Task_JiraSecurityBase<T extends TaskSecurityType> = Task_JiraSecurity<T>;

function defaultForType<T extends TaskSecurityType>(type:T):{ type:T } {
	return {type:type};
}

/**
 * @task First task when we receive a report
 * @source CERT ML Jira update. If the ticket was created by a CERT member, no triage task
 * @truth Jira ticket, labels, comment
 * @autoClose If the ticket has any label, is specific-plugin + has the [pluginName] in the summary, has an assignee, or get a comment from CERT
 */
export type Task_JiraSecurity_Triage =
	Task_JiraSecurityBase<typeof TaskSecurityType_TRIAGE>
	& Task_JiraSecurity_Triage_Attrs

export type Task_JiraSecurity_Triage_Attrs = {}
export const task_JiraSecurity_Triage_Attrs:Task_JiraSecurity_Triage_Attrs = {};
export const task_JiraSecurity_Triage_DEFAULT:Task_JiraSecurity_Triage = Object.assign({}, Task_JiraSecurity_DEFAULT,
	defaultForType(TaskSecurityType_TRIAGE),
	task_JiraSecurity_Triage_Attrs
);

/**
 * @task Add the labels about meta data on the plugin
 * @source CERT ML Jira update
 * @truth Jira ticket, labels
 * @autoClose If the ticket receives one of the automatic labels: <1% 1-3% 3-10% 10+%
 */
export type Task_JiraSecurity_Labels =
	Task_JiraSecurityBase<typeof TaskSecurityType_LABEL>
	& Task_JiraSecurity_Labels_Attrs

export type Task_JiraSecurity_Labels_Attrs = {}
export const task_JiraSecurity_Labels_Attrs:Task_JiraSecurity_Labels_Attrs = {};
export const task_JiraSecurity_Labels_DEFAULT:Task_JiraSecurity_Labels = Object.assign({}, Task_JiraSecurity_DEFAULT,
	defaultForType(TaskSecurityType_LABEL),
	task_JiraSecurity_Labels_Attrs
);

/**
 * @task Ensure the reproduction steps lead to a vulnerability
 * @source CERT ML Jira update
 * @truth Jira ticket, labels
 * @autoClose if the ticket receives the label "confirmed-by-cert"
 *
 * In case of "failed-to-reproduced", increasing priority to high
 */
export type Task_JiraSecurity_Reproduction =
	Task_JiraSecurityBase<typeof TaskSecurityType_REPRODUCTION>
	& Task_JiraSecurity_Reproduction_Attrs
	& {};

export type Task_JiraSecurity_Reproduction_Attrs = {
	failedToReproduce:boolean
}
export const task_JiraSecurity_Reproduction_Attrs:Task_JiraSecurity_Reproduction_Attrs = {
	failedToReproduce:false,
};
export const task_JiraSecurity_Reproduction_DEFAULT:Task_JiraSecurity_Reproduction = Object.assign({}, Task_JiraSecurity_DEFAULT,
	defaultForType(TaskSecurityType_REPRODUCTION),
	task_JiraSecurity_Reproduction_Attrs
);

/**
 * @task Assign the ticket to the maintainers / security contact from RPU.
 * Ensure there are some recommendations for the maintainers about the correction
 *
 * @source CERT ML Jira update
 * @truth Jira ticket, assignee or labels
 * @autoClose if the ticket got an assignee or
 *
 * TODO the ticket got 'skip-auto-assign' (which lead to CERT-attention task)
 */
export type Task_JiraSecurity_Assign =
	Task_JiraSecurityBase<typeof TaskSecurityType_ASSIGN>
	& Task_JiraSecurity_Assign_Attrs

export type Task_JiraSecurity_Assign_Attrs = {}
export const task_JiraSecurity_Assign_Attrs:Task_JiraSecurity_Assign_Attrs = {};
export const task_JiraSecurity_Assign_DEFAULT:Task_JiraSecurity_Assign = Object.assign({}, Task_JiraSecurity_DEFAULT,
	defaultForType(TaskSecurityType_ASSIGN),
	task_JiraSecurity_Assign_Attrs
);

/**
 * @task Ask the reporter to provide the credit information they want to see on the advisory
 * @source CERT ML Jira update
 * @truth Jira ticket, customfield_10820
 * @autoClose if the custom field customfield_10820 is set with a value (TODO if we have to ignore some values like TBD)
 */
export type Task_JiraSecurity_Credit =
	Task_JiraSecurityBase<typeof TaskSecurityType_CREDIT>
	& Task_JiraSecurity_Credit_Attrs

export type Task_JiraSecurity_Credit_Attrs = {}
export const task_JiraSecurity_Credit_Attrs:Task_JiraSecurity_Credit_Attrs = {};
export const task_JiraSecurity_Credit_DEFAULT:Task_JiraSecurity_Credit = Object.assign({}, Task_JiraSecurity_DEFAULT,
	defaultForType(TaskSecurityType_CREDIT),
	task_JiraSecurity_Credit_Attrs
);

/**
 * @task Write advisory data for the ticket
 * @source CERT ML Jira update
 * @truth Jira ticket, labels
 * @autoClose if the ticket receives the label "has-advisory-data" or if there is a PR in advisory-data repo
 */
export type Task_JiraSecurity_AdvisoryData =
	Task_JiraSecurityBase<typeof TaskSecurityType_ADVISORY_DATA>
	& Task_JiraSecurity_AdvisoryData_Attrs

export type Task_JiraSecurity_AdvisoryData_Attrs = {}
export const task_JiraSecurity_AdvisoryData_Attrs:Task_JiraSecurity_AdvisoryData_Attrs = {};
export const task_JiraSecurity_AdvisoryData_DEFAULT:Task_JiraSecurity_AdvisoryData = Object.assign({}, Task_JiraSecurity_DEFAULT,
	defaultForType(TaskSecurityType_ADVISORY_DATA),
	task_JiraSecurity_AdvisoryData_Attrs
);

/**
 * TODO not sure we want to have this for every plugin, TBD
 * @task Ensure the correction is effective
 * @source CERT ML Jira update
 * @truth Jira ticket, labels
 * @autoClose if the ticket receives the label "reviewed-by-cert"
 */
export type Task_JiraSecurity_Review =
	Task_JiraSecurityBase<typeof TaskSecurityType_REVIEW>
	& Task_JiraSecurity_Review_Attrs

export type Task_JiraSecurity_Review_Attrs = {}
export const task_JiraSecurity_Review_Attrs:Task_JiraSecurity_Review_Attrs = {};
export const task_JiraSecurity_Review_DEFAULT:Task_JiraSecurity_Review = Object.assign({}, Task_JiraSecurity_DEFAULT,
	defaultForType(TaskSecurityType_REVIEW),
	task_JiraSecurity_Review_Attrs
);

/**
 * When someone outside of CERT posted a comment AND not recognized as command
 *
 * @task Read the comment and act accordingly
 * @source CERT ML Jira update
 * @truth Jira ticket, comments
 * @autoClose If a new comment is posted from CERT after the comment that triggered this task
 */
export type Task_JiraSecurity_NonCertComment =
	Task_JiraSecurityBase<typeof TaskSecurityType_NON_CERT_COMMENT>
	& Task_JiraSecurity_NonCertComment_Attrs

export type Task_JiraSecurity_NonCertComment_Attrs = {
	/** Total number of comments on the ticket */
	numComments:number
	/** Number of new comments after comment from JenSec */
	numCommentsSinceCertComment:number
	/** Creation of last comment => customDate1 */
	lastCommentDate:IsoDateString
	lastComment:string
	lastCommentAuthor:JiraAuthor
}
const task_JiraSecurity_NonCertComment_Attrs:Task_JiraSecurity_NonCertComment_Attrs = {
	numComments:0,
	numCommentsSinceCertComment:-1,
	lastCommentDate:IsoDateString_1900,
	lastComment:'',
	lastCommentAuthor:JiraAuthor_DEFAULT,
};
// to prevent default value to force this value
delete (task_JiraSecurity_NonCertComment_Attrs as Partial<Task_JiraSecurity_NonCertComment_Attrs>).lastCommentDate;
export const task_JiraSecurity_NonCertComment_DEFAULT:Task_JiraSecurity_NonCertComment = Object.assign({}, Task_JiraSecurity_DEFAULT,
	defaultForType(TaskSecurityType_NON_CERT_COMMENT),
	task_JiraSecurity_NonCertComment_Attrs
);

/**
 * @task Generic task about a ticket requiring attention from CERT
 * @source CERT ML Jira update
 * @truth Jira ticket, labels (cert-attention) or created due to transition from other state
 * @autoClose if the ticket lost the label "cert-attention"
 */
export type Task_JiraSecurity_CertAttention =
	Task_JiraSecurityBase<typeof TaskSecurityType_CERT_ATTENTION>
	& Task_JiraSecurity_CertAttention_Attrs

export type Task_JiraSecurity_CertAttention_Attrs = {}
export const task_JiraSecurity_CertAttention_Attrs:Task_JiraSecurity_CertAttention_Attrs = {};
export const task_JiraSecurity_CertAttention_DEFAULT:Task_JiraSecurity_CertAttention = Object.assign({}, Task_JiraSecurity_DEFAULT,
	defaultForType(TaskSecurityType_CERT_ATTENTION),
	task_JiraSecurity_CertAttention_Attrs
);

/**
 * @task Ensure the ticket is kept visible, nothing to do in particular outside of forcing more on communication
 * @source CERT ML Jira update
 * @truth Jira ticket, labels (disclosure-deadline)
 * @autoClose if the ticket lost the label "disclosure-deadline" (move to "Done" automatically close the tasks)
 */
export type Task_JiraSecurity_DisclosureDeadline =
	Task_JiraSecurityBase<typeof TaskSecurityType_DISCLOSURE_DEADLINE>
	& Task_JiraSecurity_DisclosureDeadline_Attrs

export type Task_JiraSecurity_DisclosureDeadline_Attrs = {
	dueDate:IsoDateString
}
export const task_JiraSecurity_DisclosureDeadline_Attrs:Task_JiraSecurity_DisclosureDeadline_Attrs = {
	dueDate:IsoDateString_1900,
};
// to prevent default value to force this value
delete (task_JiraSecurity_DisclosureDeadline_Attrs as Partial<Task_JiraSecurity_DisclosureDeadline_Attrs>).dueDate;
export const task_JiraSecurity_DisclosureDeadline_DEFAULT:Task_JiraSecurity_DisclosureDeadline = Object.assign({}, Task_JiraSecurity_DEFAULT,
	defaultForType(TaskSecurityType_DISCLOSURE_DEADLINE),
	task_JiraSecurity_DisclosureDeadline_Attrs
);

//TODO invalid plugin artifact id task? or covered by the label task?
