export class GitHubUrlHelper {
	static linkToUser(login:string):string {
		return `https://github.com/${encodeURIComponent(login)}`
	}

	static linkToTeam(org:string, teamSlug:string):string {
		return `https://github.com/orgs/${encodeURIComponent(org)}/teams/${encodeURIComponent(teamSlug)}`
	}

	static linkToAdvisory(org:string, repo:string, ghsaId:string):string {
		return `https://github.com/${encodeURIComponent(org)}/${encodeURIComponent(repo)}/security/advisories/${encodeURIComponent(ghsaId)}`
	}

	static linkToAdvisory_addRemoveCollaborator(org:string, repo:string, ghsaId:string):string {
		return `https://github.com/${encodeURIComponent(org)}/${encodeURIComponent(repo)}/security/advisories/${encodeURIComponent(ghsaId)}/collaborators`
	}
}
