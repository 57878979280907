const DEFAULT_LABELS = {};

type SeverityType = 'DEFAULT' | 'DEBUG' | 'INFO' | 'NOTICE' | 'WARNING' | 'ERROR' | 'CRITICAL' | 'ALERT' | 'EMERGENCY';

type Printer = (severity:SeverityType, message:string, labels:any) => void;

let printer:Printer;
// Using APP_TYPE to be able to share the same logger between client and server helpers
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' || process.env.APP_TYPE === 'client' || process.env.FORCE_CONSOLE_LOG === 'true') {
	printer = (severity, message, labels) => {
		const now = new Date();
		const dateString = `${(now.getHours() + '').padStart(2, '0')}:${(now.getMinutes() + '').padStart(2, '0')}:${(now.getSeconds() + '').padStart(2, '0')}.${(now.getMilliseconds() + '').padStart(3, '0')}`;
		console.info(`[${severity}][${dateString}] ${message}`);
	}
} else {
	const KEY_LABEL = 'logging.googleapis.com/labels';
	printer = (severity, message, labels) => {
		console.info(JSON.stringify({
			severity:severity,
			[KEY_LABEL]:labels,
			message:message
		}));
	}
}

// How to structure logs: https://cloud.google.com/logging/docs/agent/logging/configuration#special-fields
export class Logger {
	private loggerLabels:any;

	private constructor(private name:string, private additionalLabels?:any) {
		this.loggerLabels = additionalLabels ?
			Object.assign({}, additionalLabels, {logger:this.name}, DEFAULT_LABELS) :
			Object.assign({}, {logger:this.name}, DEFAULT_LABELS);
	}

	static get(name:string, additionalLabels?:any):Logger {
		return new Logger(name, additionalLabels);
	}

	default = (...messages:any[]):void => {
		this.print('DEFAULT', messages);
	}

	debug = (...messages:any[]):void => {
		this.print('DEBUG', messages);
	}

	info = (...messages:any[]):void => {
		this.print('INFO', messages);
	}

	notice = (...messages:any[]):void => {
		this.print('NOTICE', messages);
	}

	warning = (...messages:any[]):void => {
		this.print('WARNING', messages);
	}

	/**
	 * @deprecated use {@link warning} instead
	 */
	warn = (...messages:any[]):void => {
		return this.warning(messages);
	}

	error = (...messages:any[]):void => {
		this.print('ERROR', messages);
	}

	critical = (...messages:any[]):void => {
		this.print('CRITICAL', messages);
	}

	alert = (...messages:any[]):void => {
		this.print('ALERT', messages, {mode:'alert'});
	}

	emergency = (...messages:any[]):void => {
		this.print('EMERGENCY', messages);
	}

	private print(severity:SeverityType, messages:any[], labels?:any):void {
		const message = this.processMessages(messages);
		const labelValue = labels ? Object.assign({}, labels, this.loggerLabels) : this.loggerLabels;
		printer(severity, message, labelValue)
	}

	private processMessages(messages:any[]):string {
		const result:string[] = [];

		for (let i = 0; i < messages.length; i++) {
			const m = messages[i];
			const ms = this.transformToString(m);
			result.push(ms);
		}

		return result.join(' ');
	}

	private transformToString(m:any, level = 0):string {
		if (level > 3) {
			return 'too-deep';
		}

		if (m === null) {
			return 'null';
		} else if (m === undefined) {
			return 'undefined';
		} else if (typeof m === 'string' || typeof m === 'number') {
			return '' + m;
		} else if (Array.isArray(m)) {
			return '[' + m.map(el => this.transformToString(el, level + 1)).join(',') + ']';
		} else if (m instanceof Error) {
			return m.stack || m.message + ' (no stack)';
		} else {
			try {
				return JSON.stringify(m);
			} catch (e) {
				// cyclic/circular object => [object Object]
				const ms = m.toString();
				this.error('Impossible to JSON.stringify', ms, (e as any).stack);
				return ms;
			}
		}
	}
}
