import {useCallback} from "react";
import {QueryResponse, useGetQuery} from "../../commons/hooks/useGetQuery";
import {CrudModel} from "../../commons/types/CrudModel";
import {CrudApiService2_QueryList, QueryListBody} from "./CrudApiService2";
import {QueryData, QueryData_DEFAULT} from "./QueryData";

export const useQueryCrudService = <T extends CrudModel>(
	data:QueryData,
	// crudService:CrudApiService_QueryList<T>
	crudService:CrudApiService2_QueryList<T>
):QueryResponse<QueryListBody<T>> => {
	// console.info('useQueryService', data);
	const safeData:Required<QueryData> = Object.assign({}, QueryData_DEFAULT, data);

	const fetchEntitiesString = safeData.fetchEntities.map(fe => `${fe}:o`).join(',');

	let params = [];
	safeData.limit && params.push(`limit=${safeData.limit}`);
	safeData.offset && params.push(`offset=${safeData.offset}`);
	//TODO better to put the encodeURIComponent only to relevant field, 
	// otherwise we are sending filters=numUsers%3Ai%3Abe%3A0%3A5
	// instead of filters=numUsers:i:be:0:5
	safeData.filters && params.push(`filters=${encodeURIComponent(safeData.filters)}`);
	safeData.search && params.push(`search=${encodeURIComponent(safeData.search)}`);
	// safeData.order && params.push(`order=${encodeURIComponent(safeData.order)}`);
	safeData.order && params.push(`order=${safeData.order}`);
	if (safeData.cursor !== null){
		safeData.cursor.startAtDocId && params.push(`startAtDocId=${encodeURIComponent(safeData.cursor.startAtDocId)}`);
		safeData.cursor.startAfterDocId && params.push(`startAfterDocId=${encodeURIComponent(safeData.cursor.startAfterDocId)}`);
		safeData.cursor.endBeforeDocId && params.push(`endBeforeDocId=${encodeURIComponent(safeData.cursor.endBeforeDocId)}`);
	}
	fetchEntitiesString && params.push(`fetch=${fetchEntitiesString}`);

	// only used to ensure a new request is triggered
	safeData.cacheId && params.push(`cache=${safeData.cacheId}`);

	const paramPart = params.join('&');

	// Convert QueryListResponse<T> to GetQueryProvider<T>
	const queryProvider = useCallback(() => {
		return crudService.queryList(paramPart)
	}, [crudService, paramPart]);

	const result:QueryResponse<QueryListBody<T>> = useGetQuery(queryProvider);
	return result;
}
