import * as React from "react";
import {StringArrayHelper} from "@commons/helpers/StringArrayHelper";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";

import "./JiraLabels.scss";

type JiraLabelsProps = {
	className?:string
	labels:string | string[]
}

export const JiraLabels = ({labels, className}:JiraLabelsProps) => {
	const labelArray = StringArrayHelper.toStringArray(labels);
	return (
		<div className={ClassHelper.combine('JiraLabels', className)}>
			{labelArray.map(label => 
				<a href={`https://issues.jenkins.io/issues/?jql=labels+%3D+%22${encodeURIComponent(label)}%22`}
				   title="Browse to the search results corresponding to this label in Jira"
				   target="_blank"
				   rel="noreferrer nofollow"
				   key={label}
				   className="JiraLabel">{label}</a>
			)}
		</div>
	);
}
