const DURATION_1_SECOND = 1000;
const DURATION_1_MINUTE = 60 * DURATION_1_SECOND;
const DURATION_1_HOUR = 60 * DURATION_1_MINUTE;
const DURATION_1_DAY = 24 * DURATION_1_HOUR;
const DURATION_1_MONTH = 30 * DURATION_1_DAY;

export class DurationHelper {
	static toString(valueIsMs:number):string {
		if (valueIsMs < 0) {
			return '';
		}

		let time = valueIsMs;
		let durationName:string;
		if (time > DURATION_1_MONTH) {
			time /= DURATION_1_MONTH;
			time = Math.round(time * 10) / 10;
			if (time === 1) {
				durationName = 'month';
			} else {
				durationName = 'months';
			}
		} else if (time > DURATION_1_DAY) {
			time /= DURATION_1_DAY;
			time = Math.round(time * 10) / 10;
			if (time === 1) {
				durationName = 'day';
			} else {
				durationName = 'days';
			}
		} else if (time > DURATION_1_HOUR) {
			time /= DURATION_1_HOUR;
			time = Math.round(time * 10) / 10;
			durationName = 'h';
		} else if (time > DURATION_1_MINUTE) {
			time /= DURATION_1_MINUTE;
			time = Math.round(time * 10) / 10;
			durationName = 'min';
		} else if (time > DURATION_1_SECOND) {
			time /= DURATION_1_SECOND;
			time = Math.round(time * 10) / 10;
			durationName = 's';
		} else {
			durationName = 'ms';
		}

		return `${time} ${durationName}`;
	}
}
