import {UserIdentity} from "@commons/models/UserIdentity";
import {ApiFetchResponse, WrappedJsonFetch} from "../../framework/WrappedJsonFetch";
import {AuthInfo} from '../providers/AuthProvider';

export type RefreshUserInfoType = {userInfo:AuthInfo, jwtExpirationTime:number, wasRefresh:boolean, csrfToken?:{token:string, header:string}, userIdentity?: UserIdentity}

type ForceRefreshJwt = {userInfo:AuthInfo, jwtExpirationTime:number, csrfToken?:{token:string, header:string}}

// compared to apiFetch, it does not have access token related mechanism
const authFetch = new WrappedJsonFetch();

export const authApiService = new class AuthApiService {
	//TODO it's called 4 times during the first "login", after that it's only once
	refreshUserInfo(includeUserDetails:boolean = false):Promise<ApiFetchResponse<RefreshUserInfoType>> {
		let url = 'auth/oauth/refresh-user-info';
		if (includeUserDetails) {
			url += '?includeUserDetails=true';
		}
		return authFetch.get(url);
	}

	forceRefreshJwt():Promise<ApiFetchResponse<ForceRefreshJwt>> {
		return authFetch.get('auth/oauth/force-refresh-jwt');
	}

	//TODO use communicationChannel to broadcast the logout

	// logout():Promise<ApiFetchResponse<{ done:boolean }>> {
	logout():Promise<ApiFetchResponse<void>> {
		return authFetch.get('auth/logout');
	}

	whoAmI():Promise<ApiFetchResponse<{ login:string }>> {
		return authFetch.get('api/public/who-am-i');
	}
}();

