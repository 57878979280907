import {TaskBase} from "@commons/models/Task";
import * as React from "react";
import {CrudModel} from "../../../../commons/types/CrudModel";
import {ClosureColumnDef} from "../../../../framework/table/columns/ClosureColumnDef";
import {StringColumnDefOps} from "../../../../framework/table/columns/StringColumnDef";
import {TaskBaseStatusBadge} from "./TaskBaseStatusBadge";

const wrapper = (row:TaskBase) => {
	return <TaskBaseStatusBadge task={row} />;
}

export class TaskStatusColumnDef<T extends CrudModel & TaskBase> extends ClosureColumnDef<T> {
	constructor(opts:StringColumnDefOps = {size:100, sortable:false}) {
		super('taskStatusNum', 'Task Status', wrapper, opts);
	}
}
