// To prevent CSS injection behind blocked by CSP
import * as React from "react";
import {createRoot} from 'react-dom/client';
// import ReactDOM from 'react-dom';
import {App} from "./App";

import * as serviceWorker from './serviceWorker';

// force a different checksum for js/main.xxx.chunk.js
if (process.env.REACT_APP_BUILD_TIME) {
	console.info('build', process.env.REACT_APP_BUILD_TIME, process.env.REACT_APP_BUILD_DATE);
}

export const APP_ROOT_ELEMENT_ID = 'app-root';

// from public/index.html
const container = document.getElementById(APP_ROOT_ELEMENT_ID)!;

{
	const urlQueryPart = window.location.search;
	const params = new URLSearchParams(urlQueryPart);

	// e.g., ?demo=1
	if (params.has('demo')){
		const demo = params.get('demo');
		if (demo === 'false' || demo === '0') {
			localStorage.removeItem('demo');
		} else {
			localStorage.setItem('demo', 'true');
		}
	}
}

const root = createRoot(container);
// root.render(<React.StrictMode><App /></React.StrictMode>);
root.render(<App />);

// ReactDOM.render(
//   <App />,
//   container
// );

// root.render(<React.StrictMode><App /></React.StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
