import {FunctionNM} from "@commons/types/FunctionNM";
import * as React from "react";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";

import {IconButton} from "../../../../framework/components/IconButton";
import './TopNavIcon.scss'

type TopNavIconProps = {
	// icon:React.ReactNode
	icon:FunctionNM<any, JSX.Element>
	onClick?:FunctionNM<[], void>
	title?:string
	className?:string
	colorClass?:string
}

export const TopNavIcon = ({onClick, icon, title, className, colorClass}:TopNavIconProps) => {
	return (
		<div className={ClassHelper.combine('TopNavIcon', className)}>
			{/*<div className={ClassHelper.combine('IconButton', colorClass)}*/}
			{/*     title={title} tabIndex={0}*/}
			{/*     onClick={onClick}>*/}
			{/*	<Icon />*/}
			{/*</div>*/}
			<IconButton icon={icon} onClick={onClick} className={colorClass} title={title} tabIndex={0} size="m" />
		</div>
	);

};
