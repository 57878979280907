import {useMemo} from "react";

type InputChangeReturn = {
	isFirstTime:boolean
	hasChangedSinceLastTime:boolean
	/**
	 * isNewValue = isFirstTime || hasChangedSinceLastTime
	 */
	isNewValue:boolean
}

let _id = 1;
const nextId = () => {
	return ++_id;
}

/**
 * Determine if the dependencies passed were changed since the last iteration
 * @param dependencies Any dependencies, following the same rules as React hooks
 */
export const useInputChange = (dependencies:any[]):InputChangeReturn => {
	const rCurrent = nextId();
	const rLastChange = useMemo(() => {
		console.info('useInputChange r1 body');
		return nextId();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);
	const rFirstChange = useMemo(() => {
		console.info('useInputChange r2 body');
		return nextId();
	}, []);

	const isFirstTime = (rFirstChange > rCurrent);
	const isNewValue = (rCurrent < rLastChange);
	const hasChangedSinceLastTime = (isNewValue && !isFirstTime);

	return {isFirstTime, isNewValue, hasChangedSinceLastTime};
}
