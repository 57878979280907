import * as React from "react";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";

import "./JiraStatus.scss";

type JiraStatusProps = {
	className?:string
	label:string | null
	color:string | null
}

export const JiraStatus = ({label, color, className}:JiraStatusProps) => {
	if (label) {
		return (
			<div className={ClassHelper.combine('JiraStatus', className, color || 'blue-gray')}>
				{label}
			</div>
		)
	}
	return (
		<div className={ClassHelper.combine('JiraStatus', className, color || 'purple')}
		     title="Status not yet retrieved from Jira">
			Unknown
		</div>
	);
}
