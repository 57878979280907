import * as React from "react";
import {useCallback, useState} from "react";
import {PromiseOrNot} from "@commons/helpers/PromiseHelper";
import {FunctionNM} from "@commons/types/FunctionNM";
import {CrudModel} from '../../../commons/types/CrudModel';
import {ActionBarOnlyRight} from "../../components/ActionBar";
import {Modal, ModalContentSize} from "../../components/Modal";
import {FormCloseButton} from "../../form/components/FormCloseButton";
import {FormSubmitButton} from "../../form/components/FormSubmitButton";
import {GenericErrorResponse} from "../../form/types/FormTypes";
import {CrudForm, CrudFormFragment, CrudFormSchema} from "../CrudForm";

export type OnSaveCallbackType<T, TSaveArgs> = FunctionNM<[T, TSaveArgs], PromiseOrNot<GenericErrorResponse<T> | null>>;

type UseFeatureFormModalProps<T extends CrudModel, TSaveArgs> = {
	modalTitle:string
	modalContentSize?:ModalContentSize
	formSchema:CrudFormSchema | undefined
	formFragment:CrudFormFragment<T> | undefined
	onSave:OnSaveCallbackType<T, TSaveArgs>
}
type UseFeatureFormModalReturn<T extends CrudModel, TSaveArgs> = {
	// openModal:FunctionNM<[Partial<T> | undefined], void>
	openModal:(t?:Partial<T>) => void
	renderFormModal:(args:TSaveArgs) => (JSX.Element | null)
}

export const useFeatureFormModal = <T extends CrudModel, TSaveArgs>(
	{
		modalTitle,
		modalContentSize,
		formSchema,
		formFragment,
		onSave,
	}:UseFeatureFormModalProps<T, TSaveArgs>
):UseFeatureFormModalReturn<T, TSaveArgs> => {
	const featureDisabled = formSchema === undefined || formFragment === undefined;
	const contentClassName = 'modal-width-' + (modalContentSize ?? 500)
	
	const [popupItem, setPopupItem] = useState<Partial<T> | null>(null);

	const [pending, setPending] = useState(false);

	const openPopupFor = useCallback((item:Partial<T> | undefined) => {
		const defaultItem:Partial<T> = item || {};
		setPopupItem(defaultItem);
	}, []);

	const closePopup = useCallback(() => {
		setPending(false);
		setPopupItem(null);
	}, []);

	const handleItemSubmit = useCallback(async (itemSubmitted:T, args:TSaveArgs):Promise<GenericErrorResponse<T> | null> => {
		const directOrPromise = onSave(itemSubmitted, args);
		let result:GenericErrorResponse<T> | null;
		if (directOrPromise instanceof Promise) {
			setPending(true);
			result = await directOrPromise;
		} else {
			result = directOrPromise;
		}

		if (result === null) {
			setPending(false);
			closePopup();
		}

		return result;
	}, [closePopup, onSave]);

	const renderFormModal = useCallback((args:TSaveArgs) => {
		if (featureDisabled) {
			console.info('renderModal but feature disabled');
			return null;
		}
		return (
			<Modal isOpen={popupItem !== null} onClose={closePopup} contentClassName={contentClassName}>
				<CrudForm item={popupItem!} onSubmit={handleItemSubmit} onSubmitArgs={args}
				          formFragment={formFragment} formSchema={formSchema}
				          beforeComp={(
					          <h2>{modalTitle}</h2>
				          )}
				          afterComp={(
					          <ActionBarOnlyRight>
						          <FormCloseButton onClick={closePopup} label="Close" />
						          <FormSubmitButton label="Save" type="primary" disabled={pending} />
					          </ActionBarOnlyRight>
				          )}
				/>
			</Modal>
		);
	}, [featureDisabled, popupItem, closePopup, handleItemSubmit, formFragment, formSchema, modalTitle, pending]);

	return {
		openModal:openPopupFor,
		renderFormModal,
	};
}
