import {ReactNode} from "react";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {CrudModel} from '../../../commons/types/CrudModel';
import {ColDefType} from "../ColDefType";
import {ColSizeType} from "../ColSizeType";

//TODO create variant for safe HTML
import {CrudTableColumnDefinition} from "../CrudTableColumnDefinition";
import {NullCellValue} from "../NullCellValue";

export type ImageColumnDefOps = {
	defaultVisibility?:boolean, sortable?:boolean, fixed?:boolean,
	cellClassName?:string, headerClassName?:string, size?:ColSizeType,
	altText?:string
}

export class ImageColumnDef<T extends CrudModel> implements CrudTableColumnDefinition<T> {
	type:ColDefType = 'string';

	defaultVisibility:boolean;
	sortable:boolean;
	fixed:boolean;
	cellClassName?:string;
	headerClassName?:string;

	altText?:string;
	imgHeight?:number;
	imgWidth?:number;

	constructor(public name:string,
	            public header:string,//TODO add column size in px, with predefined column size style
	            private rowToCell:((row:T) => string | null),
	            opts:ImageColumnDefOps = {}) {
		const {
			defaultVisibility, sortable, fixed,
			cellClassName, headerClassName, size,
			altText
		} = opts;
		this.defaultVisibility = defaultVisibility ?? true;
		this.sortable = sortable ?? true;
		this.fixed = fixed ?? false;
		this.altText = altText ?? undefined;
		this.cellClassName = ClassHelper.combine('ImageColumnDef', cellClassName, `column-size-${size ?? 50}`);
		this.headerClassName = ClassHelper.combine(headerClassName, `column-size-${size ?? 50}`);
	}

	nowrap():this {
		this.cellClassName = 'nowrap';
		return this;
	}

	computeCell(row:T):ReactNode {
		const value = this.rowToCell(row);
		return (
			value ? (
				<img src={value} alt={this.altText} width="36" height="36" />
			) : (
				<NullCellValue />
			)
		);
	}
}
