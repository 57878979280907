import {FunctionNM} from "@commons/types/FunctionNM";
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';

import "./ExternalLinkIconButton.scss";

export type ExternalLinkIconButtonProps = {
	url:string
	icon:FunctionNM<any, JSX.Element>
	className?:string
	title?:string
	size?:'s' | 'm'
} & OtherHtmlAttributes

//TODO refactor with IconButton
export const ExternalLinkIconButton = ({url, icon, className, title, size, ...rest}:ExternalLinkIconButtonProps) => {
	const sizeClass = {m:'size-m', s:'size-s'}[size || 'm'];
	const Icon = icon;
	const classes = ClassHelper.combine('ExternalLinkIconButton', sizeClass, 'enabled', className);

	return (
		<a href={url} target="_blank" rel="noopener noreferrer"
		        className={classes}
		        title={title}
		        {...rest}>
			{/*TODO find a way to add the focus effect on the button-effects instead of the button*/}
			<span className="button-effects" />
			{Icon && <Icon />}
		</a>
	);
}
