import * as React from "react";
import {ReactNode} from "react";
import {NoData} from "../../../../app/sections/task/commons/NoData";
import {IconBoolean} from "../../IconBoolean";
import {InlineCode} from "../../InlineCode";

import "./DetailsRow.scss";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";

type DetailsRowProps = {
	label:string
	title?:string
	value:ReactNode
	valueInlineCode?:boolean
	valueTitle?:string
	hideIfNoValue?:boolean

	/** for badges (2) / SVG (4) */
	marginToCompensate?:'svg'|'badge';
}

export const DetailsRow = ({label, title, value, valueInlineCode, valueTitle, hideIfNoValue, marginToCompensate}:DetailsRowProps) => {
	if (!value && hideIfNoValue) {
		return <></>;
	}
	return (
		<div className="DetailsRow">
			<div className={ClassHelper.combine('DetailsRowLabel')} title={title}>
				{label}
			</div>
			<div className={ClassHelper.combine('DetailsRowValue', marginToCompensate && `margin-top-neg-${marginToCompensate}`)} title={valueTitle}>
				{typeof value === 'boolean' ? (
					<IconBoolean value={value} />
				) : (value !== null && value !== undefined && value !== '') ? (
					valueInlineCode ? (
						<InlineCode>{value}</InlineCode>
					) : (
						value
					)
				) : (
					<NoData label="None" />
				)}
			</div>
		</div>
	)
};
