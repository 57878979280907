import * as React from "react";
import {FunctionNM} from "@commons/types/FunctionNM";
import {CrudModel} from '../../../commons/types/CrudModel';

//TODO create variant for safe HTML
import {StringColumnDef, StringColumnDefOps} from "./StringColumnDef";

export class ClosureColumnDef<T extends CrudModel> extends StringColumnDef<T> {
	constructor(name:string, header:string, private closure:FunctionNM<[T], React.ReactNode>, opts:StringColumnDefOps = {}) {
		super(name, header, () => '', opts);
	}

	computeCell(row:T):React.ReactNode {
		return this.closure(row);
	}
}
