import * as React from "react";
import {useCallback} from "react";
import {useLogIfStillMounted} from "../../../../commons/hooks/useLogIfStillMounted";
import {apiFetch} from "../../../../framework/apiFetch";
import {Card} from "../../../../framework/components/Card";
import {InfoPanel} from "../../../../framework/components/color/Panel";
import {Divider} from "../../../../framework/components/Divider";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {LogPanel, useLogPanel} from "../../../../framework/components/LogPanel";
import {TextButton} from "../../../../framework/components/TextButton";

const logWrapper = (appendLog:(data:any) => void) => {
	return (data:any) => {
		// if (data && data.payload && data.payload.output) {
		// 	let stdout = data.payload.output.stdout.replace(/\r/g, '');
		// 	if (stdout.trim().length !== 0) {
		// 		stdout = '==>\n' + stdout + '\n<==';
		// 		data.payload.output.stdout = '$pattern1$';
		// 	}
		//
		// 	let stderr = data.payload.output.stderr.replace(/\r/g, '');
		// 	if (stderr.trim().length !== 0) {
		// 		stderr = '==>\n' + stderr + '\n<==';
		// 		data.payload.output.stderr = '$pattern2$';
		// 	}
		//
		// 	let customOutput:string = '';
		// 	if (data.payload.output.output !== undefined) {
		// 		customOutput = data.payload.output.output.replace(/\r/g, '');
		// 		if (customOutput === '' || customOutput === '[no-findings]') {
		// 			customOutput = 'No findings found in that repository';
		// 		}
		// 		customOutput = '==>\n' + customOutput + '\n<==';
		// 		data.payload.output.output = '$pattern3$';
		// 	}
		//
		// 	let newData = JSON.stringify(data);
		// 	newData = newData.replace(/\$pattern1\$/, stdout);
		// 	newData = newData.replace(/\$pattern2\$/, stderr);
		// 	if (data.payload.output) {
		// 		newData = newData.replace(/\$pattern3\$/, customOutput);
		// 	}
		// 	const array = newData.split('\n');
		// 	appendLog(array);
		// 	return;
		// }
		appendLog(data);
	}
}

export const JiraTriageToolingPage = () => {
	const {logRows, appendLog, clearLog} = useLogPanel();
	const {logIfMounted} = useLogIfStillMounted(logWrapper(appendLog));

	const triggerRequestJiraTriage = useCallback(() => {
		const issueKey = document.querySelector<HTMLInputElement>('#triage_issueKey')!.value.trim();
		const numAnswersString = document.querySelector<HTMLInputElement>('#triage_numAnswers')!.value.trim();
		appendLog(`Requesting triage jira with issueKey=${issueKey}, numAnswersString=${numAnswersString}`);

		let numAnswers = parseInt(numAnswersString, 10);
		if (!isFinite(numAnswers)) {
			numAnswers = 5;
		}

		const url = `api/user/jira-triage-tooling/single?issueKey=${encodeURIComponent(issueKey)}&numAnswers=${encodeURIComponent(numAnswers)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);

	const triggerRequestRangeJiraTriage = useCallback(() => {
		const issueKeyFrom = document.querySelector<HTMLInputElement>('#range_from_issueKey')!.value.trim();
		const issueKeyTo = document.querySelector<HTMLInputElement>('#range_to_issueKey')!.value.trim();
		const numAnswersString = document.querySelector<HTMLInputElement>('#range_numAnswers')!.value.trim();
		appendLog(`Requesting triage range jira from=${issueKeyFrom} to=${issueKeyTo}, numAnswersString=${numAnswersString}`);

		let numAnswers = parseInt(numAnswersString, 10);
		if (!isFinite(numAnswers)) {
			numAnswers = 5;
		}

		const url = `api/user/jira-triage-tooling/range?issueKeyFrom=${encodeURIComponent(issueKeyFrom)}&issueKeyTo=${encodeURIComponent(issueKeyTo)}&numAnswers=${encodeURIComponent(numAnswers)}`;
		apiFetch.post(url).then(logIfMounted)
	}, [appendLog, logIfMounted]);

	return (
		<PageContent className="JiraTriageToolingPage">
			<InfoPanel>
				This tooling will just provide you the evaluation from OpenAI, it will not update any fields in Jira.
			</InfoPanel>
			<br/>

			<div>
				Evaluate <input id="triage_issueKey" placeholder="JENKINS-1234"/>&nbsp;
				<span title="By default the bot is requested to be provide 5 answers, that we use to make an average">(# of answers: <input
					id="triage_numAnswers" placeholder="5" type="number" style={{width: '50px'}}/>, [1-10])</span>
				<TextButton label="Trigger" onClick={triggerRequestJiraTriage}/>
			</div>
			<Divider/>
			<div>
				Triage a range from=<input id="range_from_issueKey" placeholder="JENKINS-1234"/> to=<input
				id="range_to_issueKey" placeholder="JENKINS-1234"/>&nbsp;
				<span title="By default the bot is requested to be provide 5 answers, that we use to make an average">(# of answers: <input
					id="range_numAnswers" placeholder="5" type="number" style={{width: '50px'}}/>, [1-10])</span>
				<TextButton label="Trigger" onClick={triggerRequestRangeJiraTriage}/>
				(It will send notification + store information. It's expected to be used to catch up with tickets that were missed.)
			</div>
			<Divider/>
			<Card>
				<LogPanel rows={logRows} numRowsToDisplay={30} onClearLog={clearLog}/>
			</Card>
		</PageContent>
	);
}
