import {useEffect, useState} from "react";

/**
 * Exports a boolean value reporting whether is client side or server side by checking on the window object
 */
const isClient = (typeof window === 'object');

/**
 * Exports a boolean value reporting whether the given API is supported or not
 */
const isApiSupported = (api:string) => (typeof window !== 'undefined' ? api in window : false);

// const errorMessage = 'matchMedia is not supported, this could happen both because window.matchMedia is not supported by'
// 	+ ' your current browser or you\'re using the useMediaQuery hook whilst server side rendering.';

const useMediaQuery_unsupported = (mediaQuery:string) => {
	return false;
}

// inspired by https://github.com/beautifulinteractions/beautiful-react-hooks/blob/master/src/useMediaQuery.js
const useMediaQuery_supported = (mediaQuery:string) => {
	const [isVerified, setIsVerified] = useState(!!window.matchMedia(mediaQuery).matches);

	useEffect(() => {
		const mediaQueryList = window.matchMedia(mediaQuery);
		const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches);

		try {
			mediaQueryList.addEventListener('change', documentChangeHandler);
		} catch (e) {
			//Safari isn't supporting mediaQueryList.addEventListener
			console.error(e);
			mediaQueryList.addListener(documentChangeHandler);
		}

		documentChangeHandler();
		return () => {
			try {
				mediaQueryList.removeEventListener('change', documentChangeHandler);
			} catch (e) {
				//Safari isn't supporting mediaQueryList.removeEventListener
				console.error(e);
				mediaQueryList.removeListener(documentChangeHandler);
			}

		};
	}, [mediaQuery]);

	return isVerified;
};

let _useMediaQuery;
if (!isClient || !isApiSupported('matchMedia')) {
	// eslint-disable-next-line no-console
	// console.warn('useMediaQuery', errorMessage);
	_useMediaQuery = useMediaQuery_unsupported;
} else {
	_useMediaQuery = useMediaQuery_supported;
}

export const useMediaQuery = _useMediaQuery;
