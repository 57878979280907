import * as H from "history";
import {NavLink} from "react-router-dom";
import {FunctionNM} from "@commons/types/FunctionNM";
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {OtherHtmlAttributes} from '../form/types/OtherHtmlAttributes';

import "./InternalLinkIconButton.scss";

export type InternalLinkIconButtonProps = {
	to:H.LocationDescriptorObject<any>
	icon:FunctionNM<any, JSX.Element>
	className?:string
	title?:string
	size?:'s' | 'm'
} & OtherHtmlAttributes

//TODO refactor with IconButton
export const InternalLinkIconButton = ({to, icon, className, title, size, ...rest}:InternalLinkIconButtonProps) => {
	const sizeClass = {m:'size-m', s:'size-s'}[size || 'm'];
	const Icon = icon;
	const classes = ClassHelper.combine('InternalLinkIconButton', sizeClass, 'enabled', className);

	return (
		<NavLink to={to} state={to.state} className={classes}
		         title={title ? title : undefined}
		         {...rest}>
			{/*TODO find a way to add the focus effect on the button-effects instead of the button*/}
			<span className="button-effects" />
			{Icon && <Icon />}
		</NavLink>
	);
}
