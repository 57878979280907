import {DateFormats} from "@commons/constants/DateFormats";
import {StringHelper} from "@commons/helpers/StringHelper";
import {OpenAiJiraTriage} from "@commons/models/OpenAiJiraTriage";
import {WithDbAndId} from "@commons/types/DbType";
import {DateTime} from "luxon";
import * as React from "react";
import {useCallback} from "react";
import {MdRefresh, MdWeb} from "react-icons/md";
import {useLocation} from "react-router";
import {useColumnDefs} from "../../../../commons/hooks/useColumnDefs";
import {IconButton} from "../../../../framework/components/IconButton";
import {AbstractApiService} from "../../../../framework/crud/AbstractApiService";
import {CrudPage2, CrudPageLayout, CrudPageLayoutBasic} from "../../../../framework/crud2/CrudPage2";
import {DateColumnDef} from "../../../../framework/table/columns/DateColumnDef";
import {DefaultColumnFactory} from "../../../../framework/table/columns/DefaultColumnFactory";
import {ExternalLinkColumnDef} from "../../../../framework/table/columns/ExternalLinkColumnDef";
import {FloatColumnDef} from "../../../../framework/table/columns/FloatColumnDef";
import {IntegerColumnDef} from "../../../../framework/table/columns/IntegerColumnDef";
import {InternalLinkColumnDef} from "../../../../framework/table/columns/InternalLinkColumnDef";
import {MetaInfoColumnDef} from "../../../../framework/table/columns/MetaInfoColumnDef";
import {StringColumnDef} from "../../../../framework/table/columns/StringColumnDef";
import {Pagination2} from "../../../../framework/table/Pagination2";
import {Table2} from "../../../../framework/table/Table2";
import {JiraUrlHelper} from "@commons/helpers/JiraUrlHelper";
import * as https from "node:https";

type Model = WithDbAndId<OpenAiJiraTriage>;

const crudService = new class ReleaseBlockService extends AbstractApiService<Model> {
	constructor() {
		super('api/user/jira-triage');
	}
}();

export const JiraTriageListPage = () => {
	const currentLocation = useLocation();

	const {columnDefs, initialState} = useColumnDefs<Model>(() => [
		DefaultColumnFactory.SELECT_REQUIREMENT,
		new StringColumnDef('id', 'ID', row => row.id + '', {sortable:false, defaultVisibility:false}).nowrap(),
		new IntegerColumnDef('version', 'Version', row => row.version, {
			sortable:false,
			defaultVisibility:false
		}).nowrap(),
		new StringColumnDef('creator', 'Requester', row => row.creator || '', {
			sortable:false,
			size:120
		}),
		new StringColumnDef('author', 'Author', row => row.author || '', {
			sortable:false,
			size:120,
			title:'Ticket author'
		}),
		new StringColumnDef('ticket', 'Ticket', row => row.ticketKey, {
			sortable:false,
			size:110
		}),
		new ExternalLinkColumnDef('openLink', row => JiraUrlHelper.linkToTicket(row.ticketKey)),
		new StringColumnDef('summary', 'Summary', row => StringHelper.excerpt(row.summary, 50), {
			sortable:false,
			size:350,
		}),
		new FloatColumnDef('averageScore', 'Score', row => row.averageScore?.toFixed(2) || -1, {
			sortable:false,
			size:80,
			title:'The score is the average of multiple evaluations'
		}),
		//TODO badge with color?
		new StringColumnDef('category', 'Category', row => StringHelper.uppercaseFirstLetter(row.category) || '', {
			sortable:false,
			size:125,
			inlineCode:true,
		}),
		new StringColumnDef('justification', 'Justification', row => row.justification || '', {
			sortable:false,
			size:'expand'
		}),
		new IntegerColumnDef('duration', 'Duration', row => row.duration, {
			sortable:false,
			size:50,
		}),
		new IntegerColumnDef('numAnswersRequested', '# answers', row => row.numAnswersRequested, {
			sortable:false,
			size:50,
			title:'The number of answers that were requested to the API'
		}),
		new InternalLinkColumnDef('browseToItem', row => ({
			state:{fromView:currentLocation, item:row},
			pathname:`./${encodeURIComponent(row.id)}`
		}), {icon:MdWeb, title:'Browse to details page'}),
		new MetaInfoColumnDef(),
		new DateColumnDef('createdDate', 'Created', DateFormats.FULL, row => row.updatedDate, {
			sortable:false,
			defaultVisibility:false,
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			size:175
		}),
		new DateColumnDef('updatedDate', 'Updated', DateFormats.FULL, row => row.updatedDate, {
			titleFormat:DateTime.DATETIME_FULL_WITH_SECONDS,
			size:175
		}),
		// new IconColumnDef('update', 'Update the item', MdEdit, (row) => openUpdateModal(row), {iconClassName:'hover-info'}),
		// new IconColumnDef('update', 'Update the item', MdEdit, openUpdateModalWrapper, {openUpdateModal}, {iconClassName:'hover-info'}),
		// new IconColumnDef('delete', 'Delete the item', MdDelete, deleteOneItem, {}, {iconClassName:'hover-error'}),
	], {initialPageSize:20, initialOrder:'-updatedDate'});

	return (
		<CrudPage2 pageId="jira-triage" pageClass="JiraTriageListPage" crudService={crudService}
		           initialState={initialState}
		           layout={useCallback((state, itemsData, dispatch) => (
			           <CrudPageLayoutBasic
				           pageName="Jira triage"
				           topLeftActions={<>
					           <IconButton icon={MdRefresh} title="Refresh data"
					                       onClick={() => dispatch({type:'reloadContent'})} />

					           {/*{instructionMessage && (*/}
					           {/*    <InfoPanel size="small">{instructionMessage}</InfoPanel>*/}
					           {/*)}*/}
				           </>}
				           topRightActions={<>
					           {/*<TextButton label="Reset filter" onClick={() => resetFilter(dispatch)} />*/}

					           {/*<ToggleGroup label="Done" value={filterDone(state)}*/}
					           {/*             onChange={(newValue) => filterDoneChange(newValue, state, dispatch)}*/}
					           {/*             items={[*/}
					           {/*                 {*/}
					           {/*                     value:true,*/}
					           {/*                     icon:MdCheck,*/}
					           {/*                     activeClassName:'icon-within-green',*/}
					           {/*                     title:'Only display queries without errors'*/}
					           {/*                 },*/}
					           {/*                 {*/}
					           {/*                     value:false,*/}
					           {/*                     icon:MdClose,*/}
					           {/*                     activeClassName:'icon-within-red',*/}
					           {/*                     title:'Only display queries with errors'*/}
					           {/*                 },*/}
					           {/*             ]} />*/}
					           {/*<ToggleGroup label="Assignee" value={filterAssign(state)}*/}
					           {/*             onChange={(newValue) => filterAssignChange(newValue, state, dispatch)}*/}
					           {/*             items={[*/}
					           {/*                 {*/}
					           {/*                     value:true,*/}
					           {/*                     icon:MdPerson,*/}
					           {/*                     activeClassName:'icon-within-blue',*/}
					           {/*                     title:'Only display tasks assigned to me'*/}
					           {/*                 },*/}
					           {/*                 {*/}
					           {/*                     value:false,*/}
					           {/*                     icon:MdPersonOff,*/}
					           {/*                     activeClassName:'icon-within-red',*/}
					           {/*                     title:'Only display unassigned tasks'*/}
					           {/*                 },*/}
					           {/*             ]} />*/}
				           </>}
				           table={<>
					           <Table2 columnDefs={columnDefs} className="fluid"
					                   dataState={itemsData} state={state} dispatch={dispatch} />
				           </>}
				           bottomLeftAction={<>
					           <div>Selection: {state.selection.length} (TODO)</div>
				           </>}
				           bottomRightAction={<>
					           <Pagination2 pagination={state.pagination} dispatch={dispatch}
					                        filteredTotalResults={itemsData.data?.meta?.filteredTotal}
					                        totalResults={itemsData.data?.meta?.total}
					           />
				           </>} />
		           ), [columnDefs, /*filterAssign, filterAssignChange,*/]) as CrudPageLayout<Model>}
		/>
	)
}
