import {useNavigate} from "react-router-dom";
import {useAuth} from '../../../commons/hooks/useAuth';
import {SuccessPanel} from '../../../framework/components/color/Panel';
import {Section} from '../../../framework/components/layout/Section';
import {useLocation} from "react-router";

export const ConnectionSuccessPage = () => {
	const {setAuthInfo, getFrom} = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	setTimeout(() => {
		setAuthInfo({couldHaveToken:true});

		const from = getFrom();
		navigate(from);

		// const pathname = location?.pathname;
		// const search = location?.search;
		// const hash = location?.hash;
		// const from = pathname + search + hash;
		//
		// const searchParams = new URLSearchParams(location.search);
		// const searchParamsObj = Object.fromEntries(searchParams.entries());
		//
		// const state = {
		// 	from:from,
		// 	originalQuery:searchParamsObj
		// }
		//
		//
		// // navigate('/', );
		// navigate('/', {state});
	// }, 10);
	}, 100);

	return (
		<Section noTopNav={true}>
			<SuccessPanel>
				Connection success, redirecting...
			</SuccessPanel>
		</Section>
	)
}
