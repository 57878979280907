import * as React from "react";

import "./TaskBaseStatusBadge.scss";
import {createContext, ReactNode, useCallback, useContext, useState} from "react";

type TaskCounterContextType = {
    counterRefresh:boolean
    dispatch:() => void
}

export const TaskCounterContext = createContext<TaskCounterContextType>(null as any);

type Props = {
    children:ReactNode
}
export const TaskCounterContextProvider = ({children}:Props) => {
    const [counterRefresh, setCounterRefresh] = useState<boolean>(false);
    const dispatch = useCallback(() => {
        console.info('TaskCounterContextProvider');
        setCounterRefresh(prev => !prev);
    }, []);
    const context = {counterRefresh, dispatch};

    return (
        <TaskCounterContext.Provider value={context}>
            {children}
        </TaskCounterContext.Provider>
    );
}
