import {
    MODEL_GPT_3_5_TURBO,
    MODEL_GPT_4,
    MODEL_GPT_4_TURBO,
    MODEL_GPT_4o,
    ModelType
} from "@commons/constants/OpenAiConstants";
import {OpenAiChatInstruction} from "@commons/models/OpenAiChatInstruction";
import {
    OpenAiChatThreadTooling_AuthToken_Request,
    OpenAiChatThreadTooling_AuthToken_Response,
    OpenAiChatThreadTooling_Default_Request,
    OpenAiChatThreadTooling_Type_Message
} from "@commons/protocol/OpenAiChatThreadTooling_Protocol";
import {WithDbAndId} from "@commons/types/DbType";
import {FunctionNM} from "@commons/types/FunctionNM";
import * as React from "react";
import {ChangeEvent, MouseEvent as ReactMouseEvent, useCallback, useEffect, useRef, useState} from "react";
import {MdCancel, MdDelete, MdEdit, MdPreview} from "react-icons/md";
import * as zod from "zod";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";
import {useLogIfStillMounted} from "../../../../commons/hooks/useLogIfStillMounted";
import {apiFetch} from "../../../../framework/apiFetch";
import {ActionBarOnlyRight} from "../../../../framework/components/ActionBar";
import {Card} from "../../../../framework/components/Card";
import {Divider} from "../../../../framework/components/Divider";
import {IconButton} from "../../../../framework/components/IconButton";
import {PageContent} from "../../../../framework/components/layout/PageContent";
import {TwoColumns} from "../../../../framework/components/layout/TwoColumns";
import {LogPanel, useLogPanel} from "../../../../framework/components/LogPanel";
import {SingleSelectGroup} from "../../../../framework/components/SingleSelectGroup";
import {TextButton} from "../../../../framework/components/TextButton";

import "./ChatThreadPage.scss";
import {CrudFormFragmentProps} from "../../../../framework/crud/CrudForm";
import {OnSaveCallbackType, useFeatureFormModal} from "../../../../framework/crud/features/useFeatureFormModal";
import {CrudPage2Dispatcher} from "../../../../framework/crud2/CrudPage2";
import {BooleanField} from "../../../../framework/form/fields/BooleanField";
import {StringField} from "../../../../framework/form/fields/StringField";
import {TextField} from "../../../../framework/form/fields/TextField";
import {Markdown} from "../../../../framework/components/Markdown";

let id = 0;
const nextId = () => {
    // console.warn('nextId', id);
    return ++id;
}

let cancelProgressLastId = -1;

type ChatMessage = OpenAiChatThreadTooling_Type_Message & { id: number };

export const ChatThreadPage = () => {
    const {logRows, appendLog, clearLog} = useLogPanel();
    const {logIfMounted} = useLogIfStillMounted(appendLog);

    const [model, setModel] = useState<ModelType>(MODEL_GPT_4o);
    const [instruction, setInstruction] = useState<ChatMessage | null>(null);
    // const [instruction, setInstruction] = useState<ChatMessage | null>(() => ({
    //     id:nextId(),
    //     role:'system',
    //     message:'In JavaScript'
    // }));
    const [lastResponseInProgress, setLastResponseInProgress] = useState<ChatMessage | null>(null);
    const [responses, setResponses] =
        // useState<OpenAiChatThreadTooling_Type_Message[]>([]);
        useState<ChatMessage[]>(() => [
            {id: nextId(), role: 'user', message: ''},
        ]);

    const handleModelChange = useCallback((newModel: ModelType) => {
        setModel(newModel);
    }, []);

    const triggerSendMessage = useCallback(async () => {
        const filteredResponses = responses.filter(r => r.message.trim().length > 0);

        if (filteredResponses.length === 0) {
            appendLog(`Empty message list, cancel sending`);
            return;
        }

        appendLog(`Sending message with model=${model}, messages=${filteredResponses.length}`);
        const requests = filteredResponses.map(r => ({role: r.role, message: r.message}));
        if (instruction) {
            requests.unshift(instruction);
        }
        const body: OpenAiChatThreadTooling_Default_Request = {
            model: model,
            requests: requests
        }

        const urlForToken = `api/ai/chat/thread-tooling/auth-token`;
        const sseBaseUrl = process.env.REACT_APP_OPENAI_SSE_API_URL!;

        const urlPath = `/?id=${encodeURIComponent('thread-id-' + nextId())}`

        // const bodyForToken:OpenAiChatThreadTooling_AuthToken_Request = {targetUrl: sseUrl};
        //TODO add search for the id of the thread
        const bodyForToken: OpenAiChatThreadTooling_AuthToken_Request = {targetUrl: urlPath};

        const responseForToken = await apiFetch.post<OpenAiChatThreadTooling_AuthToken_Response>(urlForToken, bodyForToken);
        if (!responseForToken.ok) {
            console.error('Error for token', responseForToken.error);
            logIfMounted('Error for token ' + responseForToken.error);
            return;
        }
        const token = responseForToken.payload.token;

        let fetchResponse;
        try {
             fetchResponse = await fetch(sseBaseUrl + urlPath, {
                method: 'POST',
                headers: {
                    Authorization: token,
                    // without this, the req.body is expected to be a string
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(body)
            });
        }
        catch (e) {
            console.error('Connection failed when trying to reach OpenAI SSE, is the service running?', e);
            logIfMounted('Connection failed when trying to reach OpenAI SSE');
            return;
        }
        if (!fetchResponse.ok) {
            console.error('Error connecting to OpenAI SSE');
            logIfMounted('Error connecting to OpenAI SSE');
            return;
        }
        const reader = fetchResponse.body!.getReader();

        const textDecoder = new TextDecoder();

        const id = nextId();
        setLastResponseInProgress({id: id, role: 'assistant', message: ''});

        let allChunks = '';
        const readNextChunk = () => {
            reader.read().then(({value, done}) => {
                if (done) {
                    console.info('Done', allChunks);
                    logIfMounted('done');
                    cancelProgressLastId = -1;
                    setLastResponseInProgress(null);
                    setResponses(curr => ([...curr, {id: nextId(), role: 'assistant', message: allChunks}]));
                } else {
                    const chunkString = textDecoder.decode(value);
                    console.info(`chunk: "${chunkString}"`);
                    // sometimes, there are more than one chunk
                    // const chunkJson = JSON.parse(chunkString);
                    // allChunks += chunkJson.content;
                    allChunks += chunkString;

                    const progressCanceled = cancelProgressLastId === id;

                    if (progressCanceled) {
                        allChunks += '\n\n_[interrupted]_'
                        cancelProgressLastId = -1;
                        setLastResponseInProgress(null);
                        setResponses(curr => ([...curr, {id: nextId(), role: 'assistant', message: allChunks}]));
                    } else {
                        setLastResponseInProgress(curr => ({...curr!, message: allChunks}));
                        readNextChunk();
                    }
                }
            })
        }

        readNextChunk();
    }, [appendLog, logIfMounted, model, instruction, responses]);

    const handleResponsesChange = useCallback((index: number, role: RoleFormType | null, message: string | null) => {
        setResponses(curr => {
            const impacted = {...curr[index]};
            if (role) {
                impacted.role = role;
            }
            if (message) {
                impacted.message = message;
            }
            return [...curr.slice(0, index), impacted, ...curr.slice(index + 1)];
        });
    }, []);

    const handleAddMessage = useCallback(() => {
        setResponses(curr => {
            const last = curr.length > 0 ? curr[curr.length - 1] : null;
            const newRole = last?.role === 'user' ? 'assistant' : 'user';
            return [...curr, {id: nextId(), role: newRole, message: ''}];
        });
    }, []);

    const handleRemoveMessage = useCallback((index: number) => {
        setResponses(curr => {
            return [...curr.slice(0, index), ...curr.slice(index + 1)];
        });
    }, []);

    const handleInstructionChange = useCallback((newInstructionContent: string) => {
        setInstruction({id: nextId(), role: 'system', message: newInstructionContent});
    }, []);

    const handleCancelProgress = useCallback((messageId:number) => {
        cancelProgressLastId = messageId;
    }, []);

    return (
        <PageContent className="ChatThreadPage">
            Model: <SingleSelectGroup value={model} items={[
            {label: 'GPT-3.5-Turbo', value: MODEL_GPT_3_5_TURBO},
            {label: 'GPT-4', value: MODEL_GPT_4},
            {label: 'GPT-4 Turbo', value: MODEL_GPT_4_TURBO},
            {label: 'GPT-4o', value: MODEL_GPT_4o},
        ]} onChange={handleModelChange}/>
            <br/><br/>

            <TwoColumns
                left={(<>
                    <Card>
                        <Card.TitleAndActions title="Instructions"></Card.TitleAndActions>
                        {/*<WarningPanel>*/}
                        {/*    Beta, instead of instructions, just debug for the moment*/}
                        {/*</WarningPanel>*/}
                        {/*<br /><br />*/}

                        <InstructionPanel instruction={instruction?.message}
                                          onInstructionChange={handleInstructionChange}/>

                        {instruction && (
                            <pre>
                               {JSON.stringify(instruction, null, 3)}
                            </pre>
                        )}
                        {responses && (
                            <pre>
                                {JSON.stringify(responses, null, 3)}
                            </pre>
                        )}
                    </Card>
                </>)}
                right={(<>
                    <ResponseList responses={responses}
                                  onRemoveMessage={handleRemoveMessage}
                                  onResponsesChange={handleResponsesChange}/>

                    {lastResponseInProgress !== null && (
                        <ResponseItem response={lastResponseInProgress} inert={true} inProgress={true} 
                                      onCancelProgress={handleCancelProgress} />
                    )}

                    <div style={{padding: '12px 24px'}}>
                        <TextButton label="Add message" type="bordered"
                                    disabled={lastResponseInProgress !== null}
                                    onClick={handleAddMessage}/>
                    </div>

                    <div style={{padding: '12px 24px'}}>
                        <TextButton label="Send" type="primary"
                                    disabled={lastResponseInProgress !== null}
                                    onClick={triggerSendMessage}/>
                    </div>
                </>)}
            />

            <Divider/>
            <Card>
                <LogPanel rows={logRows} numRowsToDisplay={10} onClearLog={clearLog}/>
            </Card>
        </PageContent>
    );
}

type ResponseListProps = {
    responses: ChatMessage[]
    onRemoveMessage?: FunctionNM<[number], void>
    onResponsesChange?: FunctionNM<[number, RoleFormType | null, string | null], void>
}

const ResponseList = ({responses, onResponsesChange, onRemoveMessage}: ResponseListProps) => {
    const handleRoleChange = useCallback((index: number, newRole: RoleFormType) => {
        onResponsesChange && onResponsesChange(index, newRole, null);
    }, [onResponsesChange]);
    const handleMessageChange = useCallback((index: number, newMessage: string) => {
        onResponsesChange && onResponsesChange(index, null, newMessage);
    }, [onResponsesChange]);
    const handleRemoveMessage = useCallback((index: number) => {
        onRemoveMessage && onRemoveMessage(index);
    }, [onRemoveMessage]);

    if (!responses) {
        return <></>;
    }
    return (
        <div className={ClassHelper.combine('ResponseList')}>
            {responses.map((r, index) => (
                <ResponseItem key={r.id} response={r} inert={index !== 0 && r.message !== '' }
                              onRoleChange={(newRole) => {
                                  handleRoleChange(index, newRole)
                              }}
                              onMessageChange={(newMessage) => {
                                  handleMessageChange(index, newMessage)
                              }}
                              onRemoveMessage={() => handleRemoveMessage(index)}
                />
            ))}
        </div>
    )
}

type ResponseItemProps = {
    response: ChatMessage
    onRoleChange?: FunctionNM<[RoleFormType], void>
    onMessageChange?: FunctionNM<[string], void>
    onRemoveMessage?: FunctionNM<[], void>
    onCancelProgress?: FunctionNM<[number], void>
    inert?: boolean
    inProgress?:boolean
}

//TODO double view, edit+preview (markdown) modes
const ResponseItem = ({response, onRoleChange, onMessageChange, onRemoveMessage, onCancelProgress, inert, inProgress}: ResponseItemProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [currentInert, setCurrentInert] = useState(inert);
    
    const messageId = response.id;

    const handleRoleChange = useCallback((newRole: RoleFormType) => {
        onRoleChange && onRoleChange(newRole);
    }, [onRoleChange]);
    const handleMessageChange = useCallback((newMessage: string) => {
        onMessageChange && onMessageChange(newMessage);
    }, [onMessageChange]);

    // const handleClickOnInert = useCallback(() => {
    //     setCurrentInert(curr => !curr);
    // }, []);

    const handleClickOnRemove = useCallback(() => {
        onRemoveMessage && onRemoveMessage();
    }, [onRemoveMessage]);

    const handleClickOnCancel = useCallback(() => {
        onCancelProgress && onCancelProgress(messageId);
    }, [onCancelProgress, messageId]);

    const handleClickOnEdit = useCallback(() => {
        setCurrentInert(curr => !curr);
    }, []);

    const handleClickOnPreview = useCallback(() => {
        setCurrentInert(curr => !curr);
    }, []);

    // const handleBlur = useCallback(() => {
    //     setCurrentInert(curr => !curr);
    // }, []);
    
    return (
        <div className={ClassHelper.combine('ResponseItem', currentInert && 'inert', inProgress && 'in-progress')}
             ref={ref}>
            <RoleDisplay initialRole={response.role} inert={currentInert}
                         onRoleChange={currentInert ? undefined : handleRoleChange}/>
            {currentInert ? (<>
                <MessageDisplayInert initialMessage={response.message} />
                {/*<div className="fake-DeleteButtonPart message-button"></div>*/}
                {inProgress ? (
                    <div className="button-panel">
                        <div className="DeleteButtonPart message-button">
                            <IconButton icon={MdCancel} onClick={handleClickOnCancel} title="Cancel the progress" />
                        </div>
                    </div>
                ) : (<>
                    <div className="button-panel">
                        <div className="EditButtonPart message-button">
                            <IconButton icon={MdEdit} onClick={handleClickOnEdit} title="Edit the message" />
                        </div>
                        <div className="DeleteButtonPart message-button">
                            <IconButton icon={MdDelete} onClick={handleClickOnRemove} title="Delete the message" />
                        </div>
                    </div>
                </>)}
            </>) : (<>
                <MessageDisplay initialMessage={response.message} onMessageChange={handleMessageChange}/>
                <div className="button-panel">
                    <div className="PreviewButtonPart message-button">
                        <IconButton icon={MdPreview} onClick={handleClickOnPreview} title="Stop the edition, back on preview mode" />
                    </div>
                    <div className="DeleteButtonPart message-button">
                        <IconButton icon={MdDelete} onClick={handleClickOnRemove} title="Delete the message" />
                    </div>
                </div>
            </>)}
        </div>
    )
}

type InstructionPanelProps = {
    instruction?:string
    onInstructionChange?:FunctionNM<[string], void>
}

const InstructionPanel = ({instruction, onInstructionChange}: InstructionPanelProps) => {
    // useEffect(() => {
    //     document.addEventListener('mouseup', handleMouseUpOnDocument);
    // }, [handleMouseUpOnDocument]);

    const [savedInstructions, setSavedInstructions] = useState<WithDbAndId<OpenAiChatInstruction>[] | null>(null);

    const handleMessageChange = useCallback((newValue: string) => {
        onInstructionChange && onInstructionChange(newValue);
    }, [onInstructionChange]);

    useEffect(() => {
        apiFetch.get<{ data: WithDbAndId<OpenAiChatInstruction>[] }>('api/ai/chat/instructions?limit=5')
            .then(response => {
                if (response.ok) {
                    setSavedInstructions(response.payload.data);
                } else {
                    console.error(response.error);
                }
            })
    }, []);

    return (
        <div className={ClassHelper.combine('InstructionPanel')}>
            {savedInstructions === null ? (
                <span>Loading...</span>
            ) : savedInstructions.length === 0 ? (
                <div>
                    No saved instructions
                </div>
            ) : (
                <div>
                    {savedInstructions.map(instruction => (
                        <span key={instruction.id}>{instruction.name}</span>
                    ))}
                </div>
            )}

            <InstructionForm initialInstruction={instruction} onInstructionChange={onInstructionChange}/>
        </div>
    )
}
type InstructionFormProps = {
    initialInstruction?: string
    onInstructionChange?: FunctionNM<[string], void>
}

const InstructionForm = ({initialInstruction, onInstructionChange}: InstructionFormProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [editMode, setEditMode] = useState(false);

    const [currentInstruction, setCurrentInstruction] = useState<string>(() => initialInstruction ?? '');

    const {
        openModal: openCreateModal,
        renderFormModal: renderCreateFormModal,
    } = useFeatureFormModal({
        modalTitle: 'Create instructions',
        formFragment: InstructionFormFragment,
        formSchema: instructionFormSchema,
        onSave: handleCreate,
        modalContentSize: 600,
    });

    const wrapperOpenCreateModal = useCallback(() => {
        openCreateModal({content: currentInstruction});
    }, [openCreateModal, currentInstruction]);

    // useEffect(() => {
    //     document.addEventListener('mouseup', handleMouseUpOnDocument);
    // }, [handleMouseUpOnDocument]);

    const handleMessageChange = useCallback((newValue: string) => {
        setCurrentInstruction(newValue);
        onInstructionChange && onInstructionChange(newValue);
    }, [onInstructionChange]);

    // const {f} = useForm()

    return (
        <div className={ClassHelper.combine('InstructionForm', editMode && 'editMode')}
             ref={ref}>

            <>{renderCreateFormModal({} as any)}</>

            <MessageDisplay initialMessage={initialInstruction ?? ''} onMessageChange={handleMessageChange}
                            placeholderText="Enter the instructions here..."/>

            {/*<StringField f={} name="Name" label="" />*/}

            <ActionBarOnlyRight className="general-margin-top">
                <TextButton label="(WIP) Save for later..." className="primary elevation-z3"
                            onClick={() => wrapperOpenCreateModal()}/>
            </ActionBarOnlyRight>
            {/*<div className={}>*/}
            {/*    /!*<TextButton icon={MdAdd} label="Save for later..." className="primary elevation-z3"*!/*/}
            {/*    /!*            onClick={() => openCreateModal()} />*!/*/}
            {/*    <TextButton label="Save for later..." className="primary elevation-z3"*/}
            {/*                onClick={() => openCreateModal()} />*/}
            {/*</div>*/}

            {/*<RoleDisplay initialRole={response.role} inert={inert}*/}
            {/*             onRoleChange={inert ? undefined : handleRoleChange} />*/}
            {/*{inert ? (<>*/}
            {/*    <MessageDisplayInert initialMessage={response.message} />*/}
            {/*    <div className="fake-DeleteButtonPart"></div>*/}
            {/*</>) : (<>*/}
            {/*    <MessageDisplay initialMessage={response.message} onMessageChange={handleMessageChange} />*/}
            {/*    <div className="DeleteButtonPart">*/}
            {/*        <IconButton icon={MdDelete} onClick={handleClickOnRemove} />*/}
            {/*    </div>*/}
            {/*</>)}*/}
        </div>
    )
}
const handleCreate: OnSaveCallbackType<OpenAiChatInstructionModel, {
    dispatch: CrudPage2Dispatcher<OpenAiChatInstructionModel>
}> = async (data: OpenAiChatInstructionModel, {dispatch}) => {
    console.warn('handleCreate before', data);
    // const createdItemOrError = await crudService.createOne(data);
    // console.warn('handleCreate after', createdItemOrError);
    // if (createdItemOrError.ok) {
    //     dispatch({type:'reloadContent'});
    //     return null;
    // } else {
    //     dispatch({type:'reloadContent'});
    //     return {[GLOBAL_ERROR_KEY]:createdItemOrError.error};
    // }
    return null;
};

type OpenAiChatInstructionModel = WithDbAndId<OpenAiChatInstruction>;

const InstructionFormFragment = ({f, item}: CrudFormFragmentProps<OpenAiChatInstructionModel>) => {
    console.info('InstructionFormFragment render');

    return (
        <>
            {item.id && (
                <StringField f={f} name="id" label="ID" readonly={true}/>
            )}

            <StringField f={f} name="name" label="Name"/>
            <TextField f={f} name="content" label="Content"/>
            <BooleanField f={f} name="favorite" label="Favorite"/>
            {/*<StringField f={f} name="email" label="Email" />*/}
            {/**/}
            {/*<TextField f={f} name="note" label="Note" />*/}
            {/*<StringField f={f} name="jiraUsername" label="Jira Username" />*/}
            {/*<StringField f={f} name="githubUsername" label="GitHub Username" />*/}
            {/**/}
            {/*<BooleanField f={f} name="jensec" label="JenSec member" />*/}
            {/*<BooleanField f={f} name="active" label="Active" />*/}
        </>
    );
}

const instructionFormSchema = zod.object({
    name: zod.string(),
    content: zod.string(),
    favorite: zod.boolean(),
    // jiraUsername:zod.string(),
    // githubUsername:zod.string(),
    // jensec:zod.boolean(),
    // active:zod.boolean(),
    // ...ZodCrudModel_DEFAULT,
});


type RoleFormType = 'user' | 'assistant' | 'system';

type RoleDisplayProps = {
    initialRole: RoleFormType
    onRoleChange?: FunctionNM<[RoleFormType], void>
    inert?: boolean
}

const RoleDisplay = ({initialRole, onRoleChange, inert}: RoleDisplayProps) => {
    const [role, setRole] = useState<RoleFormType>(initialRole);
    const handleRoleChange = useCallback(() => {
        const newRole = role === 'user' ? 'assistant' : 'user';
        setRole(newRole);
        onRoleChange && onRoleChange(newRole);
    }, [onRoleChange, role]);

    return (
        <div className={ClassHelper.combine('RoleDisplay')} onClick={inert ? undefined : handleRoleChange}>{role}</div>
    )
}

type MessageDisplayProps = {
    initialMessage: string
    placeholderText?: string
    onMessageChange?: FunctionNM<[string], void>
    onBlur?: FunctionNM<[], void>
}

const MessageDisplay = ({initialMessage, placeholderText, onMessageChange, onBlur}: MessageDisplayProps) => {
    const ref = useRef<HTMLTextAreaElement>(null);
    // const ref = useRef<HTMLElement>(null);

    const [message, setMessage] = useState<string>(initialMessage);
    const handleMessageChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        const newMessage = e.target.value;
        setMessage(newMessage);
        onMessageChange && onMessageChange(newMessage);

        ref.current!.style.height = '1px';
        ref.current!.style.height = (ref.current!.scrollHeight) + 'px';
    }, [onMessageChange]);

    const handleBlur = useCallback(() => {
        onBlur && onBlur();
    }, [onBlur]);

    // init method
    useEffect(() => {
        ref.current!.style.height = '1px';
        // Required for Firefox. Actually calling scrollHeight twice is also working, but feels odd
        ref.current!.style.overflowY = 'scroll';
        ref.current!.style.height = ref.current!.scrollHeight + 'px';
        ref.current!.style.overflowY = 'hidden';
    }, []);

    return (
        <div className={ClassHelper.combine('MessageDisplay')}>
            <textarea ref={ref} value={message} rows={1}
                      onChange={handleMessageChange} onBlur={handleBlur}
                      placeholder={placeholderText ?? 'Enter a message here...'}/>
        </div>
    )
}

type MessageDisplayInertProps = {
    initialMessage: string
    onClick?: () => void
}

const MessageDisplayInert = ({initialMessage, onClick}: MessageDisplayInertProps) => {
    const handleClick = useCallback(() => {
        console.info('[Debug] MessageDisplayInert#handleClick');
        onClick && onClick();
    }, [onClick]);

    // // init method
    // useEffect(() => {
    //     ref.current!.style.height = '1px';
    //     ref.current!.style.height = (ref.current!.scrollHeight) + 'px';
    // }, []);

    return (
        <div className={ClassHelper.combine('MessageDisplay', 'inert')} onClick={handleClick}>
            <Markdown className="fake-textarea" value={initialMessage} />
            {/*<div ref={ref} className="fake-textarea">{initialMessage}</div>*/}
        </div>
    )
}
