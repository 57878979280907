import {ReactNode} from "react";
import {MdOpenInNew} from "react-icons/md";
import {ClassHelper} from "../../../commons/helpers/ClassHelper";
import {CrudModel} from '../../../commons/types/CrudModel';
import {ExternalLinkIconButton} from "../../components/ExternalLinkIconButton";
import {ColDefType} from "../ColDefType";

//TODO create variant for safe HTML
import {CrudTableColumnDefinition} from "../CrudTableColumnDefinition";
import {NullCellValue} from "../NullCellValue";

export class ExternalLinkColumnDef<T extends CrudModel> implements CrudTableColumnDefinition<T> {
	type:ColDefType = 'string';

	defaultVisibility:boolean;
	sortable:boolean;
	fixed:boolean;
	headerClassName:string;
	cellClassName:string;
	header:string

	constructor(public name:string,
	            private rowToLink:((row:T) => string | null),
	            opts?:{ defaultVisibility?:boolean, cellClassName?:string }) {
		this.header = '';
		this.defaultVisibility = opts?.defaultVisibility ?? true;
		this.headerClassName = 'ExternalLinkColumnDef';
		this.cellClassName = ClassHelper.combine('ExternalLinkColumnDef td-icon', opts?.cellClassName);
		this.sortable = false;
		this.fixed = false;
	}

	nowrap():this {
		this.cellClassName = 'nowrap';
		return this;
	}

	computeCell(row:T):ReactNode {
		const url = this.rowToLink(row);
		if (!url) {
			return <NullCellValue />;
		}
		return (
			<ExternalLinkIconButton url={url} icon={MdOpenInNew} />
			// <a href={url} target="_blank" rel="noopener noreferrer">
			// 	<MdOpenInNew />
			// </a>
		);
	}
}
