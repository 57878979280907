import * as React from "react";
import {Dispatch, ReactNode} from "react";
import {ClassHelper} from "../../commons/helpers/ClassHelper";
import {DEFAULT_LOADING_STATE, QueryResponse} from "../../commons/hooks/useGetQuery";
import {useReducerWithCleanup} from "../../commons/hooks/useReducerWithCleanup";
import {CrudModel} from "../../commons/types/CrudModel";
import {ActionBar} from "../components/ActionBar";
import {PageContent} from "../components/layout/PageContent";
import {extractTotals} from "../crud/CrudApiService2";
import {CrudApiService2, QueryListBody} from "../crud/CrudApiService2";
import {PaginationInfo, PaginationInfo_DEFAULT} from "../crud/features/PaginationFeature";
import {QueryData, QueryData_DEFAULT} from "../crud/QueryData";
import {useQueryCrudService} from "../crud/useQueryCrudService";
import {ColumnSortInfo, ColumnSortInfo_DEFAULT} from "../table/ColumnSortInfo";
import {SelectionFullInfo} from "../table/selection/SelectionInfo";
import "./CrudPage2.scss";

type State<T> = {
	queryData:QueryData
	filters:string[]
	order:ColumnSortInfo
	selection:SelectionFullInfo<T>
	pagination:PaginationInfo
	columnVisibility:string[]
	/** When we know there is something long happening, like batch operations */
	forceLoading:boolean

	// storing the initial state to be able to reset them
	initialOrder:ColumnSortInfo
	initialFilters:string[]
}
const State_DEFAULT:State<any> = {
	queryData:QueryData_DEFAULT,
	order:ColumnSortInfo_DEFAULT,
	filters:[],
	selection:[],
	pagination:PaginationInfo_DEFAULT,
	columnVisibility:[],
	forceLoading:false,

	initialOrder:ColumnSortInfo_DEFAULT,
	initialFilters:[],
}

export type Cursor = { startAtDocId:string, startAfterDocId:undefined, endBeforeDocId:undefined } |
	{ startAtDocId:undefined, startAfterDocId:string, endBeforeDocId:undefined } |
	{ startAtDocId:undefined, startAfterDocId:undefined, endBeforeDocId:string };

type Action<T> = { type:'reloadContent' }
	| { type:'setLoading' }
	| { type:'setSelection', selection:SelectionFullInfo<T> }
	| { type:'setPagination', pagination:PaginationInfo }
	| { type:'setPaginationCursor', pagination:PaginationInfo, cursor:Cursor }
	| { type:'setColumnVisibility', columnVisibility:string[] }
	| { type:'setOrder', order:{ columnId:string } }
	| { type:'resetFilters' }
	/**
	 * addFilters and removeFields are based on one filter per fieldName
	 * addFilters if the field already exist will replace it
	 */
	| { type:'setFilters', filters:string[], order?:{ columnId:string, direction:'asc' | 'desc' } }
	/**
	 * addFilters: will remove the corresponding existing field filter if existing
	 */
	| { type:'changeFilters', addFilters?:string[], removeFields?:string[] }

export type CrudPage2State<T = any> = State<T>;
export type CrudPage2Dispatcher<T = any> = Dispatch<Action<T>>;

type CrudPage2Reducer<T = any> = (state:State<T>, action:Action<T>) => State<T>;

function serializeOrder(order:ColumnSortInfo):string {
	const orderSerialized = order.columnId === '' ? '' : `${order.sortDirection === 'desc' ? '-' : ''}${order.columnId}`
	return orderSerialized;
}

export const action_resetFilter = (dispatch:CrudPage2Dispatcher) => {
	dispatch({type:'resetFilters'})
}

export const action_resetSelection = (dispatch:CrudPage2Dispatcher) => {
	dispatch({type:'setSelection', selection:[]})
}

export const action_reloadContent = (dispatch:CrudPage2Dispatcher) => {
	dispatch({type:'reloadContent'})
}

function reducer<T>(state:State<T>, action:Action<T>):State<T> {
	switch (action.type) {
		case 'reloadContent':
			return {
				...state,
				selection:[],
				forceLoading:false,
				queryData:{
					...state.queryData,
					cacheId:state.queryData.cacheId + 1
				}
			};
		case 'setLoading':
			return {
				...state,
				selection:[],
				forceLoading:true,
			};
		case 'setSelection':
			return {...state, selection:action.selection};
		case 'setColumnVisibility':
			return {...state, columnVisibility:action.columnVisibility};
		case 'setPagination':
			return {
				...state,
				pagination:action.pagination,
				selection:[],
				queryData:{
					...state.queryData,
					// erase the potentially existing one
					cursor:null,
					limit:action.pagination.pageSize,
					offset:action.pagination.pageSize * (action.pagination.pageNo - 1),
					cacheId:state.queryData.cacheId + 1
				}
			};
		case 'setPaginationCursor':
			return {
				...state,
				selection:[],
				pagination:action.pagination,
				queryData:{
					...state.queryData,
					limit:action.pagination.pageSize,
					cursor:action.cursor,
					offset:action.pagination.pageSize * (action.pagination.pageNo - 1),
					cacheId:state.queryData.cacheId + 1
				}
			};
		case 'setOrder':
			let newOrder:ColumnSortInfo;
			if (state.order.columnId === action.order.columnId) {
				if (state.order.sortDirection === 'asc') {
					newOrder = {columnId:action.order.columnId, sortDirection:'desc'}
				} else {
					newOrder = {columnId:action.order.columnId, sortDirection:'asc'}
					// newOrder = ColumnSortInfo_NONE;
					// newOrder = defaultOrder;
				}
			} else {
				newOrder = {columnId:action.order.columnId, sortDirection:'asc'};
			}

			const orderSerialized = serializeOrder(newOrder);
			return {
				...state,
				selection:[],
				order:newOrder,
				queryData:{
					...state.queryData,
					order:orderSerialized,
					cacheId:state.queryData.cacheId + 1
				}
			};
		case 'resetFilters':
			const initialOrderSerialized = serializeOrder(state.initialOrder);

			return {
				...state,
				filters:state.initialFilters,
				order:state.initialOrder,
				// filters: [],
				queryData:{
					...state.queryData,
					filters:state.initialFilters.join(','),
					order:initialOrderSerialized,
					cacheId:state.queryData.cacheId + 1
				}
			}
		case 'setFilters':
			const setFilters_newState = {
				...state,
				selection:[],
				filters:action.filters,
				queryData:{
					...state.queryData,
					filters:action.filters.join(','),
					cacheId:state.queryData.cacheId + 1
				}
			};

			if (action.order) {
				let newOrderForFilters:ColumnSortInfo = {
					columnId:action.order.columnId,
					sortDirection:action.order.direction
				};

				const orderSerializedForFilters = serializeOrder(newOrderForFilters);
				setFilters_newState.order = newOrderForFilters;
				setFilters_newState.queryData.order = orderSerializedForFilters;
			}

			return setFilters_newState;
		case 'changeFilters':
			return reducer_changeFilters(state, action);
	}
}

function reducer_changeFilters<T>(state:State<T>, action:Action<T> & { type:'changeFilters' }):State<T> {
	let desiredFilters = state.filters.concat();
	const fieldsToRemove = new Set();
	if (action.removeFields) {
		action.removeFields.forEach(f => fieldsToRemove.add(f));
	}
	if (action.addFilters) {
		action.addFilters.forEach(f => {
			const field = f.split(':')[0];
			fieldsToRemove.add(field);
		});
	}
	if (fieldsToRemove.size > 0) {
		desiredFilters = desiredFilters.filter(f => {
			const field = f.split(':')[0];
			return !fieldsToRemove.has(field);
		});
	}
	if (action.addFilters) {
		action.addFilters.forEach(f => desiredFilters.push(f));
	}

	const newState = {
		...state,
		selection:[],
		filters:desiredFilters,
		queryData:{
			...state.queryData,
			filters:desiredFilters.join(','),
			cacheId:state.queryData.cacheId + 1
		}
	};

	return newState;
}

function initState<T>(partialState:Partial<State<T>> | undefined):State<T> {
	//TODO find some information in the localStorage / url fragment
	const state = partialState ? Object.assign({}, State_DEFAULT, partialState) : State_DEFAULT;
	state.initialFilters = !state.filters ? [] : state.filters;
	state.initialOrder = Object.assign({}, state.order);
	return state;
}

type CrudPage2Props<T extends CrudModel> = {
	pageId:string
	pageClass?:string
	crudService:CrudApiService2<T>
	layout:CrudPageLayout<T>
	others?:ReactNode
	initialState?:Partial<CrudPage2State<T>>
}

// export const CrudPage2 = <T extends CrudModel, F>({
export const CrudPage2 = <T extends CrudModel>({
	                                               // pageId,
	                                               pageClass,
	                                               crudService,
	                                               layout,
	                                               initialState,
	                                               others,
                                               }:CrudPage2Props<T>) => {
	const [
		state, dispatch
	] = useReducerWithCleanup<CrudPage2Reducer<T>>(reducer, initialState, initState);

	// const {queryData, order, filters, selection, pagination, forceLoading} = state;
	const {queryData, forceLoading} = state;

	let itemsData = useQueryCrudService(queryData, crudService);
	if (forceLoading) {
		itemsData = DEFAULT_LOADING_STATE;
	}
	// const {
	// 	filteredTotalResults,
	// 	totalResults
	// } = extractTotals(itemsData);
	extractTotals(itemsData);

	// const Layout = layout(state as State<F>, dispatch);

	return (
		<PageContent className={ClassHelper.combine('CrudPage2', pageClass)} withoutPadding={true} withShadow={true}>
			{/*<Layout />*/}
			{others}
			{layout(state as State<T>, itemsData, dispatch)}
			{/*<pre>*/}
			{/*	itemsData.loading={itemsData.loading ? 'true' : 'false'}<br />*/}
			{/*	queryData.cacheId={queryData.cacheId}<br />*/}
			{/*	filteredTotalResults={filteredTotalResults}<br />*/}
			{/*	totalResults={totalResults}<br />*/}
			{/*	/!*{JSON.stringify(itemsData, null, 3)}*!/*/}
			{/*</pre>*/}
		</PageContent>
	)
}

export type CrudPageLayout<T extends CrudModel> = (
	state:CrudPage2State<T>,
	itemsData:QueryResponse<QueryListBody<T>>,
	dispatch:CrudPage2Dispatcher<T>
) => JSX.Element;

type CrudPageLayoutColumnProps = {
	className?:string
	top?:ReactNode
	middle?:ReactNode
	bottom?:ReactNode
}
export const CrudPageLayoutColumn = ({className, top, middle, bottom}:CrudPageLayoutColumnProps) => {
	return (
		<div className={ClassHelper.combine('CrudPageLayoutColumn', className)}>
			<div className="Top">{top}</div>
			<div className="Middle">{middle}</div>
			<div className="Bottom">{bottom}</div>
		</div>
	)
}

type CrudPageLayoutBasicProps = {
	pageName:string
	className?:string
	contentAboveTable?:ReactNode
	topLeftActions?:ReactNode
	topRightActions?:ReactNode
	table?:ReactNode
	bottomLeftAction?:ReactNode
	bottomRightAction?:ReactNode
	popupPart?:ReactNode
}
export const CrudPageLayoutBasic = ({
	                                    pageName,
	                                    className,
	                                    contentAboveTable,
	                                    topLeftActions,
	                                    topRightActions,
	                                    table,
	                                    bottomLeftAction,
	                                    bottomRightAction,
	                                    popupPart,
                                    }:CrudPageLayoutBasicProps) => {
	// console.info('CrudPageLayoutBasic render');
	return (
		<div className={ClassHelper.combine('CrudPageLayoutBasic', className)}>
			{popupPart}
			<div className="CrudTopActionBar">
				<div className="Title">
					{pageName}
				</div>
				<ActionBar
					leftChildren={topLeftActions}
					rightChildren={topRightActions}
				/>
			</div>
			{contentAboveTable && (
				contentAboveTable
			)}
			<div className="Table">{table}</div>
			<div className="CrudBottomActionBar">
				<div className="part">{bottomLeftAction}</div>
				<div className="part">{bottomRightAction}</div>
			</div>
		</div>
	)
}
