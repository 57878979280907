import * as React from 'react';
import {useAuth} from '../../../../commons/hooks/useAuth';
import {SuccessPanel, WarningPanel} from '../../../../framework/components/color/Panel';
import {PageContent} from "../../../../framework/components/layout/PageContent";

export const HomePage = () => {
	const {authInfo} = useAuth();

	return (
		<PageContent>
			<div>
				{authInfo?.isAdmin ? (<>
					<SuccessPanel>
						You are an admin of the application.
					</SuccessPanel>
					<br />
				</>) : (
					authInfo?.isUser ? (<>
						<SuccessPanel>
							You are a user of the application.
						</SuccessPanel>
						<br />
					</>) : (
						<div>
							<WarningPanel>
								You are not part of the users of this application.
							</WarningPanel>
							<br />
							<div>
								Contact an admin to get your account verified so that you can have access to the
								features.
							</div>
						</div>
					)
				)}
			</div>
		</PageContent>
	);
}

//TODO add possibility for non-user to send a message about why they would like to get access
