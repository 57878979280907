import {CrudTableColumnDefinition} from "./CrudTableColumnDefinition";

type Props = {
	columnDef:CrudTableColumnDefinition<any>
}

export const ActionTableHeader = ({columnDef}:Props) => {
	const thClasses = [];
	columnDef.headerClassName && thClasses.push(columnDef.headerClassName);

	return (
		<th className={thClasses.join(' ')}
		    title={columnDef.headerTitle ? columnDef.headerTitle : undefined}>
			<div className="header">&nbsp;</div>
		</th>
	);
}
