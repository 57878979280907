import {useCallback, useEffect, useState} from "react";

// type ProviderResponse_Error = { error:string, payload:null };
// type ProviderResponse_Ok<T> = { error:null, payload:T };
// export type ProviderResponse<T> = ProviderResponse_Error | ProviderResponse_Ok<T>;
// export type GetQueryProvider<T> = () => Promise<ProviderResponse<T>> | null

type ProviderResponse_Error = { ok:false, error:string, errorId?:string };
type ProviderResponse_Ok<T> = { ok:true, payload:T };
export type ProviderResponse<T> = ProviderResponse_Error | ProviderResponse_Ok<T>;
// type GetQueryProvider<T> = () => Promise<ProviderResponse<T>> | null
export type GetQueryProviderResult<T> = T | Promise<ProviderResponse<T>> | null
export type GetQueryProvider<T> = () => GetQueryProviderResult<T>

type Response_Loading = { loading:true, error:null, errorId:null, data:null };
type Response_Error = { loading:false, error:string, errorId:string | null, data:null };
type Response_Ok<T> = { loading:false, error:null, errorId:null, data:T };
export type QueryResponse<T> = Response_Loading | Response_Error | Response_Ok<T>;

export const DEFAULT_LOADING_STATE:QueryResponse<any> = {loading:true, error:null, errorId:null, data:null};

const useGetQueryState = <T>() => {
	const [triState, setTristate] = useState<QueryResponse<T>>(DEFAULT_LOADING_STATE);

	const setError = useCallback((error:string, errorId?:string) => setTristate({loading:false, error, errorId:errorId || null, data:null}), []);
	const setValues = useCallback((values:T) => setTristate({loading:false, error:null, errorId:null, data:values}), []);
	const resetToLoading = useCallback(() => setTristate(DEFAULT_LOADING_STATE), []);

	const {loading, error, errorId, data} = triState;

	return {loading, error, errorId, data, setError, setValues, resetToLoading};
}

/**
 * @param resultProvider Must be a memorized query
 */
export const useGetQuery = <T>(resultProvider:GetQueryProvider<T>):QueryResponse<T> => {
	const {loading, error, errorId, data, setError, setValues, resetToLoading} = useGetQueryState<T>();

	useEffect(() => {
		// no effect the first time as it's the same constant
		resetToLoading();

		// to prevent memory leak when component is unmounted
		let sub = true;

		const p = resultProvider();
		if (p === null) {
			// e.g. allow features to be disabled by returning null
			return;
		}

		// to support direct return of value
		if (!(p instanceof Promise)) {
			setValues(p);
			return;
		}

		// p.then(({error, payload}) => {
		p.then((response:ProviderResponse<T>) => {
			if (sub) {
				if (response.ok) {
					setValues(response.payload);
				} else {
					setError(response.error, response.errorId);
				}
			}
		})
		return function cleanup() {
			sub = false;
		};
	}, [resultProvider, setError, setValues, resetToLoading]);

	return {loading, error, errorId, data} as QueryResponse<T>;
}
