import {FunctionNM} from "@commons/types/FunctionNM";
import {useCallback} from 'react';
import {ClassHelper} from "../../commons/helpers/ClassHelper";

import "./ToggleGroup.scss";

type ToggleItem<T = any> = {
	value:T
	className?:string
	icon:FunctionNM<any, JSX.Element>
	title?:string
	activeClassName?:string
}

export type ToggleGroupProps<T> = {
	label:string
	value:T
	items:ToggleItem<T>[]
	onChange?:FunctionNM<[T], any>
	className?:string
	title?:string
	id?:string
}

export const ToggleGroup = <T, >({
	                                 label,
	                                 value,
	                                 onChange,
	                                 items,
	                                 className,
	                                 title,
	                                 id,
                                 }:ToggleGroupProps<T>) => {

	const handleClick = useCallback((newValue:T) => {
		onChange && onChange(newValue);
	}, [onChange]);

	return (
		<div className={ClassHelper.combine('ToggleGroup', className)}
		     id={id}
		     title={title ? title : undefined}>
			{label}
			{items.map((item, index) => (
				// not expected to have a dynamic list of items, hence the key=index
				<ToggleGroupItem key={index} item={item} selected={item.value === value} onClick={handleClick} />
			))}
		</div>
	);
}

type ToggleGroupItemProps<T> = {
	selected:boolean
	item:ToggleItem<T>
	onClick?:FunctionNM<[T], any>
}

const ToggleGroupItem = <T, >({selected, item, onClick}:ToggleGroupItemProps<T>) => {
	const handleClick = useCallback(() => {
		onClick && onClick(item.value);
	}, [item.value, onClick]);

	const Icon = item.icon;
	return (
		<div className={ClassHelper.combine('ToggleGroupItem', item.className, selected && item.activeClassName)}
		     onClick={handleClick}
		     title={item.title ? item.title : undefined}>
			<>
				{item.value}
				{Icon && <Icon />}
			</>
		</div>
	)
};
