export class GitHubUserHelper {
	static ghIdToAvatarUrl(ghId:number):string;
	static ghIdToAvatarUrl(ghId:undefined | null):null;
	static ghIdToAvatarUrl(ghId:number | undefined | null):string | null {
		if (ghId === undefined  || ghId === null) {
			return null;
		}
		return `https://avatars.githubusercontent.com/u/${ghId}?v=4`
	}

	static ghIdToAvatarUrlOrDefault(ghId:number | undefined | null):string {
		if (ghId === undefined  || ghId === null) {
			ghId = 0;
		}
		return `https://avatars.githubusercontent.com/u/${ghId}?v=4`
	}
}
