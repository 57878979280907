export class JsonHelper {
	static stringifyOneLevel(object:any):string {
		let firstCall = true;
		return JSON.stringify(object, (k, v) => {
			if (firstCall === true) {
				firstCall = false;
				return v;
			}
			if ((v !== null || v instanceof Date) && (typeof v === 'object' || Array.isArray(v))) {
				return undefined;
			}
			return v
		})
	}
}
