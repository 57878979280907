import {
	TaskSecurityType_ADVISORY_DATA,
	TaskSecurityType_ASSIGN, TaskSecurityType_CERT_ATTENTION,
	TaskSecurityType_CREDIT, TaskSecurityType_DISCLOSURE_DEADLINE,
	TaskSecurityType_LABEL,
	TaskSecurityType_NON_CERT_COMMENT,
	TaskSecurityType_REPRODUCTION, TaskSecurityType_REVIEW,
	TaskSecurityType_TRIAGE
} from "./TaskJiraSecurity";

export type Counter = {
	total:number
	// + others that are custom
}

export const COUNTER_KEY_PREFIX_RPU_TASK = 'rpu-task_';
export const COUNTER_KEY_PREFIX_SECURITY_TASK = 'security-task_';

export const COUNTER_KEY_RPU_HOSTING_AUDIT = COUNTER_KEY_PREFIX_RPU_TASK + 'hosting-audit';

export const COUNTER_KEY_SECURITY_TASK_VALUE_TRIAGE = COUNTER_KEY_PREFIX_SECURITY_TASK + TaskSecurityType_TRIAGE;
export const COUNTER_KEY_SECURITY_TASK_VALUE_LABEL = COUNTER_KEY_PREFIX_SECURITY_TASK + TaskSecurityType_LABEL;
export const COUNTER_KEY_SECURITY_TASK_VALUE_REPRODUCTION = COUNTER_KEY_PREFIX_SECURITY_TASK + TaskSecurityType_REPRODUCTION;
export const COUNTER_KEY_SECURITY_TASK_VALUE_ASSIGN = COUNTER_KEY_PREFIX_SECURITY_TASK + TaskSecurityType_ASSIGN;
export const COUNTER_KEY_SECURITY_TASK_VALUE_CREDIT = COUNTER_KEY_PREFIX_SECURITY_TASK + TaskSecurityType_CREDIT;
export const COUNTER_KEY_SECURITY_TASK_VALUE_ADVISORY_DATA = COUNTER_KEY_PREFIX_SECURITY_TASK + TaskSecurityType_ADVISORY_DATA;
export const COUNTER_KEY_SECURITY_TASK_VALUE_REVIEW = COUNTER_KEY_PREFIX_SECURITY_TASK + TaskSecurityType_REVIEW;
export const COUNTER_KEY_SECURITY_TASK_VALUE_NON_CERT_COMMENT = COUNTER_KEY_PREFIX_SECURITY_TASK + TaskSecurityType_NON_CERT_COMMENT;
export const COUNTER_KEY_SECURITY_TASK_VALUE_CERT_ATTENTION = COUNTER_KEY_PREFIX_SECURITY_TASK + TaskSecurityType_CERT_ATTENTION;
export const COUNTER_KEY_SECURITY_TASK_VALUE_DISCLOSURE_DEADLINE = COUNTER_KEY_PREFIX_SECURITY_TASK + TaskSecurityType_DISCLOSURE_DEADLINE;

export const ALL_COUNTER_KEYS = [
	COUNTER_KEY_RPU_HOSTING_AUDIT,
	COUNTER_KEY_SECURITY_TASK_VALUE_TRIAGE,
	COUNTER_KEY_SECURITY_TASK_VALUE_LABEL,
	COUNTER_KEY_SECURITY_TASK_VALUE_REPRODUCTION,
	COUNTER_KEY_SECURITY_TASK_VALUE_ASSIGN,
	COUNTER_KEY_SECURITY_TASK_VALUE_CREDIT,
	COUNTER_KEY_SECURITY_TASK_VALUE_ADVISORY_DATA,
	COUNTER_KEY_SECURITY_TASK_VALUE_REVIEW,
	COUNTER_KEY_SECURITY_TASK_VALUE_NON_CERT_COMMENT,
	COUNTER_KEY_SECURITY_TASK_VALUE_CERT_ATTENTION,
	COUNTER_KEY_SECURITY_TASK_VALUE_DISCLOSURE_DEADLINE,
]

export type CounterForTask = Counter & {
	notDone:number
	done:number
	// assigned:number
	// unassigned:number
}

export const createCounterForTask = ():CounterForTask => ({total:0, notDone:0, done:0});
export const mergeCounterForTask = (a:CounterForTask, b:Partial<CounterForTask>) => ({
	total:a.total + (b.total || 0),
	notDone:a.notDone + (b.notDone || 0),
	done:a.done + (b.done || 0),
})
