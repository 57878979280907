import {FunctionNM} from "@commons/types/FunctionNM";
import * as React from "react";
import {ReactNode} from "react";
import {NavLink} from "react-router-dom";
import {ClassHelper} from "../../../../commons/helpers/ClassHelper";
import {NeutralBadge, PrimaryBadge} from "../../../../framework/components/color/Badge";

import './SideNavLink.scss'

type SideNavLinkProps = {
	// icon: NonNullable<React.ReactNode>
	icon?:FunctionNM<any, JSX.Element>
	afterIcon?:FunctionNM<any, JSX.Element>
	name:string
	to:string
	disabled?:boolean
	className?:string
	badge?:number | string
}

export const SideNavLink = ({icon, afterIcon, name, to, disabled, className, badge}:SideNavLinkProps) => {
	const classes = ClassHelper.combine('SideNavLink', disabled && 'disabled', className);
	const Icon = icon;
	const AfterIcon = afterIcon;
	const content:ReactNode = <>
		{Icon && <Icon />}
		<div className="label">
			{name}
		</div>
		{AfterIcon && <AfterIcon />}
	</>;

	return (
		(!disabled ? (
			<NavLink to={to} className={classes}>
				{content}
				{!!badge && ( // using !! to avoid 0 to be displayed without badge
					<PrimaryBadge value={badge} />
				)}
			</NavLink>
		) : (
			<div className={classes}>
				{content}
				{!!badge && (
					<NeutralBadge value={badge} />
				)}
			</div>
		))
	);
};
