import {useEffect, useRef} from 'react';

export const useRefForSpaceEnter = () => {
	const ref = useRef<any>(null);
	useEffect(() => {
		if (!ref || !ref.current) {
			return;
		}
		const curr = ref.current;

		const onKeyDown = (e:KeyboardEvent) => {
			if (e.key === ' ') {
				curr.classList.add('active');
			} else if (e.key === 'Enter') {
				curr.click();
			}
		};
		const onKeyUp = (e:KeyboardEvent) => {
			if (e.key === ' ') {
				curr.click();
			}
		};
		curr.addEventListener('keydown', onKeyDown);
		curr.addEventListener('keyup', onKeyUp);

		return () => {
			curr?.removeEventListener('keydown', onKeyDown);
			curr?.removeEventListener('keyup', onKeyUp);
		}
	}, []);
	return ref;
}
